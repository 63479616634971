import {
  Button,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { appStyles } from "../../App.Styles";
import { VariableScopeType } from "../../Common/Enums/VariableScopeType";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IVariableValue from "../../Common/Interfaces/IVariableValue";
import Loading from "../Loading/Loading";
import VariableContext from "./Variable.Context";
import VariableValue from "./VariableValue";
import VariableValueContext from "./VariableValue.Context";

interface IProps extends IComponentProps {
  context: VariableContext;
}

const Variable: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    const onValueDelete = (index: number): void => {
      context.valueContexts.splice(index, 1);
    };

    const addValue = (): void => {
      let value: IVariableValue = {
        scopeType: VariableScopeType.Tenant,
        scope: "",
        value: "",
        editable: true,
      };

      context.valueContexts.push(new VariableValueContext(value));
    };

    return context.redirect ? (
      <Redirect to="/Variables" />
    ) : (
      <React.Fragment>
        <Typography align="center" variant="h4">
          {context.editingId ? "Edit" : "Create"} a variable
        </Typography>
        {context.loading ? (
          <Loading fullscreen={false} />
        ) : (
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={context.onSubmit}
          >
            <Paper className={classes.paper}>
              <TextField
                autoComplete="off"
                fullWidth
                autoFocus
                id="variable-name"
                disabled={!!context.editingId}
                label="Name"
                onChange={(e) => context.nameField.onChange(e.target.value)}
                placeholder="Enter a name"
                value={context.nameField.value}
                error={context.nameField.hasError}
                variant="filled"
              />
              <p>{context.nameField.error}</p>
            </Paper>

            <Grid container>
              <Grid item xs={12}>
                <Typography align="center" variant="h5">
                  Scopes
                </Typography>

                {context.valueContexts.map((valueContext, index) => {
                  return (
                    <Paper
                      className={`${classes.marginTop} ${classes.paper}`}
                      key={index}
                    >
                      <VariableValue
                        valueContext={valueContext}
                        index={index}
                        onDelete={() => onValueDelete(index)}
                        onValueChange={() => context.validateForm()}
                        endpoints={context.endpoints}
                        appContext={appContext}
                        classes={classes}
                        secrets={context.secrets}
                      ></VariableValue>
                    </Paper>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Button
                  id="add-value"
                  variant={"text"}
                  fullWidth={true}
                  onClick={addValue}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormHelperText className={classes.formError}>
                  {context.form.error}
                </FormHelperText>
                <FormHelperText className={classes.formError}>
                  {context.saveError}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.marginTop}
                type="submit"
                variant="contained"
                id="variable-save"
                color="primary"
                size="large"
                disabled={
                  context.form.validate() && context.form.hasError === true
                }
                fullWidth
              >
                {
                  <React.Fragment>
                    <SaveIcon fontSize="large" /> Save
                  </React.Fragment>
                }
              </Button>
            </Grid>
          </form>
        )}
      </React.Fragment>
    );
  }
);

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps & RouteComponentProps> {
    render() {
      const context = new VariableContext(
        this.props.appContext,
        (this.props.match.params as any)["name"]
      );

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withRouter(withContext(Variable))
);
