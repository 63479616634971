import { Button, Snackbar, Typography, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Alert } from "@material-ui/lab";
import { observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import {
  BeforeCapture,
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import { appStyles } from "../../App.Styles";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IDictionary from "../../Common/Interfaces/IDictionary";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointService from "../../Common/Services/Endpoint.Service";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import RepositoriesService from "../Repositories/Repositories.Service";
import TaskList from "./TaskList";
import TasksContext from "./TasksContext";

interface IProps extends IComponentProps {
  tasks: TaskContext[];
  editable: boolean;
  editInitialLoad: boolean;
}

@observer
class Tasks extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this._context.loading = true;
    EndpointService.GetEndpoints(props.appContext)
      .then((endpoints) => {
        this._context.endpoints = endpoints;
      })
      .finally(() => (this._context.loading = false));

    this._context.loading = true;
    new RepositoriesService()
      .GetRepositories(props.appContext)
      .then((repositories) => {
        this._context.endpoints = this._context.endpoints
          .concat(
            repositories
              .map((r) => r.endpoints)
              .filter((x) => x !== undefined) as unknown as IEndpoint[]
          )
          .flat();
      })
      .finally(() => (this._context.loading = false));
  }

  @observable
  public _context = new TasksContext(
    this.props.tasks.length !== 0 ? this.props.tasks[0] : undefined,
    this.props.appContext
  );

  @observable
  public dragInProgress: boolean = false;

  @observable
  public dropDisabled: boolean = false;

  @observable
  public showError: boolean = false;

  @observable
  public reorderError: string = "";

  private addTask = () => {
    const newTask = new TaskContext(
      this.props.appContext,
      this.props.editInitialLoad
    );
    this._context.activeTask = newTask;
    this.props.tasks.push(newTask);
  };

  public onBeforeCapture = (beforeCapture: BeforeCapture) => {
    this.dragInProgress = true;
    this.showError = false;
  };

  public onDragEnd = (result: DropResult) => {
    this.dragInProgress = false;
    this.dropDisabled = false;
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (!this.reorderValid(source.index, destination.index)) {
      return;
    }
    this.handleReorder(source.index, destination.index);
  };

  public moveTask(index: number, up: boolean) {
    let endIndex = up ? index - 1 : index + 1;
    if (this.reorderValid(index, endIndex)) {
      this.handleReorder(index, endIndex);
    }
  }

  public handleReorder(startIndex: number, newIndex: number) {
    let moved = this.props.tasks.splice(startIndex, 1);
    this.props.tasks.splice(newIndex, 0, moved[0]);
    let indexChanges: IDictionary<number> = {};
    indexChanges[startIndex] = newIndex;
    if (startIndex > newIndex) {
      for (var j = newIndex; j < startIndex; j++) {
        indexChanges[j] = j + 1;
      }
    }
    if (startIndex < newIndex) {
      for (var k = startIndex + 1; k <= newIndex; k++) {
        indexChanges[k] = k - 1;
      }
    }

    for (var i = 0; i < this.props.tasks.length; i++) {
      this.props.tasks[i].updateReferencesToTasks(indexChanges);
    }
  }

  private handleClose(event?: React.SyntheticEvent, reason?: string) {
    this.showError = false;
  }

  public reorderValid(startIndex: number, endIndex: number) {
    let movedUp = endIndex < startIndex;
    if (
      movedUp &&
      endIndex <= this.props.tasks[startIndex].minimumReferencedIndex()
    ) {
      this.reorderError = `This task can not preceed Task ${
        this.props.tasks[startIndex].minimumReferencedIndex() + 1
      }`;
      this.showError = true;
      return false;
    }
    if (!movedUp) {
      let minimumReferencingTask = this.props.tasks.length;
      for (var i = startIndex; i < this.props.tasks.length; i++) {
        if (this.props.tasks[i].refersToTask(startIndex)) {
          minimumReferencingTask = i;
        }
      }
      if (endIndex >= minimumReferencingTask) {
        this.reorderError = `This task can not follow Task ${
          minimumReferencingTask + 1
        }`;
        this.showError = true;
        return false;
      }
    }
    return true;
  }

  public render() {
    return (
      <React.Fragment>
        <Typography align="center" variant="h5">
          Tasks
        </Typography>
        <Snackbar
          id="task-reorder-error"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={this.showError}
          onClose={() => this.handleClose()}
        >
          <Alert onClose={() => this.handleClose()} severity="error">
            {this.reorderError}
          </Alert>
        </Snackbar>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onBeforeCapture={this.onBeforeCapture}
        >
          <Droppable droppableId="taskList">
            {(
              provided: DroppableProvided,
              snapshot: DroppableStateSnapshot
            ) => (
              <TaskList
                provided={provided}
                snapshot={snapshot}
                editable={this.props.editable}
                editInitialLoad={this.props.editInitialLoad}
                tasks={this.props.tasks}
                appContext={this.props.appContext}
                tasksContext={this._context}
                dragInProgress={this.dragInProgress}
                dropDisabled={this.dropDisabled}
                moveTask={(index, up) => this.moveTask(index, up)}
              />
            )}
          </Droppable>
        </DragDropContext>
        {this.props.editable && (
          <Button
            id="add-task"
            variant={"text"}
            fullWidth={true}
            onClick={this.addTask}
          >
            <AddIcon />
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(Tasks);
