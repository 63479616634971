import * as React from 'react';
import { appStyles } from '../../App.Styles';
import IComponentProps from '../../Common/Interfaces/IComponentProps';
import { withStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import IValidationResult, { IValidationResultMessage } from '../../Common/Interfaces/IValidationResult';
import ErrorIcon from '@material-ui/icons/Error';

interface IProps extends IComponentProps {
    validationResult: IValidationResult;
    locationLabel: string;
}

class ValidationViewer extends React.Component<IProps> {
    private locationText(message: IValidationResultMessage, index: number) {
        return (
            <ListItemText id={`validation-location-${index}`}>
                <b>{this.props.locationLabel}:</b> {message.location}{this.lineLocationText(message)}
                </ListItemText>)
    }

    private lineLocationText(message: IValidationResultMessage) {
        return this.hasLineNumber(message) ? ` ${message.lineNumber}:${message.position}` : "";
    }

    private hasLineNumber(message: IValidationResultMessage) {
        return message.lineNumber !== undefined && message.lineNumber! > 0;
    }

    public render() {
        return (
            <React.Fragment>
                <List
                    id="validation-list"
                    disablePadding
                    className={this.props.classes.dataTree}>
                    {
                        this.props.validationResult.messages.map((message, index) => {
                            return <ListItem key={index} id={`validation-item-${index}`}>
                                <ListItemIcon><ErrorIcon color={message.severity === "error" ? 'error' : message.severity === "warning" ? 'primary' : 'secondary'}/></ListItemIcon>
                                <List>
                                    <ListItem>
                                    {this.locationText(message, index)}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText><b>Message:</b> {message.message}</ListItemText>
                                    </ListItem>
                                </List>
                            </ListItem>
                        })      
                    }
                    
                </List>
            </React.Fragment>
        )
    }
}

export default withStyles(appStyles, { withTheme: true })(ValidationViewer);