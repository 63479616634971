import Constants from "../Constants";
import cronstrue from 'cronstrue';
import { JobSortType } from "../Enums/JobSortType";

export const SortTypeDisplayName = (sortType: JobSortType): string => {
    switch (sortType) {
        case JobSortType.NextRun:
            return 'Next Run';
        case JobSortType.LastRun:
            return 'Last Run';
        default:
            return JobSortType[sortType];
    }
}

export const getScheduleDescription = (schedule: string) => {
    if (schedule.includes(Constants.TokenStart) && schedule.includes(Constants.TokenEnd)) {
        return 'Dynamic';
    }
    return cronstrue.toString(schedule)
}