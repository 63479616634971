import { DataFormat } from "../Enums/DataFormat";

export default class SchemaFactory {
    constructor(xmlSchemaGenerator: (managementHost: string, data: Document) => Promise<string>) {
        this._xmlSchemaGenerator = xmlSchemaGenerator;
    }

    private readonly _xmlSchemaGenerator : (managementHost: string, data: Document) => Promise<string>;

    public static GetJsonSchema(data: {} | [], format: DataFormat) {
        if (data && Object.keys(data).length > 0) {
            var jsonSchemaGenerator = require('json-schema-generator');
            const toJsonSchema = require('to-json-schema');
            if (format === DataFormat.HL7v2) {
                // toJsonSchema produces less strict schema which is more suitable for HL7v2 (no min lengths for string or arrays)
                return JSON.stringify(toJsonSchema(data));
            } else {
                return JSON.stringify(jsonSchemaGenerator(data));
            }            
        }
    }

    // TODO: now that xml has been split out of here, can we return the object instead
    public static FormatJsonSchema(data: {} | [] | Document, format: DataFormat) {
        const schema = this.GetJsonSchema(data, format);
        
        if (schema) {
            return JSON.stringify(JSON.parse(schema as string), null, '\t');
        }
    }

    public async GetXmlSchema(managementHost: string, data: Document): Promise<string> {
        let schema;
        if (data) {
            // could do with a library to do this clientside
            schema = await this._xmlSchemaGenerator(managementHost, data)
                .then(schema => {return schema});
        }
        return schema as string;
    }
}