import { Grid, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import ReceiverContext from "./Receiver.Context";

interface IProps extends IComponentProps {
  context: ReceiverContext;
}

const MESHReceiverProperties: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    return (
      <Grid container spacing={2} className={classes.marginTop}>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Mailbox"
            id="mesh-mailbox"
            onChange={(e) => context.mailboxField.onChange(e.target.value)}
            placeholder="Enter a MESH mailbox"
            value={context.mailboxField.value}
            error={context.mailboxField.hasError}
            variant="outlined"
          />
          <p>{context.mailboxField.error}</p>
        </Grid>
        <Grid item md={6}>
          <MappablePasswordSetter
            passwordField={context.passwordField}
            fullWidth={true}
            variant="outlined"
          />
          <p>{context.passwordField.error}</p>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Message Id"
            id="mesh-message-id"
            onChange={(e) => context.messageIdField.onChange(e.target.value)}
            placeholder="Specify Message Id"
            value={context.messageIdField.value}
            error={context.messageIdField.hasError}
            variant="outlined"
          />
          <p>{context.messageIdField.error}</p>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Workflow Id"
            id="mesh-workflow-id"
            onChange={(e) => context.workflowIdField.onChange(e.target.value)}
            placeholder="Enter a Workflow Id"
            value={context.workflowIdField.value}
            error={context.workflowIdField.hasError}
            variant="outlined"
          />
          <p>{context.workflowIdField.error}</p>
        </Grid>
      </Grid>
    );
  }
);

export default MESHReceiverProperties;
