import { Grid, Typography, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { appStyles } from "../../App.Styles";
import { VariableScopeType } from "../../Common/Enums/VariableScopeType";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IVariable from "../../Common/Interfaces/IVariable";
import IVariableValue from "../../Common/Interfaces/IVariableValue";

interface IProps extends IComponentProps {
  variable: IVariable;
}

const VariableValues: React.FC<IProps> = observer(({ variable, classes }) => {
  const GetScopeDisplay = (variableValue: IVariableValue): string => {
    switch (variableValue.scopeType) {
      case VariableScopeType.Tenant:
        return "Tenant";
      case VariableScopeType.Endpoint:
        return `Endpoint: ${variableValue.scope}`;
      case VariableScopeType.EndpointVersion:
        return `Endpoint: ${variableValue.scope.split("/")[0]} version ${
          variableValue.scope.split("/")[1]
        }`;
      case VariableScopeType.EndpointEnvironment:
        return `Endpoint: ${variableValue.scope.split("/")[0]} in ${
          variableValue.scope.split("/")[1]
        }`;
      default:
        return variableValue.scope;
    }
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="subtitle1">Scope</Typography>
      </Grid>
      <Grid item xs={6} className={classes.alignRight}>
        <Typography variant="subtitle1">Value</Typography>
      </Grid>
      {variable.values.map((val, index) => {
        return (
          <Grid key={index}>
            <Grid item xs={6}>
              <p>{GetScopeDisplay(val)}</p>
            </Grid>
            <Grid item xs={6} className={classes.alignRight}>
              <p>{val.value}</p>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default withStyles(appStyles, { withTheme: true })(VariableValues);
