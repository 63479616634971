import "react-quill/dist/quill.snow.css";

import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import FormatValidator from "../../Common/Helpers/FormatValidator";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import PropertyEditor from "../PathEditor/PropertyEditor";
import { SmsProvider } from "../../Common/Enums/SmsProvider";
import SmsProviderHelpers from "../../Common/Helpers/SmsProviderHelpers";
import { observer } from "mobx-react";

@observer
export default class SMSOutput extends React.Component<IOutputProps> {
  private toRef = React.createRef<HTMLInputElement>();
  private bodyRef = React.createRef<HTMLInputElement>();
  private senderRef = React.createRef<HTMLInputElement>();

  private setProvider = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    _: React.ReactNode
  ) => {
    this.props.context.tasks[
      this.props.task
    ].smsProvider = (SmsProvider as any)[
      (SmsProvider as any)[event.target.value as any]
    ];
  };

  public render() {
    return (
      <Grid
        item
        md={6}
        onDrop={this.props.onDrop}
      >
        {this.props.context.tasks[this.props.task].editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.props.context.tasks[this.props.task].editingPropertyToken ===
            undefined && (
            <React.Fragment>
              {this.props.context.tasks[this.props.task].output.loading ? (
                <Loading fullscreen={false} />
              ) : (
                <React.Fragment>
                  <div className={this.props.classes.emailToolbar}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Phone Number"
                          onChange={(e) =>
                            (this.props.context.tasks[
                              this.props.task
                            ].smsTo.content = e.target.value)
                          }
                          placeholder="+447123456789"
                          inputRef={this.toRef}
                          onSelect={(_) =>
                            (this.props.context.tasks[
                              this.props.task
                            ].smsTo.cursorLocation =
                              this.toRef.current &&
                              (this.toRef.current.selectionStart as number))
                          }
                          error={
                            !FormatValidator.mobileNumber(
                              this.props.context.tasks[this.props.task].smsTo
                                .content
                            )
                          }
                          onFocus={(_) =>
                            (this.props.context.tasks[
                              this.props.task
                            ].activeTextInput = this.props.context.tasks[
                              this.props.task
                            ].smsTo)
                          }
                          required={true}
                          fullWidth
                          value={
                            this.props.context.tasks[this.props.task].smsTo
                              .content
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl id="select-sms-provider" fullWidth={true}>
                          <InputLabel>Provider</InputLabel>
                          <Select
                            id="provider-select"
                            value={
                              this.props.context.tasks[this.props.task]
                                .smsProvider
                            }
                            onChange={this.setProvider}
                          >
                            {Object.keys(SmsProvider)
                              .filter((key) =>
                                isNaN(Number((SmsProvider as any)[key as any]))
                              )
                              .map((key) => (
                                <MenuItem key={key} value={key}>
                                  {SmsProviderHelpers.GetDisplayName(
                                    (SmsProvider as any)[
                                      (SmsProvider as any)[key as any]
                                    ]
                                  )}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {this.props.context.tasks[this.props.task].smsProvider ===
                        SmsProvider.GovNotify && (
                        <Grid item xs={12}>
                          <FormControl
                            id="gov-notify-sender-id"
                            fullWidth={true}
                          >
                            <TextField
                              label="SenderID"
                              onChange={(e) =>
                                (this.props.context.tasks[
                                  this.props.task
                                ].smsSenderId.content = e.target.value)
                              }
                              placeholder="GUID, leave blank for default"
                              inputRef={this.senderRef}
                              onSelect={(_) =>
                                (this.props.context.tasks[
                                  this.props.task
                                ].smsSenderId.cursorLocation =
                                  this.toRef.current &&
                                  (this.toRef.current.selectionStart as number))
                              }
                              onFocus={(_) =>
                                (this.props.context.tasks[
                                  this.props.task
                                ].activeTextInput = this.props.context.tasks[
                                  this.props.task
                                ].smsSenderId)
                              }
                              required={true}
                              fullWidth
                              value={
                                this.props.context.tasks[this.props.task]
                                  .smsSenderId.content
                              }
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                    {this.props.context.tasks[this.props.task]
                      .selectedPropertyToken && (
                      <div className={this.props.classes.emailPathLink}>
                        <Button
                          className={this.props.classes.margin}
                          onClick={this.props.onClickEditPath}
                          variant={"text"}
                        >
                          <LinkIcon />
                        </Button>
                      </div>
                    )}
                  </div>
                  <TextField
                    label="Message"
                    multiline
                    style={{ height: "100%", width: "100%" }}
                    rows="23"
                    value={
                      this.props.context.tasks[this.props.task].smsBody.content
                    }
                    inputRef={this.bodyRef}
                    onSelect={(_) =>
                      (this.props.context.tasks[
                        this.props.task
                      ].smsBody.cursorLocation =
                        this.bodyRef.current &&
                        (this.bodyRef.current.selectionStart as number))
                    }
                    onChange={(e) =>
                      (this.props.context.tasks[
                        this.props.task
                      ].smsBody.content = e.target.value)
                    }
                    onFocus={(_) =>
                      (this.props.context.tasks[
                        this.props.task
                      ].activeTextInput = this.props.context.tasks[
                        this.props.task
                      ].smsBody)
                    }
                    className={this.props.classes.marginTop}
                    variant="filled"
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </Grid>
    );
  }
}
