import { withStyles } from "@material-ui/core";
import * as React from "react";
import LoadingSpinner from "./Loading.gif";
import { styles } from "./Loading.Styles";
const classNames = require("classnames");

interface IProps {
  fullscreen: boolean;
  classes: any;
}

class Loading extends React.Component<IProps> {
  public render() {
    return (
      <div
        className={classNames(
          this.props.classes.loading,
          this.props.fullscreen ? this.props.classes.loadingFullScreen : null
        )}
      >
        <div className={this.props.classes.loadingContent}>
          <img
            className={this.props.classes.loadingImg}
            src={LoadingSpinner}
            alt="Loading"
          />
          <h1>Loading..</h1>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Loading);
