import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FieldState } from "formstate";
import { observer } from "mobx-react";
import React from "react";
import { appStyles } from "../../App.Styles";
import { VariableScopeType } from "../../Common/Enums/VariableScopeType";
import VariableScopeTypeHelpers from "../../Common/Helpers/VariableScopeTypeHelpers";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import ISecret from "../../Common/Interfaces/ISecret";
import MagicMenu from "../MagicMenu/MagicMenu";
import VariableValueContext from "./VariableValue.Context";
import SecretIcon from "@material-ui/icons/VpnKey";

interface IProps extends IComponentProps {
  valueContext: VariableValueContext;
  index: number;
  onDelete: () => void;
  endpoints: IEndpoint[];
  onValueChange: () => void;
  secrets: ISecret[];
}

const VariableValue: React.FC<IProps> = observer(
  ({
    valueContext,
    index,
    onDelete,
    endpoints,
    classes,
    onValueChange,
    secrets,
  }) => {
    const ShowEndpointField = (scope: VariableScopeType): boolean => {
      return scope !== VariableScopeType.Tenant;
    };

    const ShowEndpointVersionField = (scope: VariableScopeType): boolean => {
      return scope === VariableScopeType.EndpointVersion;
    };

    const ShowEndpointEnvironmentField = (
      scope: VariableScopeType
    ): boolean => {
      return scope === VariableScopeType.EndpointEnvironment;
    };

    const UpdateFieldAndValidateForm = function <T>(
      value: T,
      field: FieldState<T>
    ) {
      field.onChange(value);
      onValueChange();
      valueContext.form.validate();
    };

    const GetEndpointVersionOptions = () => {
      let endpointName = valueContext.endpointField.value;
      if (!endpointName) {
        return;
      }
      let endpoint = endpoints.find((e) => e.name === endpointName);
      if (endpoint) {
        return endpoint.versions.map((v) => {
          return (
            <MenuItem
              id={`value-scope-version-${v.version}`}
              key={v.version}
              value={v.version}
              disabled={!valueContext.editable}
            >
              {v.version}
            </MenuItem>
          );
        });
      }
    };

    return (
      <Grid container spacing={1} className={classes.margin}>
        <Grid item xs={2}>
          <FormControl
            fullWidth
            disabled={!valueContext.editable}
            className={classes.alignLeft}
          >
            <Select
              id={`scope-select-${index}`}
              value={
                (VariableScopeType as any)[
                  VariableScopeType[valueContext.scopeField.value]
                ]
              }
              label="Scope"
              fullWidth
              onChange={(e: React.ChangeEvent<{ value: any }>) => {
                UpdateFieldAndValidateForm(
                  (VariableScopeType as any)[
                    (VariableScopeType as any)[e.target.value]
                  ],
                  valueContext.scopeField
                );
              }}
            >
              {Object.keys(VariableScopeType)
                .filter((key) => isNaN(Number(VariableScopeType[key as any])))
                .map((key) => (
                  <MenuItem
                    id={`${VariableScopeType[key as any]}-scope`}
                    key={key}
                    value={key}
                  >
                    {VariableScopeTypeHelpers.GetDisplayName(
                      (VariableScopeType as any)[
                        (VariableScopeType as any)[key as any]
                      ]
                    )}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Scope</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          {ShowEndpointField(valueContext.scopeField.value) && (
            <FormControl fullWidth className={classes.marginLeft}>
              <Autocomplete
                id={`variable-endpoint-${index}`}
                options={endpoints.map((e) => e.name)}
                getOptionLabel={(name) => name}
                value={valueContext.endpointField.value}
                onChange={(_e, value) => {
                  UpdateFieldAndValidateForm(
                    value as string,
                    valueContext.endpointField
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={valueContext.endpointField.hasError}
                  />
                )}
              />
              <FormHelperText>Endpoint</FormHelperText>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={2}>
          {ShowEndpointVersionField(valueContext.scopeField.value) && (
            <FormControl fullWidth className={classes.alignLeft}>
              <Select
                id={`variable-endpoint-version-${index}`}
                className={classes.marginLeft}
                value={valueContext.endpointVersionField.value}
                error={valueContext.endpointVersionField.hasError}
                onChange={(e) => {
                  UpdateFieldAndValidateForm(
                    e.target.value as string,
                    valueContext.endpointVersionField
                  );
                }}
                label="version"
              >
                {GetEndpointVersionOptions()}
              </Select>
              <FormHelperText className={classes.marginLeft}>
                Endpoint Version
              </FormHelperText>
            </FormControl>
          )}

          {ShowEndpointEnvironmentField(valueContext.scopeField.value) && (
            <FormControl fullWidth className={classes.alignLeft}>
              <Select
                displayEmpty={true}
                id={`variable-endpoint-environment-${index}`}
                value={valueContext.endpointEnvironmentField.value}
                error={valueContext.endpointEnvironmentField.hasError}
                className={classes.marginLeft}
                onChange={(e) => {
                  UpdateFieldAndValidateForm(
                    e.target.value as string,
                    valueContext.endpointEnvironmentField
                  );
                }}
              >
                <MenuItem value="Staging">Staging</MenuItem>
                <MenuItem value="Production">Production</MenuItem>
              </Select>
              <FormHelperText className={classes.marginLeft}>
                Environment
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Input
              id={`variable-value-${index}`}
              placeholder="Value"
              onChange={(e) =>
                UpdateFieldAndValidateForm(
                  e.target.value,
                  valueContext.valueField
                )
              }
              value={valueContext.valueField.value}
              onClick={(e) => e.stopPropagation()}
              disabled={!valueContext.editable}
              error={valueContext.valueField.hasError}
              fullWidth
              endAdornment={
                secrets.length > 0 && (
                  <InputAdornment position="end">
                    <MagicMenu
                      title="Secrets"
                      icon={<SecretIcon />}
                      items={secrets.map((s) => s.key)}
                      onClick={(e) =>
                        UpdateFieldAndValidateForm(
                          `--*${e}?*--`,
                          valueContext.valueField
                        )
                      }
                    />
                  </InputAdornment>
                )
              }
            />
            <FormHelperText>Value</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            id={`variable-value-${index}=delete`}
            disabled={!valueContext.editable}
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <FormHelperText className={classes.formError}>
            {valueContext.form.error}
          </FormHelperText>
        </Grid>
      </Grid>
    );
  }
);

export default withStyles(appStyles, { withTheme: true })(VariableValue);
