import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import { MapType } from "../../../Common/Enums/MapType";
import { DataOriginSelect } from "../../DataOriginSelect/DataOriginSelect";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import TaskContext from "../Context/Task.Context";
import * as React from "react";

interface IProps {
  taskIndex: number;
  context: EndpointWizardContext;
  classes: any;
}

const XmlOutputMappingProperties: React.FC<IProps> = observer(
  ({ taskIndex, classes, context }) => {
    const displayMapTypeChoice = (): boolean => {
      return (
        context.tasks[taskIndex].output.mapType === MapType.XSLT || canMapXSLT()
      );
    };

    const canMapXSLT = (): boolean => {
      return (
        inputIsXml() ||
        context.tasks.some(
          (t, i) => i < taskIndex && t.output.mode === DataFormat.XML
        )
      );
    };

    const inputIsXml = (): boolean => {
      return context.input.mode === DataFormat.XML;
    };

    const xsltSelected = (): boolean => {
      return context.tasks[taskIndex].output.mapType === MapType.XSLT;
    };

    const onSourceChanged = (origin: number): void => {
      context.tasks[taskIndex].output.selectedDataOrigin = origin;
      context.tasks[taskIndex].output.modified = true;
    };

    return (
      <>
        {displayMapTypeChoice() && (
          <Grid container>
            <Grid item xs={xsltSelected() ? 6 : 12}>
              <FormControl
                className={classNames(
                  classes.margin,
                  classes.dataFromSelectors
                )}
                fullWidth={true}
              >
                <RadioGroup
                  row={true}
                  value={MapType[context.tasks[taskIndex].output.mapType]}
                  onChange={(e, value) =>
                    context.switchMapType(
                      MapType[value as any] as unknown as MapType,
                      context.selectedDataOrigin.mode,
                      context.tasks[taskIndex].output.mode,
                      true,
                      taskIndex
                    )
                  }
                >
                  <FormControlLabel
                    id="radio-path"
                    value={MapType[MapType.Path]}
                    control={<Radio />}
                    label="Path"
                  />
                  <FormControlLabel
                    id="radio-xslt"
                    value={MapType[MapType.XSLT]}
                    control={<Radio />}
                    label="XSLT"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {xsltSelected() && (
              <Grid item xs={6}>
                <DataOriginSelect
                  tasks={context.tasks}
                  taskIndex={taskIndex}
                  value={context.tasks[taskIndex].output.selectedDataOrigin}
                  taskValid={(task: TaskContext) => {
                    return task.output.mode === DataFormat.XML;
                  }}
                  includeInput={context.input.mode === DataFormat.XML}
                  onChange={onSourceChanged}
                  label="Source"
                  idPrefix="source"
                  selectSourceMode={true}
                />
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  }
);

export default XmlOutputMappingProperties;
