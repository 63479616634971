import * as React from "react";

import {
  AccordionDetails,
  Button,
  Accordion,
  AccordionSummary,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import AceEditor from "react-ace";
import AceInputContext from "../EndpointWizard/Context/AceInputContext";
import { AdapterFieldEditorType } from "../../Common/Enums/AdapterFieldEditorType";
import CursorLocation from "../EndpointWizard/CursorLocation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IAdapterFieldDefinition } from "../../Common/Interfaces/IAdapterDefinition";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import PropertyEditor from "../PathEditor/PropertyEditor";
import QuillInputContext from "../EndpointWizard/Context/QuillInputContext";
import QuillWrapper from "../QuillWrapper/QuillWrapper";
import ReactQuill from "react-quill";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";
import _ from "lodash";
import { containsToken } from "../../Common/Helpers/TokenHelpers";
import { observer } from "mobx-react";

@observer
export default class AdapterOutput extends React.Component<IOutputProps> {
  constructor(props: IOutputProps) {
    super(props);
    if (_.isEmpty(this.task.adapterFieldValues)) {
      this.props.context.tasks[
        this.props.context.activeTask
      ].adapter?.fields.map((field) => {
        if (field.editorType === AdapterFieldEditorType.Text) {
          this.task.adapterFieldValues[field.name] = new TextInputContext();
        } else if (field.editorType === AdapterFieldEditorType.TextArea) {
          this.task.adapterFieldValues[field.name] = new TextInputContext();
        } else if (field.editorType === AdapterFieldEditorType.RichText) {
          this.task.adapterFieldValues[field.name] = new QuillInputContext();
        } else if (field.editorType === AdapterFieldEditorType.CodeEditor) {
          this.task.adapterFieldValues[field.name] = new AceInputContext();
        } else if (field.editorType === AdapterFieldEditorType.Select) {
          this.task.adapterFieldValues[field.name] = new TextInputContext();
        }
      });
    }
  }

  private task = this.props.context.tasks[this.props.context.activeTask];

  private renderTextField = (
    field: IAdapterFieldDefinition,
    fieldRef: React.RefObject<HTMLInputElement>
  ) => {
    return (
      <Grid item xs={12}>
        <TextField
          id={`adapter-field-value-${field.name}`}
          type="text"
          label={field.name}
          inputRef={fieldRef}
          onChange={(e) =>
            (this.task.adapterFieldValues[field.name].content = e.target.value)
          }
          onSelect={(_) =>
            (this.task.adapterFieldValues[field.name].cursorLocation =
              fieldRef.current && (fieldRef.current.selectionStart as number))
          }
          onFocus={(_) =>
            (this.task.activeTextInput =
              this.task.adapterFieldValues[field.name])
          }
          error={
            field.required && !this.task.adapterFieldValues[field.name]?.content
          }
          placeholder={field.name}
          fullWidth
          value={this.task.adapterFieldValues[field.name]?.content}
          variant="outlined"
        />
      </Grid>
    );
  };

  private renderTextAreaField = (
    field: IAdapterFieldDefinition,
    fieldRef: React.RefObject<HTMLInputElement>
  ) => {
    return (
      <Grid item xs={12}>
        <TextField
          id={`adapter-field-value-${field.name}`}
          multiline
          style={{ height: "100%", width: "100%" }}
          rows="23"
          value={this.task.adapterFieldValues[field.name]?.content}
          inputRef={fieldRef}
          onChange={(e) =>
            (this.task.adapterFieldValues[field.name].content = e.target.value)
          }
          onSelect={(_) =>
            (this.task.adapterFieldValues[field.name].cursorLocation =
              fieldRef.current && (fieldRef.current.selectionStart as number))
          }
          onFocus={(_) =>
            (this.task.activeTextInput =
              this.task.adapterFieldValues[field.name])
          }
          variant="filled"
        />
      </Grid>
    );
  };

  private renderRichTextField = (field: IAdapterFieldDefinition) => {
    return (
      <Grid item xs={12}>
        <QuillWrapper
          id={`adapter-field-value-${field.name}`}
          setRef={(ref) =>
            ((
              this.task.adapterFieldValues[field.name] as QuillInputContext
            ).editorRef = ref as ReactQuill)
          }
          style={{ height: "446px" }}
          defaultValue={this.task.adapterFieldValues[field.name]?.content}
          onChange={(code) =>
            (this.task.adapterFieldValues[field.name].content = code)
          }
          onFocus={() =>
            (this.task.activeTextInput =
              this.task.adapterFieldValues[field.name])
          }
          onChangeSelection={(range) => {
            range !== null &&
              !isNaN(range.index) &&
              (this.task.adapterFieldValues[field.name].cursorLocation =
                range.index);
          }}
        />
      </Grid>
    );
  };

  private renderCodeEditorField = (field: IAdapterFieldDefinition) => {
    return (
      <Grid item xs={12} id={`adapter-field-value-${field.name}`}>
        <AceEditor
          onChange={(code) =>
            (this.task.adapterFieldValues[field.name].content = code)
          }
          value={this.task.adapterFieldValues[field.name]?.content}
          mode="text"
          showGutter={true}
          onFocus={(_) =>
            (this.task.activeTextInput =
              this.task.adapterFieldValues[field.name])
          }
          onCursorChange={(e) =>
            (this.task.adapterFieldValues[field.name].cursorLocation =
              new CursorLocation(e.getCursor().row, e.getCursor().column))
          }
          width="100%"
          highlightActiveLine={true}
          fontSize={20}
          theme="tomorrow"
          name="input-editor"
          setOptions={{
            useWorker: false,
            showLineNumbers: true,
            tabSize: 4,
          }}
        />
      </Grid>
    );
  };

  private renderSelectField = (field: IAdapterFieldDefinition) => {
    return (
      <Grid item xs={12}>
        <Select
          value={this.task.adapterFieldValues[field.name]?.content}
          onChange={(e) =>
            (this.task.adapterFieldValues[field.name].content = e.target
              .value as string)
          }
        >
          {field.values.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  };

  public render() {
    return (
      <Grid onDrop={this.props.onDrop} item md={6}>
        {this.task.editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.task.editingPropertyToken === undefined && (
          <React.Fragment>
            {this.task.output.loading ? (
              <Loading fullscreen={false} />
            ) : (
              <React.Fragment>
                <Grid container spacing={2}>
                  {this.task.adapter &&
                    this.task.adapter.fields.map((field) => {
                      const fieldRef = React.createRef<HTMLInputElement>();

                      return (
                        <Accordion style={{ width: "100%" }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id={`adapter-field-${field.name}`}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  {field.name} {field.required && "*"}
                                  {this.props.context.tasks[this.props.task]
                                    .selectedPropertyToken &&
                                    containsToken(
                                      this.task.adapterFieldValues[field.name]
                                        ?.content,
                                      this.props.context.tasks[this.props.task]
                                        .selectedPropertyToken
                                    ) && (
                                      <Button
                                        className={this.props.classes.margin}
                                        onClick={this.props.onClickEditPath}
                                        variant={"text"}
                                      >
                                        <LinkIcon />
                                      </Button>
                                    )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid item xs={12}>
                                {field.editorType ===
                                  AdapterFieldEditorType.Text &&
                                  this.renderTextField(field, fieldRef)}
                                {field.editorType ===
                                  AdapterFieldEditorType.TextArea &&
                                  this.renderTextAreaField(field, fieldRef)}
                                {field.editorType ===
                                  AdapterFieldEditorType.RichText &&
                                  this.renderRichTextField(field)}
                                {field.editorType ===
                                  AdapterFieldEditorType.CodeEditor &&
                                  this.renderCodeEditorField(field)}
                                {field.editorType ===
                                  AdapterFieldEditorType.Select &&
                                  this.renderSelectField(field)}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
