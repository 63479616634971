import { Grid, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import ArchiveAttachments from "./ArchiveAttachments";
import ReceiverContext from "./Receiver.Context";

interface IProps extends IComponentProps {
  context: ReceiverContext;
}

const POP3ReceiverProperties: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    return (
      <Grid container spacing={2} className={classes.marginTop}>
        <Grid item md={12}>
          <TextField
            fullWidth
            autoComplete="off"
            label="POP3 Server"
            id="pop3-server"
            onChange={(e) => context.serverField.onChange(e.target.value)}
            placeholder="Enter a POP3 Server hostname"
            value={context.serverField.value}
            error={context.serverField.hasError}
            variant="outlined"
          />
          <p>{context.serverField.error}</p>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Subject Prefix"
            id="pop3-subject"
            onChange={(e) =>
              context.subjectPrefixField.onChange(e.target.value)
            }
            placeholder="Enter a subject prefix"
            value={context.subjectPrefixField.value}
            error={context.subjectPrefixField.hasError}
            variant="outlined"
          />
          <p>{context.subjectPrefixField.error}</p>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Username"
            id="pop3-username"
            onChange={(e) => context.usernameField.onChange(e.target.value)}
            placeholder="Enter a username"
            value={context.usernameField.value}
            error={context.usernameField.hasError}
            variant="outlined"
          />
          <p>{context.usernameField.error}</p>
        </Grid>
        <Grid item md={12}>
          <MappablePasswordSetter
            passwordField={context.passwordField}
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
        <Grid item md={12}>
          <ArchiveAttachments
            archiveAttachments={context.archiveAttachments}
            appContext={appContext}
            classes={classes}
          />
        </Grid>
      </Grid>
    );
  }
);

export default POP3ReceiverProperties;
