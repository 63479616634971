import { LoggableAction } from "../Enums/LoggableAction";

export default class LoggableActionHelpers {
  public static GetDisplayName(loggableAction: LoggableAction) {
    switch (loggableAction) {
      case LoggableAction.EndpointAuthentication:
        return "Endpoint Authentication";
      case LoggableAction.EndpointAuthenticationFailure:
        return "Endpoint Authentication Failure";
      case LoggableAction.EndpointException:
        return "Endpoint Exception";
      case LoggableAction.EndpointInvocation:
        return "Endpoint Invocation";
      case LoggableAction.EndpointManagementAuthenticationFailure:
        return "Endpoint Management Service Authentication Failure";
      case LoggableAction.EndpointTaskFailure:
        return "Endpoint Task Failure";
      case LoggableAction.EndpointTaskSkipped:
        return "Endpoint Task Skipped";
      case LoggableAction.EndpointTaskSuccess:
        return "Endpoint Task Success";
      case LoggableAction.EndpointValidationFailure:
        return "Endpoint Input Validation Failure";
      case LoggableAction.NonExistantEndpointInvocation:
        return "Non-Existant Endpoint Invocation";
      case LoggableAction.EndpointTaskBegins:
        return "Endpoint Task Begins";
      case LoggableAction.EndpointResponds:
        return "Endpoint Responds";
      case LoggableAction.FaxReceivingFailed:
        return "Receiving Fax Failed";
      case LoggableAction.MESHMessageReceived:
        return "MESH Message Received";
      case LoggableAction.EmailMessageReceived:
        return "Email Message Received";
      case LoggableAction.ReceiverException:
        return "Receiver Exception";
      case LoggableAction.EmailAttachmentExtractionError:
        return "Email Attachment Extraction Error";
      case LoggableAction.EndpointValidationSuccess:
        return "Endpoint Input Validation Success";
      case LoggableAction.EndpointTaskValuesResolved:
        return "Endpoint Task Values Resolved";
      case LoggableAction.EmailMessageProcessed:
        return "Email Message Processed";
      case LoggableAction.EmailMessageRejected:
        return "Email Message Rejected";
      case LoggableAction.EmailMessageAccepted:
        return "Email Message Accepted";
      case LoggableAction.EndpointInvocationFailure:
        return "Endpoint Invocation Failure";
      case LoggableAction.MESHMessageProcessed:
        return "MESH Message Processed";
      case LoggableAction.MESHMessageRejected:
        return "MESH Message Rejected";
      case LoggableAction.MESHMessageAccepted:
        return "MESH Message Accepted";
      case LoggableAction.MESHMessageReport:
        return "MESH Message Report";
      case LoggableAction.EndpointTimeElapsedRecorded:
        return "Endpoint Execution Time Recorded"
      default:
        return loggableAction;
    }
  }

  public static getLoggableActionTaskTypes() {
    return [
      LoggableAction.EndpointTaskBegins,
      LoggableAction.EndpointTaskFailure,
      LoggableAction.EndpointTaskValuesResolved,
      LoggableAction.EndpointTaskSuccess,
      LoggableAction.EndpointTaskInvocationDemoMode,
      LoggableAction.EndpointTaskSkipped];
  }

  public static shouldDisplayTaskBehaviour(actions: LoggableAction[] = []) {
    if (!actions.length) {
      return false;
    }

    const taskActions = this.getLoggableActionTaskTypes();
    const dashboardActions = actions.map(item => item.toString());
    return taskActions.some(item => dashboardActions.includes(item.toString()));
  }
}
