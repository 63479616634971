import * as React from "react";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  AccordionDetails,
  Card,
  TextField,
  CardHeader,
  IconButton,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import XSLTParameterContext from "../EndpointWizard/Context/XSLTParameterContext";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";

interface IProps {
  parameters: XSLTParameterContext[];
  onFocus: (input: TextInputContext) => void;
}

@observer
export default class XSLTParameters extends React.Component<IProps> {
  private handleOnSelect(e) {
    return e.target && (e.target.selectionStart as number);
  }

  public render() {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary id="panel-parameters" expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                Parameters ({this.props.parameters.length})
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              {this.props.parameters.map((parameter, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Card key={index}>
                        <CardHeader
                          title={
                            <React.Fragment>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <TextField
                                    id={"parameter-name-" + index}
                                    label="Name"
                                    onChange={(e) =>
                                      (parameter.name.content = e.target.value)
                                    }
                                    placeholder="Name"
                                    fullWidth
                                    required
                                    onSelect={(e) =>
                                      (parameter.name.cursorLocation =
                                        this.handleOnSelect(e))
                                    }
                                    onFocus={(_) =>
                                      this.props.onFocus(parameter.name)
                                    }
                                    value={parameter.name.content}
                                    error={
                                      !parameter.name.content ||
                                      this.props.parameters.filter(
                                        (x) =>
                                          x.name.content ===
                                          parameter.name.content
                                      ).length > 1
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    id={"parameter-value-" + index}
                                    label="Value"
                                    onChange={(e) =>
                                      (parameter.value.content = e.target.value)
                                    }
                                    placeholder="Value"
                                    required
                                    onSelect={(e) =>
                                      (parameter.value.cursorLocation =
                                        this.handleOnSelect(e))
                                    }
                                    fullWidth
                                    onFocus={(_) =>
                                      this.props.onFocus(parameter.value)
                                    }
                                    value={parameter.value.content}
                                    error={!parameter.value.content}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                          action={
                            <IconButton
                              onClick={() =>
                                this.props.parameters.splice(index, 1)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        ></CardHeader>
                      </Card>
                    </Grid>
                  </React.Fragment>
                );
              })}
              <Grid item xs={12}>
                <Button
                  id="add-parameter"
                  variant="text"
                  fullWidth
                  onClick={() =>
                    this.props.parameters.push(new XSLTParameterContext())
                  }
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}
