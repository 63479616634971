import { observable } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import { ApolloQueryResult } from "apollo-client";
import { LoggableAction } from "../../Common/Enums/LoggableAction";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import { Dictionary } from "lodash";

export default class DashboardContext {
  constructor(
    drillDown: boolean,
    startDate?: string | null,
    endDate?: string | null,
    includeEndpoints?: string | null,
    actions?: string | null,
    transactionId?: string | null
  ) {
    this.drillDown = drillDown;
    if (startDate) {
      this.startDate = dayjs(startDate);
    }
    if (endDate) {
      this.endDate = dayjs(endDate);
    }
    if (includeEndpoints) {
      this.includeEndpoints = includeEndpoints.split(",");
    }
    if (actions) {
      this.actions = actions.split(",").map((a) => Number(a));
    }
    if (transactionId) {
      this.propertyFilters["transactionId"] = transactionId;
    }
    this.resetSlider();
  }

  @observable
  public loading: boolean = true;

  @observable
  public startDate: Dayjs = dayjs().hour(0).minute(0);

  @observable
  public endDate: Dayjs = dayjs().hour(23).minute(59);

  @observable
  public endpoints: IEndpoint[] = [];

  @observable
  public includeEndpoints?: string[];

  @observable
  public endpointNames: string[] = [];

  @observable
  public refetchDashboardData?: (
    variables?:
      | {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
          endpoints: string[];
        }
      | undefined
  ) => Promise<ApolloQueryResult<any>>;

  @observable
  public refetchUsageTableData?: (
    variables?:
      | {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
          endpoints: string[] | undefined;
          actions: LoggableAction[];
          transactionId: string;
          method: string;
          ip: string;
        }
      | undefined
  ) => Promise<ApolloQueryResult<any>>;

  @observable
  public drillDown: boolean = false;

  @observable
  public actions: LoggableAction[] = [];

  @observable
  public propertyFilters: Dictionary<string> = {};

  @observable
  public selectedPropertyFilter: string = "";

  public initialize() {
    if (!this.includeEndpoints) {
      this.includeEndpoints = this.endpointNames;
    }
    this.propertyFilters = {};
    this.selectedPropertyFilter = "";
  }

  @observable
  public redirect: boolean = false;

  @observable
  public selectAllEndpoints: boolean = false;

  @observable
  public timeSliderValue: any;

  @observable
  public sliderValue: number[] = [0, 24];

  public resetSlider() {
    this.sliderValue = [
      this.startDate.hour(),
      this.endDate.minute() === 59
        ? this.endDate.hour() + 1
        : this.endDate.hour(),
    ];
  }
}
