import AppContext from "../../App.Context";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import { observable } from "mobx";
import BaseContext from "../../Common/BaseContext";
import Constants from "../../Common/Constants";

export default class EndpointSelectorContext extends BaseContext {
    constructor(
        appContext: AppContext, 
        endpoints: IEndpoint[], 
        endpoint: IEndpoint,
        endpointEnvironmentOrVersion: string,
        validate?: boolean,
        input?: string) {
        super(appContext);
        this.endpoints = endpoints;

        if (validate !== undefined) {
            this.validate = validate;
        }

        if (endpoint && endpoint.versions && input) {
            this.editMode = true;
            this.input = input;
            this.endpoint = endpoint;
        }

        if (endpointEnvironmentOrVersion){
            this.endpointEnvironmentOrVersion = endpointEnvironmentOrVersion;
        }
    }

    public editMode: boolean = false;

    @observable
    public endpoints: IEndpoint[] = [];

    @observable
    public input: string = '';

    @observable
    public endpoint?: IEndpoint; 

    @observable
    public endpointEnvironmentOrVersion: string = Constants.Production;

    @observable
    public validate?: boolean = true; 
}