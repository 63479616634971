import {
  faClock,
  faCodeBranch,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import VariableIcon from "@material-ui/icons/AttachMoney";
import SecretIcon from "@material-ui/icons/VpnKey";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import { AuthenticationProvider } from "../../Common/Enums/AuthenticationProvider";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { Can } from "../Authorisation/AbilityContext";

const MagicAppBar: React.FC<RouteComponentProps & IComponentProps> = observer(
  ({ history, location, match, staticContext, classes, appContext }) => {
    const handleLogout = async () => {
      if (
        appContext.authenticationProvider !== AuthenticationProvider.Internal
      ) {
        history.push("/oidc-signout");
      } else {
        appContext.logOut();
      }
    };

    return (
      <React.Fragment>
        {appContext.loggedIn && appContext.profileComplete && (
          <AppBar position="static">
            <Toolbar>
              <Grid container wrap="nowrap">
                <Grid item>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.grow}
                  >
                    <MenuItem>
                      <Link
                        id="logo-link"
                        to="/"
                        className={classNames(
                          classes.link,
                          match.url === "/" ||
                            match.url.startsWith("/Dashboard")
                            ? classes.active
                            : classes.whiteText
                        )}
                      >
                        <img
                          src="/logo.png"
                          className={classes.logoImg}
                          alt="AireGlu"
                        />
                        AireGlu
                      </Link>
                    </MenuItem>
                  </Typography>
                </Grid>
                <Can I="list" a="Endpoint">
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      <MenuItem>
                        <Link
                          id="endpoints-link"
                          to="/Endpoints"
                          className={classNames(
                            classes.link,
                            match.url.startsWith("/Endpoint")
                              ? classes.active
                              : classes.whiteText
                          )}
                        >
                          <FontAwesomeIcon icon={faGlobe} size="lg" /> Endpoints
                        </Link>
                      </MenuItem>
                    </Typography>
                  </Grid>
                </Can>
                <Can I="list" a="Repository">
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      <MenuItem>
                        <Link
                          id="repositories-link"
                          to="/Repositories"
                          className={classNames(
                            classes.link,
                            match.url.startsWith("/Repositor")
                              ? classes.active
                              : classes.whiteText
                          )}
                        >
                          <FontAwesomeIcon icon={faCodeBranch} size="lg" />{" "}
                          Repositories
                        </Link>
                      </MenuItem>
                    </Typography>
                  </Grid>
                </Can>
                <Can I="list" a="Job">
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      <MenuItem>
                        <Link
                          id="jobs-link"
                          to="/Jobs"
                          className={classNames(
                            classes.link,
                            match.url.startsWith("/Job")
                              ? classes.active
                              : classes.whiteText
                          )}
                        >
                          <FontAwesomeIcon icon={faClock} size="lg" /> Jobs
                        </Link>
                      </MenuItem>
                    </Typography>
                  </Grid>
                </Can>
                <Can I="list" a="Retriever">
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      <MenuItem>
                        <Link
                          id="receivers-link"
                          to="/Receivers"
                          className={classNames(
                            classes.link,
                            match.url.startsWith("/Receiver")
                              ? classes.active
                              : classes.whiteText
                          )}
                        >
                          <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                          Receivers
                        </Link>
                      </MenuItem>
                    </Typography>
                  </Grid>
                </Can>
                <Can I="list" a="Secret">
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      <MenuItem>
                        <Link
                          id="secrets-link"
                          to="/Secrets"
                          className={classNames(
                            classes.link,
                            match.url.startsWith("/Secret")
                              ? classes.active
                              : classes.whiteText
                          )}
                        >
                          <SecretIcon style={{ verticalAlign: "bottom" }} />{" "}
                          Secrets
                        </Link>
                      </MenuItem>
                    </Typography>
                  </Grid>
                </Can>
                <Can I="list" a="Variable">
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      <MenuItem>
                        <Link
                          id="variables-link"
                          to="/Variables"
                          className={classNames(
                            classes.link,
                            match.url.startsWith("/Variable")
                              ? classes.active
                              : classes.whiteText
                          )}
                        >
                          <VariableIcon style={{ verticalAlign: "bottom" }} />
                          Variables
                        </Link>
                      </MenuItem>
                    </Typography>
                  </Grid>
                </Can>
              </Grid>
              <IconButton
                //aria-owns={window.open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={(event) => (appContext.anchorEl = event.currentTarget)}
                color="inherit"
                id="menu-link"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={appContext.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={appContext.anchorEl !== undefined}
                onClose={() => (appContext.anchorEl = undefined)}
              >
                <MenuItem onClick={() => (appContext.anchorEl = undefined)}>
                  <Link
                    id="account-link"
                    className={classes.link}
                    to="/Account"
                  >
                    My account
                  </Link>
                </MenuItem>
                <hr />
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        )}
      </React.Fragment>
    );
  }
);

export default withStyles(appStyles, { withTheme: true })(
  withRouter(MagicAppBar)
);
