import React from "react";
import { observer } from "mobx-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import TrackedTextField from "../Input/TrackedTextField";

interface IProps {
  task: TaskContext;
}

const AireAuditEventTypeControls: React.FC<IProps> = observer(({ task }) => {
  const eventTypes = task.aireAudit.eventTypes;
  const isValid = eventTypes.every((eventType) => eventType.isValid());

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          {!isValid && <ErrorIcon color="error" />}
          <Typography variant="h6">Event Type ({eventTypes.length})</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Box>
            {eventTypes.length === 0 && (
              <Typography color="error">
                At least one event type is required
              </Typography>
            )}
            {eventTypes.map((eventType, index) => (
              <Box key={index}>
                <Box
                  display="flex"
                  marginTop={2}
                  marginBottom={2}
                  style={{ gap: 8 }}
                >
                  <TrackedTextField
                    context={eventType.code}
                    label="Code"
                    placeholder="Code"
                    required
                    fullWidth
                    variant="outlined"
                    id={`event-type-code-${index}`}
                    error={!eventType.code.content}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ flex: 1 }}
                    onFocus={(_) => (task.activeTextInput = eventType.code)}
                  />
                  <TrackedTextField
                    context={eventType.codeSystem}
                    label="Code System"
                    placeholder="Code System"
                    required={false}
                    fullWidth
                    variant="outlined"
                    id={`event-type-code-system-${index}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ flex: 1 }}
                    onFocus={(_) =>
                      (task.activeTextInput = eventType.codeSystem)
                    }
                  />
                  <TrackedTextField
                    context={eventType.displayName}
                    label="Display Name"
                    placeholder="Display Name"
                    required={false}
                    fullWidth
                    variant="outlined"
                    id={`event-type-display-name-${index}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ flex: 1 }}
                    onFocus={(_) =>
                      (task.activeTextInput = eventType.displayName)
                    }
                  />
                  <IconButton
                    onClick={() => task.aireAudit.removeEventType(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Divider style={{ marginTop: 16 }} />
              </Box>
            ))}
          </Box>
          <Box width="100%">
            <Button
              variant="text"
              fullWidth
              id="add-event-type"
              onClick={() => task.aireAudit.addEventType()}
            >
              <AddIcon /> Add Event Type
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default AireAuditEventTypeControls;
