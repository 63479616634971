import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react";
import * as React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import Help from "../Help/Help";
import ArchiveAttachmentContext from "./ArchiveAttachment.Context";

interface IProps extends IComponentProps {
  archiveAttachments: ArchiveAttachmentContext[];
}

const ArchiveAttachments: React.FC<IProps> = observer(
  ({ archiveAttachments, appContext, classes }) => {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                Archive Attachments ({archiveAttachments.length}){" "}
                <Help
                  classes={classes}
                  helpText="Compressed archives can be extracted and data from one file within can be retrieved. Currently supported formats: .zip"
                />
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              {archiveAttachments.map((attachment, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Card key={index}>
                        <CardHeader
                          title={
                            <TextField
                              label="Archive Name"
                              onChange={(e) =>
                                attachment.archiveNameField.onChange(
                                  e.target.value
                                )
                              }
                              placeholder="Archive Name"
                              required
                              fullWidth
                              value={attachment.archiveNameField.value}
                              error={!attachment.archiveNameField.hasError}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                          action={
                            <IconButton
                              onClick={() =>
                                archiveAttachments.splice(index, 1)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        ></CardHeader>
                        <Divider></Divider>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="File Name"
                                onChange={(e) =>
                                  attachment.fileNameField.onChange(
                                    e.target.value
                                  )
                                }
                                placeholder="File Name"
                                required
                                fullWidth
                                value={attachment.fileNameField.value}
                                error={!attachment.fileNameField.hasError}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <MappablePasswordSetter
                                passwordField={attachment.passwordField}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </React.Fragment>
                );
              })}
              <Grid item xs={12}>
                <Button
                  variant="text"
                  fullWidth
                  onClick={() =>
                    archiveAttachments.push(new ArchiveAttachmentContext())
                  }
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default ArchiveAttachments;
