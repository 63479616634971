import LoggingService from "./Logging.Service";
import AppContext from "../../App.Context";
import IEndpoint from "../Interfaces/IEndpoint";
import { IMapping } from "../Interfaces/IMapping";
import IInput from "../Interfaces/IInput";
import { DataFormat } from "../Enums/DataFormat";
import { DataSource } from "../Enums/DataSource";
import DataFormatHelper from "../Helpers/DataFormatHelper";
import IWithErrorsResponse from "../Interfaces/IWithErrorsResponse";
import IEndpointUpdate from "../Interfaces/IEndpointUpdate";
import { getToken } from "../Helpers/AuthenticationHelpers";

export default class EndpointService {
  public static Get = (
    appContext: AppContext,
    name: string
  ): Promise<IEndpoint> => {
    const uri = `${appContext.managementHost}/Endpoint/${name}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Export = (
    appContext: AppContext,
    name: string,
    type: string,
    includeEncrypted: boolean
  ): Promise<string> => {
    const controllerEndpointName = includeEncrypted ? "ExportWithEncrypted" : "ExportWithoutEncrypted";
    const uri = `${appContext.managementHost}/Endpoint/${name}/${controllerEndpointName}/${type}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.text())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static GetEndpoints = (
    appContext: AppContext
  ): Promise<IEndpoint[]> => {
    const uri = `${appContext.managementHost}/Endpoint`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static GetEndpointNames = (
    appContext: AppContext
  ): Promise<string[]> => {
    const uri = `${appContext.managementHost}/Endpoint/Names`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Preview = (
    appContext: AppContext,
    mapping: IMapping,
    endpointName: string,
    endpointVersion: number
  ): Promise<string> => {
    if (appContext) {
      const uri = `${appContext.managementHost}/Endpoint/Preview`;
      return fetch(uri, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          endpointName: endpointName,
          endpointVersion: endpointVersion,
          mapping: mapping,
        }),
      })
        .then((response) => {
          if (response.ok) return response.text();
          else throw new Error("There was an error loading the preview");
        })
        .catch((error) => {
          LoggingService.Log(error);
          alert("There was an error loading the preview");
          return error.Message;
        });
    }
    return new Promise(() => "");
  };

  public static GetInputData = (
    managementHost: string,
    input: IInput
  ): Promise<any> => {
    if (managementHost) {
      const uri = `${managementHost}/Endpoint/Input`;
      return fetch(uri, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(input),
      })
        .then((response) => response.json())
        .catch((error) => {
          LoggingService.Log(error);
          throw error;
        });
    }
    return new Promise(() => "");
  };

  public static GetTemplate = (
    managementHost: string,
    format: DataFormat,
    resource: string
  ): Promise<any> => {
    if (managementHost) {
      const uri = `${managementHost}/Endpoint/GetTemplate?format=${format}&resource=${resource}`;
      return fetch(uri, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        method: "GET",
      })
        .then((response) => response.text())
        .catch((error) => {
          LoggingService.Log(error);
          throw error;
        });
    }
    return new Promise(() => "");
  };

  public static Save = (
    appContext: AppContext,
    data: IEndpoint,
    overwrite: boolean,
    fromVersion?: number
  ): Promise<IWithErrorsResponse> => {
    let uri = `${appContext.managementHost}/Endpoint`;
    if (fromVersion) {
      uri += `?fromVersion=${fromVersion}`;
    }
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: overwrite ? "PUT" : "POST",
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        return {
          status: response.status,
          result: await response.json(),
        };
      })
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Import = (
    appContext: AppContext,
    data: string
  ): Promise<IWithErrorsResponse> => {
    let uri = `${appContext.managementHost}/Endpoint`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then(async (response) => {
        return {
          status: response.status,
          result: await response.json(),
        };
      })
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Update = (
    appContext: AppContext,
    update: IEndpointUpdate
  ): Promise<IEndpoint> => {
    let uri = `${appContext.managementHost}/Endpoint/Toggle`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(update),
    })
      .then(async (response) => await response.json())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Deploy = (
    appContext: AppContext,
    version: number,
    id: string,
    environments: string[]
  ): Promise<string> => {
    const uri = `${appContext.managementHost}/Endpoint/${id}/Deploy/${version}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(environments),
    })
      .then((response) => response.text())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Delete = (
    appContext: AppContext,
    id: string
  ): Promise<boolean> => {
    const uri = `${appContext.managementHost}/Endpoint/${id}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    })
      .then((response) => response.ok)
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Run = (
    appContext: AppContext,
    name: string,
    username: string,
    input: string,
    version: string,
    method: string,
    dataSource: DataSource,
    dataFormat: DataFormat
  ): Promise<Response> => {
    if (appContext) {
      let uri = `${appContext.runtimeHost}/${username}/${name}/${version}`;
      const request: RequestInit = {
        method,
        redirect: "manual",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };

      if (method !== "GET") {
        request.headers!["Content-Type"] = `${DataFormatHelper.GetContentType(
          dataFormat
        )}`;
        if (dataSource === DataSource.Body) {
          request.body = input;
          if (dataFormat === DataFormat.QueryString) {
            request.headers!["Content-Type"] =
              "application/x-www-form-urlencoded";
          }
        } else {
          uri += input ? `?${input}` : "";
        }
      } else {
        uri += input ? `?${input}` : "";
      }

      return fetch(uri, request).then((response) => response);
    }
    return new Promise(() => "");
  };
}
