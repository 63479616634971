import TextInputContext from "./TextInputContext";
import AceEditor from "react-ace";
import AceEditorHelpers from "../../../Common/Helpers/AceEditorHelpers";
import CursorLocation from "../CursorLocation";
import BaseEditorHelpers from "../../../Common/Helpers/BaseEditorHelpers";
import Constants from "../../../Common/Constants";

export default class AceInputContext extends TextInputContext {
    
    public editorRef?: AceEditor

    public addToken(propertyToken: string) {
        this.content = new AceEditorHelpers()
                .addTokenToOutput(
                    propertyToken,
                    this.cursorLocation as CursorLocation,
                    this.content
                );
    }
    
    public getSelectedToken() : string | undefined {
        const lines = this.content.split('\n');
        const line = lines[(this.cursorLocation as CursorLocation)!.row];
        const cursorLineLocation = (this.cursorLocation as CursorLocation)!.column as number

        return new BaseEditorHelpers().getSelectedToken(
            line, cursorLineLocation, 
            Constants.TokenStart, Constants.TokenEnd);
    }
}