export default class XmlHelpers {
    public static GetDuplicateChildNodeNames = (node: Element) => {
        return Array.from({ length: node.childElementCount }, (v, k) => k)
            .map((value) => {
                return node.children.item(value)!.nodeName;
            })
            .sort()
            .map((value, index, arr) => {
                if (arr[index] === arr[index - 1] || arr[index] === arr[index + 1]) {
                    return value;
                }
                return undefined;
            })
            .filter(x => x !== undefined)
    }
}