import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import { isNaN } from "lodash";
import { observer } from "mobx-react";
import React, {useState } from "react";
import {
  DataFormat,
  DataFormatDisplayName,
} from "../../Common/Enums/DataFormat";
import { HttpMethod } from "../../Common/Enums/HttpMethod";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import { FHIRR4Resource } from "../../Common/HL7/4.0.0 (R4 Sequence)/Resource";
import { DataSource } from "../../Common/Enums/DataSource";
import Prettifier from "../../Common/Helpers/Prettifier";
import BrushIcon from "@material-ui/icons/Brush";
import LinkIcon from "@material-ui/icons/Link";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import XmlOutputMappingProperties from "../EndpointWizard/Output/XmlOutputMappingProperties";
import { Alert } from "@material-ui/lab";

interface IProps {
  task: TaskContext;
  context: EndpointWizardContext;
  classes: any;
  onClickEditPath: () => void;
}

const RequestOutputFormat: React.FC<IProps> = observer(
  
  ({ task, classes, context, onClickEditPath }) => {
    const [showInfoAlert, setInfoAlertValue] = useState(task.output.mode === DataFormat.Stream);

    return (
      <div  style={showInfoAlert ? { height: '110px' } : {}} className={classes.toggleContainer}>
        {
          <FormControl fullWidth={true}>
            <InputLabel>Format</InputLabel>
            <Select
              value={task.output.mode}
              onChange={(e: React.ChangeEvent<{ value: any }>) => {
                
                const value = (DataFormat as any)[(DataFormat as any)[e.target.value] ];

                if (value === DataFormat.Stream) {
                  setInfoAlertValue(true);
                } else {
                  setInfoAlertValue(false);
                }

                context.switchOutputMode(
                  context.selectedDataOrigin.mode,
                  (DataFormat as any)[(DataFormat as any)[e.target.value]],
                  true,
                  context.activeTask
                );
              }}
            >
              {Object.keys(DataFormat)
                .filter(
                  (key) =>
                    isNaN(Number(DataFormat[key as any])) &&
                    [
                      DataFormat.HTML,
                      DataFormat.Callback
                    ].includes((DataFormat as any)[DataFormat[key as any]]) ===
                      false
                )
                .map((key) => (
                  <MenuItem
                    disabled={
                      task.requestMethod === HttpMethod.GET &&
                      ![DataFormat.QueryString, DataFormat.None].includes(
                        (DataFormat as any)[(DataFormat as any)[key as any]]
                      )
                    }
                    key={key}
                    value={key}
                  >
                    {DataFormatDisplayName(
                      (DataFormat as any)[(DataFormat as any)[key as any]]
                    )}
                  </MenuItem>
                ))}
            </Select>
            {showInfoAlert && (
              <div style={{ padding: "10px" }}>
                <Alert style={{padding:"0 10px"}} severity="info">
                  Supported formats are PDF and DOC
                </Alert>
              </div>
            )}
          </FormControl>
        }
        {[DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
          task.output.mode
        ) && (
          <FormControl fullWidth={true} className={classes.margin}>
            <InputLabel>Resource</InputLabel>
            <Select
              value={task.output.fhir.resource}
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                task.switchFHIRResource(
                  (FHIRR4Resource as any)[
                    (FHIRR4Resource as any)[e.target.value]
                  ],
                  task.output.mode
                )
              }
            >
              {Object.keys(FHIRR4Resource)
                .filter((key) => isNaN(Number(FHIRR4Resource[key as any])))
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {(FHIRR4Resource as any)[key]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {[DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
          task.output.mode
        ) === false && (
          <XmlOutputMappingProperties
            taskIndex={context.tasks.indexOf(task)}
            context={context}
            classes={classes}
          />
        )}
        {task.output.mode === DataFormat.QueryString && (
          <FormControl
            fullWidth={true}
            className={classes.margin + classes.dataFromSelectors}
          >
            <RadioGroup
              row={true}
              value={
                task.requestMethod !== HttpMethod.GET
                  ? DataSource[task.output.dataSource]
                  : DataSource[DataSource.QueryString]
              }
              onChange={(e, value) => {
                task.output.dataSource = DataSource[
                  value as any
                ] as unknown as DataSource;
              }}
            >
              <FormControlLabel
                value={DataSource[DataSource.Body]}
                control={<Radio />}
                disabled={task.requestMethod === HttpMethod.GET}
                label="Body"
              />
              <FormControlLabel
                value={DataSource[DataSource.QueryString]}
                control={<Radio />}
                label="Querystring"
              />
            </RadioGroup>
          </FormControl>
        )}
        {task.selectedPropertyToken && (
          <Button
            className={classes.margin}
            onClick={onClickEditPath}
            variant={"text"}
          >
            <LinkIcon />
          </Button>
        )}
        <FormControl>
          <InputLabel></InputLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={task.useCRLFLineEndings}
                onChange={(e, value) => (task.useCRLFLineEndings = value)}
              />
            }
            label="CRLF"
          />
        </FormControl>
        <Button
          className={classes.margin}
          variant="text"
          onClick={() =>
            (task.requestContent.content = Prettifier.prettify(
              task.requestContent.content,
              task.output.mode
            ))
          }
        >
          <BrushIcon />
        </Button>
      </div>
    );
  }
);

export default RequestOutputFormat;
