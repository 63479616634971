import { FieldState, FormState } from "formstate";
import { Dictionary } from "lodash";
import { autorun, computed, observable, reaction } from "mobx";
import AppContext from "../../../App.Context";
import { AttachmentLocation } from "../../../Common/Enums/AttachmentLocation";
import { Behaviour } from "../../../Common/Enums/Behaviour";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import { HttpMethod } from "../../../Common/Enums/HttpMethod";
import { JobAction } from "../../../Common/Enums/JobAction";
import { ResponseHandlerType } from "../../../Common/Enums/ResponseHandlerType";
import { SmsProvider } from "../../../Common/Enums/SmsProvider";
import EndpointHelpers from "../../../Common/Helpers/EndpointHelpers";
import Prettifier from "../../../Common/Helpers/Prettifier";
import PropertyHelpers from "../../../Common/Helpers/PropertyHelpers";
import Serializers from "../../../Common/Helpers/Serializers";
import { FHIRR4Resource } from "../../../Common/HL7/4.0.0 (R4 Sequence)/Resource";
import FHIRHelper from "../../../Common/HL7/FHIRHelper";
import IAdapterDefinition from "../../../Common/Interfaces/IAdapterDefinition";
import IDictionary from "../../../Common/Interfaces/IDictionary";
import IEndpoint from "../../../Common/Interfaces/IEndpoint";
import IEndpointXmlSchema from "../../../Common/Interfaces/IEndpointXmlSchema";
import EndpointService from "../../../Common/Services/Endpoint.Service";
import CertificateContext from "../../Certificates/CertificateContext";
import ITest from "../../EndpointTests/ITest";
import IFilter from "../../TaskFilters/IFilter";
import AceInputContext from "./AceInputContext";
import HeaderContext from "./HeaderContext";
import QuillInputContext from "./QuillInputContext";
import EndpointData from "./Step.Context";
import TextInputContext from "./TextInputContext";
import XSLTParameterContext from "./XSLTParameterContext";
import { JwtAlgorithm } from "../../../Common/Enums/Algorithm";
import JWTClaimContext from "./JWTClaimContext";
import Constants from "../../../Common/Constants";
import AireAuditContext from "./AireAuditContext";

export default class TaskContext {
  public constructor(appContext: AppContext, editInitialLoad: boolean) {
    this._appContext = appContext;
    this.output = new EndpointData();
    this.editInitialLoad = editInitialLoad;
  }

  private _appContext: AppContext;

  @observable
  public output: EndpointData;

  @observable
  public filters: IFilter[] = [];

  @observable
  public mappingValid: boolean = true;

  @observable
  public reinitializeOutput: boolean = true;

  @observable
  public behaviour: Behaviour = Behaviour.Request;

  @observable
  public behaviourSelection: string = Behaviour[Behaviour.Request];

  @observable
  public addedProperties: IDictionary<string> = {};

  @observable
  public addedPropertyOrigins: IDictionary<number> = {};

  @observable
  public preview?: string;

  @computed
  public get previewIsValid(): boolean {
    return EndpointHelpers.isHTTPTaskOutputValid(
      this.output.mode,
      this.output.error,
      this.preview
    );
  }

  @observable
  public editInitialLoad: boolean = false;

  @observable
  public functions: {} = {};

  @observable
  public submissionUrl: TextInputContext = new TextInputContext();

  @observable
  public requestMethod: HttpMethod = HttpMethod.POST;

  @observable
  public retryOnFailure: boolean = false;

  @observable
  public retainStatusCode: boolean = true;

  @observable
  public activeTextInput: TextInputContext | null = null;

  @observable
  public requestContent: AceInputContext = new AceInputContext();

  @observable
  public responseContent: AceInputContext = new AceInputContext();

  @observable
  public redirectContent: AceInputContext = new AceInputContext();

  @observable
  public responseHandlerContent: AceInputContext = new AceInputContext();

  @observable
  public responseHandlerJsonSchema?: string;

  @observable
  public responseHandlerXmlSchemas?: IEndpointXmlSchema[];

  @observable
  public emailTo: TextInputContext = new TextInputContext();

  @observable
  public emailBcc: TextInputContext = new TextInputContext();

  @observable
  public emailFrom: TextInputContext = new TextInputContext();

  @observable
  public emailSubject: TextInputContext = new TextInputContext();

  @observable
  public archiveAttachment: boolean = false;

  @observable
  public archiveFilename: TextInputContext = new TextInputContext();

  @observable
  public archivePassword: TextInputContext = new TextInputContext();

  @observable
  public isHtmlBody: boolean = true;

  @observable
  public isRawHtml: boolean = false;

  @observable
  public attachmentIsFile?: boolean = undefined;

  @observable
  public emailAttachment: TextInputContext = new TextInputContext();

  @observable
  public emailAttachmentMime: TextInputContext = new TextInputContext();

  @observable
  public emailAttachmentFilename: TextInputContext = new TextInputContext();

  @observable
  public emailBodyHtml: QuillInputContext = new QuillInputContext();

  @observable
  public emailBodyHtmlRaw: AceInputContext = new AceInputContext();

  @observable
  public emailBodyPlain: TextInputContext = new TextInputContext();

  @observable
  public emailUseCustomSmtp: boolean = false;

  @observable
  public emailSmtpHost: TextInputContext = new TextInputContext();

  @observable
  public emailSmtpPort: TextInputContext = new TextInputContext();

  @observable
  public emailSmtpUsername: TextInputContext = new TextInputContext();

  @observable
  public emailSmtpPassword: TextInputContext = new TextInputContext();

  @observable
  public emailSmtpUseSSL: boolean = false;

  @observable
  public smsTo: TextInputContext = new TextInputContext();

  @observable
  public smsBody: TextInputContext = new TextInputContext();

  @observable
  public smsProvider: SmsProvider = SmsProvider.Messagebird;

  @observable
  public smsSenderId: TextInputContext = new TextInputContext();

  @observable
  public faxFrom: TextInputContext = new TextInputContext();

  @observable
  public faxTo: TextInputContext = new TextInputContext();

  @observable
  public faxNumber: TextInputContext = new TextInputContext();

  @observable
  public faxAttachment: TextInputContext = new TextInputContext();

  @observable
  public faxSubject: TextInputContext = new TextInputContext();

  @observable
  public faxBody: QuillInputContext = new QuillInputContext();

  @observable
  public pdfAttachment: TextInputContext = new TextInputContext();

  @observable
  public pdfBody: QuillInputContext = new QuillInputContext();

  @observable
  public rawHtmlPdfBody: AceInputContext = new AceInputContext();

  @observable pdfAttachmentLocation: AttachmentLocation =
    AttachmentLocation.After;

  @observable
  public jobExpression: TextInputContext = new TextInputContext("0 3 * * *");

  @observable
  public meshSenderMailbox: TextInputContext = new TextInputContext();

  @observable
  public meshSenderMailboxPassword: TextInputContext = new TextInputContext();

  @observable
  public meshRecipientMailbox: TextInputContext = new TextInputContext();

  @observable
  public meshWorkflowId: TextInputContext = new TextInputContext();

  @observable
  public meshSubject: TextInputContext = new TextInputContext();

  @observable
  public meshBody: TextInputContext = new TextInputContext();

  @observable
  public meshCompress: boolean = false;

  @observable
  public meshLocalId: TextInputContext = new TextInputContext();

  @observable
  public jobName: TextInputContext = new TextInputContext();

  @observable
  public jobDisableOnFailure: boolean = false;

  @observable
  public jobContent: AceInputContext = new AceInputContext();

  @observable
  public jobEndpoint?: IEndpoint;

  @observable
  public jobEndpointEnvironmentOrVersion: string = Constants.Production;

  @observable
  public jobSchedule: string = "0 3 * * *";

  @observable
  public jobAction: JobAction = JobAction.Create;

  @observable
  public tests: ITest[] = [];

  @observable
  public headers: HeaderContext[] = [];

  @observable
  public jwtHeaders: JWTClaimContext[] = [];

  @observable
  public jwtPayload: JWTClaimContext[] = [];

  @observable
  public statusCode: TextInputContext = new TextInputContext("200");

  @observable
  public xsltParameters: XSLTParameterContext[] = [];

  @observable
  public jobStartDate?: Date | null;

  @observable
  public jobEndDate?: Date | null;

  @observable
  public manualStartDate: TextInputContext = new TextInputContext();

  @observable
  public manualEndDate: TextInputContext = new TextInputContext();

  @observable
  public updatedStartDate: boolean = false;

  @observable
  public updatedEndDate: boolean = false;

  @observable
  public isDynamicJobStartDate: boolean = false;

  @observable
  public isDynamicJobEndDate: boolean = false;

  @observable
  public sftpUrl: TextInputContext = new TextInputContext();

  @observable
  public sftpUsername: TextInputContext = new TextInputContext();

  @observable
  public sftpFilename: TextInputContext = new TextInputContext();

  @observable
  public sftpPassword: TextInputContext = new TextInputContext();

  @observable
  public sftpBody: TextInputContext = new TextInputContext();

  @observable sftpPort: TextInputContext = new TextInputContext();

  @observable
  public showDates: boolean = true;

  @observable
  public showManualDates: boolean = true;

  @observable
  public editingPropertyToken?: string;

  @observable
  public clientCertificates: CertificateContext[] = [];

  @observable
  public selectedPropertyToken?: string;

  @observable
  public editingPropertyTokenOrigin?: number;

  @observable
  public algorithm: JwtAlgorithm = JwtAlgorithm.RS512;

  @observable
  public jwtSecret: TextInputContext = new TextInputContext();

  @observable
  public jwtEncryptionSecret: TextInputContext = new TextInputContext();

  @observable
  public aireAudit: AireAuditContext = new AireAuditContext();

  @observable
  public mappingSelectedDataOrigin: number = -1;

  @observable
  public taskId: string = "";

  @observable
  public deleteOnJobExpire: boolean = false;

  public handleShowDatesChange = () => {
    this.showDates = !this.showDates;
  };

  public handleDeleteOnJobExpireChange = () => {
    this.deleteOnJobExpire = !this.deleteOnJobExpire;
  };

  public handleManualDatesChange = () => {
    this.showManualDates = !this.showManualDates;
  };

  public onDateClear = (isStartDate: boolean) => {
    if (isStartDate) {
      this.jobStartDate = null;
    } else {
      this.jobEndDate = null;
    }
  };

  public dateFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isStartDate: boolean
  ) => {
    var manualInput = e.target.value;

    if (isStartDate) {
      this.updatedStartDate = false;
      this.manualStartDate.content = manualInput;
    } else {
      this.updatedEndDate = false;
      this.manualEndDate.content = manualInput;
    }
  };

  public onDateChange = (date: Date | null, isStartDate: boolean) => {
    if (date) {
      if (isStartDate) {
        this.jobStartDate = date;
      } else {
        this.jobEndDate = date;
      }
    }

    this.manualStartDate.content = "";
    this.manualEndDate.content = "";
    this.isDynamicJobStartDate = false;
    this.isDynamicJobEndDate = false;
  };

  public updateSelectedToken = autorun(() => {
    if (this.activeTextInput && this.activeTextInput.cursorLocation) {
      this.selectedPropertyToken = this.activeTextInput.getSelectedToken();
    }
  });

  @observable
  public adapter?: IAdapterDefinition;

  @observable
  public adapterFieldValues: Dictionary<TextInputContext> = {};

  public deleteAddedProperty(property: string) {
    delete this.addedProperties[property];
    delete this.addedPropertyOrigins[property];
  }

  public refersToTask(task: number): boolean {
    let addedPropertyOrigin: string;
    for (addedPropertyOrigin in this.addedPropertyOrigins) {
      if (this.addedPropertyOrigins[addedPropertyOrigin] === task) {
        //no need to keep checking if we found this
        return true;
      }
    }
    for (var i = 0; i < this.filters.length; i++) {
      if (
        this.filters[i].dataOrigin != null &&
        this.filters[i].dataOrigin! === task
      ) {
        return true;
      }
    }
    if (
      this.responseHandlerRequestField.value &&
      +this.responseHandlerRequestField.value === task
    ) {
      return true;
    }
    return false;
  }

  public minimumReferencedIndex(): number {
    let minimum = Number.POSITIVE_INFINITY;
    if (this.responseHandlerRequestField.value) {
      minimum = +this.responseHandlerRequestField.value;
    }
    let minimumPropertyOrigin = this.minimumPropertyOrigin();
    let minimumFilterOrigin = this.minimumFilterOrigin();
    minimum = Math.min(minimum, minimumPropertyOrigin, minimumFilterOrigin);
    return minimum === Number.POSITIVE_INFINITY ? -1 : minimum;
  }

  private minimumPropertyOrigin(): number {
    let addedPropertyOrigin: string;
    let minimum = Number.POSITIVE_INFINITY;
    for (addedPropertyOrigin in this.addedPropertyOrigins) {
      if (this.addedPropertyOrigins[addedPropertyOrigin] < minimum) {
        minimum = this.addedPropertyOrigins[addedPropertyOrigin];
      }
    }
    return minimum;
  }

  private minimumFilterOrigin(): number {
    let minimum = Number.POSITIVE_INFINITY;
    for (var i = 0; i < this.filters.length; i++) {
      if (
        this.filters[i].dataOrigin != null &&
        this.filters[i].dataOrigin! < minimum
      ) {
        minimum = this.filters[i].dataOrigin!;
      }
    }
    return minimum;
  }

  public deleteReferencesToTask(task: number) {
    let referencingProperties: string[] = [];
    let addedPropertyOrigin: string;

    for (addedPropertyOrigin in this.addedPropertyOrigins) {
      if (task === this.addedPropertyOrigins[addedPropertyOrigin]) {
        referencingProperties.push(addedPropertyOrigin);
      }
    }

    referencingProperties.forEach((p) => {
      this.deleteAddedProperty(p);
    });

    this.filters = this.filters.filter(
      (f) => f.dataOrigin == null || f.dataOrigin! !== task
    );

    if (
      this.responseHandlerRequestField.value &&
      +this.responseHandlerRequestField.value === task
    ) {
      this.responseHandlerRequestField.onChange("");
    }
  }

  public decrementTaskReferences(startingTask: number) {
    this.decrementPropertyOrigins(startingTask);
    this.decrementFilterOrigins(startingTask);
    this.decrementResponseReference(startingTask);
  }

  private decrementPropertyOrigins(startingTask: number) {
    let addedPropertyOrigin: string;
    for (addedPropertyOrigin in this.addedPropertyOrigins) {
      if (this.addedPropertyOrigins[addedPropertyOrigin] > startingTask) {
        this.addedPropertyOrigins[addedPropertyOrigin] =
          this.addedPropertyOrigins[addedPropertyOrigin] - 1;
      }
    }
  }

  private decrementFilterOrigins(startingTask: number) {
    for (var f = 0; f < this.filters.length; f++) {
      if (
        this.filters[f].dataOrigin != null &&
        this.filters[f].dataOrigin! > startingTask
      ) {
        this.filters[f].dataOrigin = this.filters[f].dataOrigin! - 1;
      }
    }
  }

  private decrementResponseReference(startingTask: number) {
    if (
      this.responseHandlerRequestField.value &&
      +this.responseHandlerRequestField.value > startingTask
    ) {
      this.responseHandlerRequestField.onChange(
        (+this.responseHandlerRequestField.value - 1).toString()
      );
    }
  }

  private updatePropertyOrigins(indexChanges: IDictionary<number>) {
    let addedPropertyOrigin: string;
    for (addedPropertyOrigin in this.addedPropertyOrigins) {
      if (
        indexChanges[this.addedPropertyOrigins[addedPropertyOrigin]] !==
        undefined
      ) {
        this.addedPropertyOrigins[addedPropertyOrigin] =
          indexChanges[this.addedPropertyOrigins[addedPropertyOrigin]];
      }
    }
  }

  private updateResponseReference(indexChanges: IDictionary<number>) {
    if (
      this.responseHandlerRequestField.value &&
      indexChanges[this.responseHandlerRequestField.value] !== undefined
    ) {
      this.responseHandlerRequestField.onChange(
        indexChanges[+this.responseHandlerRequestField.value].toString()
      );
    }
  }

  private updateFilterOrigins(indexChanges: IDictionary<number>) {
    for (var f = 0; f < this.filters.length; f++) {
      if (
        this.filters[f].dataOrigin != null &&
        indexChanges[this.filters[f].dataOrigin!] !== undefined
      ) {
        this.filters[f].dataOrigin = indexChanges[this.filters[f].dataOrigin!];
      }
    }
  }

  public updateReferencesToTasks(indexChanges: IDictionary<number>) {
    this.updatePropertyOrigins(indexChanges);
    this.updateResponseReference(indexChanges);
    this.updateFilterOrigins(indexChanges);
  }

  public labelField = new FieldState("");

  public responseHandlerRequestField = new FieldState("").validators(
    (val) =>
      this.behaviour === Behaviour.ResponseHandler &&
      val === "" &&
      "Select a HTTP request task"
  );
  public responseHandlerTypeField = new FieldState(
    ResponseHandlerType.Forward
  ).validators(
    (val) =>
      this.behaviour === Behaviour.ResponseHandler &&
      val === undefined &&
      "Select a type"
  );

  taskForm = new FormState({
    label: this.labelField,
    responseHandlerRequest: this.responseHandlerRequestField,
    responseHandlerType: this.responseHandlerTypeField,
  });

  public getContent(preview?: boolean): string {
    if (this.behaviour === Behaviour.Request) {
      return this.requestContent.content;
    } else if (this.behaviour === Behaviour.Response) {
      return this.responseContent.content;
    } else if (this.behaviour === Behaviour.ResponseHandler) {
      return preview &&
        this.responseHandlerTypeField.value ===
          ResponseHandlerType.TransparentForward
        ? "Complete Http Response"
        : this.responseHandlerContent.content;
    } else if (this.behaviour === Behaviour.Email) {
      return this.isHtmlBody
        ? this.emailBodyHtml.content
        : this.emailBodyPlain.content;
    } else if (this.behaviour === Behaviour.SMS) {
      return this.smsBody.content;
    } else if (this.behaviour === Behaviour.Fax) {
      return this.faxBody.content;
    } else if (this.behaviour === Behaviour.Job) {
      return this.jobContent.content;
    } else if (this.behaviour === Behaviour.MESH) {
      return this.meshBody.content;
    } else if (this.behaviour === Behaviour.Redirect) {
      return this.redirectContent.content;
    } else if (this.behaviour === Behaviour.Mapping) {
      return preview ? this.responseContent.content : this.preview || "";
    } else if (this.behaviour === Behaviour.PDF) {
      let content = this.pdfBody.content;
      if (this.isRawHtml) {
        content = this.rawHtmlPdfBody.content;
      }
      return preview ? content : '{"pdfResult" : "pdf in base 64"}';
    } else if (this.behaviour === Behaviour.Adapter) {
      const primaryField = this.adapter?.fields.find((field) => field.primary);
      if (primaryField) {
        return this.adapterFieldValues[primaryField.name]?.content;
      }
    } else if (this.behaviour === Behaviour.JWT) {
      return '{"token" : "encoded token"}';
    } else if (this.behaviour === Behaviour.AireAudit) {
      return "No preview available";
    }

    return "";
  }

  public setContent(value: string) {
    if (this.behaviour === Behaviour.Request) {
      this.requestContent.content = value;
    } else if (this.behaviour === Behaviour.ResponseHandler) {
      this.responseHandlerContent.content = value;
    } else if (this.behaviour === Behaviour.Response) {
      this.responseContent.content = value;
    } else if (this.behaviour === Behaviour.Mapping) {
      this.responseContent.content = value;
    } else if (this.behaviour === Behaviour.Email) {
      this.isHtmlBody
        ? (this.emailBodyHtml.content = value)
        : (this.emailBodyPlain.content = value);
    } else if (this.behaviour === Behaviour.SMS) {
      this.smsBody.content = value;
    } else if (this.behaviour === Behaviour.Fax) {
      this.faxBody.content = value;
    } else if (this.behaviour === Behaviour.Job) {
      this.jobContent.content = value;
    } else if (this.behaviour === Behaviour.MESH) {
      this.meshBody.content = value;
    } else if (this.behaviour === Behaviour.Redirect) {
      this.redirectContent.content = value;
    } else if (this.behaviour === Behaviour.PDF) {
      this.isRawHtml
        ? (this.rawHtmlPdfBody.content = value)
        : (this.pdfBody.content = value);
    } else if (this.behaviour === Behaviour.SFTP) {
      this.sftpBody.content = value;
    }
  }

  public updateHL7v2OutputData = () => {
    if (this.output.mode === DataFormat.HL7v2) {
      this.output.loading = true;
      EndpointService.GetInputData(this._appContext.managementHost, {
        input: this.getContent(),
        format: DataFormat.HL7v2,
      })
        .then((result) => {
          this.output.data = result;

          if (!result.success) this.output.error = result.error;
          else this.output.error = null;
        })
        .finally(() => (this.output.loading = false));
    }
  };

  public updateData = autorun(
    async () => {
      if (this.output) {
        this.output.modified = true;

        if (
          [DataFormat.JSON, DataFormat.HL7FHIRJSON, DataFormat.Text].includes(
            this.output.mode
          )
        ) {
          this.output.data = Serializers.parseJsonIfValid(this.getContent()) as
            | {}
            | [];
        } else if (
          [DataFormat.XML, DataFormat.HL7FHIRXML, DataFormat.HTML].includes(
            this.output.mode
          )
        ) {
          this.output.data = Serializers.parseXmlIfValid(
            this.getContent()
          ) as Document;
        } else if (this.output.mode === DataFormat.QueryString) {
          this.output.data = Serializers.parseUrlIfValid(
            this.getContent()
          ) as {};
        } else if (this.output.mode === DataFormat.HL7v2) {
          this.output.data = await EndpointService.GetInputData(
            this._appContext.managementHost,
            {
              input: this.getContent(),
              format: DataFormat.HL7v2,
            }
          );
        } else if (this.output.mode === DataFormat.Turtle) {
          this.output.data = Serializers.parseTurtleIfValid(
            this.getContent()
          ) as {} | [];
        }
        // Validate HL7 compliance
        if (
          [DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
            this.output.mode
          )
        ) {
          FHIRHelper.Valid(this.output.mode, this.getContent()).then(
            (result) => {
              this.output.fhir.validationResult = result;
            }
          );
        } else {
          this.output.fhir.validationResult = undefined;
        }
      }
    },
    { delay: 500 }
  );

  public updateMappingValid = reaction(
    () => this.textInputContextFields.map((p) => p.content),
    (properties) =>
      (this.mappingValid = PropertyHelpers.propertiesValid(
        properties,
        this.addedProperties
      )),
    { delay: 200 }
  );

  @computed
  public get textInputContextFields(): TextInputContext[] {
    return EndpointHelpers.getTextInputContextFields(this);
  }

  public switchFHIRResource = (resource: FHIRR4Resource, mode: DataFormat) => {
    this.output.fhir.resource = resource;
    this.output.loading = true;
    EndpointService.GetTemplate(
      this._appContext.managementHost,
      mode,
      this.output.fhir.resource
    )
      .then((result) => {
        this.setContent(Prettifier.prettify(result, mode));
      })
      .finally(() => (this.output.loading = false));
  };

  public getUniquePropertyTokenName(tokenKey: string): string {
    let uniqueProperty = tokenKey;
    // Ensure unique properties
    if (this.isUniqueAcrossAllTextFields(uniqueProperty)) {
      let propertyPostfix = 1;
      while (
        this.isUniqueAcrossAllTextFields(uniqueProperty + propertyPostfix)
      ) {
        propertyPostfix++;
      }
      uniqueProperty = uniqueProperty + propertyPostfix;
    }
    return uniqueProperty;
  }

  private isUniqueAcrossAllTextFields(uniqueProperty: string) {
    let textInputs: any = [];

    if (this.behaviour === Behaviour.Request) {
      textInputs = [this.requestContent, this.submissionUrl];
    } else if (this.behaviour === Behaviour.Email) {
      textInputs = [
        this.emailBodyHtml,
        this.emailBodyPlain,
        this.emailSubject,
        this.emailTo,
        this.emailAttachment,
        this.emailAttachmentFilename,
        this.emailSmtpHost,
        this.emailSmtpPort,
        this.emailSmtpUsername,
        this.emailSmtpPassword,
      ];
    } else if (this.behaviour === Behaviour.Job) {
      textInputs = [this.jobName, this.jobContent, this.jobExpression];
    } else if (this.behaviour === Behaviour.Fax) {
      textInputs = [
        this.faxAttachment,
        this.faxFrom,
        this.faxBody,
        this.faxSubject,
        this.faxTo,
        this.faxNumber,
      ];
    } else if (this.behaviour === Behaviour.SMS) {
      textInputs = [this.smsBody, this.smsTo];
    } else if (this.behaviour === Behaviour.MESH) {
      textInputs = [this.meshBody];
    } else if (this.behaviour === Behaviour.PDF) {
      textInputs = [
        this.isRawHtml ? this.rawHtmlPdfBody : this.pdfBody,
        this.pdfAttachment,
      ];
    } else if (
      this.behaviour === Behaviour.Mapping ||
      this.behaviour === Behaviour.Response
    ) {
      textInputs = [this.responseContent];
    } else if (this.behaviour === Behaviour.SFTP) {
      textInputs = [
        this.sftpBody,
        this.sftpFilename,
        this.sftpPassword,
        this.sftpUrl,
        this.sftpUsername,
      ];
    }

    return textInputs.some((textInput: TextInputContext) => {
      return (
        textInput &&
        !!textInput.content &&
        textInput.content.includes(
          PropertyHelpers.getPropertyToken(uniqueProperty)
        )
      );
    });
  }

  public setAddedProperty = (
    property: string,
    path: string,
    origin?: number
  ) => {
    this.addedProperties[property] = path;
    if ([undefined, null, -1].includes(origin) === false) {
      this.addedPropertyOrigins[property] = origin as number;
    }
  };

  public set useCRLFLineEndings(value: boolean) {
    this._useCRLFLineEndings = value;

    if (this.activeTextInput) {
      if (value) {
        this.activeTextInput.content = this.activeTextInput.content.replace(
          "\n",
          "\r\n"
        );
      } else {
        this.activeTextInput.content = this.activeTextInput.content.replace(
          "\r\n",
          "\n"
        );
      }
    }
  }

  @computed
  public get useCRLFLineEndings() {
    return this._useCRLFLineEndings || false;
  }

  @observable
  private _useCRLFLineEndings?: boolean = false;
}
