import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { appStyles } from "../../App.Styles";
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  InputLabel,
  Input,
  IconButton,
  Paper,
} from "@material-ui/core";
import SecretContext from "./Secret.Context";
import { Redirect } from "react-router";
import Help from "../Help/Help";
import Loading from "../Loading/Loading";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import SaveIcon from "@material-ui/icons/Save";
import ErrorIcon from "@material-ui/icons/Error";
import TickIcon from "@material-ui/icons/Check";
import classNames from "classnames";
import BaseEditorHelpers from "../../Common/Helpers/BaseEditorHelpers";

interface IProps extends IComponentProps {
  context: SecretContext;
}

@observer
export class Secret extends React.Component<IProps> {
  private editorHelpers = new BaseEditorHelpers();

  public render() {
    return this.props.context.redirect ? (
      <Redirect to="/Secrets" />
    ) : (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              Add a secret{" "}
              <Help
                classes={this.props.classes}
                helpText="Secrets should be used to store sensitive values such as API keys and credentials. They are stored in encrypted form, so you won't be able to access the plaintext value once saved."
              />
            </Typography>
            {this.props.context.response && (
              <Paper
                className={classNames(
                  this.props.classes.paper,
                  this.props.classes.marginTop
                )}
                id="secret-create-results"
              >
                {this.props.context.response &&
                this.props.context.response!.status < 400 &&
                this.props.context.response!.status > -1 ? (
                  <TickIcon color="primary" />
                ) : (
                  <ErrorIcon color="error" />
                )}
                {this.props.context.response!.status > -1 && (
                  <Typography className={this.props.classes.tileText}>
                    {this.props.context.response!.status < 400
                      ? "Success!"
                      : typeof this.props.context.response.result === "string"
                      ? this.props.context.response.result
                      : "Error"}
                  </Typography>
                )}
                {this.props.context.response!.result &&
                  this.props.context.response!.result!.errors &&
                  Object.keys(this.props.context.response!.result!.errors).map(
                    (key) => (
                      <Typography>
                        {key ? key.toUpperCase() : "General"}:{" "}
                        {this.props.context.response!.result!.errors![key].map(
                          (error) => error
                        )}
                      </Typography>
                    )
                  )}
              </Paper>
            )}
          </Grid>
          {this.props.context.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <form
              className={this.props.classes.form}
              noValidate
              autoComplete="off"
              onSubmit={this.props.context.onSubmit}
            >
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    id="secret-name"
                    autoComplete="off"
                    fullWidth
                    autoFocus
                    label="Name"
                    onChange={(e) =>
                      this.props.context.keyField.onChange(e.target.value)
                    }
                    onKeyPress={(e) =>
                      this.editorHelpers.replaceSpaceWithDashOnKeyPress(e)
                    }
                    placeholder="Enter a name"
                    value={this.props.context.keyField.value}
                    error={this.props.context.keyField.hasError}
                    variant="filled"
                  />
                  <p>{this.props.context.keyField.error}</p>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Value</InputLabel>
                  <Input
                    id="secret-value"
                    autoComplete="new-password"
                    fullWidth
                    onChange={(e) =>
                      this.props.context.valueField.onChange(e.target.value)
                    }
                    placeholder="Enter a value"
                    value={this.props.context.valueField.value}
                    error={this.props.context.valueField.hasError}
                    type={this.props.context.showValue ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            (this.props.context.showValue = !this.props.context
                              .showValue)
                          }
                        >
                          {this.props.context.showValue ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p>{this.props.context.valueField.error}</p>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  id="secret-save"
                  className={this.props.classes.marginTop}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={this.props.context.form.hasError === true}
                  fullWidth
                >
                  {
                    <React.Fragment>
                      <SaveIcon fontSize="large" /> Save
                    </React.Fragment>
                  }
                </Button>
              </Grid>
            </form>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps> {
    render() {
      const context = new SecretContext(this.props.appContext);

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(withContext(Secret));
