import {
  Accordion,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { JwtAlgorithm } from "../../Common/Enums/Algorithm";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import JwtClaims from "./JwtClaims";
import LinkIcon from "@material-ui/icons/Link";
import JwtHelpers from "../../Common/Helpers/JwtHelpers";
import WarningIcon from "@material-ui/icons/Warning";

interface IProps {
  task: TaskContext;
  context: EndpointWizardContext;
  classes: any;
  onDrop: (ev: React.DragEvent<HTMLElement>) => void;
  onClickEditPath: () => void;
}

const JwtProperties: React.FC<IProps> = observer(
  ({ task, classes, context, onDrop, onClickEditPath }) => {
    const secretRef = React.createRef<HTMLInputElement>();
    const encryptionRef = React.createRef<HTMLInputElement>();

    return (
      <Accordion style={{ margin: classes.margin }} expanded>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="overline">Algorithm</Typography>
          </Grid>
          <Grid item xs={6}>
            {task.algorithm === JwtAlgorithm.None ? null : (
              <Typography variant="overline">
                Signing Secret (Private key in PKCS #8)
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Select
              className={classes.padding}
              fullWidth
              value={task.algorithm}
              id="jwtAlgorithm"
              onChange={(e) => {
                task.algorithm = JwtHelpers.GetJWTAlgorithmFromNumber(
                  e.target.value as number
                );
                if (
                  task.algorithm === JwtAlgorithm.None &&
                  secretRef.current !== null
                ) {
                  secretRef.current.value = "";
                }
              }}
            >
              {Object.keys(JwtAlgorithm)
                .filter((key) =>
                  isNaN(Number((JwtAlgorithm as any)[key as any]))
                )
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    <Grid container>
                      <Grid item xs={2}>
                        <ListItemText>
                          {JwtHelpers.GetAlgorithmDisplayName(
                            JwtHelpers.GetJWTAlgorithmFromNumber(parseInt(key))
                          )}
                        </ListItemText>
                      </Grid>
                      <Grid item xs={10}>
                        {key === JwtAlgorithm.None.toString() ? (
                          <ListItemIcon>
                            <div title="Warning - unsecured tokens can be forged">
                              <WarningIcon style={{ height: "1.2em" }} />
                            </div>
                          </ListItemIcon>
                        ) : null}
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          {task.algorithm === JwtAlgorithm.None ? null : (
            <>
              <Grid item xs={6}>
                <MappablePasswordSetter
                  passwordField={task.jwtSecret}
                  onDrop={onDrop}
                  onFocus={(_) => (task.activeTextInput = task.jwtSecret)}
                  inputRef={secretRef}
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </>
          )}
          {task.algorithm !== JwtAlgorithm.AES128HS256 ? null : (
            <>
              <Grid item xs={12}>
              <Typography variant="overline">
                Encryption Secret
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <MappablePasswordSetter
                  passwordField={task.jwtEncryptionSecret}
                  onDrop={onDrop}
                  onFocus={(_) => (task.activeTextInput = task.jwtEncryptionSecret)}
                  inputRef={encryptionRef}
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </>
          )}
          <Grid item xs={2}>
            {task.selectedPropertyToken && (
              <Button
                className={classes.margin}
                onClick={onClickEditPath}
                variant={"text"}
              >
                <LinkIcon />
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider></Divider>
        <JwtClaims
          task={task}
          title={"JWT-Headers"}
          claims={task.jwtHeaders}
          prefix="header"
        />
        <JwtClaims
          task={task}
          title={"JWT-Payload"}
          claims={task.jwtPayload}
          prefix="payload"
        />
      </Accordion>
    );
  }
);

export default JwtProperties;
