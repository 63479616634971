import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import SecretsContext from "./Secrets.Context";
import { appStyles } from "../../App.Styles";
import Help from "../Help/Help";
import UpIcon from "@material-ui/icons/ArrowUpward";
import {
  withStyles,
  Button,
  Grid,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import SecretIcon from "@material-ui/icons/VpnKey";
import DeleteIcon from "@material-ui/icons/Delete";
import ISecret from "../../Common/Interfaces/ISecret";
import SecretService from "../Secret/Secret.Service";
import classNames from "classnames";
import { SecretSortType } from "../../Common/Enums/SecretSortType";
import { Can } from "../Authorisation/AbilityContext";

interface IProps extends IComponentProps {
  context: SecretsContext;
}

@observer
export class Secrets extends React.Component<IProps> {
  private onClickDelete = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    secret: ISecret
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      window.confirm(
        `Are you sure you want to delete '${secret.key}'? Any usages of the secret will NOT be removed!`
      )
    ) {
      SecretService.Delete(this.props.appContext, secret.id as string).then(
        () => this.props.context.getSecrets(this.props.appContext)
      );
    }
  };

  public render() {
    return this.props.context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Can I="create" a="Secret">
            <Link to="/Secret">
              <Button
                id="add-secret"
                variant="outlined"
                color="default"
                size="large"
                fullWidth
              >
                <AddIcon fontSize="large" />
              </Button>
            </Link>
          </Can>
          {
            <Grid
              item
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <FormControl
                className={classNames(
                  this.props.classes.margin,
                  this.props.classes.dataFromSelectors
                )}
              >
                <Grid item>
                  <FormLabel data-testid="secret-sort-label">
                    Sort By:&nbsp;
                    <Select
                      value={this.props.context.secretSortType}
                      inputProps={{
                        "data-testid": "secret-sort",
                      }}
                      id="secret-sort-select"
                      onChange={(e) => {
                        this.props.context.secretSortType = (
                          SecretSortType as any
                        )[e.target.value as any] as unknown as SecretSortType;
                        this.props.context.sortSecrets();
                      }}
                    >
                      {Object.keys(SecretSortType)
                        .filter((key) =>
                          isNaN(Number((SecretSortType as any)[key as any]))
                        )
                        .map((key) => (
                          <MenuItem
                            key={key}
                            id={`secret-sort-select-${key}`}
                            value={key}
                          >
                            {
                              (SecretSortType as any)[
                                (SecretSortType as any)[key as any]
                              ]
                            }
                          </MenuItem>
                        ))}
                    </Select>
                    <Help
                      classes={this.props.classes}
                      helpText="Sort secrets alphabetically or by date added"
                    />
                  </FormLabel>
                </Grid>
              </FormControl>
            </Grid>
          }
        </Grid>
        <Can I="list" a="Secret">
          <React.Fragment>
            {this.props.context.secrets.map((secret, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={
                    this.props.context.secrets.length > 1
                      ? this.props.context.secrets.length === 2
                        ? 6
                        : 4
                      : 12
                  }
                >
                  <Card id={`${secret.key}-card`}>
                    <CardHeader
                      avatar={
                        <Avatar>
                          <SecretIcon />
                        </Avatar>
                      }
                      action={
                        <IconButton
                          id={`${secret.key}-delete`}
                          onClick={(e) => this.onClickDelete(e, secret)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      title={secret.key}
                      subheader={`Added: ${secret.added!.toLocaleString()}`}
                    />
                  </Card>
                </Grid>
              );
            })}
          </React.Fragment>
        </Can>
        {this.props.context.secrets.length === 0 && (
          <Typography className={this.props.classes.centerText} variant="h5">
            <UpIcon fontSize="large" /> Add your secrets..
            <Help
              classes={this.props.classes}
              helpText="Secrets should be used to store sensitive values such as API keys and credentials. They are stored in encrypted form, so you won't be able to access the plaintext value once saved."
            />
          </Typography>
        )}
      </Grid>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps> {
    render() {
      const context = new SecretsContext(this.props.appContext);

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(withContext(Secrets));
