import LoggingService from '../../Common/Services/Logging.Service';
import AppContext from '../../App.Context';
import ICreateSecret from '../../Common/Interfaces/ICreateSecret';
import IWithErrorsResponse from '../../Common/Interfaces/IWithErrorsResponse';
import { getToken } from '../../Common/Helpers/AuthenticationHelpers';

export default class SecretService {
    public static Save = (appContext: AppContext, data: ICreateSecret): Promise<IWithErrorsResponse> => {
        const uri = `${appContext.managementHost}/Secret`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(async response => {
                return {
                    status: response.status,
                    result: await response.json()
                }
            })
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Delete = (appContext: AppContext, id: string): Promise<boolean> => {
        const uri = `${appContext.managementHost}/Secret/${id}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then(response => response.ok)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }
}