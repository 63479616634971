import { ReceiverType } from "../Enums/ReceiverType";

export const getDisplayName = (type: ReceiverType) => {
  switch (type) {
    case ReceiverType.POP3:
      return "POP3 Email Receiver";
    case ReceiverType.Fax:
      return "Fax Receiver";
    case ReceiverType.MESH:
      return "MESH Receiver";
    case ReceiverType.SMS:
      return "SMS Receiver";
    default:
      break;
  }
};
