import { SmsProvider } from "../Enums/SmsProvider";

export default class SmsProviderHelpers {
    public static GetDisplayName(provider: SmsProvider) {
        switch (provider) {
            case SmsProvider.Messagebird:
                return "MessageBird";
            case SmsProvider.GovNotify:
                return "GOV.UK Notify";
            default:
                return provider;
        }
    }
}