import { FieldState, FormState } from "formstate";
import { observable } from "mobx";
import { FormEvent } from "react";
import AppContext from "../../App.Context";
import BaseContext from "../../Common/BaseContext";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import ISecret from "../../Common/Interfaces/ISecret";
import IVariable from "../../Common/Interfaces/IVariable";
import EndpointService from "../../Common/Services/Endpoint.Service";
import RepositoriesService from "../Repositories/Repositories.Service";
import SecretsService from "../Secrets/Secrets.Service";
import VariableService from "./Variable.Service";
import VariableValueContext from "./VariableValue.Context";


export default class VariableContext extends BaseContext {
    constructor(
        appContext: AppContext,
        name?: string) {
        super(appContext);
        this.getEndpoints(appContext);
        this.getSecrets(appContext);
        this.appContext = appContext;
        if (name) {
            this.editingId = name;
            this.loading = true;

            VariableService.Get(appContext, name)
                .then(result => {
                    this.extractResult(result);
                })
                .finally(() => this.loading = false)
        }
    }

    @observable
    public redirect: boolean = false;

    @observable
    public editingId?: string;

    @observable
    public loading: boolean = false;

    @observable
    public endpoints: IEndpoint[] = [];

    @observable
    public valueContexts: VariableValueContext[] = [];

    @observable
    public saveError: string = "";

    @observable
    public secrets: ISecret[] = [];

    public nameField = new FieldState('').validators((val) => (!val && 'Enter a variable name')
    //eslint-disable-next-line no-useless-escape
        || (!RegExp('^[a-zA-Z\-]+$').test(val) && 'Variable name containing letters and dashes (-) only required'));

    private valuesValid = async (): Promise<boolean> => {
        var contextResults = await Promise.all(this.valueContexts.map(c => c.validate()));
        return !contextResults.some(valid => !valid);
    }

    form = new FormState({
        name: this.nameField
    }).validators((async (f) => this.valueContexts.length === 0 && "Please add at least one scope value"),
        f => this.hasDuplicateScopes() && "Duplicate scopes are not allowed");

    @observable
    public appContext: AppContext;


    private hasDuplicateScopes = () => {
        var values = this.valueContexts.map(v => { return v.buildValue() });
        var scopes = values.map(v => { return `${v.scopeType}:${v.scope}` });
        return new Set(scopes).size !== scopes.length;
    }

    public onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const validationResult = await this.form.validate();

        if (validationResult.hasError || !await this.valuesValid()) {
            return false;
        }

        this.loading = true;

        await VariableService.Save(this.appContext, this.buildVariableObject(), !this.editingId).then(response => {

            if (response.ok) {
                this.redirect = true;
            }
            else if (response.status === 409) {
                this.saveError = "Variable name already taken"
            }
            else {
                this.saveError = "Error saving variable"
            }
        })
            .finally(() => this.loading = false);
    };



    private buildVariableObject = (): IVariable => {
        return {
            id: this.editingId,
            name: this.nameField.value,
            values: this.valueContexts.map(v => v.buildValue()).filter(v => v.editable)
        }
    };

    private extractResult = (variable: IVariable) => {
        this.nameField.value = variable.name;
        this.editingId = variable.id;

        this.valueContexts = variable.values.map((value) => {
            return new VariableValueContext(value)
        });

    };

    public validateForm = () => {
        this.form.validate();
    }

    private getEndpoints(appContext: AppContext) {
        const endpointServicePromise = EndpointService.GetEndpoints(appContext);
        const repositoriesServicePromise = new RepositoriesService().GetRepositories(appContext);

        Promise.all([endpointServicePromise, repositoriesServicePromise]).then(([endpoints, repositories]) => {
            this.endpoints = this.endpoints
                .concat(endpoints)
                .concat(repositories.map(repository => repository.endpoints ?? []).flat())
                .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        })
            .finally(() => this.loading = false);
    }

    public getSecrets(appContext: AppContext) {
        new SecretsService().Get(appContext)
            .then(secrets => this.secrets = secrets)
            .finally(() => this.loading = false);
    }
}