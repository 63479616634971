import { observable } from "mobx";
import ISecret from "../../Common/Interfaces/ISecret";
import AppContext from "../../App.Context";
import SecretsService from "./Secrets.Service";
import { SecretSortType } from "../../Common/Enums/SecretSortType";

export default class SecretsContext {
    constructor(appContext: AppContext) {
        this.getSecrets(appContext);
    }

    @observable
    public secrets: ISecret[] = [];

    @observable
    public loading: boolean = false;

    @observable
    public secretSortType: SecretSortType = SecretSortType.Name;

    public getSecrets(appContext: AppContext) {
        this.loading = true;
        new SecretsService()
            .Get(appContext)
            .then(secrets => {
                this.secrets = secrets
                this.sortSecrets();
            })
            .finally(() => this.loading = false);
    }

    public sortSecrets() {
        if (this.secrets.length) {
            if (this.secretSortType === "Name") { //Sort alphabetically
                this.secrets =this.secrets.slice().sort(function (a, b): any {
                    var secretA = a.key.toUpperCase();
                    var secretB = b.key.toUpperCase();
                    return (secretA < secretB) ? -1 : (secretA > secretB) ? 1 : 0;
                })
            } else { // Sort by date
                this.secrets = this.secrets.slice().sort(function (a, b): any {
                    if (b.added && a.added) {
                        return (b.added.getTime() - a.added.getTime())
                    }
                    return 0;
                });
            }
        }
    }
}