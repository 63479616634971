import * as React from 'react';
import Loading from '../Loading/Loading';
import { Grid, TextField, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, withStyles, Typography, Button, InputAdornment, Paper, Box } from '@material-ui/core';
import IComponentProps from '../../Common/Interfaces/IComponentProps';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router';
import EndpointContext from './EndpointContext';
import { appStyles } from '../../App.Styles';
import MagicMenu from '../MagicMenu/MagicMenu';
import AceEditor from 'react-ace';
import "brace/mode/json";
import "brace/mode/text";
import "brace/mode/xml";
import 'brace/theme/tomorrow';
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from '@material-ui/icons/ExitToApp';
import Prettifier from '../../Common/Helpers/Prettifier';
import { DataFormat } from '../../Common/Enums/DataFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import EndpointService from '../../Common/Services/Endpoint.Service';
import EndpointInputTester from '../EndpointInputTester/EndpointInputTester';
import IEndpoint from '../../Common/Interfaces/IEndpoint';
import RunIcon from '@material-ui/icons/PlayArrow';
import DataFormatHelper from '../../Common/Helpers/DataFormatHelper';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import TickIcon from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';
import { EndpointType } from '../../Common/Enums/EndpointType';
import { Tooltip, IconButton } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard'
import AssignmentIcon from '@material-ui/icons/Assignment'; 
import EndpointExport from './EndpointExport';
import { isImageMediaType, isOtherStreamMediaType } from '../../Common/mediaType';

@observer
class Endpoint extends React.Component<IComponentProps & RouteComponentProps> {
    private readonly _version = parseInt((this.props.match.params as any)["version"]);
    private readonly _context = new EndpointContext(this.props.appContext, (this.props.match.params as any)["name"], this._version);
    private readonly _type = (this.props.match.params as any)["source"] === "Repository" ? EndpointType.Repository : EndpointType.Wizard;
    private _contentType; 

    public handleExport = (type: string) => {
        this._context.copied = false;
        this._context.loading = true;
        this._context.exportFormat = type;
        EndpointService.Export(this.props.appContext, this._context.endpoint!.name, type, this._context.includeEncrypted)
            .then(result => {
                if (type === "JSON") {
                    this._context.export = Prettifier.prettify(result, DataFormat.JSON)
                } else {
                    this._context.export = result
                }
            })
            .finally(() => this._context.loading = false)
    }

    public handleIncludeEncrypted = (includeEncrypted: boolean) => {
        this._context.includeEncrypted = includeEncrypted;
        this.handleExport(this._context.exportFormat);
    }

    private onInputChange = (input: string, _?: IEndpoint) => {
        this._context.input = input;
    }

    private runEndpoint = () => {
        if (window.confirm('Note that running an endpoint will execute any tasks associated with it, this should not be considered an offline test!')) {
            this._context.loading = true;
            EndpointService.Run(
                this.props.appContext,
                this._context.endpoint!.name,
                this.props.appContext.username as string,
                this._context.input,
                this._version.toString(),
                this._context.endpoint!.versions.find(v => v.version === this._version)!.method,
                this._context.endpoint!.versions.find(v => v.version === this._version)!.inputSource,
                this._context.endpoint!.versions.find(v => v.version === this._version)!.inputMode)
                .then(async result => {
                    this._contentType = result.headers.get('Content-Type');
                    this._context.resultStatus = result.status;

                    if(isImageMediaType(this._contentType)){
                        const blob = await result.blob();
                        const imageUrl = URL.createObjectURL(blob);
                        this._context.result = imageUrl;
                    }
                    else if(isOtherStreamMediaType(this._contentType)){
                        const blob = await result.blob();
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = "_blank";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this._context.resultStatus = result.status;
                    }
                    else if (this._context.endpoint!.versions.find(v => v.version === this._version)!.tasks.some(t => !!t.redirectContext) && result.type === 'opaqueredirect') {
                        this._context.result = "Redirect response";
                        this._context.resultStatus = 301;
                    } else {
                        const content = await result.text();
                        this._context.result = Prettifier.prettify(content, DataFormatHelper.GetDataFormatByContent(content) as DataFormat);
                    }
                })
                .catch(e => {
                    this._context.result = e.toString();
                    this._context.resultStatus = -1;
                })
                .finally(() => this._context.loading = false);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Grid
                    justify="flex-end"
                    container>
                    <Grid item>
                        <MagicMenu
                            title="Export"
                            icon={<ExportIcon />}
                            items={["JSON", "YAML"]}
                            onClick={this.handleExport}
                            buttonVariant="fab"
                            buttonColor="primary" />
                    </Grid>
                </Grid>
                <Typography align="center" variant="h4">
                    {
                        this._type === EndpointType.Repository ?
                            <FontAwesomeIcon icon={faCodeBranch} color="green" /> :
                            <FontAwesomeIcon icon={faMagic} color="green" />
                    }
                    <span className={this.props.classes.grow}>
                        Endpoint{this._context.endpoint && <em>: {this._context.endpoint.name}</em>}
                    </span>
                </Typography>
                {
                    this._context.export && !this._context.loading && (
                        <EndpointExport
                            exportText={this._context.export}
                            closeExport={() => this._context.export = ''}
                            includeEncrypted={this._context.includeEncrypted}
                            setIncludeEncrypted={this.handleIncludeEncrypted}
                            appContext={this.props.appContext} />
                    )
                }
                {
                    this._context.loading ?
                        <Loading
                            fullscreen={false} /> :
                        <React.Fragment>
                            <Grid
                                item xs={12}
                                container
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <Grid                                    
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <TextField
                                        label='Endpoint URL'
                                        className={this.props.classes.marginTop}
                                        value={`${this.props.appContext.runtimeHost}/${this.props.appContext.username}/${this._context.endpoint!.name}`}
                                        style={{"width":"90vw"}}
                                        disabled
                                        variant="filled"
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: 
                                                <InputAdornment position="start">
                                                    {this._context.endpoint!.versions.find(v => v.version === this._version)!.authenticationContexts.length > 0 && <LockIcon/>}
                                                    {this._context.endpoint!.versions.find(v => v.version === this._version)!.method}
                                                </InputAdornment>,
                                            endAdornment: 
                                                <CopyToClipboard text={`${this.props.appContext.runtimeHost}/${this.props.appContext.username}/${this._context.endpoint!.name}`}>
                                                    <Tooltip title="Copy" aria-label={`Copy${this.props.appContext.runtimeHost}/${this.props.appContext.username}/${this._context.endpoint!.name}`}>
                                                        <IconButton>
                                                            <AssignmentIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />                                    
                                </Grid>
                                <FormControl className={this.props.classes.marginTop}>
                                    <FormLabel>Environments</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled
                                                    checked={this._context.endpoint!.environments['Staging'] === this._version} />
                                            }
                                            label="Staging"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled
                                                    checked={this._context.endpoint!.environments['Production'] === this._version} />
                                            }
                                            label="Production"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <EndpointInputTester
                                    appContext={this.props.appContext}
                                    endpoint={this._context.endpoint as IEndpoint}
                                    input={this._context.input}
                                    onChange={this.onInputChange}
                                    version={this._version} />
                                {
                                    this._context.result && (
                                        <React.Fragment>
                                            <Grid
                                                container justify="center" alignItems="center" direction="column">
                                                <Grid item/>
                                                <Grid item>
                                                    <Paper className={classNames(this.props.classes.paper, this.props.classes.headerTiles, this.props.classes.marginTop)}>
                                                        {
                                                            this._context.resultStatus < 400 && this._context.resultStatus > -1 ?
                                                                <TickIcon color="primary"/> :
                                                                <ErrorIcon color="error"/>       
                                                        }
                                                        <Typography className={this.props.classes.tileText} id="endpoint-result">
                                                            Response: {this._context.resultStatus}
                                                        </Typography>
                                                    </Paper>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="default"
                                                        className={this.props.classes.margin}
                                                        onClick={e => this._context.result = ''}>
                                                        <CloseIcon />
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            {isImageMediaType(this._contentType) ? (
                                                <Box style={{ width: "100%", marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Box style={{ width: "auto" }}>
                                                        <img src={this._context.result} alt="Loaded from endpoint" />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <AceEditor
                                                className={this.props.classes.marginTop}
                                                mode={DataFormatHelper.GetModeString(DataFormatHelper.GetDataFormatByContent(this._context.result) as DataFormat)}
                                                name="endpoint-result"
                                                theme="tomorrow"
                                                fontSize={20}
                                                showPrintMargin={true}
                                                width='100%'
                                                readOnly
                                                showGutter={true}
                                                highlightActiveLine={false}
                                                value={this._context.result}
                                                editorProps={{ $blockScrolling: true }}
                                                setOptions={{
                                                    useWorker: false,
                                                    readOnly: true,
                                                    highlightGutterLine: false
                                                }} />
                                            )}
                                        </React.Fragment>
                                    )
                                }
                                <Button
                                    id="run-endpoint"
                                    onClick={this.runEndpoint}
                                    className={this.props.classes.marginTop}
                                    fullWidth
                                    variant="contained"
                                    color="primary"><RunIcon /> Run
                                </Button>
                            </Grid>
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default withStyles(appStyles, { withTheme: true })(withRouter(Endpoint));