export enum DataFormat {
  JSON,
  XML,
  QueryString,
  HL7v2,
  HL7FHIRJSON,
  HL7FHIRXML,
  HTML,
  None,
  Text,
  Turtle,
  Stream,
  Callback
}

export const DataFormatDisplayName = (format: DataFormat): string => {
  switch (format) {
    case DataFormat.HL7FHIRJSON:
      return "HL7 FHIR - JSON";
    case DataFormat.HL7FHIRXML:
      return "HL7 FHIR - XML";
    default:
      return DataFormat[format];
  }
};
