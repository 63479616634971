export enum LoggableAction {
  EndpointInvocation = 0,
  NonExistantEndpointInvocation = 1,
  EndpointValidationFailure = 2,
  EndpointException = 3,
  EndpointAuthentication = 4,
  EndpointAuthenticationFailure = 5,
  EndpointManagementAuthenticationFailure = 6,
  EndpointTaskSuccess = 7,
  EndpointTaskInvocationDemoMode = 8,
  EndpointTaskFailure = 9,
  EndpointTaskSkipped = 10,
  EndpointTaskBegins = 11,
  EndpointResponds = 12,
  FaxReceivingFailed = 13,
  MESHMessageReceived = 14,
  EmailMessageReceived = 15,
  ReceiverException = 16,
  EmailAttachmentExtractionError = 17,
  EndpointValidationSuccess = 18,
  EndpointTaskValuesResolved = 19,
  EmailMessageProcessed = 20,
  EmailMessageRejected = 21,
  EmailMessageAccepted = 22,
  EndpointInvocationFailure = 23,
  MESHMessageProcessed = 24,
  MESHMessageRejected = 25,
  MESHMessageAccepted = 26,
  MESHMessageReport = 27,
  EndpointTimeElapsedRecorded = 32
}
