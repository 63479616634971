import BaseEditorHelpers from "./BaseEditorHelpers";
import ReactQuill from "react-quill";

export default class QuillHelpers extends BaseEditorHelpers {

    public addTokenToOutput(
        token: string,
        cursorIndex: number,
        quillEditor: ReactQuill
    ) {
        if (quillEditor) {
            quillEditor.getEditor().insertText(cursorIndex, token);
            return quillEditor.getEditor().getText();
        }
        return null;
    }
}