import * as React from "react";
import { observer } from "mobx-react";
import { IconButton } from "@material-ui/core";
import DebugIcon from "@material-ui/icons/BugReport";
import AppContext from "../../App.Context";
import TransactionContextViewerContext from "./TransactionContextViewer.Context";
import TransactionContextViewerContent from "./TransactionContextViewerContent";

interface IPublicProps {
  appContext: AppContext;
  classes: any;
  id: string;
  onDelete: () => void;
}

interface IProps extends IPublicProps {
  context: TransactionContextViewerContext;
}

const handleClose = (context: TransactionContextViewerContext) => {
  context.anchorEl = undefined;
};

const onClick = (
  event: React.MouseEvent<HTMLButtonElement>,
  context: TransactionContextViewerContext
) => {
  context.anchorEl = event.currentTarget;
};

const TransactionContextViewer: React.FC<IProps> = observer(
  ({ appContext, classes, id, context, onDelete }) => {
    return (
      <span>
        <IconButton id={`debugicon-${id}`} onClick={(e) => onClick(e, context)}>
          <DebugIcon />
        </IconButton>
        <TransactionContextViewerContent
          {...{
            appContext: appContext,
            classes: classes,
            id: id,
            anchorEl: context.anchorEl,
            onClose: () => handleClose(context),
            onDelete: onDelete,
          }}
        />
      </span>
    );
  }
);

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IPublicProps> {
    render() {
      const context = new TransactionContextViewerContext();

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withContext(TransactionContextViewer);
