import { observable } from "mobx";
import AppContext from "../../App.Context";
import IVariable from "../../Common/Interfaces/IVariable";
import VariablesService from "../Variables/Variables.Service";

export default class VariableSelectorContext {
    constructor(appContext: AppContext) {
        this.getVariables(appContext);
    }

    @observable
    public loading: boolean = false;

    @observable
    public variables: IVariable[] = [];

    public getVariables(appContext: AppContext) {
        new VariablesService().Get(appContext)
            .then(variables => this.variables = variables)
            .finally(() => this.loading = false);
    }
}