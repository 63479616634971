import { FieldState, FormState } from "formstate";
import { observable } from "mobx";
import { VariableScopeType } from "../../Common/Enums/VariableScopeType";
import IVariableValue from "../../Common/Interfaces/IVariableValue";

export default class VariableValueContext {

    constructor(value: IVariableValue) {
        this.valueField.onChange(value.value);
        this.scopeField.onChange(value.scopeType);
        this.editable = value.editable;
        this.populateFields(value);
    }

    @observable
    public valueField = new FieldState('').validators(val => !val && "Please enter a value");
    @observable
    public scopeField = new FieldState(VariableScopeType.Tenant);
    @observable
    public endpointField = new FieldState('');
    @observable
    public endpointVersionField = new FieldState('');
    @observable
    public endpointEnvironmentField = new FieldState('');

    @observable
    public editable: boolean = true;

    public populateFields(value: IVariableValue) {
        switch (value.scopeType) {
            case VariableScopeType.Endpoint:
                this.endpointField.onChange(value.scope);
                break;
            case VariableScopeType.EndpointVersion:
                this.endpointField.onChange(value.scope.split('/')[0]);
                this.endpointVersionField.onChange(value.scope.split('/')[1]);
                break;
            case VariableScopeType.EndpointEnvironment:
                this.endpointField.onChange(value.scope.split('/')[0]);
                this.endpointEnvironmentField.onChange(value.scope.split('/')[1]);
                break;
            default:
                return;
        }
    }

    private buildScopeString(): string {
        switch (this.scopeField.value) {
            case VariableScopeType.Endpoint:
                return this.endpointField.value;
            case VariableScopeType.EndpointVersion:
                return `${this.endpointField.value}/${this.endpointVersionField.value}`
            case VariableScopeType.EndpointEnvironment:
                return `${this.endpointField.value}/${this.endpointEnvironmentField.value}`
            default:
                return "";
        }
    }

    public buildValue(): IVariableValue {
        return {
            value: this.valueField.value,
            editable: this.editable,
            scopeType: this.scopeField.value,
            scope: this.buildScopeString()
        };
    }
    
    form = new FormState({
        value: this.valueField,
        scope: this.scopeField,
        endpoint: this.endpointField,
        version: this.endpointVersionField,
        environment: this.endpointEnvironmentField
    }).validators(f => (f.scope.value === VariableScopeType.EndpointVersion && !f.version.value && "Please select a version"),
        f => (f.scope.value === VariableScopeType.EndpointEnvironment && !f.environment.value && "Please select an environment"),
        f => (f.scope.value !== VariableScopeType.Tenant && !f.endpoint.value && "Please select an endpoint")
    );

    public async validate(): Promise<boolean> {
        if (!this.editable) {
            return true;
        }
        let result = await this.form.validate();
        return !result.hasError;
    }

}