import { FieldState, FormState } from "formstate";

export default class ArchiveAttachmentContext {
    constructor(archiveName?: string, fileName?: string, password?: string) {
        this.archiveNameField.value = archiveName || '';
        this.fileNameField.value = fileName || '';
        this.passwordField.value = password || '';
    }

    public archiveNameField = new FieldState('').validators((val) => (!val && 'Enter an archive name'));
    public fileNameField = new FieldState('').validators((val) => (!val && 'Enter a filename'));
    public passwordField = new FieldState('');

    form = new FormState({
        archiveName: this.archiveNameField,
        fileName: this.fileNameField,
        password: this.passwordField
    });
}