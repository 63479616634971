import * as React from "react";
import { observer, useLocalStore } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { styles } from "./Account.Styles";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Input,
  Paper,
  Avatar,
  Typography,
  SnackbarContent,
} from "@material-ui/core";
import { Redirect } from "react-router";
import LoginContext from "./Login.Context";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import Loading from "../Loading/Loading";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

const Login: React.FC<IComponentProps> = observer(({ classes, appContext }) => {
  const context: LoginContext = useLocalStore(
    () => new LoginContext(appContext)
  );
  let location = useLocation();
  let from = (location.state as any)?.from || { pathname: "/" };
  return (
    <React.Fragment>
      {context.redirect ? (
        <Redirect to={from.pathname} />
      ) : context.loading ? (
        <Loading fullscreen={true} />
      ) : (
        <div className={classes.main}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={context.onSubmit}
            >
              <FormControl margin="normal" required fullWidth>
                <InputLabel>Username</InputLabel>
                <Input
                  autoComplete="username"
                  autoFocus
                  id="Username"
                  value={context.username.value}
                  error={context.username.hasError}
                  onChange={(e) => context.username.onChange(e.target.value)}
                />
              </FormControl>
              {context.username.hasError ? (
                <SnackbarContent
                  className={classes.error}
                  message={
                    <span className={classes.message}>
                      <ErrorIcon />
                      {context.username.error}
                    </span>
                  }
                />
              ) : null}
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="Password"
                  autoComplete="current-password"
                  value={context.password.value}
                  error={context.username.hasError}
                  onChange={(e) => context.password.onChange(e.target.value)}
                />
              </FormControl>
              {context.password.hasError ? (
                <SnackbarContent
                  className={classes.error}
                  message={
                    <span className={classes.message}>
                      <ErrorIcon />
                      {context.password.error}
                    </span>
                  }
                />
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
              {context.loginErrors.map((error, index) => {
                return (
                  <SnackbarContent
                    key={index}
                    className={classes.error}
                    message={
                      <span className={classes.message}>
                        <ErrorIcon className={classes.margin} />
                        {error}
                        {error === "The email address is unconfirmed" && (
                          <Typography align="center">
                            This is a subscription only environment, please
                            contact us to have your account activated.
                          </Typography>
                        )}
                      </span>
                    }
                  />
                );
              })}
            </form>
            Not registered yet?
            <Link
              to="/Register"
              className={classNames(classes.link, classes.primaryText)}
            >
              Create Account
            </Link>
            <br />
            Forgot your password?
            <Link
              to="/ResetPassword"
              className={classNames(classes.link, classes.primaryText)}
            >
              Reset
            </Link>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
});

export default withStyles(styles, { withTheme: true })(Login);
