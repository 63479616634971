import { observable } from "mobx";
import AppContext from "../../App.Context";
import { IReceiverBase } from "../../Common/Interfaces/IReceiver";
import ReceiversService from "./Receivers.Service";

export default class ReceiversContext {
    constructor(appContext: AppContext) {
        this.GetReceivers(appContext);
    }

    @observable
    public loading: boolean = false;

    @observable
    public receivers: IReceiverBase[] = [];

    public GetReceivers(appContext: AppContext) {
        this.loading = true;
        new ReceiversService()
            .GetReceivers(appContext)
            .then(receivers => this.receivers = receivers)
            .finally(() => this.loading = false);
    }
}