import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { styles } from "./Account.Styles";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Input,
  Paper,
  Avatar,
  Typography,
  SnackbarContent,
} from "@material-ui/core";
import VerififiedUserIcon from "@material-ui/icons/VerifiedUser";
import UserIcon from "@material-ui/icons/PermIdentity";
import ErrorIcon from "@material-ui/icons/Error";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import RegisterContext from "./Register.Context";
import classNames from "classnames";

@observer
class Register extends React.Component<IComponentProps> {
  private _context: RegisterContext = new RegisterContext(
    this.props.appContext
  );

  public render() {
    return (
      <React.Fragment>
        {this._context.registered ? (
          <div className={this.props.classes.main}>
            <Paper className={this.props.classes.paper}>
              <Avatar className={this.props.classes.avatar}>
                <VerififiedUserIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Thankyou
              </Typography>
              <Typography align="center">
                You have been successfully registered!
              </Typography>
              <Typography align="center" id="register-confirmation">
                This is a subscription only environment, please contact us to
                have your account activated.
              </Typography>
              <hr />
              <Link
                to="/Login"
                className={classNames(
                  this.props.classes.link,
                  this.props.classes.primaryText
                )}
              >
                Login
              </Link>
            </Paper>
          </div>
        ) : this._context.loading ? (
          <Loading fullscreen={true} />
        ) : (
          <div className={this.props.classes.main}>
            <Paper className={this.props.classes.paper}>
              <Avatar className={this.props.classes.avatar}>
                <UserIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Create Account
              </Typography>
              <form
                className={this.props.classes.form}
                noValidate
                autoComplete="off"
                onSubmit={this._context.onSubmit}
              >
                <FormControl margin="normal" required fullWidth>
                  <InputLabel>Username</InputLabel>
                  <Input
                    autoComplete="username"
                    autoFocus
                    id="username"
                    value={this._context.username.value}
                    error={this._context.username.hasError}
                    onChange={(e) =>
                      this._context.username.onChange(e.target.value)
                    }
                  />
                </FormControl>
                {this._context.username.hasError ? (
                  <SnackbarContent
                    className={this.props.classes.error}
                    message={
                      <span className={this.props.classes.message}>
                        <ErrorIcon />
                        {this._context.username.error}
                      </span>
                    }
                  />
                ) : null}
                <FormControl margin="normal" required fullWidth>
                  <InputLabel>Email Address</InputLabel>
                  <Input
                    autoComplete="email"
                    id="email"
                    value={this._context.email.value}
                    error={this._context.email.hasError}
                    onChange={(e) =>
                      this._context.email.onChange(e.target.value)
                    }
                  />
                </FormControl>
                {this._context.email.hasError && (
                  <SnackbarContent
                    className={this.props.classes.error}
                    message={
                      <span className={this.props.classes.message}>
                        <ErrorIcon />
                        {this._context.email.error}
                      </span>
                    }
                  />
                )}
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    value={this._context.password.value}
                    error={this._context.password.hasError}
                    onChange={(e) =>
                      this._context.password.onChange(e.target.value)
                    }
                  />
                </FormControl>
                {this._context.password.hasError ? (
                  <SnackbarContent
                    className={this.props.classes.error}
                    message={
                      <span className={this.props.classes.message}>
                        <ErrorIcon />
                        {this._context.password.error}
                      </span>
                    }
                  />
                ) : null}
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Confirm Password</InputLabel>
                  <Input
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    value={this._context.confirmPassword.value}
                    error={this._context.confirmPassword.hasError}
                    onChange={(e) =>
                      this._context.confirmPassword.onChange(e.target.value)
                    }
                  />
                </FormControl>
                {this._context.confirmPassword.hasError ? (
                  <SnackbarContent
                    className={this.props.classes.error}
                    message={
                      <span className={this.props.classes.message}>
                        <ErrorIcon />
                        {this._context.confirmPassword.error}
                      </span>
                    }
                  />
                ) : null}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={this.props.classes.submit}
                >
                  Submit
                </Button>
                {this._context.registerErrors.map((error) => {
                  return (
                    <SnackbarContent
                      className={this.props.classes.error}
                      message={
                        <span className={this.props.classes.message}>
                          <ErrorIcon className={this.props.classes.margin} />
                          {error}
                        </span>
                      }
                    />
                  );
                })}
              </form>
              Already registered?
              <Link
                to="/Login"
                className={classNames(
                  this.props.classes.link,
                  this.props.classes.primaryText
                )}
              >
                Login
              </Link>
            </Paper>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Register);
