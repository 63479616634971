import { DataFormat } from "../Enums/DataFormat";

export default class Serializers {
    public static parseJsonIfValid(str: string) {
        try {
            return JSON.parse(str);
        } catch {
            return;
        }
    }

    public static parseXmlIfValid(str: string): Document | undefined {
        let xml: Document = new DOMParser()
            .parseFromString(str, "text/xml");
        if (xml.getElementsByTagName('parsererror').length > 0) {
            return;
        } 
        return xml;
    }

    public static parseUrlIfValid(str: string): {} | undefined {
        try {
            return JSON.parse(
                '{"' + decodeURI(str)
                    .replace(/"/g, '\\"')
                    .replace(/&/g, '","')
                    .replace(/=/g, '":"') + '"}')
        } catch {
            return;
        }
    }

    public static parseTurtleIfValid(str: string): {} | undefined {
        try {
            const N3 = require("n3");
            const parser = new N3.Parser();
            const graph = parser.parse(str);

            return !!graph;
        } catch {
            return;
        }
    }

    public static parseHL7v2IfValid(str: string): [] | undefined {
        try {
            const hl7 = require('simple-hl7');
            const parser = new hl7.Parser();
            return parser.parse(str);            
        } catch (error) {
            return;
        }        
    }

    public static parseIfValid(str: string, format: DataFormat): {} | [] | Document | undefined {
        if ([DataFormat.HL7FHIRXML, DataFormat.XML].includes(format)) {
            return this.parseXmlIfValid(str);
        } else if ([DataFormat.QueryString].includes(format)) {
            return this.parseUrlIfValid(str);
        } else if ([DataFormat.HL7v2].includes(format)) {
            return this.parseHL7v2IfValid(str);
        } else if ([DataFormat.Turtle].includes(format)) {
            return this.parseTurtleIfValid(str);
        } else {
            return this.parseJsonIfValid(str);
        }
    }

    public static serializeJsonIfValid(obj: {}): string | undefined {
        try {
            return JSON.stringify(obj, null, '\t');
        } catch {
            return;
        }
    }

    public static serializeUrlIfValid(obj: {}): string | undefined {
        try {
            return Object.keys(obj).map(k => k + '=' + encodeURIComponent((obj as any)[k])).join('&');
        } catch {
            return;
        }
    }

    public static serializeXmlIfValid(obj: Document): string | undefined {
        try {
            return new XMLSerializer()
                .serializeToString(obj);
        } catch (x){
            return;
        }
    }
}