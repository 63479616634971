import AppContext from '../../App.Context';
import { getToken } from '../../Common/Helpers/AuthenticationHelpers';
import IJob from '../../Common/Interfaces/IJob';
import IPagedResults from '../../Common/Interfaces/IPagedResults';
import LoggingService from '../../Common/Services/Logging.Service';

export default class JobsService {
    private CallGetJobs = (appContext: AppContext, page?: number, searchTerm?: string): Promise<string> => {
        let uri = `${appContext.managementHost}/Job`;
        
        if(page || searchTerm) {uri += "?";}

        if (page !== undefined) { uri += `page=${page}&`}
        if (searchTerm) { uri += `searchTerm=${searchTerm}`; }

        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public GetJobs(appContext: AppContext): Promise<IJob[]> {
        return this.CallGetJobs(appContext)
            .then(result => {
                const repositories: IJob[] = JSON.parse(result, this.reviver);
                return repositories;
            });
    }

    public GetPagedJobs(appContext: AppContext, page: number, searchTerm?: string): Promise<IPagedResults<IJob>> {
        return this.CallGetJobs(appContext, page, searchTerm)
            .then(result => {
                const repositories: IPagedResults<IJob> = JSON.parse(result, this.reviver);
                return repositories;
            });
    }

    private reviver(key: string, value: string) {
        if (["added", "next", "last"].includes(key)) {
            if (value) {
                return new Date(value);
            } else { return }
        }
      
        return value;
    }
}