import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react-lite";
import * as React from "react";
import CertificateContext from "./CertificateContext";
import Certificate from "./Certificate";
import TaskContext from "../EndpointWizard/Context/Task.Context";

interface IProps {
  task: TaskContext;
}

const Certificates: React.FC<IProps> = observer(({ task }) => {
  return (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              Certificates ({task.clientCertificates.length})
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            {task.clientCertificates.map((certificate, index) => (
              <Grid item xs={12} key={index}>
                <Certificate
                  task={task}
                  certificate={certificate}
                  onDeleteCertificate={() => task.clientCertificates.splice(index, 1)}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="text"
                fullWidth
                onClick={() => task.clientCertificates.push(new CertificateContext())}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});
export default Certificates;
