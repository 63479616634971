import { observable } from "mobx";
import { FieldState, FormState } from "formstate";
import { FormEvent } from "react";
import JobService from "./Job.Service";
import BaseContext from "../../Common/BaseContext";
import AppContext from "../../App.Context";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import IJob from "../../Common/Interfaces/IJob";
import EndpointService from "../../Common/Services/Endpoint.Service";
import RepositoriesService from "../Repositories/Repositories.Service";
import EndpointHelpers from "../../Common/Helpers/EndpointHelpers";
import { DataFormat } from "../../Common/Enums/DataFormat";
import Constants from "../../Common/Constants";
import { applyTenancyToV2Origin } from "../../Common/TenancyHelpers";

export default class JobContext extends BaseContext {
  constructor(
    appContext: AppContext,
    getEndpoint: (appContext: AppContext, name: string) => Promise<IEndpoint>,
    id?: string
  ) {
    super(appContext);
    this.getEndpoints(appContext);
    this.getEndpoint = getEndpoint;
    if (id) {
      this.editingId = id;
      this.loading = true;
      JobService.Get(appContext, id)
        .then((result) => {
          this.extractResult(result);
        })
        .finally(() => (this.loading = false));
    }
  }

  private getEndpoint: (
    appContext: AppContext,
    name: string
  ) => Promise<IEndpoint>;

  public shouldButtonBeDisabled = () => {
    return (
      this.form.hasError === true ||
      !this.endpoint ||
      (this.inputFormat !== DataFormat.None &&
        EndpointHelpers.isInputNullOrEmpty(this.input))
    );
  };

  public get inputFormat() {
    if (this.endpoint) {
      return EndpointHelpers.GetProductionOrActiveVersion(this.endpoint!, this.endpointEnvironmentOrVersion)?.inputMode;
    }
    return undefined;
  }

  @observable
  public redirect: boolean = false;

  @observable
  public showDates: boolean = false;

  @observable
  public editingId?: string;

  @observable
  public loading: boolean = false;

  @observable
  public input: string = "";

  @observable
  public endpoint?: IEndpoint;

  @observable
  public endpointEnvironmentOrVersion: string = Constants.Production;

  @observable
  public schedule: string = "0 3 * * *";

  @observable
  public disableOnFailure: boolean = false;

  @observable
  public startDate?: Date | null;

  @observable
  public active: boolean = false;

  @observable
  public endDate?: Date | null;

  @observable
  public jobs: IJob[] = [];

  @observable
  public endpoints: IEndpoint[] = [];

  @observable
  public saveError: string = "";

  @observable
  public deleteOnJobExpire: boolean = false;

  public nameField = new FieldState("").validators(
    (val) => !val && "Please enter a job name",
    (val) => val.indexOf(" ") >= 0 && "Job name should not have empty spaces"
  );

  form = new FormState({
    name: this.nameField,
  });

  public onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationResult = await this.form.validate();

    if (validationResult.hasError) {
      return false;
    }

    if(!(!!this.editingId)){
      this.active = true;
    }

    this.loading = true;
    JobService.Save(
      this.AppContext,
      {
        id: this.editingId,
        name: validationResult.value.name.value,
        schedule: this.schedule,
        endpointName: this.endpoint!.name,
        endpointEnvironmentOrVersion: this.endpointEnvironmentOrVersion,
        input: this.input,
        active: this.active,
        disableOnFailure: this.disableOnFailure,
        startDate: this.startDate ? this.startDate : null,
        endDate: this.endDate ? this.endDate : null,
        deleteOnExpire: this.deleteOnJobExpire
      },
      this.editingId
    )
      .then((response) => {
        if (response.ok) {
          window.parent.postMessage({"isSaved": true}, applyTenancyToV2Origin(this.AppContext));
          if(window.location === window.parent.location){
          this.redirect = true;
          }
        } else if (response.status === 409) {
          this.saveError = "Job name already taken";
        } else {
          this.saveError = "Error saving job";
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  private extractResult = (job: IJob) => {
    this.input = job.input;
    this.nameField.value = job.name;
    this.schedule = job.schedule;
    this.startDate = job.startDate ? (job.startDate as Date) : null;
    this.endDate = job.endDate ? (job.endDate as Date) : null;
    this.disableOnFailure = job.disableOnFailure;
    this.showDates = job.startDate || job.endDate ? true : false;
    this.loading = true;
    this.active = job.active;
    this.endpointEnvironmentOrVersion = job.endpointEnvironmentOrVersion;
    this.getEndpoint(this.AppContext, job.endpointName)
      .then((endpoint) => (this.endpoint = endpoint))
      .finally(() => (this.loading = false));
    this.deleteOnJobExpire = job.deleteOnExpire;
  };

  private getEndpoints(appContext: AppContext) {
    this.loading = true;
    const getEndpointsPromise = EndpointService.GetEndpoints(appContext)
      .then((endpoints) => {
        return endpoints
      })

    const getRepoPromise = new RepositoriesService()
      .GetRepositories(appContext)
      .then((repositories) => { 
        return (repositories
              .map((r) => r.endpoints)
              .filter((x) => x !== undefined) as unknown as IEndpoint[]
              ).flat();
      })
      return Promise.all([getEndpointsPromise, getRepoPromise])
      .then(([endpoints, repoEndpoints]) => {
        this.endpoints = endpoints.concat(repoEndpoints)})
      .finally(() => (this.loading = false))
  }
}


