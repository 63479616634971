import * as React from 'react';

import AceEditor from 'react-ace';
import CursorLocation from '../EndpointWizard/CursorLocation';
import { DataFormat } from '../../Common/Enums/DataFormat';
import DataFormatHelper from '../../Common/Helpers/DataFormatHelper';
import { Grid } from '@material-ui/core';
import IOutputProps from '../EndpointWizard/IOutputProps';
import { MapType } from '../../Common/Enums/MapType';
import PropertyEditor from '../PathEditor/PropertyEditor';
import RequestOutputFormat from './RequestOutputFormat';
import RequestOutputProperties from './RequestOutputProperties';
import TextInputContext from '../EndpointWizard/Context/TextInputContext';
import XSLTParameters from '../XSLTParameters/XSLTParameters';
import { observer } from 'mobx-react';
import { useDraggableToken } from '../../Common/Hooks/useDraggableToken';

const RequestOutput: React.FC<IOutputProps> = observer(({ context, onDrop, appContext, task, classes, onClickEditPath }) => {
    
    // MAG-610
    useDraggableToken({ context, task });

    return (
        <Grid
            item md={6}
            onDrop={onDrop}>
            {
                context.tasks[task].editingPropertyToken && (
                    <PropertyEditor
                        appContext={appContext}
                        context={context}
                        task={task} />
                )
            }
            {
                context.tasks[task].editingPropertyToken === undefined && (
                    <React.Fragment>
                        <div onClick={() => {
                            context.isEditorTokenImportEnabled = true;
                        }}>
                            <RequestOutputProperties task={context.tasks[task]} classes={classes} context={context} />
                            <RequestOutputFormat context={context} classes={classes}
                                task={context.tasks[task]} onClickEditPath={onClickEditPath} />
                            {
                                context.tasks[task].output.mapType === MapType.XSLT && (
                                    <XSLTParameters
                                        onFocus={(input: TextInputContext) => context.tasks[task].activeTextInput = input}
                                        parameters={context.tasks[task].xsltParameters} />
                                )
                            }
                        </div>
                        {context.tasks[task].output.mode !== DataFormat.None && (
                            <AceEditor
                                className="editor-request-output"
                                mode={context.tasks[task].output.mapType === MapType.XSLT ?
                                    DataFormatHelper.GetModeString(DataFormat.XML) :
                                    DataFormatHelper.GetModeString(context.tasks[task].output.mode)}
                                theme="tomorrow"
                                onChange={(code) => context.tasks[task].requestContent.content = code}
                                fontSize={20}
                                onCursorChange={(e) =>
                                    context.tasks[task].requestContent.cursorLocation = new CursorLocation(
                                        e.getCursor().row,
                                        e.getCursor().column)}
                                onFocus={_ => context.tasks[task].activeTextInput = context.tasks[task].requestContent}
                                onSelectionChange={() => {
                                        if (context.tasks[task].output.mapType === MapType.XSLT) {
                                            context.isEditorTokenImportEnabled = false
                                        } else {
                                            context.isEditorTokenImportEnabled = true
                                        }
                                    }
                                }
                                
                                showPrintMargin={true}
                                width='100%'
                                showGutter={true}
                                highlightActiveLine={true}
                                value={context.tasks[task].requestContent.content}
                                editorProps={{ $blockScrolling: true }}
                                setOptions={{
                                    useWorker: false,
                                    showLineNumbers: true,
                                    tabSize: 4,
                                    newLineMode: context.tasks[task].useCRLFLineEndings === true ? "windows" as any : true
                                }} />
                        )}
                    </React.Fragment>
                )
            }
        </Grid>
    )
});
export default RequestOutput;