import { observable } from "mobx";
import { FieldState, FormState} from 'formstate';
import UrlHelpers from '../../Common/Helpers/UrlHelpers';
import RepositoryService from "./Repository.Service";
import BaseContext from "../../Common/BaseContext";
import AppContext from "../../App.Context";
import { FormEvent } from "react";
import IValidationResult, { IValidationResultMessage } from "../../Common/Interfaces/IValidationResult";
import IRepositoryRetrievalResult from "./IRepositoryRetrievalResult";
import FormatValidator from "../../Common/Helpers/FormatValidator";

export default class RepositoryContext extends BaseContext {
    constructor(appContext: AppContext, id?: string) {
        super(appContext);
        if (id) {
            this.editing = true;
            this.loading = true;
            RepositoryService.Sync(appContext, id)
                .then(result => {
                    this.extractResult(result);
                })
                .finally(() => this.loading = false)
        }
    }

    public editing: boolean = false;

    @observable
    public loading: boolean = false;

    public urlValidation = (x) => !UrlHelpers.validURL(x) && 'Valid repository URL required';
    public gitAuthentication = (x) => this.authentication === "SSH" && x.startsWith("git@") === false && 'SSH repository should start with git@...';
    public httpAuthentication = (x) => this.authentication !== "SSH" && x.startsWith("http") === false && 'HTTP repository should start with http';

    public repositoryUrlField = new FieldState('https://myrepositories.com/me/myrepo.git')
        .validators((val) => this.urlValidation(val) || this.gitAuthentication(val) || this.httpAuthentication(val));
    public errorEmailField = new FieldState('').validators((val) => (val && !FormatValidator.email(val)) && 'Enter a valid email address');
    public repositoryUserNameField = new FieldState('').validators((val) => this.authentication === "Credentials" && !val && 'Repository username required');
    public repositoryPasswordField = new FieldState('').validators((val) => this.authentication === "Credentials" && !val && 'Repository password required');;
    public repositorySshKeyField = new FieldState('').validators((val) => this.authentication === "SSH" && !val && 'Repository SSH key required');;

    public repositoryBranchField = new FieldState('').validators(val => /\s/.test(val) && 'Enter a valid branch');

    @observable
    public redirect: boolean = false;

    @observable
    public validationResult?: IValidationResult;

    @observable
    public retrievalResult?: IRepositoryRetrievalResult;

    @observable
    public urlModified: boolean = true;

    @observable
    public automaticallySync: boolean = true;

    @observable
    public authentication: "None" | "Credentials" | "SSH" = "None";

    form = new FormState({
        repositoryUrl: this.repositoryUrlField,
        repositoryErrorEmail: this.errorEmailField,
        repositoryUsername: this.repositoryUserNameField,
        repositoryPassword: this.repositoryPasswordField,
        repositorySshKey: this.repositorySshKeyField,
        repositoryBranch: this.repositoryBranchField
    });

    public onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();

        const validationResult = await this.form.validate();
        
        if (validationResult.hasError) {            
            return false;
        }
        
        this.loading = true;

        RepositoryService.Save(
            this.AppContext,
            {
                url: validationResult.value.repositoryUrl.value,
                errorEmail: validationResult.value.repositoryErrorEmail.value,
                automaticallySync: this.automaticallySync,
                repositoryUsername: validationResult.value.repositoryUsername.value,
                repositoryPassword: validationResult.value.repositoryPassword.value,
                repositorySshKey: validationResult.value.repositorySshKey.value,
                repositoryBranch: validationResult.value.repositoryBranch.value
            })
            .then((result) => {
                this.extractResult(result);
            })
            .finally(() => {
                this.loading = false;
            })
    };

    private extractResult(result: IRepositoryRetrievalResult) {
        let validationResultItems: IValidationResultMessage[] = [];

        if (result.errors) {
            validationResultItems = Object.keys(result.errors).map(key => {
                return {
                    location: key,
                    message: result.errors[key],
                    severity: "error"
                }
            }) as IValidationResultMessage[]
        }
        this.validationResult = {
            valid: !result.errors && !result.conflicts,
            messages: validationResultItems
        }
        this.retrievalResult = result;
        if (this.validationResult.valid) {
            // this.redirect = true;
        }
        this.urlModified = false;
    }
}