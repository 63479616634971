import * as React from 'react';
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { styles } from './EndpointImport.Styles';
import { withStyles, Typography, Button, Paper } from '@material-ui/core';
import { Redirect, withRouter, RouteComponentProps } from 'react-router';
import EndpointService from '../../Common/Services/Endpoint.Service';
import EndpointImportContext from './EndpointImport.Context';
import AceEditor, { Marker } from 'react-ace';
import "brace/mode/json";
import "brace/mode/text";
import "brace/mode/xml";
import 'brace/theme/tomorrow';
import 'brace/ext/language_tools';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../App.Context';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import TickIcon from '@material-ui/icons/Check';
import YAML from 'yamljs';
import { useState } from 'react';

interface IProps extends IComponentProps {
    context: EndpointImportContext
}

const onImport = async (appContext: AppContext, context: EndpointImportContext) => {
    if (containsToken(context.definition)) {
        context.response = { result: {errors: {general: [`Endpoint includes encrypted value placeholders, please replace them and try again.`]}}, status: -1};
        return
    }

    let def: string | undefined;
    try {
        def = JSON.stringify(YAML.parse(context.definition));
    } catch (error) {
        def = context.definition;
    }
    
    if (def) {
        context.response = await EndpointService.Import(appContext, def);
        if (context.response && context.response!.status < 400) {
            context.redirect = true;
        }
    }
}

const tokenRegex = new RegExp('--\\*\\*\\S+\\*\\*--');
const containsToken = (definition: string): boolean => {
    return tokenRegex.test(definition);
}

const getTokenMarkers = (definition: string, classes: any): Marker[] => {
    if (!containsToken(definition)) {
        return [];
    }

    const lines = definition.split(/\r?\n/);
    return lines.flatMap((line, index) => {
        const match = tokenRegex.exec(line);
        if (match !== null && match.length > 0) {
            return {
                startRow: index,
                endRow: index,
                startCol: match.index,
                endCol: match.index + match[0].length,
                type: "background",
                className: classes.marker
            }
        }
        else {
            return [];
        }
    })
}

const Retriever: React.FC<IProps> = observer(({ classes, context, appContext }) => {
    const [markers, setMarkers] = useState<Marker[]>([]);

    const onChange = (code: string) => {
        context.definition = code
        setMarkers(getTokenMarkers(code, classes))
    }

    return (
        context.redirect ?
            <Redirect to="/Endpoints" /> :
            <React.Fragment>
                {
                    context.response && (
                        <Paper className={classNames(classes.paper, classes.marginTop)} id="import-results">
                            {
                                context.response && (context.response!.status < 400 && context.response!.status > -1) ?
                                    <TickIcon color="primary"/> :
                                    <ErrorIcon color="error"/>       
                            }
                            { context.response!.status > -1 && (
                                <Typography className={classes.tileText}>
                                    {context.response!.status < 400 
                                        ? 'Success!' 
                                        : ((typeof(context.response.result) === "string") ? context.response.result : 'Error')}
                                </Typography>
                            )}
                            {
                                context.response!.result &&
                                context.response!.result!.errors && Object.keys(context.response!.result!.errors)
                                    .map(key => <Typography>{key ? key.toUpperCase() : 'General'}: {context.response!.result!.errors![key].map(error => error)}</Typography>)
                            }
                        </Paper>
                    )
                }
                <div id="definition-editor">
                    <AceEditor
                        className={classes.marginTop}
                        onChange={onChange}
                        mode="json"
                        theme="tomorrow"
                        fontSize={20}
                        showPrintMargin={true}
                        width='100%'
                        showGutter={true}
                        markers={markers}
                        highlightActiveLine={false}
                        value={context.definition}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            useWorker: false,
                            highlightGutterLine: false
                        }} />
                </div>
                <Button
                    id="import"
                    onClick={() => onImport(appContext, context)}
                    className={classes.marginTop}
                    fullWidth
                    variant="contained"
                    color="primary"><FontAwesomeIcon icon={faFileImport}/>
                    <span className={classes.margin}>Import</span>
                </Button>
            </React.Fragment>
    )
})

const withContext = (WrappedComponent: React.ComponentType<any>) => {
    class HOC extends React.Component<IComponentProps & RouteComponentProps> {
        render() {
            const context = new EndpointImportContext(this.props.appContext);

            return (
                <WrappedComponent
                    {...this.props}
                    context={context} />
            );
        }
    }
    return HOC;
}

export default withStyles(styles, { withTheme: true })(withRouter(withContext(Retriever)));