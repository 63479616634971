import {
  Accordion,
  Grid,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import React, { useRef } from "react";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import { observer } from "mobx-react";
import { HttpMethod } from "../../Common/Enums/HttpMethod";
import Certificates from "../Certificates/Certificates";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import HeaderProperties from "../Headers/HeaderProperties";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import { DataFormat } from "../../Common/Enums/DataFormat";
import Help from "../Help/Help";

interface IProps {
  task: TaskContext;
  context: EndpointWizardContext;
  classes: any;
}

const RequestOutputProperties: React.FC<IProps> = observer(
  ({ task, classes, context }) => {
    const urlRef = useRef<HTMLInputElement>(null);

    return (
      <Accordion style={{ width: "100%", margin: classes.margin }} expanded>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              placeholder="http://example.com"
              label="URL"
              fullWidth
              variant="outlined"
              inputRef={urlRef}
              error={!FormatValidator.url(task.submissionUrl.content)}
              onChange={(e) => (task.submissionUrl.content = e.target.value)}
              value={task.submissionUrl.content}
              onFocus={(_) => (task.activeTextInput = task.submissionUrl)}
              onSelect={(_) =>
                (task.submissionUrl.cursorLocation =
                  urlRef.current && (urlRef.current.selectionStart as number))
              }
              data-testid="request-url"
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              className={classes.padding}
              fullWidth
              value={task.requestMethod}
              onChange={(e) => {
                context.HandleOutputMode(
                  e.target.value,
                  DataFormat.QueryString,
                  DataFormat.QueryString,
                  true,
                  context.activeTask
                );
                task.requestMethod = (HttpMethod as any)[
                  e.target.value as any
                ] as unknown as HttpMethod;
              }}
            >
              {Object.keys(HttpMethod)
                .filter((key) => isNaN(Number((HttpMethod as any)[key as any])))
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {(HttpMethod as any)[(HttpMethod as any)[key as any]]}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel label={
                  <React.Fragment>
                  Retry external requests on failure
                  <Help
                    classes={classes}
                    helpText="If selected, AireGlu will automatically retry the request if it fails due to temporary issues like network problems or server unavailability. It will not retry for errors like authentication failures or invalid input, and will not retry calls to other AireGlu endpoints"                        
                    />
                </React.Fragment>
              } control={
                <Checkbox 
                  classes={classes} 
                  checked={task.retryOnFailure}
                  onChange={(e) => task.retryOnFailure = e.target.checked}
                />}/>
            </FormGroup>
          </Grid>
        </Grid>
        <HeaderProperties task={task} />
        <Certificates task={task} />
      </Accordion>
    );
  }
);

export default RequestOutputProperties;
