import { DataFormat } from '../Enums/DataFormat';

export default class FHIRHelper {
    public static async Valid(format: DataFormat, output: string) {
        if ([DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(format)) {
            const Fhir = require('fhir').Fhir;
            const fhir = new Fhir();
            try {
                return fhir.validate(output)
            } catch (error) {
                return { valid: false, messages: [] }
            }
        }
    }
}