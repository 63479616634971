import * as React from "react";
import {
  AccordionDetails,
  Button,
  Accordion,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { DataFormat } from "../../Common/Enums/DataFormat";
import AceEditor from "react-ace";
import { Behaviour } from "../../Common/Enums/Behaviour";
import BrushIcon from "@material-ui/icons/Brush";
import CursorLocation from "../EndpointWizard/CursorLocation";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import { MapType } from "../../Common/Enums/MapType";
import Prettifier from "../../Common/Helpers/Prettifier";
import PropertyEditor from "../PathEditor/PropertyEditor";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";
import XSLTParameters from "../XSLTParameters/XSLTParameters";
import { observer } from "mobx-react";
import { useDraggableToken } from "../../Common/Hooks/useDraggableToken";
import { useRef } from "react";
import OutputFormat from "../OutputFormat/OutputFormat";

const MappingOutput: React.FC<IOutputProps> = observer(
  ({ context, onDrop, appContext, task, classes, onClickEditPath }) => {
    // MAG-610
    useDraggableToken({ context, task });

    const statusCodeRef = useRef<HTMLInputElement>(null);

    return (
      <Grid item md={6} onDrop={onDrop}>
        {context.tasks[task].editingPropertyToken && (
          <PropertyEditor
            appContext={appContext}
            context={context}
            task={task}
          />
        )}
        {context.tasks[task].editingPropertyToken === undefined && (
          <React.Fragment>
            <Grid container className={classes.margin} spacing={2}>
              <Grid item xs={7}>
                <OutputFormat context={context} task={task} classes={classes} />
              </Grid>
              <Grid item xs={2} className={classes.padding}>
                {context.tasks[task].behaviour === Behaviour.Response && (
                  <FormControl>
                    <TextField
                      placeholder="200"
                      label="Status Code"
                      id="status-code"
                      variant="outlined"
                      inputRef={statusCodeRef}
                      fullWidth
                      error={
                        !FormatValidator.statusCode(
                          context.tasks[task].statusCode.content
                        )
                      }
                      onChange={(e) =>
                        (context.tasks[task].statusCode.content =
                          e.target.value)
                      }
                      value={context.tasks[task].statusCode.content}
                      onFocus={(_) =>
                        (context.tasks[task].activeTextInput =
                          context.tasks[task].statusCode)
                      }
                      onSelect={(_) =>
                        (context.tasks[task].statusCode.cursorLocation =
                          statusCodeRef.current &&
                          (statusCodeRef.current.selectionStart as number))
                      }
                      data-testid="request-url"
                    />
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={3}>
                {context.tasks[task].selectedPropertyToken && (
                  <Button onClick={onClickEditPath} variant={"text"}>
                    <LinkIcon />
                  </Button>
                )}
                <Button
                  variant="text"
                  onClick={() =>
                    (context.tasks[task].responseContent.content =
                      Prettifier.prettify(
                        context.tasks[task].responseContent.content,
                        context.tasks[task].output.mode
                      ))
                  }
                >
                  <BrushIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid container className={classes.margin}>
              {context.tasks[task].output.loading ? (
                <Loading fullscreen={false} />
              ) : (
                <React.Fragment>
                  {context.tasks[task].output.mapType === MapType.XSLT && (
                    <div
                      style={{ width: "100%" }}
                      onClick={() =>
                        (context.isEditorTokenImportEnabled = true)
                      }
                    >
                      <XSLTParameters
                        onFocus={(input: TextInputContext) =>
                          (context.tasks[task].activeTextInput = input)
                        }
                        parameters={context.tasks[task].xsltParameters}
                      />
                    </div>
                  )}
                  <Accordion style={{ width: "100%" }} expanded>
                    <AccordionDetails>
                      <Grid container>
                        <Grid item xs={12}>
                          <AceEditor
                            mode={
                              context.tasks[task].output.mapType ===
                              MapType.XSLT
                                ? DataFormatHelper.GetModeString(DataFormat.XML)
                                : DataFormatHelper.GetModeString(
                                    context.tasks[task].output.mode
                                  )
                            }
                            theme="tomorrow"
                            onChange={(code) => {
                              context.tasks[task].output.modified = true;
                              context.tasks[task].responseContent.content =
                                code;
                            }}
                            fontSize={20}
                            onCursorChange={(e) =>
                              (context.tasks[
                                task
                              ].responseContent.cursorLocation =
                                new CursorLocation(
                                  e.getCursor().row,
                                  e.getCursor().column
                                ))
                            }
                            onFocus={(_) =>
                              (context.tasks[task].activeTextInput =
                                context.tasks[task].responseContent)
                            }
                            onSelectionChange={() => {
                              if (
                                context.tasks[task].output.mapType ===
                                MapType.XSLT
                              ) {
                                context.isEditorTokenImportEnabled = false;
                              } else {
                                context.isEditorTokenImportEnabled = true;
                              }
                            }}
                            showPrintMargin={true}
                            width="100%"
                            showGutter={true}
                            highlightActiveLine={true}
                            value={context.tasks[task].responseContent.content}
                            editorProps={{ $blockScrolling: true }}
                            setOptions={{
                              useWorker: false,
                              showLineNumbers: true,
                              tabSize: 4,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
);

export default MappingOutput;
