import { TextField, TextFieldProps } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";

interface IProps {
  context: TextInputContext;
}
const TrackedTextField: React.FC<IProps & TextFieldProps> = observer(
  ({ context, ...props }) => {
    const inputRef = React.createRef<HTMLInputElement>();

    const onSelect = () => {
      context.cursorLocation =
        inputRef!.current && (inputRef!.current.selectionStart as number);
    };

    return (
      <TextField
        inputRef={inputRef}
        onChange={(e) => (context.content = e.target.value)}
        onSelect={onSelect}
        value={context.content}
        {...props}
      />
    );
  }
);

export default TrackedTextField;
