import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { getToken } from "../../Common/Helpers/AuthenticationHelpers";
import { Permission, Subject } from "../Authorisation/AppAbilities";
import { useAbility } from "../Authorisation/AbilityContext";
import { AccessDenied } from "../AccessDenied/AccessDenied";

interface Props extends RouteProps {
  permission?: Permission;
  subject?: Subject;
}

export const AuthenticatedRoute: React.FC<Props> = (props: Props ) => {
  const ability = useAbility();
  const isLoggedIn = getToken() !== null;

  if (props.permission && props.subject) {
    const canView = ability.can(props.permission, props.subject);
    if (!canView) {
      return <Route {...props} component={AccessDenied} />;
    }
  }

  if (isLoggedIn) {
    return <Route {...props} />;
  }
  const redirectProps = {
    to: {
      pathname: "/Login",
      state: { from: props.location },
    },
  };
  return <Redirect {...redirectProps} />;
};
