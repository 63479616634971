import { Slider } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";

interface IProps {
    values: number[],
    className: string,
    handleSliderChange : (event: React.ChangeEvent<{}>, value: number | number[]) => void,
    handleSliderChangeCommitted : (event: React.ChangeEvent<{}>, value: number | number[]) => void,
}


const TimeRangeSlider: React.FC<IProps> = observer(({values, className, handleSliderChange, handleSliderChangeCommitted}) => {
    
    
const marks: any[] = [{
    value: 0,
    label: "00:00"
}, {
    value: 1,

}, {
    value: 2,

}, {
    value: 3,

}, {
    value: 4,

}, {
    value: 5,
}, {
    value: 6,
    label: "06:00"
}, {
    value: 7,
}, {
    value: 8,
}, {
    value: 9,
}, {
    value: 10,
}, {
    value: 11,
}, {
    value: 12,
    label: "12:00"
}, {
    value: 13,
}, {
    value: 14,
}, {
    value: 15,
}, {
    value: 16,
}, {
    value: 17,
}, {
    value: 18,
    label: "18:00"
}, {
    value: 19,
}, {
    value: 20,
}, {
    value: 21,
}, {
    value: 22,
}, {
    value: 23,
}, {
    value: 24,
    label: "23:59"
}
]


const formatTimeLabel = (value: number): string => {
    return value < 24 ? `${value.toString().padStart(2, "0")}:00` : "23:59";
}



    return (
        <Slider
                                                                        value={values}
                                                                        onChange={handleSliderChange}
                                                                        onChangeCommitted={handleSliderChangeCommitted}
                                                                        valueLabelDisplay="auto"
                                                                        max={24}
                                                                        marks={marks}
                                                                        valueLabelFormat={formatTimeLabel}
                                                                        className={className}
                                                                    />
    )
});

export default TimeRangeSlider;