import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { appStyles } from "../../App.Styles";
import {
  withStyles,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Chip,
  Avatar,
  Theme,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import RepositoriesContext from "./Repositories.Context";
import { AppService } from "../../App.Service";
import Loading from "../Loading/Loading";
import Card from "@material-ui/core/Card";
import DoneIcon from "@material-ui/icons/CheckCircle";
import DashIcon from "@material-ui/icons/RemoveCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCodeBranch,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import IRepository from "../../Common/IRepository";
import RepositoryService from "../Repository/Repository.Service";
import UpIcon from "@material-ui/icons/ArrowUpward";
import RefreshIcon from "@material-ui/icons/Refresh";
import Help from "../Help/Help";
import RepositoryHelp from "../RepositoryHelp/RepositoryHelp";
import { Can } from "../Authorisation/AbilityContext";

interface IProps extends IComponentProps {
  context: RepositoriesContext;
  theme: Theme;
}

@observer
export class Repositories extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
    this._context = props.context;
    if (!props.appContext.username) {
      this._context.loading = true;
      AppService.Context(props.appContext)
        .then((result) => {
          props.appContext.username = result.username;
        })
        .finally(() => (this._context.loading = false));
    }
  }
  private readonly _context: RepositoriesContext;

  private onClickDelete = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    repository: IRepository
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const confirmed = window.confirm(
      `Are you sure you want to delete '${repository.url}'?`
    );
    if (confirmed) {
      this._context.loading = true;
      RepositoryService.Delete(
        this.props.appContext,
        repository.id as string
      ).then(() => {
        this._context.GetRepositories(this.props.appContext);
      });
    }
  };

  public render() {
    return this._context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <Grid container spacing={2}>
        <Can I="create" a="Repository">
          <Grid item xs={12}>
            <Link to="/Repository">
              <Button
                id="add-repository"
                variant="outlined"
                color="default"
                size="large"
                fullWidth
              >
                <AddIcon fontSize="large" />
              </Button>
            </Link>
          </Grid>
        </Can>
        <Can I="list" a="Repository">
          <React.Fragment>
            {this._context.repositories.map((repository, index) => {
              const repoName = repository.url
                .match(/([^/]+$)/)
                ?.shift()
                ?.replace(/.git/, "");
              const componentId = repository.repositoryBranch
                ? `${repoName}-${repository.repositoryBranch}`
                : repoName;

              return (
                <Grid
                  key={index}
                  item
                  xs={
                    this._context.repositories.length > 1
                      ? this._context.repositories.length === 2
                        ? 6
                        : 4
                      : 12
                  }
                >
                  <Card id={`${componentId}-card`}>
                    <CardHeader
                      title={
                        <React.Fragment>
                          <Chip
                            avatar={
                              <Avatar>
                                {repository.automaticallySync ? (
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    color={
                                      this.props.theme.palette.secondary.main
                                    }
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCodeBranch}
                                    size="lg"
                                    color={
                                      this.props.theme.palette.secondary.main
                                    }
                                  />
                                )}
                              </Avatar>
                            }
                            label={<b>{repository.url}</b>}
                          />
                        </React.Fragment>
                      }
                      action={
                        <React.Fragment>
                          <Link
                            to={`/Repository/${repository.id}`}
                            className={this.props.classes.link}
                          >
                            <IconButton id={`${componentId}-sync`}>
                              <RefreshIcon />
                            </IconButton>
                          </Link>
                          <Can I="delete" a="Repository">
                            <IconButton
                              id={`${componentId}-delete`}
                              onClick={(e) => this.onClickDelete(e, repository)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Can>
                        </React.Fragment>
                      }
                    ></CardHeader>
                    <Divider></Divider>
                    <CardContent>
                      {repository.repositoryBranch && (
                        <Typography>
                          <b>Branch: </b>
                          {repository.repositoryBranch}
                        </Typography>
                      )}
                      <Typography></Typography>
                      <Typography>
                        <b>Sync status: </b>
                        {repository.automaticallySync ? (
                          <React.Fragment>
                            Automatic{" "}
                            {repository.currentSyncStatus ? (
                              <DoneIcon
                                color="primary"
                                fontSize="small"
                                style={{ verticalAlign: "bottom" }}
                              />
                            ) : (
                              <ErrorIcon
                                color="error"
                                fontSize="small"
                                style={{ verticalAlign: "bottom" }}
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Manual{" "}
                            <DashIcon
                              fontSize="small"
                              style={{ verticalAlign: "bottom" }}
                            />
                          </React.Fragment>
                        )}
                      </Typography>
                      <Typography>
                        <b>Last sync:</b>{" "}
                        {(repository.synched as Date).toLocaleString()}
                      </Typography>
                      <Typography>
                        <b>Next sync: </b>
                        {repository.nextSync
                          ? repository.nextSync.toLocaleString()
                          : "-"}
                      </Typography>
                      <br />
                      {repository.endpoints && (
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                              Endpoints ({repository.endpoints!.length})
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {repository.endpoints!.map((endpoint, index) => {
                                return (
                                  <ListItem
                                    key={index}
                                    id={"repository-endpoint-" + endpoint.name}
                                  >
                                    <Typography>
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        color="green"
                                      />{" "}
                                      {endpoint.name} <b>Versions: </b>
                                      {endpoint.versions.length}
                                    </Typography>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </React.Fragment>
        </Can>
        {this._context.repositories.length === 0 && (
          <Typography className={this.props.classes.centerText} variant="h5">
            <UpIcon fontSize="large" /> Link a repository to store your
            endpoints in GIT..
            <Help classes={this.props.classes} helpText={<RepositoryHelp />} />
          </Typography>
        )}
      </Grid>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps> {
    render() {
      const context = new RepositoriesContext(this.props.appContext);

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withContext(Repositories)
);
