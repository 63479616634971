import { observable } from "mobx";
import Constants from "../../Common/Constants";

export default class MagicCronBuilderContext {
    constructor(expression: string) {
        this.dynamicExpression = expression.includes(Constants.TokenStart) && expression.includes(Constants.TokenEnd);
        if (this.dynamicExpression) {this.viewingMode = 'cron';} 
        else { this.viewingMode = 'wizard'; }
    }

    @observable
    public description: string = '';

    @observable
    public viewingMode: 'wizard' | 'cron';

    @observable
    public dynamicExpression: boolean;

}