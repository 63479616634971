import * as React from "react";

import { Button, Grid, TextField } from "@material-ui/core";

import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import PropertyEditor from "../PathEditor/PropertyEditor";
import UrlHelpers from "../../Common/Helpers/UrlHelpers";
import { observer } from "mobx-react";

@observer
export default class RedirectOutput extends React.Component<IOutputProps> {
  private readonly task =
    this.props.context.tasks[this.props.context.activeTask];
  private toRef = React.createRef<HTMLInputElement>();

  public render() {
    return (
      <Grid item md={6} onDrop={this.props.onDrop}>
        {this.props.context.tasks[this.props.task].editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.props.context.tasks[this.props.task].editingPropertyToken ===
          undefined && (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid
                item
                xs={
                  this.props.context.tasks[this.props.task]
                    .selectedPropertyToken
                    ? 10
                    : 11
                }
              >
                <TextField
                  id="task-submission-url"
                  onDrop={this.props.onDrop}
                  autoFocus={true}
                  label="Redirect URL"
                  required={true}
                  inputRef={this.toRef}
                  error={!UrlHelpers.validURL(this.task.submissionUrl.content)}
                  onChange={(e) =>
                    (this.task.submissionUrl.content = e.target.value)
                  }
                  onSelect={(_) =>
                    (this.task.submissionUrl.cursorLocation =
                      this.toRef.current &&
                      (this.toRef.current.selectionStart as number))
                  }
                  onFocus={(_) => {
                    this.task.activeTextInput = this.task.submissionUrl;
                  }}
                  placeholder="Enter a redirect URL"
                  value={this.task.submissionUrl.content}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              {this.props.context.tasks[this.props.task]
                .selectedPropertyToken && (
                <Button
                  className={this.props.classes.redirectPathLink}
                  onClick={this.props.onClickEditPath}
                  variant={"text"}
                >
                  <LinkIcon />
                </Button>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
