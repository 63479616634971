import { observable } from "mobx";
import AppContext from "../../App.Context";
import { JobSortType } from "../../Common/Enums/JobSortType";
import { JobOrderType } from "../../Common/Enums/JobOrderType";
import IJob from "../../Common/Interfaces/IJob";
import JobsService from "./Jobs.Service";


export default class JobsContext {
    constructor(appContext: AppContext) {
        this.GetJobs(appContext);
    }

    @observable
    public loading: boolean = false;

    @observable
    public jobs: IJob[] = [];

    @observable
    public page: number = 1;

    @observable
    public total: number = 0;

    @observable
    public searchTerm?: string;

    @observable
    public jobSortType: JobSortType = JobSortType.Name;

    @observable
    public jobOrderType: JobOrderType = JobOrderType.Ascending;

    @observable
    public showActiveOnly: boolean = false;

    @observable
    public submittedSearchTerm?: string;

    public GetJobs(appContext: AppContext) {
        this.loading = true;
        new JobsService()
            .GetPagedJobs(appContext, this.page, this.searchTerm)
            .then(result => {
                this.jobs =  this.jobs.concat(result.results);
                this.sortJobs();
                if (this.jobOrderType === JobOrderType.Descending) {
                    this.jobs = this.jobs.reverse();
                }
                this.total = result.total;
                if (this.showActiveOnly) {
                    this.jobs = this.jobs.filter(job => job.active);
                    this.total = this.jobs.length;
                }
            })
            .finally(() => this.loading = false);
    }

    public Search(appContext: AppContext) {
        this.loading = true;
        this.page = 1;
        new JobsService()
            .GetPagedJobs(appContext, this.page, this.searchTerm)
            .then(result => {
                this.jobs = result.results;
                this.sortJobs();
                this.total = result.total;
            })
            .finally(() => this.loading = false);
    }

    public sortJobs() {
        if (this.jobs.length) {
            if (this.jobSortType === JobSortType.Name) { //Sort alphabetically
                this.jobs = this.jobs.slice().sort(function (a, b): any {
                    var jobA = a.name.toUpperCase();
                    var jobB = b.name.toUpperCase();
                    return (jobA < jobB) ? -1 : (jobA > jobB) ? 1 : 0;
                })
            } else if (this.jobSortType === JobSortType.NextRun) {
                this.jobs = this.jobs.slice().sort(function (a, b): any {
                    const timeA = a.next ? a.next.getTime() : new Date(0).getTime();
                    const timeB = b.next ? b.next.getTime() : new Date(0).getTime();
                    return (timeB - timeA);   
                })
            } else if (this.jobSortType === JobSortType.LastRun) {
                this.jobs = this.jobs.slice().sort(function (a, b): any {
                    const timeA = a.last ? a.last.getTime() : new Date(0).getTime();
                    const timeB = b.last ? b.last.getTime() : new Date(0).getTime();
                    return (timeB - timeA);
                })
            } else { // Sort by date
                this.jobs = this.jobs.slice().sort(function (a, b): any {
                    if (b.added && a.added) {
                        return (b.added.getTime() - a.added.getTime())
                    }
                    return 0;
                });
            }
        }
    }
}