import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { appStyles } from "../../App.Styles";
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  NativeSelect,
} from "@material-ui/core";
import ReceiverContext from "./Receiver.Context";
import { Redirect, withRouter, RouteComponentProps } from "react-router";
import Help from "../Help/Help";
import Loading from "../Loading/Loading";
import SaveIcon from "@material-ui/icons/Save";
import EndpointSelector from "../EndpointSelector/EndpointSelector";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointService from "../../Common/Services/Endpoint.Service";
import { ReceiverType } from "../../Common/Enums/ReceiverType";
import { getDisplayName } from "../../Common/Helpers/ReceiverTypeHelpers";
import POP3ReceiverProperties from "./POP3ReceiverProperties";
import AppContext from "../../App.Context";
import MESHReceiverProperties from "./MESHReceiverProperties";
import SMSReceiverProperties from "./SMSReceiverProperties";
import EndpointHelpers from "../../Common/Helpers/EndpointHelpers";
import { DataFormat } from "../../Common/Enums/DataFormat";

interface IProps extends IComponentProps {
  context: ReceiverContext;
}

const onEndpointChange = (
  input: string,
  context: ReceiverContext,
  endpoint?: IEndpoint,
  endpointEnvironmentOrVersion?: string
) => {
  context.input = input;
  if (endpoint) {
    context.endpoint = endpoint;
    var version = endpoint.versions.find(
      (v) => v.version === endpoint!.environments["Production"]
    );
    context.inputFormat = version?.inputMode;
  }
  context.endpointEnvironmentOrVersion = endpointEnvironmentOrVersion;
};

const endpointValid = (
  receiverType: ReceiverType,
  endpoint?: IEndpoint, 
  endpointEnvironmentOrVersion?: string
): boolean => {
  if (!endpoint || !endpointEnvironmentOrVersion) {
    return false;
  }
  let version = EndpointHelpers.GetProductionOrActiveVersion(endpoint!, endpointEnvironmentOrVersion!);
  if (!version) {
    return false;
  }
  if (
    version.inputMode === DataFormat.Stream &&
    receiverType !== ReceiverType.MESH
  ) {
    alert("Stream endpoints are currently only supported for MESH receivers");
    return false;
  }
  return true;
};

const renderReceiverComponent = (
  context: ReceiverContext,
  classes: {},
  appContext: AppContext
) => {
  switch (context.receiverType) {
    case ReceiverType.POP3:
      return (
        <POP3ReceiverProperties
          context={context}
          classes={classes}
          appContext={appContext}
        />
      );
    case ReceiverType.Fax:
      return;
    case ReceiverType.MESH:
      return (
        <MESHReceiverProperties
          context={context}
          classes={classes}
          appContext={appContext}
        />
      );
    case ReceiverType.SMS:
      return (
        <SMSReceiverProperties
          context={context}
          classes={classes}
          appContext={appContext}
        />
      );
    default:
      break;
  }
};

const onReceiverTypeChange = (
  e: React.ChangeEvent<HTMLSelectElement>,
  context: ReceiverContext
) => {
  context.receiverType = (ReceiverType as any)[
    (ReceiverType as any)[e.target.value]
  ];
  if (!endpointValid(context.receiverType, context.endpoint)) {
    context.endpoint = undefined;
  }
};

const receiverTypeDisabled = (type: ReceiverType, appContext: AppContext) => {
  return (
    type === ReceiverType.MESH &&
    (!appContext.allowedMeshMailboxes ||
      appContext.allowedMeshMailboxes.length === 0)
  );
};

const Receiver: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    return context.redirect ? (
      <Redirect to="/Receivers" />
    ) : (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              {context.editingId ? "Edit" : "Create"} a receiver{" "}
              <Help
                classes={classes}
                helpText={renderHelp(context.receiverType, classes)}
              />
            </Typography>
          </Grid>
          {context.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={context.onSubmit}
            >
              <Grid container>
                <Grid item xs={5}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    autoFocus
                    id="receiver-name"
                    disabled={!!context.editingId}
                    label="Name"
                    onChange={(e) => context.nameField.onChange(e.target.value)}
                    placeholder="Enter a name"
                    value={context.nameField.value}
                    error={context.nameField.hasError}
                    variant="filled"
                  />
                  <p>{context.nameField.error}</p>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    className={classes.marginLeft}
                    autoComplete="off"
                    fullWidth
                    label="Error Email"
                    onChange={(e) =>
                      context.errorEmailField.onChange(e.target.value)
                    }
                    placeholder="Enter an email address"
                    value={context.errorEmailField.value}
                    error={context.errorEmailField.hasError}
                    variant="filled"
                  />
                  <p>{context.errorEmailField.error}</p>
                </Grid>
                <Grid item md={2}>
                  <FormControlLabel
                    className={classes.marginLeft}
                    control={
                      <Checkbox
                        className={classes.marginLeft}
                        onChange={() =>
                          (context.disableOnFailure = !context.disableOnFailure)
                        }
                        checked={context.disableOnFailure}
                      />
                    }
                    label="Disable on failure"
                  />
                  <Help
                    classes={classes}
                    helpText="If selected, the receiver will be disabled if the endpoint it calls returns a non-success status code"
                  />
                </Grid>
                <Grid item md={6} style={{ backgroundColor: "white" }}>
                  <EndpointSelector
                    endpoint={context.endpoint!}
                    endpoints={context.endpoints}
                    endpointEnvironmentOrVersion={context.endpointEnvironmentOrVersion!}
                    input={context.displayInput}
                    appContext={appContext}
                    validate={false}
                    onChange={(input: string, endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string) =>
                      onEndpointChange(input, context, endpoint, endpointEnvironmentOrVersion)
                    }
                    endpointValid={(endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string) =>
                      endpointValid(context.receiverType, endpoint, endpointEnvironmentOrVersion)
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <div className={classes.marginLeft}>
                    <NativeSelect
                      id="select-receiver-type"
                      fullWidth
                      disabled={!!context.editingId}
                      value={context.receiverType}
                      onChange={(e) => onReceiverTypeChange(e, context)}
                    >
                      {Object.keys(ReceiverType)
                        .filter((key) =>
                          isNaN(Number((ReceiverType as any)[key as any]))
                        )
                        .map((key) => (
                          <option
                            key={key}
                            id={`receiver-type-${key}`}
                            disabled={receiverTypeDisabled(
                              ReceiverType[key],
                              appContext
                            )}
                            value={key}
                          >
                            {getDisplayName(ReceiverType[key])}
                          </option>
                        ))}
                    </NativeSelect>
                    {renderReceiverComponent(context, classes, appContext)}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.marginTop}
                  type="submit"
                  variant="contained"
                  id="receiver-save"
                  color="primary"
                  size="large"
                  disabled={
                    (context.form.validate() &&
                      context.form.hasError === true) ||
                    (context.receiverType === ReceiverType.POP3 &&
                      context.pop3Form.validate() &&
                      context.pop3Form.hasError === true) ||
                    (context.archiveAttachments.map((a) => a.form.validate()) &&
                      context.archiveAttachments.some(
                        (a) => a.form.hasError === true
                      )) ||
                    !context.endpoint
                  }
                  fullWidth
                >
                  {
                    <React.Fragment>
                      <SaveIcon fontSize="large" /> Save
                    </React.Fragment>
                  }
                </Button>
              </Grid>
            </form>
          )}
        </Grid>
      </React.Fragment>
    );
  }
);

const renderHelp = (type: ReceiverType, classes: any) => {
  const genericHelp = (
    <React.Fragment>
      <Typography>
        Receivers run in the background and can collect data from various
        sources
      </Typography>
      <Typography>
        Retrieved data can be mapped to an endpoint, using Liquid template
        syntax: {"{{"} data {"}}"}
      </Typography>
    </React.Fragment>
  );
  switch (type) {
    case ReceiverType.POP3:
      return (
        <React.Fragment>
          {genericHelp}
          <Typography>
            Data is retrieved from a POP3 server and made available in the
            following format:
          </Typography>
          <Typography>{"{"}</Typography>
          <Typography>
            <span className={classes.marginLeft}>
              {
                '"Headers": {"From": "<email>","To": "<email>","Subject": "<text>","Date": "<date>"},'
              }
            </span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>{'"Content": "<text>",'}</span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>
              {
                '"Attachments": [{"FileName": "<text>","Bytes": "<base64>","Text": "<text>","FileSize": <int></int>,"ContentType": "<mimetype>"}]'
              }
            </span>
          </Typography>
          <Typography>{"}"}</Typography>
        </React.Fragment>
      );
    case ReceiverType.Fax:
      return (
        <React.Fragment>
          {genericHelp}
          <Typography>
            Data is received from a Twilio Programmable Fax line, and made
            available in the following format:
          </Typography>
          <Typography>{"{"}</Typography>
          <Typography>
            <span className={classes.marginLeft}>
              {'"NumPages": "<text>",'}
            </span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>{'"From": "<text>",'}</span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>{'"To": "<text>",'}</span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>
              {'"ContentPDF": "<base64>",'}
            </span>
          </Typography>
          <Typography>{"}"}</Typography>
          <Typography>
            <span>
              {
                "The receiver is activated by http POST to: runtimeservice/receiver/{username}/{receiverName}/{route?}"
              }
            </span>
          </Typography>
        </React.Fragment>
      );
    case ReceiverType.MESH:
      return (
        <React.Fragment>
          {genericHelp}
          <Typography>
            Data is retrieved from a MESH mailbox and made available in the
            following format:
          </Typography>
          <Typography>{"{"}</Typography>
          <Typography>
            <span className={classes.marginLeft}>
              {
                '"Headers": {"SenderMailboxId": "<mailbox id>","RecipientMailboxId": "<mailbox id>","Subject": "<text>","WorkflowId": "<id>", "MessageId": "<id>" },'
              }
            </span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>{'"Content": "<base64>",'}</span>
          </Typography>
          <Typography>{"}"}</Typography>
          <Typography>
            The content of a MESH message can be either text or binary file type. Therefore, the content of the message will be a base64 string.
            If you are expecting a text file, you can decode the base64 string by calling the inbuilt mapping function:
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>{'--*AG:Base64Decode({{data.Content}})*--'}</span>
          </Typography>
        </React.Fragment>
      );
    case ReceiverType.SMS:
      return (
        <React.Fragment>
          {genericHelp}
          <Typography>
            Data is retrieved from SMS - .Gov Notify and made available in the
            following format:
          </Typography>
          <Typography>{"{"}</Typography>
          <Typography>
            <span className={classes.marginLeft}>
              {
                '"Headers": {"id": "UUID","sourceNumber": 447700912345,"destinationNumber": 07700987654,"message": Hello Notify!,"dateReceived": "2017-05-14T12:15:30.000000Z"}'
              }
            </span>
          </Typography>
          <Typography>
            <span className={classes.marginLeft}>{'"Content": "JSON",'}</span>
          </Typography>
          <Typography>{"}"}</Typography>
          <Typography>
            <span>
              {
                "The receiver is activated by http POST to: runtimeservice/receiver/GovNotify/{username}/{receiverName}"
              }
            </span>
          </Typography>
        </React.Fragment>
      );
    default:
      return "";
  }
};

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps & RouteComponentProps> {
    render() {
      const context = new ReceiverContext(
        this.props.appContext,
        EndpointService.Get,
        (this.props.match.params as any)["type"],
        (this.props.match.params as any)["id"]
      );

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withRouter(withContext(Receiver))
);
