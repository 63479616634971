import { ResponseHandlerType } from "../../Common/Enums/ResponseHandlerType";
import JWTClaimContext from "../../Components/EndpointWizard/Context/JWTClaimContext";
import TaskContext from "../../Components/EndpointWizard/Context/Task.Context";
import TextInputContext from "../../Components/EndpointWizard/Context/TextInputContext";
import {
  callbackInput,
  callbackSchema,
  sampleHL7v2,
  sampleJson,
  sampleUrl,
  sampleXml,
} from "../../Components/EndpointWizard/SampleObject";
import Constants from "../Constants";
import { Behaviour } from "../Enums/Behaviour";
import { DataFormat } from "../Enums/DataFormat";
import { JobAction } from "../Enums/JobAction";
import { JwtDataType } from "../Enums/JwtDataType";
import SchemaGenerator from "../Factories/SchemaFactory";
import IAdapterDefinition from "../Interfaces/IAdapterDefinition";
import IEndpoint from "../Interfaces/IEndpoint";
import IEndpointContextBase from "../Interfaces/IEndpointContextBase";
import IEndpointVersion from "../Interfaces/IEndpointVersion";
import IEndpointXmlSchema from "../Interfaces/IEndpointXmlSchema";
import IGetInputDataResult from "../Interfaces/IGetInputDataResult";
import { IMappableTaskResult } from "../Interfaces/IMappableTaskResult";
import ISchemaGenerationResult from "../Interfaces/ISchemaGenerationResult";
import ITask from "../Interfaces/ITask";
import EndpointService from "../Services/Endpoint.Service";
import SchemaService from "../Services/Schema.Service";
import FormatValidator from "./FormatValidator";
import Prettifier from "./Prettifier";
import Serializers from "./Serializers";

export default class EndpointHelpers {
  constructor(managementHost: string) {
    this._managementHost = managementHost;
  }

  private _managementHost: string;

  public static GetBehaviourContext = (task: ITask): IEndpointContextBase => {
    switch (task.behaviour) {
      case Behaviour.Request:
        return task.requestContext as IEndpointContextBase;
      case Behaviour.ResponseHandler:
        return task.responseHandlerContext as IEndpointContextBase;
      case Behaviour.Email:
        return task.emailContext as IEndpointContextBase;
      case Behaviour.MESH:
        return task.meshContext as IEndpointContextBase;
      case Behaviour.SMS:
        return task.smsContext as IEndpointContextBase;
      case Behaviour.Job:
        return task.jobContext as IEndpointContextBase;
      case Behaviour.Fax:
        return task.faxContext as IEndpointContextBase;
      case Behaviour.Response:
        return task.responseContext as IEndpointContextBase;
      case Behaviour.Redirect:
        return task.redirectContext as IEndpointContextBase;
      case Behaviour.Mapping:
        return task.mappingContext as IEndpointContextBase;
      case Behaviour.PDF:
        return task.pdfContext as IEndpointContextBase;
      case Behaviour.Adapter:
        return task.adapterContext as IEndpointContextBase;
      case Behaviour.SFTP:
        return task.sftpContext as IEndpointContextBase;
      case Behaviour.JWT:
        return task.jwtContext as IEndpointContextBase;
      case Behaviour.AireAudit:
        return task.aireAuditContext as IEndpointContextBase;
    }
  };

  public static GetActiveVersion = (
    endpointVersions: IEndpointVersion[],
    version: number
  ): IEndpointVersion => {
    return endpointVersions.find(
      (v) => v.version === version
    ) as IEndpointVersion;
  };

  public static GetProductionVersion(
    endpoint: IEndpoint
  ): IEndpointVersion | undefined {
    return endpoint.versions.find(
      (v) => v.version === endpoint!.environments[Constants.Production]
    );
  }

  public static GetProductionOrActiveVersion(
    endpoint: IEndpoint,
    endpointEnvironmentOrVersion: string
  ): IEndpointVersion | undefined {
    let version;
    if (
      !endpointEnvironmentOrVersion ||
      endpointEnvironmentOrVersion === Constants.Production
    ) {
      version = EndpointHelpers.GetProductionVersion(endpoint);
    } else {
      version = EndpointHelpers.GetActiveVersion(
        endpoint.versions,
        Number.parseInt(endpointEnvironmentOrVersion)
      );
    }
    return version;
  }

  public static isEmailTaskValid = (task: TaskContext): boolean => {
    const htmlValid = task.isRawHtml
      ? task.emailBodyHtmlRaw.content !== ""
      : task.emailBodyHtml.content !== "";

    const emailAttachmentValid =
      task.emailAttachmentMime.content !== "" &&
      task.emailAttachmentFilename.content !== "";

    const anyAddressesValid =
      FormatValidator.optionalEmail(task.emailTo.content) &&
      FormatValidator.optionalEmail(task.emailBcc.content);

    const atLeastOneValidToOrBccAddress =
      FormatValidator.email(task.emailTo.content) ||
      FormatValidator.email(task.emailBcc.content);

    const recipientsValid = anyAddressesValid && atLeastOneValidToOrBccAddress;

    return (
      recipientsValid &&
      task.emailSubject.content !== "" &&
      EndpointHelpers.isEmailSmtpValid(task) &&
      (task.isHtmlBody ? htmlValid : task.emailBodyPlain.content !== "") &&
      (task.emailAttachment.content === "" || emailAttachmentValid) &&
      task.mappingValid
    );
  };

  private static isEmailSmtpValid = (task: TaskContext): boolean => {
    if (!task.emailUseCustomSmtp) return true;
    return (
      task.emailSmtpHost.content !== "" &&
      task.emailSmtpPort.content !== "" &&
      task.emailSmtpUsername.content !== ""
    );
  };

  public static isMESHTaskValid = (task: TaskContext): boolean => {
    return (
      task.meshRecipientMailbox.content !== "" &&
      task.meshWorkflowId.content !== "" &&
      task.meshBody.content !== "" &&
      task.meshSenderMailbox.content !== ""
    );
  };

  public static isSMSTaskValid = (task: TaskContext): boolean =>
    FormatValidator.mobileNumber(task.smsTo.content) &&
    task.smsBody.content !== "";

  public static isRequestValid = (task: TaskContext): boolean =>
    FormatValidator.url(task.submissionUrl.content) && task.previewIsValid;

  public static isResponseValid = (task: TaskContext): boolean =>
    FormatValidator.statusCode(task.statusCode.content) && task.previewIsValid;

  public static isResponseHandlerValid = (task: TaskContext) => {
    switch (task.responseHandlerTypeField.value) {
      case ResponseHandlerType.Receive:
        return (
          (task.output.mode === DataFormat.JSON &&
            !!task.responseHandlerJsonSchema &&
            EndpointHelpers.isHTTPTaskOutputValid(
              task.output.mode,
              task.output.error,
              task.responseHandlerJsonSchema
            )) ||
          (task.responseHandlerXmlSchemas !== undefined &&
            task.responseHandlerXmlSchemas !== null &&
            task.responseHandlerXmlSchemas!.length > 0 &&
            EndpointHelpers.isHTTPTaskOutputValid(
              task.output.mode,
              task.output.error,
              undefined,
              task.responseHandlerXmlSchemas
            )) ||
          task.output.mode == DataFormat.Stream
        );
      case ResponseHandlerType.Forward:
        return (
          task.previewIsValid && FormatValidator.url(task.submissionUrl.content)
        );
      case ResponseHandlerType.TransparentForward:
        return FormatValidator.url(task.submissionUrl.content);
    }
  };

  public static isFaxTaskValid = (task: TaskContext): boolean => {
    return (
      FormatValidator.faxNumber(task.faxNumber.content) &&
      !!task.faxFrom.content &&
      !!task.faxTo.content &&
      !!task.faxSubject.content &&
      !!task.faxBody.content
    );
  };

  public static isAdapterTaskValid = (
    task: TaskContext,
    adapter?: IAdapterDefinition
  ): boolean => {
    return (
      !!adapter &&
      adapter.fields.some(
        (field) =>
          field.required &&
          !task.adapterFieldValues[field.name]?.content &&
          ["", undefined].includes(task.adapterFieldValues[field.name]?.content)
      ) === false
    );
  };

  public static isJobTaskValid = (task: TaskContext): boolean => {
    switch (task.jobAction) {
      case JobAction.Create:
        return (
          !!task.jobEndpoint && !!task.jobSchedule && !!task.jobName.content
        );
      case JobAction.UpdateSchedule:
        return !!task.jobSchedule && !!task.jobName.content;
      case JobAction.Remove:
        return !!task.jobName.content;
      default:
        return false;
    }
  };

  public static isInputNullOrEmpty = (input: string): boolean => {
    return input === null || input.match(/^ *$/) !== null;
  };

  public static isHTTPTaskOutputValid = (
    format: DataFormat,
    error: string | null,
    content?: string,
    xmlSchemas?: IEndpointXmlSchema[]
  ): boolean => {
    if ([DataFormat.JSON, DataFormat.HL7FHIRJSON].includes(format)) {
      return !!content && Serializers.parseJsonIfValid(content) !== undefined;
    } else if (format === DataFormat.QueryString) {
      return !content || Serializers.parseUrlIfValid(content) !== undefined;
    } else if (
      [DataFormat.XML, DataFormat.HL7FHIRXML, DataFormat.HTML].includes(format)
    ) {
      if (xmlSchemas) {
        return xmlSchemas!.some((x) => !x.schema) === false;
      }
      return !!content && Serializers.parseXmlIfValid(content) !== undefined;
    } else if (format === DataFormat.HL7v2) {
      return (
        !!content &&
        Serializers.parseHL7v2IfValid(content) !== undefined &&
        !error
      );
    } else if (
      [DataFormat.None, DataFormat.Text, DataFormat.Stream].includes(format)
    ) {
      return true;
    } else if (format === DataFormat.Turtle) {
      return (
        !!content &&
        Serializers.parseTurtleIfValid(content) !== undefined &&
        !error
      );
    }

    return false;
  };

  public static isSftpTaskValid = (task: TaskContext): boolean => {
    return (
      !!task.sftpUrl.content &&
      !!task.sftpUsername.content &&
      !!FormatValidator.filename(task.sftpFilename.content) &&
      !!task.sftpBody.content &&
      !!FormatValidator.port(task.sftpPort.content)
    );
  };

  public static isJwtTaskValid = (task: TaskContext): boolean => {
    return (
      areJwtClaimsValid(task.jwtHeaders) && areJwtClaimsValid(task.jwtPayload)
    );
  };

  public static isAireAuditTaskValid = (task: TaskContext): boolean => {
    return task.aireAudit.isValid();
  };

  public static confirmRegenerateSchema = (
    editInitialLoad: boolean,
    confirmedCallback: () => void,
    unconfirmedCallback: () => void
  ) => {
    if (editInitialLoad) {
      if (
        window.confirm("Are you sure you would like to regenerate the schema?")
      ) {
        confirmedCallback();
      } else {
        unconfirmedCallback();
      }
    } else {
      unconfirmedCallback();
    }
  };

  public GenerateSchema = (
    data: {} | [] | Document,
    format: DataFormat
  ): Promise<ISchemaGenerationResult> => {
    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(format)) {
      if (data && data instanceof Document && (data as Document).firstChild) {
        return new SchemaGenerator(SchemaService.GenerateXsd)
          .GetXmlSchema(this._managementHost, data as Document)
          .then((response) => {
            return {
              xmlSchemas: JSON.parse(response),
            };
          });
      }
    } else if (
      [
        DataFormat.JSON,
        DataFormat.QueryString,
        DataFormat.HL7FHIRJSON,
        DataFormat.HL7v2,
      ].includes(format)
    ) {
      if (data !== {}) {
        return Promise.resolve({
          jsonSchema: SchemaGenerator.FormatJsonSchema(data, format),
        });
      }
    }
    return Promise.resolve({});
  };

  public GetInputData(
    mode: DataFormat,
    fhirResource?: string
  ): Promise<IGetInputDataResult> {
    switch (mode) {
      case DataFormat.JSON:
        return Promise.resolve({
          data: sampleJson,
          text: JSON.stringify(sampleJson, null, "\t"),
        });
      case DataFormat.XML:
        return Promise.resolve({
          data: Serializers.parseXmlIfValid(sampleXml) as Document,
          text: sampleXml,
        });
      case DataFormat.QueryString:
        return Promise.resolve({
          data: Serializers.parseUrlIfValid(sampleUrl) as {},
          text: sampleUrl,
        });
      case DataFormat.HL7FHIRJSON:
      case DataFormat.HL7FHIRXML:
        if (fhirResource) {
          return EndpointService.GetTemplate(
            this._managementHost,
            mode,
            fhirResource
          ).then((result) => {
            return {
              text: Prettifier.prettify(result, mode),
              data: Serializers.parseIfValid(result, mode) as
                | {}
                | []
                | Document,
            };
          });
        } else {
          return Promise.resolve({
            data: {},
            text: "",
          });
        }
      case DataFormat.HL7v2:
        return EndpointService.GetInputData(this._managementHost, {
          input: sampleHL7v2,
          format: DataFormat.HL7v2,
        }).then((result) => {
          return {
            data: result,
            text: sampleHL7v2,
          };
        });
      case DataFormat.Callback:
        return Promise.resolve({
          data: callbackInput,
          schema: callbackSchema,
          text: callbackInput,
        });
      default:
        return Promise.resolve({
          data: {},
          text: "",
        });
    }
  }

  public static GetTaskResults(tasks: TaskContext[]): IMappableTaskResult[] {
    return tasks.map((task) => {
      return {
        format: task.output.mode,
        result: task.getContent(),
      };
    });
  }

  public static getTextInputContextFields(
    contexts: TaskContext
  ): TextInputContext[] {
    let textInputContextFields: TextInputContext[] = [];
    for (const field in contexts) {
      if (contexts[field] instanceof TextInputContext) {
        textInputContextFields.push(contexts[field]);
      }
    }
    textInputContextFields = textInputContextFields
      .concat(contexts.xsltParameters.map((x) => x.name))
      .concat(contexts.xsltParameters.map((x) => x.value))
      .concat(...contexts.headers.map((h) => h.values));
    return textInputContextFields;
  }
}

function areJwtClaimsValid(claims: JWTClaimContext[]): boolean {
  claims.forEach((c) => {
    if (!c.key.content || !c.value.content) return false;
    if (
      c.type === JwtDataType.number &&
      !FormatValidator.number(c.value.content)
    )
      return false;
  });
  var duplicateKeys = claims.filter(
    (claim, index) =>
      claims.findIndex((c) => c.key.content === claim.key.content) !== index
  );
  if (duplicateKeys.length > 0) return false;
  return true;
}
