import { FieldState, FormState } from 'formstate';
import { observable } from "mobx";
import { FormEvent } from "react";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import {AccountService } from "./Account.Service";
import AppContext from '../../App.Context';
import { IAccountDetails } from './IAccountDetails';
export default class AccountContext {
    
    constructor(appContext: AppContext) {
        this.loading = true;
        this.appContext = appContext;
        AccountService.GetAccountDetails(appContext.managementHost)
            .then(accountDetails => {
                this.usernameField.value = accountDetails.userName || '';
                this.emailField.value = accountDetails.email || '';
                this.passwordField.value = accountDetails.smtpDetails.password;
                this.useCustomSmtpField.value = accountDetails.useCustomSmtp;
                this.smtpUsernameField.value = accountDetails.smtpDetails.username;
                this.defaultFromField.value = accountDetails.smtpDetails.defaultFrom;
                this.useSSLField.value = accountDetails.smtpDetails.useSSL;
                this.hostField.value = accountDetails.smtpDetails.host;
                this.portField.value = accountDetails.smtpDetails.port;
            })
            .finally(() => this.loading = false);            
    }

    private appContext: AppContext;

    @observable
    public loading: boolean = false;

    public passwordField = new FieldState('');
    public smtpUsernameField = new FieldState('');
    public usernameField = new FieldState('');
    public defaultFromField = new FieldState('').validators((val) => (val && !FormatValidator.email(val)) && 'Enter a valid email address');
    public emailField = new FieldState('').validators((val) => (val && !FormatValidator.email(val)) && 'Enter a valid email address');
    public useCustomSmtpField = new FieldState<boolean>(false);
    public useSSLField = new FieldState<boolean>(true);
    public hostField = new FieldState('').validators((val) => (!val && 'Enter a host'));
    public portField = new FieldState('');

    form = new FormState({
        smtp: this.smtpUsernameField,
        username: this.smtpUsernameField,
        password: this.passwordField,
        useCustomSmtp: this.useCustomSmtpField,
        useSSL: this.useSSLField,
        host: this.hostField,
        port: this.portField,
        defaultFrom: this.defaultFromField
    });

    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.loading = true;

        const validationResult = await this.form.validate();
        
        if (validationResult.hasError) {  
            this.loading = false;          
            return false;
        }

        const accountDetails: IAccountDetails = {
            useCustomSmtp: validationResult.value.useCustomSmtp.value,
            smtpDetails: {
                host: validationResult.value.host.value,
                port: validationResult.value.port.value,
                username: validationResult.value.username.value,
                password: validationResult.value.password.value,
                useSSL: validationResult.value.useSSL.value,
                defaultFrom: validationResult.value.defaultFrom.value,
                portString: ""
            }
        };

        AccountService.UpdateAccountDetails(this.appContext, accountDetails)
            .then(() => this.appContext.accountDetails = accountDetails)
            .finally(() => this.loading = false);
    
    };

}