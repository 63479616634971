import { observable } from "mobx";
import AppContext from "../../App.Context";
import VariablesService from "./Variables.Service";
import IVariable from "../../Common/Interfaces/IVariable";

export default class VariablesContext {
    constructor(appContext: AppContext) {
        this.getVariables(appContext);
    }

    @observable
    public variables: IVariable[] = [];

    @observable
    public loading: boolean = false;

    public getVariables(appContext: AppContext) {
        this.loading = true;
        new VariablesService()
            .Get(appContext)
            .then(variables => this.variables = variables)
            .finally(() => this.loading = false);
    }
}