import { observable } from "mobx";
import TextInputContext from "./TextInputContext";
import {
  ActionType,
  OutcomeIndicator,
  EventIdentifierType,
  AuditableObjectType,
} from "../../../Common/Enums/Audit";

export class AuditActorContext {
  @observable
  public userId: TextInputContext;
  @observable
  public alternativeUserId: TextInputContext;
  @observable
  public userName: TextInputContext;

  constructor(
    userId: string = "",
    alternativeUserId: string = "",
    userName: string = ""
  ) {
    this.userId = new TextInputContext(userId);
    this.alternativeUserId = new TextInputContext(alternativeUserId);
    this.userName = new TextInputContext(userName);
  }

  public isValid(): boolean {
    return (
      !!this.userId.content ||
      !!this.alternativeUserId.content ||
      !!this.userName.content
    );
  }
}

export class AuditAuditableObjectDetailContext {
  @observable
  public type: TextInputContext;
  @observable
  public value: TextInputContext;

  constructor(type: string = "", value: string = "") {
    this.type = new TextInputContext(type);
    this.value = new TextInputContext(value);
  }

  public isValid(): boolean {
    return !!this.type.content || !!this.value.content;
  }
}

export class AuditAuditableObjectContext {
  @observable
  public objectId: TextInputContext;

  @observable
  public type: AuditableObjectType;

  @observable
  public details: AuditAuditableObjectDetailContext[];

  constructor(
    objectId: string = "",
    type: AuditableObjectType = AuditableObjectType.SystemObject,
    details: AuditAuditableObjectDetailContext[] = []
  ) {
    this.objectId = new TextInputContext(objectId);
    this.type = type;
    this.details = details;
  }

  public addDetail() {
    this.details.push(new AuditAuditableObjectDetailContext());
  }

  public removeDetail(index: number) {
    this.details.splice(index, 1);
  }

  public isValid(): boolean {
    return !!this.objectId.content && this.details.every((d) => d.isValid());
  }
}

export class AuditEventTypeContext {
  @observable
  public code: TextInputContext;
  @observable
  public codeSystem: TextInputContext;
  @observable
  public displayName: TextInputContext;

  constructor(
    code: string = "",
    codeSystem: string = "",
    displayName: string = ""
  ) {
    this.code = new TextInputContext(code);
    this.codeSystem = new TextInputContext(codeSystem);
    this.displayName = new TextInputContext(displayName);
  }

  public isValid(): boolean {
    return !!this.code.content;
  }
}

export default class AireAuditContext {
  constructor() {
    this.addEventType();
    this.addAuditActor();
  }

  @observable
  public auditSource: TextInputContext = new TextInputContext("AireGlu");

  @observable
  public outcomeIndicator: OutcomeIndicator = OutcomeIndicator.Success;

  @observable
  public actionType: ActionType = ActionType.Read;

  @observable
  public eventIdentifierType: EventIdentifierType =
    EventIdentifierType.ApplicationActivity;

  @observable
  public auditActors: AuditActorContext[] = [];

  @observable
  public auditableObjects: AuditAuditableObjectContext[] = [];

  @observable
  public eventTypes: AuditEventTypeContext[] = [];

  public isValid(): boolean {
    return (
      !!this.auditSource.content &&
      this.auditActors.length > 0 &&
      this.eventTypes.length > 0 &&
      this.auditActors.every((a) => a.isValid()) &&
      this.eventTypes.every((e) => e.isValid()) &&
      this.auditableObjects.every((a) => a.isValid())
    );
  }

  public addAuditActor() {
    this.auditActors.push(new AuditActorContext());
  }

  public removeAuditActor(index: number) {
    this.auditActors.splice(index, 1);
  }

  public addAuditableObject() {
    const auditableObject = new AuditAuditableObjectContext();
    auditableObject.addDetail();
    this.auditableObjects.push(auditableObject);
  }

  public removeAuditableObject(index: number) {
    this.auditableObjects.splice(index, 1);
  }

  public addEventType() {
    this.eventTypes.push(new AuditEventTypeContext());
  }

  public removeEventType(index: number) {
    this.eventTypes.splice(index, 1);
  }
}
