import * as React from 'react';
import onClickOutside from "react-onclickoutside";
import MagicMenuContext from './MagicMenuContext';
import { Button, Menu, MenuItem, Fab } from '@material-ui/core';
import { observer } from 'mobx-react';

interface IProps {
    onClick: (item: string) => void,
    items: string[],
    title: string,
    icon?: any,
    buttonVariant?: "text" |  "outlined" | "contained" | "fab" | undefined,
    buttonColor?: "primary" | "inherit" | "secondary" | "default" | undefined
}

@observer
class MagicMenu extends React.Component<IProps> {
    private _context = new MagicMenuContext();

    public handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {
        this._context.anchorEl = undefined;
    }

    public render() {
        return (
            <React.Fragment>
                {
                    this.props.buttonVariant === "fab" ?
                    <Fab id="magicmenu-button"  color={this.props.buttonColor} onClick={e => this._context.anchorEl = e.currentTarget}>
                        {this.props.icon}
                    </Fab> :
                    <Button id="magicmenu-button" variant={this.props.buttonVariant} color={this.props.buttonColor} onClick={e => this._context.anchorEl = e.currentTarget}>
                        {this.props.icon}
                    </Button>
                }
                <Menu
                    anchorEl={this._context.anchorEl}
                    open={Boolean(this._context.anchorEl)}>
                    {
                        this.props.items.map((item, index) => 
                            <MenuItem
                                id={`magicmenu-option-${index}`}
                                key={index} 
                                onClick={() => this.props.onClick(item)}>
                                {item}
                            </MenuItem>)
                    }
                </Menu>
            </React.Fragment>
        )
    }
}

export default onClickOutside(MagicMenu);