import { computed, observable } from "mobx";
import { FieldState, FormState } from "formstate";
import { FormEvent } from "react";
import ReceiverService from "./Receiver.Service";
import BaseContext from "../../Common/BaseContext";
import AppContext from "../../App.Context";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import ReceiversService from "../Receivers/Receivers.Service";
import EndpointService from "../../Common/Services/Endpoint.Service";
import RepositoriesService from "../Repositories/Repositories.Service";
import IReceiver, { IReceiverBase } from "../../Common/Interfaces/IReceiver";
import IReceiverPOP3Context from "../../Common/Interfaces/IReceiverPOP3Context";
import IDictionary from "../../Common/Interfaces/IDictionary";
import { ReceiverType } from "../../Common/Enums/ReceiverType";
import ArchiveAttachmentContext from "./ArchiveAttachment.Context";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import IReceiverMESHContext from "../../Common/Interfaces/IReceiverMESHContext";
import { DataFormat } from "../../Common/Enums/DataFormat";
import IReceiverSMSContext from "../../Common/Interfaces/IReceiverSMSContext";
import { applyTenancyToV2Origin } from "../../Common/TenancyHelpers";

export default class ReceiverContext extends BaseContext {
  constructor(
    appContext: AppContext,
    getEndpoint: (appContext: AppContext, name: string) => Promise<IEndpoint>,
    type?: ReceiverType,
    id?: string
  ) {
    super(appContext);
    this.getReceivers(appContext);
    this.getEndpoints(appContext);
    this.getEndpoint = getEndpoint;
    if (id && type) {
      this.editingId = id;
      this.receiverType = type;
      this.loading = true;
      ReceiverService.Get(appContext, this.receiverType, id)
        .then((result) => {
          this.extractResult(result);
        })
        .finally(() => (this.loading = false));
    }
  }

  private getEndpoint: (
    appContext: AppContext,
    name: string
  ) => Promise<IEndpoint>;

  @observable
  public redirect: boolean = false;

  @observable
  public editingId?: string;

  @observable
  public loading: boolean = false;

  @observable
  public input: string = "";

  @observable
  public endpoint?: IEndpoint;
  
  @observable
  public endpointEnvironmentOrVersion?: string;

  @observable
  public addedProperties: IDictionary<string> = {};

  @observable
  public receiverType: ReceiverType = ReceiverType.POP3;

  @observable
  public receivers: IReceiverBase[] = [];

  @observable
  public archiveAttachments: ArchiveAttachmentContext[] = [];

  @observable
  public endpoints: IEndpoint[] = [];

  @observable
  public disableOnFailure: boolean = false;

  @observable
  public inputFormat?: DataFormat;

  @computed
  public get displayInput() {
    if (this.inputFormat && this.inputFormat === DataFormat.Stream) {
      return "Will stream body as stream input, will pass headers as parameters";
    } else if (this.inputFormat && this.inputFormat === DataFormat.None) {
      return "No input";
    }
    return this.input;
  }

  public nameField = new FieldState("").validators(
    (val) =>
      (!val && "Enter a receiver name") ||
      (!this.editingId &&
        this.receivers.some((s) => s.name === val) &&
        "A receiver with this name already exists")
  );
  public errorEmailField = new FieldState("").validators(
    (val) => val && !FormatValidator.email(val) && "Enter a valid email address"
  );

  form = new FormState({
    name: this.nameField,
    errorEmail: this.errorEmailField,
  });

  public serverField = new FieldState("").validators(
    (val) => !val && "Enter a POP3 server"
  );
  public subjectPrefixField = new FieldState("").validators(
    (val) => !val && "Enter a subject prefix"
  );
  public usernameField = new FieldState("").validators(
    (val) => !val && "Enter a username"
  );
  public passwordField = new FieldState("");

  pop3Form = new FormState({
    server: this.serverField,
    subjectPrefix: this.subjectPrefixField,
    username: this.usernameField,
    password: this.passwordField,
  });

  public mailboxField = new FieldState("").validators(
    (val) => !val && "Enter a mailbox"
  );

  public workflowIdField = new FieldState("");

  public messageIdField = new FieldState("");

  meshForm = new FormState({
    mailbox: this.mailboxField,
    password: this.passwordField,
    workflowId: this.workflowIdField,
    messageId: this.messageIdField,
  });

  public destinationNumberField = new FieldState("").validators(
    (val) => val && !FormatValidator.number(val) && "Enter a valid destination number"
  );

  public sourceNumberField = new FieldState("").validators(
    (val) => val && !FormatValidator.number(val) && "Enter a valid source number"
  );

  public messagePatternField = new FieldState("");

  smsForm = new FormState({
    destinationNumber: this.destinationNumberField,
    password: this.passwordField,
    sourceNumber: this.sourceNumberField,
    messagePattern: this.messagePatternField,
  });

  public onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationResult = await this.form.validate();
    let context = {};

    if (validationResult.hasError) {
      return false;
    }

    if (this.receiverType === ReceiverType.POP3) {
      const pop3ValidationResult = await this.pop3Form.validate();
      if (pop3ValidationResult.hasError) {
        return false;
      }
      context = {
        server: pop3ValidationResult.value.server.value,
        username: pop3ValidationResult.value.username.value,
        password: pop3ValidationResult.value.password.value,
        subjectPrefix: pop3ValidationResult.value.subjectPrefix.value,
        archiveAttachmentContexts: this.archiveAttachments
          .filter(async (a) => (await a.form.validate()).hasError === false)
          .map((a) => {
            return {
              archiveName: a.archiveNameField.value,
              fileName: a.fileNameField.value,
              password: a.passwordField.value,
            };
          }),
      };
    }
    if (this.receiverType === ReceiverType.MESH) {
      const meshValidationResult = await this.meshForm.validate();
      if (meshValidationResult.hasError) {
        return false;
      }

      context = {
        mailbox: meshValidationResult.value.mailbox.value,
        password: meshValidationResult.value.password.value,
        workflowId: meshValidationResult.value.workflowId.value,
        messageId: meshValidationResult.value.messageId.value,
      };
    }

    if (this.receiverType === ReceiverType.SMS) {
      const smsValidationResult = await this.smsForm.validate();
      if (smsValidationResult.hasError) {
        return false;
      }

      context = {
        messagePattern: smsValidationResult.value.messagePattern.value,
        password: smsValidationResult.value.password.value,
        destinationNumber: smsValidationResult.value.destinationNumber.value,
        sourceNumber: smsValidationResult.value.sourceNumber.value
      };
    }

    this.loading = true;
    ReceiverService.Save(
      this.AppContext,
      {
        id: this.editingId,
        name: validationResult.value.name.value,
        endpointName: this.endpoint!.name,
        endpointEnvironmentOrVersion: this.endpointEnvironmentOrVersion,
        template: this.input,
        active: true,
        disableOnFailure: this.disableOnFailure,
        errorEmail: validationResult.value.errorEmail.value,
        paths: this.addedProperties,
        type: this.receiverType,
        context: context,
      } as IReceiver<unknown>,
      this.editingId
    )
      .then(() => {
        const tenantedOrigin = applyTenancyToV2Origin(this.AppContext)
        window.parent.postMessage({"isSaved": true}, tenantedOrigin);
        if(window.location === window.parent.location){
            this.redirect = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  private extractResult = (receiver: IReceiver<unknown>) => {
    this.input = receiver.template;
    this.nameField.value = receiver.name;
    this.errorEmailField.value = receiver.errorEmail;
    this.disableOnFailure = receiver.disableOnFailure;
    this.inputFormat = receiver.inputFormat;
    this.endpointEnvironmentOrVersion = receiver.endpointEnvironmentOrVersion

    if (receiver.type === ReceiverType.POP3) {
      const context = receiver.context as IReceiverPOP3Context;
      this.serverField.value = context.server;
      this.usernameField.value = context.username;
      this.passwordField.value = context.password || "";
      this.subjectPrefixField.value = context.subjectPrefix;
      this.archiveAttachments = context.archiveAttachmentContexts.map(
        (c) =>
          new ArchiveAttachmentContext(
            c.archiveName,
            c.fileName,
            c.password || ""
          )
      );
    }
    if (receiver.type === ReceiverType.MESH) {
      const context = receiver.context as IReceiverMESHContext;
      this.mailboxField.value = context.mailbox;
      this.workflowIdField.value = context.workflowId;
      this.messageIdField.value = context.messageId;
    }
    if (receiver.type === ReceiverType.SMS) {
      const context = receiver.context as IReceiverSMSContext;
      this.passwordField.value = context.password || "";
      this.destinationNumberField.value = context.destinationNumber;
      this.sourceNumberField.value = context.sourceNumber;
      this.messagePatternField.value = context.messagePattern;
    }

    this.loading = true;
    this.getEndpoint(this.AppContext, receiver.endpointName)
      .then((endpoint) => {
          this.endpoint = endpoint;
      })
      .finally(() => (this.loading = false));
  };

  private getReceivers(appContext: AppContext) {
    this.loading = true;
    new ReceiversService()
      .GetReceivers(appContext)
      .then((receivers) => (this.receivers = receivers))
      .finally(() => (this.loading = false));
  }

  private getEndpoints(appContext: AppContext) {
    const endpointServicePromise = EndpointService.GetEndpoints(appContext);
    const repositoriesServicePromise =
      new RepositoriesService().GetRepositories(appContext);

    Promise.all([endpointServicePromise, repositoriesServicePromise])
      .then(([endpoints, repositories]) => {
        this.endpoints = this.endpoints
          .concat(endpoints)
          .concat(
            repositories.map((repository) => repository.endpoints).flat()
          );
      })
      .finally(() => (this.loading = false));
  }
}
