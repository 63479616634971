export const sampleJson = {
  id: 1,
  name: "Fox Mulder",
  interests: ["paranormal", "aliens"],
};

export const sampleXml = `<?xml version="1.0" encoding="utf-8"?>
<agent>
    <name id="1">Fox Mulder</name>
    <interests>
        <interest>paranormal</interest>
        <interest>aliens</interest>
    </interests>
</agent>`;

export const sampleUrl = "id=1&name=Fox+Mulder";

export const sampleXslt = `<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet version="2.0"
xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output omit-xml-declaration="yes"/>
<xsl:template match="/">{0}</xsl:template>
</xsl:stylesheet>`;

export const sampleHL7v2 =
  "MSH|^~\\&|MERIDIAN|Demo Server|||20100202163120+1100||ORU^R01|XX02021630854-1539|P|2.3.1^AUS&&ISO^AS4700.2&&L|||||AUS\rPID|1||||SMITH^Jessica^^^^^L||19700201|F|||1 Test Street^^WODEN^ACT^2606^AUS^C~2 Test Street^^WODEN^ACT^2606^AUS^C";

export const faxReceiverInput =
  "NumPages=1&ContentPDF=Base64PDFbytes&FaxStatus=Delivered&From=+1234567890&To=+1098765432";

export const callbackInput = `{
	"success": true,
	"errors": [],
	"originalInput": {},
	"endpointOutput": {},
	"transactionId": "19fe5156-0297-4938-a68f-977c8b8bdc1e"
}`;

export const callbackSchema = `{
	"$schema": "http://json-schema.org/draft-04/schema#",
	"description": "",
	"type": "object",
	"properties": {
		"transactionId": {
			"type": "string"
		},
		"success": {
			"type": "boolean"
		},
		"errors": {
			"type": "array",
			"items": {
				"type": "string"
			}
		},
		"originalInput": {
			"type": "object",
			"properties": {},
			"required": []
		},
		"endpointOutput": {
			"type": "object",
			"properties": {},
			"required": []
		}
	},
	"required": [
		"success",
		"originalInput"
	]
}`;
