import AppContext from '../../App.Context';
import LoggingService from '../../Common/Services/Logging.Service';
import { IReceiverBase } from '../../Common/Interfaces/IReceiver';
import { getToken } from '../../Common/Helpers/AuthenticationHelpers';

export default class ReceiversService {
    private CallGetReceivers = (appContext: AppContext): Promise<string> => {
        const uri = `${appContext.managementHost}/Receiver`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public GetReceivers(appContext: AppContext): Promise<IReceiverBase[]> {
        return this.CallGetReceivers(appContext)
            .then(result => {
                const repositories: IReceiverBase[] = JSON.parse(result, this.reviver);
                return repositories;
            });
    }

    private reviver(key: string, value: string) {
        if (["added"].includes(key)) {
            if (value) {
                return new Date(value);
            } else { return }
        }

        return value;
    }
}