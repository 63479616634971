import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../../Common/Interfaces/IComponentProps";
import {
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import DeployIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import Loading from "../../Loading/Loading";
import { Redirect } from "react-router";
import { AppService } from "../../../App.Service";
import PreviousIcon from "@material-ui/icons/NavigateBefore";
import EndpointWizardContextFactory from "../Context/EndpointWizardContextFactory";
import EndpointService from "../../../Common/Services/Endpoint.Service";
import classNames from "classnames";
import ErrorIcon from "@material-ui/icons/Error";
import { applyTenancyToV2Origin } from "../../../Common/TenancyHelpers";

interface IProps extends IComponentProps {
  context: EndpointWizardContext;
  onClickNext: (nextIndex: number) => void;
  editMode: boolean;
}

@observer
export default class PreviewDeployStep extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
    if (!this.props.appContext.username) {
      AppService.Context(this.props.appContext).then((result) => {
        this.props.appContext.username = result.username;
      });
    }
  }

  public onEnvironmentChange = (environment: string) => {
    const environmentIndex =
      this.props.context.deploymentEnvironments.indexOf(environment);
    if (environmentIndex > -1) {
      this.props.context.deploymentEnvironments.splice(environmentIndex, 1);
    } else {
      this.props.context.deploymentEnvironments.push(environment);
    }
  };

  public onSubmit = () => {
    this.props.context.loading = true;
    const id = this.props.context.endpoint
      ? this.props.context.endpoint.id
      : undefined;

    EndpointService.Save(
      this.props.appContext,
      new EndpointWizardContextFactory(this.props.appContext).CreateEndpoint(
        this.props.context,
        id
      ),
      id !== undefined,
      this.props.context.derivedVersion
    )
      .then((res) => {
        if (res.status === 200) {
          this.props.context.redirect = true;  
          window.parent.postMessage(
              { isSaved: true },
              applyTenancyToV2Origin(this.props.appContext)
            );
        } else {
          this.props.context.saveError = res.result.errors
            ? res.result.errors[0][0]
            : "Something went wrong while saving the endpoint.";
        }
      })
      .catch(
        () =>
          (this.props.context.saveError =
            "Something went wrong while saving the endpoint.")
      )
      .finally(() => (this.props.context.loading = false));
  };

  public render() {
    return this.props.context.redirect ? (
      <Redirect to="/Endpoints" />
    ) : (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.props.context.saveError.length > 0}
          style={{ width: "100%", maxWidth: "100%" }}
          className={`${this.props.classes.invalid} ${this.props.classes.snackbarInvalidTop}`}
        >
          <span
            className={classNames(
              this.props.classes.margin,
              this.props.classes.message
            )}
          >
            <ErrorIcon />
            {this.props.context.saveError}
          </span>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" variant="h5">
              Preview & Deploy
            </Typography>
          </Grid>
          {this.props.context.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <React.Fragment>
              <Grid
                item
                xs={12}
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                {this.props.context.endpointNameField.value &&
                  this.props.appContext.username && (
                    <TextField
                      label="Endpoint URL"
                      className={this.props.classes.marginTop}
                      value={`${this.props.appContext.managementHost}/${this.props.appContext.username}/${this.props.context.endpointNameField.value}`}
                      fullWidth
                      disabled
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                <FormControl className={this.props.classes.marginTop}>
                  <FormLabel>Environments</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => this.onEnvironmentChange("Staging")}
                          checked={this.props.context.deploymentEnvironments.includes(
                            "Staging"
                          )}
                        />
                      }
                      label="Staging"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="production"
                          onChange={() =>
                            this.onEnvironmentChange("Production")
                          }
                          checked={this.props.context.deploymentEnvironments.includes(
                            "Production"
                          )}
                        />
                      }
                      label="Production"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={6}>
            <Button
              onClick={() =>
                this.props.onClickNext(this.props.context.activeStep - 1)
              }
              variant="contained"
              color="default"
              size="large"
              fullWidth
            >
              <PreviousIcon fontSize="large" /> Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              id="deploy"
              onClick={this.onSubmit}
              variant="contained"
              color="primary"
              size="large"
              disabled={
                this.props.context.allPreceedingStepsValid(
                  this.props.context.activeStep + 1
                ) === false || this.props.context.readOnly
              }
              fullWidth
            >
              {this.props.context.deploymentEnvironments.length > 0 ? (
                <React.Fragment>
                  <DeployIcon fontSize="large" /> Save & Deploy
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <SaveIcon fontSize="large" /> Save
                </React.Fragment>
              )}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
