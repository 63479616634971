export enum ActionType {
  Create,
  Read,
  Update,
  Delete,
  Execute,
}

export enum OutcomeIndicator {
  Success,
  MinorFailure,
  SeriousFailure,
  CriticalFailure,
}

export enum EventIdentifierType {
  ProvisioningEvent,
  MedicationEvent,
  ResourceAssignment,
  CareEpisode,
  CareProtocol,
  Disclosure,
  CDTPatientSearchActivity,
  ApplicationActivity,
  AuditLogUsed,
  BeginTransferInstances,
  DicomInstancesAccessed,
  DicomInstancesTransffered,
  StudyDeleted,
  Export,
  Import,
  NetworkActivity,
  OrderRecord,
  PatientRecord,
  ProcedureRecord,
  Query,
  SecurityAlert,
  UserAuthentication,
  EmergencyOverrideStarted,
  UseOfRestrictedFunction,
  Login,
  Logout,
}

export enum AuditableObjectType {
  Person,
  SystemObject,
  Organization,
  Other,
}
