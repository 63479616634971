import { VariableScopeType } from "../Enums/VariableScopeType";

export default class VariableScopeTypeHelpers {
    public static GetDisplayName(scopeType: VariableScopeType) {
        switch (scopeType) {
            case VariableScopeType.Tenant:
                return "Tenant";
            case VariableScopeType.Endpoint:
                return "Endpoint";
            case VariableScopeType.EndpointVersion:
                return "Endpoint Version";
            case VariableScopeType.EndpointEnvironment:
                return "Endpoint Environment";
            default:
                return scopeType;
        }
    }
}