import LoggingService from '../../Common/Services/Logging.Service';
import AppContext from '../../App.Context';
import ISecret from '../../Common/Interfaces/ISecret';
import { getToken } from '../../Common/Helpers/AuthenticationHelpers';

export default class SecretsService {
    public CallGet = (appContext: AppContext): Promise<string> => {
        const uri = `${appContext.managementHost}/Secret`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public Get(appContext: AppContext): Promise<ISecret[]> {
        return this.CallGet(appContext)
            .then(result => {
                return JSON.parse(result, this.reviver)
            });
    }

    private reviver(key: string, value: string) {
        if (["added"].includes(key)) {
          return new Date(value);
        }
      
        return value;
    }
}