import {
  Checkbox,
  Accordion,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import AppContext from "../../App.Context";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import TaskContext from "../EndpointWizard/Context/Task.Context";

interface IProps {
  appContext: AppContext;
  task: TaskContext;
  classes: any;
}

const MESHOutputProperties: React.FC<IProps> = observer(
  ({ appContext, task, classes }) => {
    const recipientMailboxRef = useRef<HTMLInputElement>(null);
    const workflowIDRef = useRef<HTMLInputElement>(null);
    const senderMailboxPasswordRef = useRef<HTMLInputElement>(null);
    const subjectRef = useRef<HTMLInputElement>(null);
    const localIdRef = useRef<HTMLInputElement>(null);

    return (
      <Accordion style={{ width: "100%" }} className={classes.padding} expanded>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="overline" gutterBottom>
              Sender
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="MESH Sender Mailbox"
              onChange={(e) =>
                (task.meshSenderMailbox.content = e.target.value)
              }
              placeholder="Enter the MESH sender mailbox"
              value={task.meshSenderMailbox.content}
              error={!task.meshSenderMailbox.content}
              onFocus={(_) => (task.activeTextInput = null)}
              fullWidth
              required
              id="sender-mailbox"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <MappablePasswordSetter
              passwordField={task.meshSenderMailboxPassword}
              onFocus={(_) => {
                task.activeTextInput = task.meshSenderMailboxPassword;
              }}
              inputRef={senderMailboxPasswordRef}
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" gutterBottom>
              Recipient
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Recipient MESH Mailbox"
              onChange={(e) =>
                (task.meshRecipientMailbox.content = e.target.value)
              }
              placeholder="Enter a submission MESH mailbox"
              value={task.meshRecipientMailbox.content}
              error={!task.meshRecipientMailbox.content}
              fullWidth
              required
              variant="outlined"
              onFocus={(_) =>
                (task.activeTextInput = task.meshRecipientMailbox)
              }
              onSelect={(_) =>
                (task.meshRecipientMailbox.cursorLocation =
                  recipientMailboxRef.current &&
                  (recipientMailboxRef.current.selectionStart as number))
              }
              inputRef={recipientMailboxRef}
              id="recipient-mailbox"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.marginLeft}
              label="MESH Workflow ID"
              onChange={(e) => (task.meshWorkflowId.content = e.target.value)}
              placeholder="Enter a submission MESH workflow ID"
              value={task.meshWorkflowId.content}
              error={!task.meshWorkflowId.content}
              fullWidth
              required
              variant="outlined"
              onFocus={(_) => (task.activeTextInput = task.meshWorkflowId)}
              onSelect={(_) =>
                (task.meshWorkflowId.cursorLocation =
                  workflowIDRef.current &&
                  (workflowIDRef.current.selectionStart as number))
              }
              inputRef={workflowIDRef}
              id="workflow-id"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" gutterBottom>
              Message Options
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Subject"
              onChange={(e) => (task.meshSubject.content = e.target.value)}
              placeholder="Enter a subject"
              value={task.meshSubject.content}
              error={!task.meshSubject.content}
              fullWidth
              required
              variant="outlined"
              onFocus={(_) => (task.activeTextInput = task.meshSubject)}
              onSelect={(_) =>
                (task.meshSubject.cursorLocation =
                  subjectRef.current &&
                  (subjectRef.current.selectionStart as number))
              }
              inputRef={subjectRef}
              id="subject"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Local Id"
              onChange={(e) => (task.meshLocalId.content = e.target.value)}
              placeholder="For GP Connect: Sender ODS Code"
              value={task.meshLocalId.content}
              fullWidth
              variant="outlined"
              onFocus={(_) => (task.activeTextInput = task.meshLocalId)}
              onSelect={(_) =>
                (task.meshSubject.cursorLocation =
                  localIdRef.current &&
                  (localIdRef.current.selectionStart as number))
              }
              inputRef={localIdRef}
              id="localID"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.marginLeft}
                  onChange={() => (task.meshCompress = !task.meshCompress)}
                  checked={task.meshCompress}
                />
              }
              label="Use Compression"
            />
          </Grid>
        </Grid>
      </Accordion>
    );
  }
);

export default MESHOutputProperties;
