import {
  Avatar,
  Button,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import UpIcon from "@material-ui/icons/ArrowUpward";
import VariableIcon from "@material-ui/icons/AttachMoney";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IVariable from "../../Common/Interfaces/IVariable";
import Help from "../Help/Help";
import Loading from "../Loading/Loading";
import VariablesContext from "./Variables.Context";
import VariablesService from "./Variables.Service";
import VariableValues from "./VariableValues";
import { Can } from "../Authorisation/AbilityContext";

interface IProps extends IComponentProps {
  context: VariablesContext;
}

const Variables: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    const onClickDelete = (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      variable: IVariable
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        window.confirm(`Are you sure you want to delete '${variable.name}'?`)
      ) {
        VariablesService.Delete(appContext, variable.id as string).then(() =>
          context.getVariables(appContext)
        );
      }
    };

    return context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <Grid container spacing={2}>
        <Can I="create" a="Variable">
          <Grid item xs={12}>
            <Link to="/Variable">
              <Button
                id="add-variable"
                variant="outlined"
                color="default"
                size="large"
                fullWidth
              >
                <AddIcon fontSize="large" />
              </Button>
            </Link>
          </Grid>
        </Can>
        <Can I="list" a="Variable">
          <React.Fragment>
            {context.variables.map((variable, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={
                    context.variables.length > 1
                      ? context.variables.length === 2
                        ? 6
                        : 4
                      : 12
                  }
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id={`variable-${variable.name}`}
                    >
                      <Avatar className={classes.margin}>
                        <VariableIcon />
                      </Avatar>
                      <Grid container justify="flex-end">
                        <Grid item xs={8}>
                          <Typography className={classes.margin}>
                            {variable.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.alignRight}>
                          <Can I="edit" a="Variable">
                            <Link to={`/Variable/${variable.name}`}>
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </Can>
                          <Can I="delete" a="Variable">
                            <IconButton
                              id={`variable-${variable.name}-delete`}
                              onClick={(e) => onClickDelete(e, variable)}
                              disabled={canDelete(variable)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Can>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <Divider />
                    <Can I="view" a="Variable">
                      <AccordionDetails>
                        <Grid container>
                          <VariableValues
                            appContext={appContext}
                            variable={variable}
                          ></VariableValues>
                        </Grid>
                      </AccordionDetails>
                    </Can>
                    <Divider />
                  </Accordion>
                </Grid>
              );
            })}
          </React.Fragment>
        </Can>
        {context.variables.length === 0 && (
          <Typography className={classes.centerText} variant="h5">
            <UpIcon fontSize="large" /> Add your variables...
            <Help
              classes={classes}
              helpText="Variables should be used to store non-sensitive values. You can specify value scopes per endpoint, version, environment, etc."
            />
          </Typography>
        )}
      </Grid>
    );
  }
);

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps> {
    render() {
      const context = new VariablesContext(this.props.appContext);

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

const canDelete = (variable: IVariable) => {
  return variable.values.some((v) => !v.editable);
};

export default withStyles(appStyles, { withTheme: true })(
  withContext(Variables)
);
