import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import UserIcon from "@material-ui/icons/PermIdentity";
import { observer, useLocalStore } from "mobx-react";
import * as React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import Loading from "../Loading/Loading";
import AccountContext from "./Account.Context";
import { styles } from "./Account.Styles";

const Account: React.FC<IComponentProps> = observer(
  ({ classes, appContext }) => {
    const context = useLocalStore(() => new AccountContext(appContext));

    return context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <React.Fragment>
        {
          <div className={classes.main}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <UserIcon />
              </Avatar>
              <TextField
                id="username"
                label="User Name"
                value={context.usernameField.value}
                fullWidth
                variant="filled"
                disabled={true}
              />
              <TextField
                id="email"
                label="Email"
                value={context.emailField.value}
                fullWidth
                variant="filled"
                disabled={true}
              />
              <form
                className={classes.form}
                noValidate
                autoComplete="off"
                onSubmit={context.onSubmit}
              >
                <FormControl margin="normal" required fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="use-custom-smtp"
                        checked={context.useCustomSmtpField.value}
                        onChange={() =>
                          context.useCustomSmtpField.onChange(
                            !context.useCustomSmtpField.value
                          )
                        }
                      />
                    }
                    label="Use custom SMTP"
                  />
                </FormControl>
                {context.useCustomSmtpField.value && (
                  <React.Fragment>
                    <FormControl margin="normal" required fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="use-ssl"
                            checked={context.useSSLField.value}
                            onChange={() =>
                              context.useSSLField.onChange(
                                !context.useSSLField.value
                              )
                            }
                          />
                        }
                        label="Use SSL for SMTP"
                      />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel>Smtp Host</InputLabel>
                      <Input
                        autoComplete="smtp host"
                        autoFocus
                        id="smtphost"
                        onChange={(e) =>
                          context.hostField.onChange(e.target.value)
                        }
                        value={context.hostField.value}
                      />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel>Default From</InputLabel>
                      <Input
                        id="defaultfrom"
                        onChange={(e) =>
                          context.defaultFromField.onChange(e.target.value)
                        }
                        value={context.defaultFromField.value}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Smtp Port</InputLabel>
                      <Input
                        autoComplete="port"
                        id="port"
                        onChange={(e) =>
                          context.portField.onChange(e.target.value)
                        }
                        value={context.portField.value}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Smtp Username</InputLabel>
                      <Input
                        id="smtpusername"
                        autoComplete="off"
                        onChange={(e) =>
                          context.smtpUsernameField.onChange(e.target.value)
                        }
                        value={context.smtpUsernameField.value}
                      />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <MappablePasswordSetter
                        passwordField={context.passwordField}
                      />
                    </FormControl>
                  </React.Fragment>
                )}
                <Button
                  id="save-button"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Save
                </Button>
              </form>
            </Paper>
          </div>
        }
      </React.Fragment>
    );
  }
);

export default withStyles(styles, { withTheme: true })(Account);
