import * as React from "react";
import {
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Observer, observer, useLocalStore } from "mobx-react";
import AppContext from "../../App.Context";
import { Can } from "../Authorisation/AbilityContext";
import CloseIcon from "@material-ui/icons/Close";
import EndpointService from "../../Common/Services/Endpoint.Service";
import { EndpointType } from "../../Common/Enums/EndpointType";
import EndpointsAddButton from "./EndpointsAddButton";
import EndpointsContext from "./Endpoints.Context";
import EndpointsTableFooter from "./EndpointsTableFooter";
import EndpointsTableHeader from "./EndpointsTableHeader";
import EndpointsTableRow from "./EndpointsTableRow";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import IEndpointUpdate from "../../Common/Interfaces/IEndpointUpdate";
import IEndpointVersion from "../../Common/Interfaces/IEndpointVersion";
import Loading from "../Loading/Loading";
import { Redirect } from "react-router-dom";
import RepositoriesService from "../Repositories/Repositories.Service";
import SearchIcon from "@material-ui/icons/Search";
import UpIcon from "@material-ui/icons/ArrowUpward";
import localForage from "localforage";
import { styles } from "./Endpoints.Styles";
import { useEffect } from "react";
import { applyTenancyToV2Origin } from "../../Common/TenancyHelpers";

const Endpoints: React.FC<IComponentProps> = observer(
  ({ classes, appContext }) => {
    const _context = useLocalStore(() => new EndpointsContext());

    useEffect(() => {
      getEndpoints().then(() => {
        // This page is serving as home page for the iframing once it has finished loading 
        // send a message to the parent window
        if(_context.loading === false){
          const tenantedOrigin = applyTenancyToV2Origin(appContext);

          window.parent.postMessage({ isLoading: _context.loading }, tenantedOrigin);
        }
      });
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onVersionChange = (
      e: React.ChangeEvent<{ value: any }>,
      endpoint: IEndpoint
    ) => {
      if (
        _context.selectedVersions.some((v) => v.endpointName === endpoint.name)
      ) {
        if (!e.target.value) {
          _context.selectedVersions = _context.selectedVersions.filter(
            (v) => v.endpointName !== endpoint.name
          );
        } else {
          _context.selectedVersions.find(
            (v) => v.endpointName === endpoint.name
          )!.endpointVersion = endpoint.versions.find(
            (ev) => ev.version === parseInt(e.target.value)
          ) as IEndpointVersion;
        }
      } else {
        _context.selectedVersions.push({
          endpointName: endpoint.name,
          endpointGroup: endpoint.group,
          endpointVersion: endpoint.versions.find(
            (ev) => ev.version === parseInt(e.target.value)
          ) as IEndpointVersion,
          environments: Object.keys(endpoint.environments),
        });
      }
    };

    const handleChangePage = (
      _: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      _context.page = newPage;
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      _context.rowsPerPage = parseInt(event.target.value, 10);
      _context.page = 0;
      localForage.setItem("rowsperpage", _context.rowsPerPage);
    };

    const setPersistTransactionData = async (
      endpoint: IEndpoint,
      value: boolean
    ) => {
      let version = _context.selectedVersions.find(
        (v) => v.endpointName === endpoint.name
      )!.endpointVersion;

      version.persistTransactionData = value;

      let update: IEndpointUpdate = {
        id: endpoint.id!,
        version: version.version,
        persistTransactionData: value,
      };

      EndpointService.Update(appContext, update).catch((error) => {
        window.alert("Something went wrong with updating the endpoint.");
        //In case this broke, set the value back
        version.persistTransactionData = !value;
      });
    };

    const onClickDelete = async (endpoint: IEndpoint, refetch: () => void) => {
      const msg: string =
        `Are you sure you want to delete '${endpoint.name}'?` +
        "\nDoing so will also delete any jobs and receivers which use this endpoint.";
      const confirmed = window.confirm(msg);
      let endpointIndex = _context.endpoints.indexOf(endpoint);
      if (confirmed) {
        await EndpointService.Delete(
          appContext as AppContext,
          endpoint.id as string
        ).then((result) =>
          result
            ? _context.endpoints.splice(endpointIndex, 1)
            : window.alert("Something went wrong with deleting the endpoint.")
        );
        await refetch();
      }
    };

    const setActive = async (endpoint: IEndpoint, value: boolean) => {
      let version = _context.selectedVersions.find(
        (v) => v.endpointName === endpoint.name
      )!.endpointVersion;

      version.active = value;

      let update: IEndpointUpdate = {
        id: endpoint.id!,
        version: version.version,
        active: value,
      };

      EndpointService.Update(appContext, update).catch((error) => {
        window.alert("Something went wrong with updating the endpoint.");
        //In case this broke, set the value back
        version.active = !value;
      });
    };

    const clearSearch = () => {
      _context.searchedEndpoint = "";
      _context.page = 0;
      updateEndpointSearch();
    };

    const onSearchChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
      _context.searchedEndpoint = e.target.value;
      _context.page = 0;
      updateEndpointSearch();
    };

    const updateEndpointSearch = async () => {
      return _context.setSearch();
    };

    const getEndpoints = async () => {
      _context.loading = true;
      return EndpointService.GetEndpoints(appContext).then((endpoints) => {
        endpoints.forEach((e) => (e.type = EndpointType.Wizard));
        endpoints.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        _context.endpoints = endpoints;
        return new RepositoriesService()
          .GetRepositories(appContext)
          .then((repositories) => {
            let repoEndpoints = repositories
              .map((r) => r.endpoints)
              .filter((x) => x !== undefined)
              .flat() as unknown as IEndpoint[];

            repoEndpoints.forEach((e) => (e.type = EndpointType.Repository));
            _context.endpoints = _context.endpoints
              .concat(repoEndpoints)
              .flat();
            _context.endpoints.map((e) => _context.selectLastVersion(e));
            _context.loading = false;
          });
      });
    };

    return _context.redirect ? (
      <Redirect to="/Endpoint" />
    ) : _context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <Observer>
        {() => (
          <Grid container spacing={2}>
            <Can I="create" a="Endpoint">
              <EndpointsAddButton
                setRedirect={(value) => (_context.redirect = value)}
              />
            </Can>
            <Can I="list" a="Endpoint">
              {_context.endpoints && (
                <React.Fragment>
                  {_context.endpoints.length > 0 ? (
                    <Grid item xs={12}>
                      <Paper>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="flex-end"
                        >
                          <Input
                            id="searchbar"
                            value={_context.searchedEndpoint}
                            onChange={onSearchChanged}
                            placeholder="Search endpoints"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton onClick={clearSearch}>
                                  <CloseIcon id="closeIcon" />
                                </IconButton>
                              </InputAdornment>
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                          />
                        </Grid>

                        <Table>
                          <TableHead>
                            <EndpointsTableHeader
                              endpoints={_context.endpoints}
                              context={_context}
                              setShowRepository={(value) =>
                                (_context.showRepositoryEndpoints = value)
                              }
                            />
                          </TableHead>
                          <TableBody>
                            {_context.pagedEndpoints.map((endpoint, index) => (
                              <EndpointsTableRow
                                context={_context}
                                appContext={appContext as AppContext}
                                endpoint={endpoint}
                                endpointIndex={index}
                                isRepositoryEndpoint={
                                  endpoint.type === EndpointType.Repository
                                }
                                refetch={getEndpoints}
                                onVersionChange={onVersionChange}
                                setPersistTransactionData={
                                  setPersistTransactionData
                                }
                                key={endpoint.name}
                                onClickDelete={() =>
                                  onClickDelete(endpoint, getEndpoints)
                                }
                                setActive={setActive}
                              />
                            ))}
                          </TableBody>
                          <TableFooter>
                            <EndpointsTableFooter
                              context={_context}
                              endpoints={_context.filteredEndpoints}
                              handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </TableFooter>
                        </Table>
                      </Paper>
                    </Grid>
                  ) : (
                    <Typography className={classes.centerText} variant="h5">
                      <UpIcon fontSize="large" /> Let's get started! Add some
                      endpoints to start integrating..
                    </Typography>
                  )}
                </React.Fragment>
              )}
            </Can>
          </Grid>
        )}
      </Observer>
    );
  }
);

export default withStyles(styles, { withTheme: true })(Endpoints);
