export default class {
  public static authTokenName = "magicmantoken";
  public static profile = "profile";
  public static TokenStart = "--*";
  public static TokenEnd = "*--";
  public static EscapedTokenStart = "--\\*";
  public static EscapedTokenEnd = "\\*--";
  public static StreamInputMapping = "{{input.stream}}";
  public static StreamInputTokenMapping = "--*stream*--";
  public static Production = "Production"
  public static StreamResponseTokenMapping = "--*ResponseStream*--";
}
