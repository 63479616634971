import { DataFormat } from "../../Common/Enums/DataFormat";
import EndpointWizardContext from "./Context/EndpointWizard.Context";
import SchemaService from "../../Common/Services/Schema.Service";
import DataFactory from "../../Common/Factories/DataFactory";
import AppContext from "../../App.Context";
import Serializers from "../../Common/Helpers/Serializers";
import { ViewingMode } from "./ViewingMode";
import IEndpointVersion from "../../Common/Interfaces/IEndpointVersion";
import debounce from "lodash.debounce";

export const getPreviewSchema = (context: EndpointWizardContext, task?: number) => {
    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(
        task === undefined ?
            context.input.mode :
            context.tasks[task].output.mode)) {
        const schema = task === undefined ?
            context.xmlInputSchemas?.find(schema => schema.namespace === context.input.previewXsdNamespace)?.schema :
            context.tasks[task].responseHandlerXmlSchemas?.find(schema => schema.namespace === context.tasks[task].output.previewXsdNamespace)?.schema;
        if (schema) return schema;
        else return "";
    } else {
        return task === undefined ? context.inputSchema : context.tasks[task].responseHandlerJsonSchema;
    }
}

export const updateSchema = (appContext: AppContext, schema: string, context: EndpointWizardContext, task?: number, force?: boolean) => {
    const format = task === undefined ?
        context.input.mode :
        context.tasks[task].output.mode;

    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(format)) {
        if (task === undefined) {
            context.xmlInputSchemas![
                context.xmlInputSchemas?.findIndex(schema => schema.namespace === context.input.previewXsdNamespace) as number
            ].schema = schema;
            if (context.input.viewingMode !== ViewingMode.Preview || force) {
                generateInputDataFromSchema(appContext, context);
            }
        } else {
            context.tasks[task].responseHandlerXmlSchemas![
                context.tasks[task].responseHandlerXmlSchemas?.findIndex(schema => schema.namespace === context.tasks[task].output.previewXsdNamespace) as number
            ].schema = schema;
        }
    } else {
        if (task === undefined) {
            context.inputSchema = schema;
            if (context.input.viewingMode !== ViewingMode.Preview || force) {
                generateInputDataFromSchema(appContext, context);
            }
        } else {
            context.tasks[task].responseHandlerJsonSchema = schema;
        }
    }
    validateSchemaDebounced(context, appContext);
}

export const generateInputDataFromSchema = async (appContext: AppContext, context: EndpointWizardContext) => {
    const dataFactory = new DataFactory(SchemaService.GenerateXml);

    if ([DataFormat.JSON, DataFormat.QueryString, DataFormat.HL7FHIRJSON, DataFormat.HL7v2].includes(context.input.mode)) {
        let json = await dataFactory.GenerateJSON(context.input.mode, context.inputSchema!);
        context.inputContent = json;
    } else if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(context.input.mode)) {
        if(context.xmlInputSchemas!.every(xmlSchema => Serializers.parseXmlIfValid(xmlSchema.schema))) {

            const xml = await dataFactory.GenerateXML(appContext.managementHost, context.xmlInputSchemas!);
            context.inputContent = xml;
        }
    }
    validateSchemaDebounced(context, appContext);
}

export const validateExampleSchema = async (context: EndpointWizardContext, appcontext: AppContext) => {
    const endpointVersion: IEndpointVersion = {
        inputMode: context.input.mode,
        inputSource: context.input.dataSource,
        method: context.method,
        tasks: [],
        version: 1,
        xmlSchemas: context.xmlInputSchemas,
        schema: context.inputSchema!
    } as unknown as IEndpointVersion;

    if ([DataFormat.JSON, DataFormat.QueryString, DataFormat.HL7FHIRJSON, DataFormat.HL7v2].includes(context.input.mode)) {
        endpointVersion.schema = context.inputSchema!;
    }

    SchemaService.Validate(appcontext.managementHost, endpointVersion, context.inputContent)
        .then(result => {context.validationResult = {
            valid: result.valid,
            messages: result.errors.map((e) => {
                return {
                    location: e.location ? e.location : 'Property',
                    severity: 'error',
                    message: e.message,
                    position: e.position,
                    lineNumber: e.lineNumber
                }
            })
            };
            if(result.valid && context.input.viewingMode === ViewingMode.Validation) {
                context.input.viewingMode = ViewingMode.Preview;
            }
        })
        .finally(() => { context.loading = false })
}

export const validateSchemaDebounced = 
    debounce(validateExampleSchema,
        500, { leading: true, trailing: true });

