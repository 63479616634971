import * as React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  DataFormat,
  DataFormatDisplayName,
} from "../../Common/Enums/DataFormat";
import { Behaviour } from "../../Common/Enums/Behaviour";
import BrushIcon from "@material-ui/icons/Brush";
import { FHIRR4Resource } from "../../Common/HL7/4.0.0 (R4 Sequence)/Resource";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import LinkIcon from "@material-ui/icons/Link";
import Prettifier from "../../Common/Helpers/Prettifier";
import XmlOutputMappingProperties from "../EndpointWizard/Output/XmlOutputMappingProperties";
import { observer } from "mobx-react";
import { useRef } from "react";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";

interface IProps {
  context: EndpointWizardContext;
  task: number;
  classes: any;
}

const OutputFormat: React.FC<IProps> = observer(
  ({ context, task, classes }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <FormControl fullWidth={true}>
            <InputLabel id="format-label">Format</InputLabel>
            <Select
              value={context.tasks[task].output.mode}
              label="Format"
              labelId="format-label"
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                context.switchOutputMode(
                  context.selectedDataOrigin.mode,
                  (DataFormat as any)[(DataFormat as any)[e.target.value]],
                  true,
                  context.activeTask
                )
              }
            >
              {Object.keys(DataFormat)
                .filter(
                  (key) =>
                    isNaN(Number(DataFormat[key as any])) &&
                    [
                      DataFormat.None,
                      DataFormat.Stream,
                      DataFormat.Callback,
                    ].includes((DataFormat as any)[DataFormat[key as any]]) ===
                      false
                )
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {DataFormatDisplayName(
                      (DataFormat as any)[(DataFormat as any)[key as any]]
                    )}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={7}>
          {[DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
            context.tasks[task].output.mode
          ) && (
            <FormControl fullWidth={true}>
              <InputLabel>Resource</InputLabel>
              <Select
                value={context.tasks[task].output.fhir.resource}
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  context.tasks[task].switchFHIRResource(
                    (FHIRR4Resource as any)[
                      (FHIRR4Resource as any)[e.target.value]
                    ],
                    context.tasks[task].output.mode
                  )
                }
              >
                {Object.keys(FHIRR4Resource)
                  .filter((key) => isNaN(Number(FHIRR4Resource[key as any])))
                  .map((key) => (
                    <MenuItem key={key} value={key}>
                      {(FHIRR4Resource as any)[key]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {[DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
            context.tasks[task].output.mode
          ) === false && (
            <XmlOutputMappingProperties
              taskIndex={task}
              context={context}
              classes={classes}
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

export default OutputFormat;
