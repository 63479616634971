import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardHeader, Checkbox, FormControlLabel, Grid, IconButton,  TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { observer } from "mobx-react";
import * as React from 'react';
import MappablePasswordSetter from '../AuthenticationMethods/MappablePasswordSetter';
import TaskContext from '../EndpointWizard/Context/Task.Context';
import CertificateContext from './CertificateContext';
import TrackedTextField from '../Input/TrackedTextField';

interface IProps {
    certificate: CertificateContext,
    onDeleteCertificate: Function,
    allowedExtensions?: string[],
    task: TaskContext
}

const Certificate: React.FC<IProps> = observer(({ certificate, onDeleteCertificate, allowedExtensions=[".pfx", ".p12"], task }) => {
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const [useMappableSecret, setUseMappableSecret] = React.useState(false);

    React.useEffect(() => {
      if(certificate.mappableCert.content){
        setUseMappableSecret(true);
        certificate.name = '';
      }
    }, []);

    const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files![0];

        if (file) {
            certificate.name = file.name;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (readerEvent: ProgressEvent) => {
                const fileReader = readerEvent.target as FileReader;
                certificate.base64 = fileReader.result as string;
            }
        }
    }

    const handleSecretToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      certificate.base64 = '';
      certificate.name = '';
      certificate.mappableCert.content = '';
      setUseMappableSecret(e.target.checked);
  };

    const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault();
      const droppedText = event.dataTransfer.getData('text');
      certificate.mappableCert.content = droppedText;
  };

    return (
        <Card>
        <CardHeader
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useMappableSecret}
                      onChange={handleSecretToggle}
                    />
                  }
                  label="Use Secret"
                />
              </Grid>
              <Grid item xs>
                {!useMappableSecret ? (
                  certificate.name && certificate.base64 ? (
                    <Typography>
                      <FontAwesomeIcon icon={faCertificate} /> {certificate.name}
                    </Typography>
                  ) : (
                    <input
                      onChange={fileChangeHandler}
                      accept={allowedExtensions.join(",")}
                      type="file"
                    />
                  )
                ) : (
                  <TrackedTextField
                    placeholder="--*my-secret?*--"
                    label="Mappable Certificate"
                    fullWidth
                    data-testid="mappable-certificate"
                    context={certificate.mappableCert}
                    onFocus={() => (task.activeTextInput = certificate.mappableCert)}
                    onDrop={handleDrop}
                    variant="outlined"
                  />
                )}
              </Grid>
              <Grid item>
                <MappablePasswordSetter 
                  inputRef={passwordRef}
                  variant="outlined"
                  onFocus={(_) => (task.activeTextInput = certificate.password)}
                  passwordField={certificate.password}
                  infoTextSize="small"
                />
              </Grid>
            </Grid>
          }
          subheader={
            <Box textAlign="left" width="100%" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    Password is stored securely and cannot be viewed
                </Typography>
            </Box>
          }
          action={
            <IconButton onClick={() => onDeleteCertificate()}>
              <DeleteIcon />
            </IconButton>
          }
        />
      </Card>
    );
});

export default Certificate;