import {
  faBackward,
  faCalendar,
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faFilter,
  faFrown,
  faGlobe,
  faPlus,
  faTasks,
  faTimes,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { loader } from "graphql.macro";
import { observer, Observer, useLocalStore } from "mobx-react";
import * as React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import { LoggableAction } from "../../Common/Enums/LoggableAction";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { ICountPerDay } from "../../Common/Interfaces/ICountPerDay";
import { ICountPerEndpoint } from "../../Common/Interfaces/ICountPerEndpoint";
import { ILineClickEvent } from "../../Common/Interfaces/ILineClickEvent";
import IPieClickEvent from "../../Common/Interfaces/IPieClickEvent";
import Loading from "../Loading/Loading";
import UsageDataTable from "../UsageDataTable/UsageDataTable";
import DashboardContext from "./DashboardContext";
import EndpointLineChart from "./EndpointLineChart";
import EndpointPieChart from "./EndpointPieChart";
import TimeRangeSlider from "./TimeRangeSlider";
import { AuthenticatedRoute } from "../AuthenticatedRoute/AuthenticatedRoute";
import dayjs, { Dayjs } from "dayjs";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import EndpointService from "../../Common/Services/Endpoint.Service";
import { useEffect } from "react";
import RepositoriesService from "../Repositories/Repositories.Service";

const classNames = require("classnames");

type Props = IComponentProps & RouteComponentProps;

const Dashboard: React.FC<Props> = observer(
  ({ classes, appContext, location, match, history }) => {
    const _context = useLocalStore(
      () =>
        new DashboardContext(
          match.path.includes("/DrillDown"),
          new URLSearchParams(location.search).get("startDate"),
          new URLSearchParams(location.search).get("endDate"),
          new URLSearchParams(location.search).get("endpoints"),
          new URLSearchParams(location.search).get("actions"),
          new URLSearchParams(location.search).get("transactionId")
        )
    );

    const theme = useTheme();

    useEffect(() => {
      _context.loading = true;
      EndpointService.GetEndpointNames(appContext).then((endpointNames) => {
        _context.endpointNames = endpointNames;
        RepositoriesService.GetEndpointNames(appContext).then(
          (repoEndpointNames) => {
            _context.endpointNames =
              _context.endpointNames.concat(repoEndpointNames);
            _context.loading = false;
            _context.initialize();
          }
        );
      });
    }, [_context, appContext]);

    const handleSelect = (startDate: Date, endDate: Date) => {
      _context.startDate = dayjs(
        `${dayjs(startDate).format("YYYY-MM-DD")} 00:00:00`,
        "YYYY-MM-DD HH:mm:ss"
      );
      _context.endDate = dayjs(
        `${dayjs(endDate).format("YYYY-MM-DD")} 23:59:59`,
        "YYYY-MM-DD HH:mm:ss"
      );
      _context.resetSlider();
      setQuery(
        _context.startDate,
        _context.endDate,
        _context.includeEndpoints || [],
        _context.actions
      );
      _context.refetchDashboardData &&
        _context.refetchDashboardData({
          startDate: _context.startDate,
          endDate: _context.endDate,
          endpoints: _context.includeEndpoints as string[],
        });
    };

    const setQuery = (
      startDate: Dayjs,
      endDate: Dayjs,
      endpoints: string[],
      actions: LoggableAction[],
      transactionId?: string
    ) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("startDate", startDate.toISOString());
      searchParams.set("endDate", endDate.toISOString());
      searchParams.set("endpoints", endpoints.join(","));
      searchParams.set("actions", actions.join(","));
      if (transactionId) {
        searchParams.set("transactionId", transactionId);
      } else {
        searchParams.delete("transactionId");
      }
      history.push(window.location.pathname + "?" + searchParams.toString());
    };

    const renderEndpointPieChart = (data: ICountPerEndpoint[]) => {
      return (
        <EndpointPieChart
          onClick={(e) => onClickPie(e, [LoggableAction.EndpointInvocation])}
          data={data}
          palette={theme.palette}
        />
      );
    };

    const handleEndpointFilter = (event: React.ChangeEvent<{ value: any }>) => {
      _context.includeEndpoints = event.target.value as unknown as string[];

      if (_context.drillDown) {
        _context.refetchUsageTableData &&
          _context.refetchUsageTableData({
            actions: _context.actions,
            startDate: _context.startDate,
            endDate: _context.endDate,
            endpoints: _context.includeEndpoints,
            transactionId: _context.propertyFilters["transactionId"],
            method: _context.propertyFilters["method"],
            ip: _context.propertyFilters["ip"],
          });
      } else {
        _context.refetchDashboardData &&
          _context.refetchDashboardData({
            startDate: _context.startDate,
            endDate: _context.endDate,
            endpoints: _context.includeEndpoints,
          });
      }
      setQuery(
        _context.startDate,
        _context.endDate,
        _context.includeEndpoints,
        _context.actions
      );
    };

    const getDrillDownUrl = (
      startDate: Dayjs,
      endDate: Dayjs,
      endpoints: string[],
      actions: LoggableAction[]
    ) => {
      let url = "/DrillDown";
      if (startDate) {
        url += `?startDate=${startDate.toISOString()}`;
      }
      if (endDate) {
        url += `&endDate=${endDate.toISOString()}`;
      }
      if (endpoints) {
        url += `&endpoints=${endpoints}`;
      }
      url += `&actions=${actions}`;
      return url;
    };

    const renderStats = (
      count: number,
      actionName: string,
      relatedActions: LoggableAction[],
      icon: IconDefinition,
      actionNamePostfix?: string
    ) => {
      return (
        <Grid item={true} lg={3} xs={12} sm={6}>
          <Paper className={classNames(classes.paper, classes.headerTiles)}>
            <Link
              className={classes.link}
              to={getDrillDownUrl(
                _context.startDate,
                _context.endDate,
                _context.includeEndpoints || [],
                relatedActions
              )}
            >
              <Button fullWidth>
                <FontAwesomeIcon
                  icon={icon}
                  size="lg"
                  className={classes.headerTileIcon}
                />
                <Typography className={classes.tileText}>
                  <span id={`${actionName}-count`}>{count}</span> {actionName}
                  {count !== 1 && "s"} {actionNamePostfix}
                </Typography>
              </Button>
            </Link>
          </Paper>
        </Grid>
      );
    };

    const getScaleUnit = (): "day" | "hour" => {
      return rangeIsOneDay() ? "hour" : "day";
    };

    const onClickPie = (event: IPieClickEvent, actions: LoggableAction[]) => {
      if (event) {
        _context.includeEndpoints = [event.name];
        _context.actions = actions;
        _context.redirect = true;
      }
    };

    const onClickLine = (event: ILineClickEvent, actions: LoggableAction[]) => {
      if (event) {
        if (!rangeIsOneDay()) {
          _context.startDate = dayjs(
            `${event.activeLabel}`,
            "YYYY-MM-DD hh:mm:ss"
          );
          _context.endDate = dayjs(
            `${event.activeLabel}`,
            "YYYY-MM-DD hh:mm:ss"
          )
            .add(23, "hours")
            .add(59, "minutes");
        } else {
          _context.startDate = dayjs(
            `${event.activeLabel}`,
            "YYYY-MM-DD HH:mm:ss"
          );
          let endDate = dayjs(
            `${event.activeLabel}`,
            "YYYY-MM-DD HH:mm:ss"
          ).add(59, "minutes");

          _context.endDate = endDate;
        }
        _context.actions = actions;
        _context.redirect = true;
      }
    };

    const toggleEndpointSelect = () => {
      if (
        _context.includeEndpoints &&
        _context.includeEndpoints.length === _context.endpointNames.length
      ) {
        _context.includeEndpoints = [];
      } else {
        _context.includeEndpoints = _context.endpointNames;
      }
    };

    const dateSummary = (): string => {
      if (rangeIsOneDay()) {
        return `${_context.startDate.format("YYYY-MM-DD")}`;
      } else {
        return `${_context.startDate.format(
          "YYYY-MM-DD"
        )} - ${_context.endDate.format("YYYY-MM-DD")}`;
      }
    };

    const timeRangeSummary = (): string => {
      return `${_context.startDate.format("HH:mm")}-${_context.endDate.format(
        "HH:mm"
      )}`;
    };

    const rangeIsOneDay = (): boolean => {
      return _context.startDate.isSame(_context.endDate, "day");
    };

    const timeRangeIsDefault = (): boolean => {
      return (
        _context.startDate.format("HH:mm") === "00:00" &&
        _context.endDate.format("HH:mm") === "23:59"
      );
    };

    const handleSliderChange = (
      event: React.ChangeEvent<{}>,
      value: number | number[]
    ) => {
      if (value[0] !== value[1]) {
        _context.sliderValue = value as number[];
      }
    };

    const handleSliderChangeCommitted = (
      event: React.ChangeEvent<{}>,
      value: number | number[]
    ) => {
      let startDate = new Date(_context.startDate.toISOString());
      startDate.setHours(value[0]);
      _context.startDate = dayjs(startDate);

      let endDate = new Date(_context.startDate.toISOString());
      endDate.setHours(value[1] === 24 ? 23 : value[1]);
      endDate.setMinutes(value[1] === 24 ? 59 : 0);
      _context.endDate = dayjs(endDate);
    };

    return _context.loading ? (
      <Loading fullscreen={false} />
    ) : _context.redirect ? (
      <Redirect
        to={getDrillDownUrl(
          _context.startDate,
          _context.endDate,
          _context.includeEndpoints || [],
          _context.actions
        )}
      />
    ) : (
      <React.Fragment>
        {_context.endpointNames.length < 1 ? (
          <Redirect to="/Endpoints" />
        ) : (
          <Accordion id="endpointFilterPanel">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Avatar className={classes.margin}>
                    <FontAwesomeIcon icon={faFilter} size="lg" />
                  </Avatar>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    color="primary"
                    id="dashboard-endpoint-filter-heading"
                  >
                    <FontAwesomeIcon
                      className={classes.marginRight}
                      icon={faGlobe}
                      size="lg"
                      color={
                        _context.includeEndpoints &&
                        _context.includeEndpoints.length !==
                          _context.endpointNames.length
                          ? theme.palette.secondary.main
                          : theme.palette.primary.main
                      }
                    />
                    {_context.includeEndpoints &&
                    _context.includeEndpoints.length !==
                      _context.endpointNames.length
                      ? `${_context.includeEndpoints.length} endpoint${
                          _context.includeEndpoints.length > 1 ? "s" : ""
                        }`
                      : `All endpoints (${_context.endpointNames.length})`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    color="primary"
                    id="dashboard-time-filter-heading"
                  >
                    <FontAwesomeIcon
                      className={classes.marginRight}
                      icon={faCalendar}
                      size="lg"
                    />
                    {dateSummary()}{" "}
                    {rangeIsOneDay() && (
                      <React.Fragment>
                        <FontAwesomeIcon
                          className={`${classes.marginRight} ${classes.marginLeft}`}
                          icon={faClock}
                          size="lg"
                          color={
                            timeRangeIsDefault()
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main
                          }
                        />{" "}
                        {timeRangeSummary()}
                      </React.Fragment>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Grid container>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} style={{ marginLeft: "-30px" }}>
                  <RangeDatePicker
                    minDate={dayjs().subtract(30, "days")}
                    maxDate={dayjs()}
                    startDate={dayjs(_context.startDate)}
                    endDate={dayjs(_context.endDate)}
                    onChange={(startDate, endDate) =>
                      handleSelect(startDate, endDate)
                    }
                  />
                  {rangeIsOneDay() && (
                    <Grid container className={classes.content}>
                      <TimeRangeSlider
                        values={_context.sliderValue}
                        className={classes.margin}
                        handleSliderChange={handleSliderChange}
                        handleSliderChangeCommitted={
                          handleSliderChangeCommitted
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <Button onClick={toggleEndpointSelect} id="endpointToggle">
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <span className={classes.marginLeft}>Toggle All</span>
                    </Button>
                    <InputLabel shrink>Endpoints</InputLabel>
                    <Select
                      id="selectEndpointDropdown"
                      style={{ minWidth: "300px" }}
                      multiple
                      value={_context.endpointNames.filter(
                        (endpointName: string) =>
                          _context.includeEndpoints &&
                          _context.includeEndpoints.includes(endpointName)
                      )}
                      onChange={handleEndpointFilter}
                      input={<Input />}
                      renderValue={(selected) =>
                        `${(selected as string[]).length} selected`
                      }
                    >
                      {_context.endpointNames.map(
                        (endpointName: string, index: number) => (
                          <MenuItem key={index} value={endpointName}>
                            <Checkbox
                              checked={
                                _context.includeEndpoints &&
                                _context.includeEndpoints.some(
                                  (e) => e === endpointName
                                )
                              }
                            />
                            <ListItemText
                              id={`selectEndpointOptions-${endpointName}`}
                              primary={endpointName}
                            />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {_context.drillDown ? (
          <Grid container spacing={3} className={classes.marginTop}>
            <Grid item xs={12}>
              <AuthenticatedRoute
                exact={false}
                path="/DrillDown"
                render={() => (
                  <UsageDataTable
                    appContext={appContext}
                    context={_context}
                    setQuery={setQuery}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Link to="/" className={classes.link}>
                <Button
                  id="drilldown"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    _context.initialize();
                  }}
                  fullWidth
                >
                  Back{" "}
                  <FontAwesomeIcon
                    className={classes.marginLeft}
                    icon={faBackward}
                  />
                </Button>
              </Link>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
            {_context.includeEndpoints && (
              <Query
                query={loader("../../GraphQL/Queries/dashboard.graphql")}
                variables={{
                  startDate: _context.startDate,
                  endDate: _context.endDate,
                  endpoints: _context.includeEndpoints,
                }}
              >
                {(result) => {
                  const { loading, error, data, refetch } = result;
                  const noDataAndNoError = !data && !error;
                  if (loading || noDataAndNoError) {
                    return <Loading fullscreen={true} />;
                  }
                  if (error) {
                    return (
                      <FontAwesomeIcon
                        className={classes.centerText}
                        icon={faFrown}
                        size="10x"
                        color={theme.palette.error.main}
                      />
                    );
                  }
                  _context.refetchDashboardData = refetch;
                  return (
                    <Observer>
                      {() => (
                        <Grid
                          container
                          spacing={3}
                          className={classes.marginTop}
                        >
                          {renderStats(
                            data.usagedashboard.invocations as number,
                            "invocation",
                            [LoggableAction.EndpointInvocation],
                            faGlobe
                          )}
                          {renderStats(
                            data.usagedashboard.errors as number,
                            "error",
                            [
                              LoggableAction.EndpointAuthenticationFailure,
                              LoggableAction.EndpointException,
                              LoggableAction.EndpointTaskFailure,
                              LoggableAction.EndpointValidationFailure,
                              LoggableAction.NonExistantEndpointInvocation,
                              LoggableAction.EndpointInvocationFailure,
                            ],
                            faExclamationCircle
                          )}
                          {renderStats(
                            data.usagedashboard.run as number,
                            "task",
                            [LoggableAction.EndpointTaskSuccess],
                            faTasks,
                            "run"
                          )}
                          {renderStats(
                            data.usagedashboard.skipped as number,
                            "task",
                            [LoggableAction.EndpointTaskSkipped],
                            faTimes,
                            "skipped"
                          )}
                          {(data.usagedashboard.invocations > 0 ||
                            data.usagedashboard.errors > 0) && (
                            <React.Fragment>
                              <Grid item md={12}>
                                <Paper>
                                  <Typography
                                    component="h2"
                                    variant="h6"
                                    color="primary"
                                    align="center"
                                    gutterBottom
                                  >
                                    Invocations
                                  </Typography>
                                  <div style={{ width: "100%", height: 300 }}>
                                    <EndpointLineChart
                                      data={
                                        data.usagedashboard
                                          .invocationsPerDay as ICountPerDay[]
                                      }
                                      onClick={(e) =>
                                        onClickLine(e, [
                                          LoggableAction.EndpointInvocation,
                                        ])
                                      }
                                      scaleUnit={getScaleUnit()}
                                      startDate={_context.startDate}
                                      endDate={_context.endDate}
                                      classes={classes}
                                      id="invocation-line-chart"
                                    />
                                  </div>
                                </Paper>
                              </Grid>
                              <Grid item xs={6}>
                                <Paper>
                                  <Typography
                                    component="h2"
                                    variant="h6"
                                    color="primary"
                                    align="center"
                                    gutterBottom
                                  >
                                    Errors
                                  </Typography>
                                  <div style={{ width: "100%", height: 500 }}>
                                    <EndpointLineChart
                                      data={
                                        data.usagedashboard
                                          .errorsPerDay as ICountPerDay[]
                                      }
                                      onClick={(e) =>
                                        onClickLine(e, [
                                          LoggableAction.EndpointAuthenticationFailure,
                                          LoggableAction.EndpointException,
                                          LoggableAction.EndpointTaskFailure,
                                          LoggableAction.EndpointValidationFailure,
                                          LoggableAction.NonExistantEndpointInvocation,
                                          LoggableAction.EmailAttachmentExtractionError,
                                          LoggableAction.ReceiverException,
                                          LoggableAction.EndpointInvocationFailure,
                                        ])
                                      }
                                      scaleUnit={getScaleUnit()}
                                      startDate={_context.startDate}
                                      endDate={_context.endDate}
                                      classes={classes}
                                      id="error-line-chart"
                                    />
                                  </div>
                                </Paper>
                              </Grid>
                              <Grid item xs={6}>
                                <Paper>
                                  <Typography
                                    component="h2"
                                    variant="h6"
                                    color="primary"
                                    align="center"
                                    gutterBottom
                                  >
                                    Endpoints
                                  </Typography>
                                  <div style={{ width: "100%", height: 500 }}>
                                    {renderEndpointPieChart(
                                      data.usagedashboard
                                        .invocationsPerEndpoint as ICountPerEndpoint[]
                                    )}
                                  </div>
                                </Paper>
                              </Grid>
                            </React.Fragment>
                          )}
                          <Grid item xs={12}>
                            <Link
                              className={classes.link}
                              to={getDrillDownUrl(
                                _context.startDate,
                                _context.endDate,
                                _context.includeEndpoints || [],
                                [
                                  LoggableAction.EndpointAuthentication,
                                  LoggableAction.EndpointAuthenticationFailure,
                                  LoggableAction.EndpointException,
                                  LoggableAction.EndpointInvocation,
                                  LoggableAction.EndpointManagementAuthenticationFailure,
                                  LoggableAction.EndpointTaskFailure,
                                  LoggableAction.EndpointTaskSkipped,
                                  LoggableAction.EndpointTaskSuccess,
                                  LoggableAction.EndpointValidationFailure,
                                  LoggableAction.NonExistantEndpointInvocation,
                                  LoggableAction.EndpointTaskBegins,
                                  LoggableAction.EndpointResponds,
                                  LoggableAction.FaxReceivingFailed,
                                  LoggableAction.MESHMessageReceived,
                                  LoggableAction.EmailMessageReceived,
                                  LoggableAction.ReceiverException,
                                  LoggableAction.EmailAttachmentExtractionError,
                                  LoggableAction.EndpointValidationSuccess,
                                  LoggableAction.EndpointTaskValuesResolved,
                                  LoggableAction.EmailMessageProcessed,
                                  LoggableAction.EmailMessageRejected,
                                  LoggableAction.EmailMessageAccepted,
                                  LoggableAction.EndpointInvocationFailure,
                                  LoggableAction.MESHMessageProcessed,
                                  LoggableAction.MESHMessageRejected,
                                  LoggableAction.MESHMessageAccepted,
                                  LoggableAction.MESHMessageReport,
                                ]
                              )}
                            >
                              <Button
                                id="drilldown"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                              >
                                More{" "}
                                <FontAwesomeIcon
                                  className={classes.marginLeft}
                                  icon={faPlus}
                                />
                              </Button>
                            </Link>
                          </Grid>
                        </Grid>
                      )}
                    </Observer>
                  );
                }}
              </Query>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
);

export default withStyles(appStyles, { withTheme: true })(
  withRouter(Dashboard)
);
