import { observable, autorun } from "mobx";
import DashboardContext from "../Dashboard/DashboardContext";
import IUsageDataPoint from "../../Common/Interfaces/IUsageDataPoint";
import LoggableActionHelpers from "../../Common/Helpers/LoggableActionHelpers";

export default class UsageDataTableContext {
    constructor(
        dashboardContext: DashboardContext,
        transactionMode: boolean) {
        this._dashboardContext = dashboardContext;
        this.transactionMode = transactionMode;
    }

    private _dashboardContext: DashboardContext;

    @observable
    public propertyFilterValue: string = '';

    @observable
    public points: IUsageDataPoint[] = [];

    @observable
    public total: number = 0;

    public updateFilterValue = autorun(
        async () => {
            if (this._dashboardContext.selectedPropertyFilter) {
                this._dashboardContext.propertyFilters[this._dashboardContext.selectedPropertyFilter as string] = this.propertyFilterValue;
            }
        }, { delay: 500 }
    );

    @observable
    public page: number = 0;

    @observable
    public rowsPerPage: number = 10;

    @observable
    public previewAnchorEl: HTMLElement | undefined;

    @observable
    public preview?: string;

    @observable
    public orderBy: string = "timestamp";

    @observable
    public orderDesc: boolean = true;

    @observable
    public transactionMode: boolean = false;

    public displayTaskBehaviourColumn = () => {
        if (this._dashboardContext.actions) {
            return LoggableActionHelpers.shouldDisplayTaskBehaviour(this._dashboardContext.actions);
        }
        return false;
    };
}