import {
  faClock,
  faGlobe,
  faFax,
  faEnvelope,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Divider,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import AceEditor from "react-ace";
import { Link } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import { DataFormat } from "../../Common/Enums/DataFormat";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import Help from "../Help/Help";
import Loading from "../Loading/Loading";
import ReceiversContext from "./Receivers.Context";
import { IReceiverBase } from "../../Common/Interfaces/IReceiver";
import ReceiverService from "../Receiver/Receiver.Service";
import AppContext from "../../App.Context";
import MagicFriendlyDate from "../MagicFriendlyDate/MagicFriendlyDate";
import { Can, AbilityContext } from "../Authorisation/AbilityContext";
import { useContext } from "react";
import { ReceiverType } from "../../Common/Enums/ReceiverType";

interface IProps extends IComponentProps {
  context: ReceiversContext;
}

const onClickDelete = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  receiver: IReceiverBase,
  appContext: AppContext,
  context: ReceiversContext
) => {
  event.preventDefault();
  event.stopPropagation();
  if (window.confirm(`Are you sure you want to delete '${receiver.name}'?`)) {
    ReceiverService.Delete(
      appContext,
      receiver.type,
      receiver.id as string
    ).then(() => context.GetReceivers(appContext));
  }
};

const onClickActive = (receiver: IReceiverBase, appContext: AppContext) => {
  receiver.active = !receiver.active;
  ReceiverService.Save(appContext, receiver, receiver.id);
};

const Receivers: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    const ability = useContext(AbilityContext);

    const renderReceiverIcon = (type: ReceiverType) => {
      switch (type) {
        case ReceiverType.SMS:
          return faSms;
        case ReceiverType.Fax:
          return faFax;
        case ReceiverType.POP3:
          return faEnvelope;
        default:
          return faClock;
      }
    };

    const getReceiverPreview = (receiver: IReceiverBase) => {
      if (receiver.inputFormat === DataFormat.Stream) {
        return "Will stream body as stream input";
      }
      return receiver.template;
    };

    return context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <Grid container spacing={2}>
        <Can I="create" a="Retriever">
          <Grid item xs={12}>
            <Link to="/Receiver">
              <Button
                id="add-receiver"
                variant="outlined"
                color="default"
                size="large"
                fullWidth
              >
                <AddIcon fontSize="large" />
              </Button>
            </Link>
          </Grid>
        </Can>
        <Can I="list" a="Retriever">
          <React.Fragment>
            {context.receivers.map((receiver, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id={`receiver-${receiver.name}`}
                    >
                      <Avatar className={classes.margin}>
                        <FontAwesomeIcon
                          icon={renderReceiverIcon(receiver.type)}
                          size="lg"
                        />
                      </Avatar>
                      <Grid container justify="space-between">
                        <Grid item xs={3}>
                          <Typography className={classes.margin}>
                            {receiver.name}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            className={classes.margin}
                          >
                            Added: {receiver.added!.toLocaleString()}
                          </Typography>
                        </Grid>
                        <Grid container item xs={3} alignContent="center">
                          <Typography>
                            <b>Last retrieval:</b>{" "}
                            <MagicFriendlyDate date={receiver.last as Date} />
                          </Typography>
                        </Grid>
                        <Grid container item xs={3} alignContent="center">
                          <Typography>
                            {receiver.type === ReceiverType.MESH ||
                            receiver.type === ReceiverType.POP3 ? (
                              <>
                                <b>Last polled:</b>{" "}
                                <MagicFriendlyDate
                                  date={receiver.lastPolled as Date}
                                />
                              </>
                            ) : null}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            onClick={(e) => e.stopPropagation()}
                            control={
                              <Switch
                                onChange={() =>
                                  onClickActive(receiver, appContext)
                                }
                                checked={receiver.active}
                                disabled={ability.cannot("edit", "Retriever")}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <Divider />
                    <Can I="view" a="Retriever">
                      <AccordionDetails>
                        <Avatar className={classes.margin}>
                          <FontAwesomeIcon icon={faGlobe} size="lg" />
                        </Avatar>
                        <Grid container>
                          <Grid item xs={3}>
                            <Typography>{receiver.endpointName}</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <AceEditor
                              className={classNames(
                                classes.margin,
                                classes.receiverPreviewEditor
                              )}
                              mode={DataFormatHelper.GetModeString(
                                receiver.inputFormat as DataFormat
                              )}
                              theme="tomorrow"
                              fontSize={20}
                              showPrintMargin={true}
                              height="200px"
                              width="100%"
                              readOnly={true}
                              showGutter={true}
                              highlightActiveLine={false}
                              value={getReceiverPreview(receiver)}
                              editorProps={{ $blockScrolling: true }}
                              setOptions={{
                                useWorker: false,
                                showLineNumbers: true,
                                tabSize: 4,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Can>
                    <Divider />
                    <AccordionActions>
                      <Can I="edit" a="Retriever">
                        <Link to={`/Receiver/${receiver.type}/${receiver.id}`}>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </Can>
                      <Can I="delete" a="Retriever">
                        <IconButton
                          id={`receiver-${receiver.name}-delete`}
                          onClick={(e) =>
                            onClickDelete(e, receiver, appContext, context)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Can>
                    </AccordionActions>
                  </Accordion>
                </Grid>
              );
            })}
          </React.Fragment>
        </Can>
        {context.receivers.length === 0 && (
          <Typography className={classes.centerText} variant="h5">
            <UpIcon fontSize="large" /> Create a receiver..
            <Help
              classes={classes}
              helpText="Receivers run in the background and can collect data from various sources"
            />
          </Typography>
        )}
      </Grid>
    );
  }
);

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps> {
    render() {
      const context = new ReceiversContext(this.props.appContext);

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withContext(Receivers)
);
