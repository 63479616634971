import { Button, Grid, withStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import "brace/mode/json";
import "brace/mode/text";
import "brace/mode/xml";
import 'brace/theme/tomorrow';
import copy from 'clipboard-copy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import AceEditor from 'react-ace';
import { appStyles } from '../../App.Styles';
import Help from '../Help/Help';
import AppContext from '../../App.Context';

interface IProps {
    classes: any;
    exportText: string;
    includeEncrypted: boolean;
    setIncludeEncrypted: (boolean) => void;
    closeExport: () => void;
    appContext: AppContext;
}

const helpMessage = "If encrypted values are not included, this endpoint can't be imported anywhere else. However, including encrypted values as plaintext risks exposing sensitive data such as authentication and mail passwords."

const EndpointExport: React.FC<IProps> = observer(({ classes, exportText, includeEncrypted, setIncludeEncrypted, closeExport, appContext }) => {
    const [copied, setCopied] = useState(false);

    const onCheckboxChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setIncludeEncrypted(checked);
    };

    return (
        <React.Fragment>
            <Grid
                justify="space-between"
                container>
                <Grid item>
                    <FormControlLabel
                        style={{height: '100%'}}
                        control={
                            <Checkbox 
                                id="checkbox-include-encrypted"
                                checked={includeEncrypted} 
                                onChange={onCheckboxChange}/>
                        }
                        label="Include Encrypted Values as Plaintext"
                        labelPlacement="start"
                        />
                        <Help
                            classes={classes}
                            helpText={helpMessage} />
                </Grid>
                <Grid item/>
                <Grid item>
                    <Button
                        title="Copy Endpoint Definition"
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                        onClick={() => {
                            copy(exportText);
                            setCopied(true);
                            setTimeout(() => {
                                setCopied(false);
                            }, 3000)
                        }}>
                        {copied ? <CheckIcon /> : <CopyIcon />}
                    </Button>
                    <Button
                        title="Hide Endpoint Definition"
                        variant="contained"
                        color="default"
                        className={classes.margin}
                        onClick={closeExport}>
                        <CloseIcon />
                    </Button>
                </Grid>
            </Grid>
            <div id="endpoint-export">
                <AceEditor
                    className={classes.marginTop}
                    mode="json"
                    theme="tomorrow"
                    fontSize={20}
                    showPrintMargin={true}
                    width='100%'
                    showGutter={true}
                    highlightActiveLine={false}
                    value={exportText}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        useWorker: false,
                        highlightGutterLine: false
                    }} />
            </div>
        </React.Fragment>
    )
})

export default withStyles(appStyles, { withTheme: true })(EndpointExport);