import { DataFormat } from "../Enums/DataFormat";
import { IExampleOutput } from "../../Components/EndpointWizard/IExampleOutput";
import { ResponseHandlerType } from "../Enums/ResponseHandlerType";
import EndpointHelpers from "./EndpointHelpers";
import AppContext from "../../App.Context";

export default class ExampleHelpers {
    constructor(appContext: AppContext) {
        this._managementHost = appContext.managementHost;
    }

    private readonly _managementHost: string;

    public async GetResponseHandlerExampleOutput(mode: DataFormat, type: ResponseHandlerType): Promise<IExampleOutput> {
        let output = '';
        
        if (type === ResponseHandlerType.Forward) {
            if (mode === DataFormat.JSON) {
                output = '{\n    "response": --*{response}*--,\n    "values": {}\n}';
            } else if (mode === DataFormat.XML) {
                output = '<xml>\n    <response>--*{response}*--</response>\n    <values></values>\n</xml>';
            }
        } else if (type === ResponseHandlerType.Receive) {
            const result = await new EndpointHelpers(this._managementHost).GetInputData(mode);
            output = result.text;
        }

        return {
            code: output,
            addedProperties: {}
        };
    }
}