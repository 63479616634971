import { observable } from "mobx";
import { FormState, FieldState } from "formstate";
import { FormEvent } from "react";
import Constants from "../../Common/Constants";
import { AccountService } from "./Account.Service";
import BaseContext from "../../Common/BaseContext";
import ApolloClientFactory from "../../Common/Factories/ApolloClientFactory";
import { AppService } from "../../App.Service";

export default class LoginContext extends BaseContext {
  public username = new FieldState("").validators(
    (val) => !val && "Username required"
  );
  public password = new FieldState("").validators(
    (val) => !val && "Password required"
  );

  @observable
  public loading = false;

  @observable
  public redirect = false;

  @observable
  public loginErrors: string[] = [];

  form = new FormState({
    username: this.username,
    password: this.password,
  });

  onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationResult = await this.form.validate();

    if (validationResult.hasError) {
      return false;
    }

    this.loading = true;

    await AccountService.Login(
      this.AppContext.managementHost as string,
      validationResult.value.username.value,
      validationResult.value.password.value
    )
      .then((result) => {
        if (!result.errors && result.token) {
          this.AppContext.loggedIn = true;
          localStorage.setItem(Constants.authTokenName, result.token);
          this.AppContext.apolloClient = ApolloClientFactory.Get(
            this.AppContext.managementHost,
            result.token
          );
          this.AppContext.contextPromise = AppService.Context(this.AppContext);
          this.AppContext.contextPromise.then((result) => {
            this.AppContext.username = result.username;
            AccountService.GetAccountDetails(
              this.AppContext.managementHost
            ).then((accountDetails) => {
              this.AppContext.accountDetails = accountDetails;
            });
          });
          this.AppContext.loadAdapters();
          this.AppContext.loadMeshMailboxes();
          this.redirect = true;
        } else if (result.errors) {
          this.loginErrors = result.errors;
        } else {
          this.loginErrors = ["There were problems logging you in"];
        }
      })
      .catch(() => (this.loginErrors = ["There were problems logging you in"]))
      .finally(() => (this.loading = false));
  };
}
