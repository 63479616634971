import AppContext from "../../App.Context";
import LoggingService from "../../Common/Services/Logging.Service";
import IReceiver, { IReceiverBase } from "../../Common/Interfaces/IReceiver";
import { ReceiverType } from "../../Common/Enums/ReceiverType";
import { getToken } from "../../Common/Helpers/AuthenticationHelpers";

export default class ReceiverService {
    public static Save = (appContext: AppContext, data: IReceiverBase, id?: string) => {
        const uri = `${appContext.managementHost}/Receiver/${ReceiverType[data.type]}`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: id ? 'PATCH' : 'POST',
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Get = <T>(appContext: AppContext, receiverType: ReceiverType, id: string): Promise<IReceiver<T>> => {
        const uri = `${appContext.managementHost}/Receiver/${ReceiverType[receiverType]}/${id}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Delete = (appContext: AppContext, receiverType: ReceiverType, id: string): Promise<boolean> => {
        const uri = `${appContext.managementHost}/Receiver/${ReceiverType[receiverType]}/${id}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then(response => response.ok)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }
}