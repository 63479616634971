import "brace/mode/json";
import "brace/mode/xml";
import "brace/mode/html";
import "brace/theme/tomorrow";

import * as React from "react";

import { Button, Grid, Snackbar, Typography } from "@material-ui/core";
import {
  DataFormat,
  DataFormatDisplayName,
} from "../../../Common/Enums/DataFormat";

import AdapterOutput from "../../AdapterOutput/AdapterOutput";
import { Behaviour } from "../../../Common/Enums/Behaviour";
import { DataSource } from "../../../Common/Enums/DataSource";
import EmailOutput from "../../EmailOutput/EmailOutput";
import EndpointHelpers from "../../../Common/Helpers/EndpointHelpers";
import { EndpointStepType } from "../EndpointStepType";
import EndpointTests from "../../EndpointTests/EndpointTests";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import ErrorIcon from "@material-ui/icons/Error";
import { EvaluateXPath } from "../../../Common/Helpers/PathEvaluators";
import FaxOutput from "../../FaxOutput/FaxOutput";
import { HttpMethod } from "../../../Common/Enums/HttpMethod";
import IComponentProps from "../../../Common/Interfaces/IComponentProps";
import IOutputProps from "../IOutputProps";
import ISchemaGenerationResult from "../../../Common/Interfaces/ISchemaGenerationResult";
import { JSONPath } from "jsonpath-plus";
import JobOutput from "../../JobOutput/JobOutput";
import JwtOutput from "../../JwtOutput/JwtOutput";
import MESHOutput from "../../MESHOutput/MESHOutput";
import { MapType } from "../../../Common/Enums/MapType";
import NextIcon from "@material-ui/icons/NavigateNext";
import ParsedOutputViewer from "./ParsedOutputViewer";
import PdfOutput from "../../PdfOutput/PdfOutput";
import Prettifier from "../../../Common/Helpers/Prettifier";
import PreviousIcon from "@material-ui/icons/NavigateBefore";
import RedirectOutput from "../../RedirectOutput/RedirectOutput";
import RefreshIcon from "@material-ui/icons/Refresh";
import RequestOutput from "../../RequestOutput/RequestOutput";
import ResponseHandlerOutput from "../../ResponseHandlerOutput/ResponseHandlerOutput";
import { ResponseHandlerType } from "../../../Common/Enums/ResponseHandlerType";
import ResponseOutput from "../../ResponseOutput/ResponseOutput";
import SMSOutput from "../../SMSOutput/SMSOutput";
import SftpOutput from "../../SftpOutput/SftpOutput";
import TaskContext from "../Context/Task.Context";
import TaskFilters from "../../TaskFilters/TaskFilters";
import { ViewingMode } from "../ViewingMode";
import classNames from "classnames";
import { observer } from "mobx-react";
import AireAuditOutput from "../../AireAuditOutput/AireAuditOutput";
import MappingOutput from "../../MappingOutput/MappingOutput";

interface IProps extends IComponentProps {
  context: EndpointWizardContext;
  onClickNext: (nextIndex: number) => void;
  task: number;
}

@observer
export default class OutputStep extends React.Component<IProps> {
  public constructor(props: any) {
    super(props);
    if (
      [Behaviour.Request, Behaviour.Response, Behaviour.Mapping].includes(
        this.props.context.tasks[this.props.task].behaviour
      ) &&
      this.props.context.tasks[this.props.task].reinitializeOutput
    ) {
      this.props.context.tasks[this.props.task].output.mode =
        this.props.context.tasks[this.props.task].requestMethod !==
        HttpMethod.GET
          ? this.props.context.input.mode !== DataFormat.None &&
            this.props.context.input.mode !== DataFormat.Callback
            ? this.props.context.input.mode
            : DataFormat.JSON
          : DataFormat.QueryString;
      const exampleOutput = this.props.context.getRequestExampleOutput(
        this.props.context.selectedDataOrigin.mode,
        this.props.context.tasks[this.props.task].output.mode,
        this.props.context.selectedDataOrigin.data,
        undefined,
        this.props.task
      );
      this.props.context.tasks[this.props.task].addedProperties =
        exampleOutput.addedProperties;
      this.props.context.tasks[this.props.task].setContent(exampleOutput.code);
      this.props.context.tasks[this.props.task].reinitializeOutput = false;
    } else if (
      this.props.context.tasks[this.props.task].reinitializeOutput &&
      this.props.context.tasks[this.props.task].behaviour ===
        Behaviour.ResponseHandler
    ) {
      this.props.context.tasks[this.props.task].output.mode =
        this.props.context.input.mode !== DataFormat.None
          ? this.props.context.input.mode
          : DataFormat.JSON;
      this.props.context.exampleHelpers
        .GetResponseHandlerExampleOutput(
          this.props.context.tasks[this.props.task].output.mode,
          this.props.context.tasks[this.props.task].responseHandlerTypeField
            .value
        )
        .then((result) => {
          this.props.context.tasks[this.props.task].addedProperties =
            result.addedProperties;
          this.props.context.tasks[this.props.task].setContent(result.code);
          this.props.context.updatePreview();
          this.props.context.tasks[this.props.task].reinitializeOutput = false;
        });
    } else if (
      this.props.context.tasks[this.props.task].behaviour ===
        Behaviour.Redirect &&
      this.props.context.tasks[this.props.task].reinitializeOutput
    ) {
      this.props.context.tasks[this.props.task].output.mode =
        DataFormat.QueryString;
      const exampleOutput = this.props.context.getRequestExampleOutput(
        this.props.context.selectedDataOrigin.mode,
        this.props.context.tasks[this.props.task].output.mode,
        this.props.context.selectedDataOrigin.data,
        undefined,
        this.props.task
      );
      this.props.context.tasks[this.props.task].addedProperties =
        exampleOutput.addedProperties;
      this.props.context.tasks[this.props.task].setContent(exampleOutput.code);
      this.props.context.tasks[this.props.task].reinitializeOutput = false;
    }

    this.props.context.tasks[this.props.task].output.loaded = true;
    this.props.context.updatePreview();

    if (
      this.props.context.tasks[this.props.task].requestMethod === HttpMethod.GET
    ) {
      this.props.context.tasks[this.props.task].output.dataSource =
        DataSource.QueryString;
    }
  }

  /**
   * MAG-610
   * Reset the property if the previous step had it disabled
   */
  componentDidMount() {
    if (!this.props.context.isEditorTokenImportEnabled) {
      this.props.context.isEditorTokenImportEnabled = true;
    }
  }

  private getOutputComponent = (behaviour: Behaviour) => {
    switch (behaviour) {
      case Behaviour.Request:
        return RequestOutput;
      case Behaviour.ResponseHandler:
        return ResponseHandlerOutput;
      case Behaviour.Email:
        return EmailOutput;
      case Behaviour.MESH:
        return MESHOutput;
      case Behaviour.Fax:
        return FaxOutput;
      case Behaviour.SMS:
        return SMSOutput;
      case Behaviour.Job:
        return JobOutput;
      case Behaviour.Response:
        return ResponseOutput;
      case Behaviour.Redirect:
        return RedirectOutput;
      case Behaviour.Mapping:
        return MappingOutput;
      case Behaviour.PDF:
        return PdfOutput;
      case Behaviour.Adapter:
        return AdapterOutput;
      case Behaviour.SFTP:
        return SftpOutput;
      case Behaviour.JWT:
        return JwtOutput;
      case Behaviour.AireAudit:
        return AireAuditOutput;
    }
  };

  private onClickNext = () => {
    this.props.context.tasks[
      this.props.context.activeTask
    ].updateHL7v2OutputData();

    if (
      this.props.context.tasks[this.props.context.activeTask].behaviour ===
        Behaviour.ResponseHandler &&
      this.props.context.tasks[this.props.task].responseHandlerTypeField
        .value === ResponseHandlerType.TransparentForward
    ) {
      this.props.context.tasks[this.props.task].responseHandlerContent.content =
        "--*{response}*--";
    }

    if (
      this.props.context.tasks[this.props.context.activeTask].output.modified
    ) {
      this.props.context.loading = true;

      this.props.context
        .requestUpdateOutput()
        .then((result) => {
          if (
            this.props.context.tasks[this.props.context.activeTask]
              .behaviour === Behaviour.ResponseHandler &&
            this.props.context.tasks[this.props.context.activeTask]
              .responseHandlerTypeField.value === ResponseHandlerType.Receive
          ) {
            this.props.context.tasks[
              this.props.task
            ].responseHandlerJsonSchema = (
              result as ISchemaGenerationResult
            ).jsonSchema;
            this.props.context.tasks[
              this.props.task
            ].responseHandlerXmlSchemas = (
              result as ISchemaGenerationResult
            ).xmlSchemas;
          } else {
            this.props.context.tasks[this.props.task].preview =
              Prettifier.prettify(
                result as string,
                this.props.context.tasks[this.props.task].output.mode
              );
          }

          if (
            this.props.context.valid(EndpointStepType.Output, this.props.task)
          ) {
            this.props.onClickNext(this.props.context.activeStep + 1);
          }
        })
        .finally(() => {
          this.props.context.loading = false;
          this.props.context.tasks[this.props.task].output.modified = false;
          this.props.context.updatePreview();
        });
    } else {
      if (this.props.context.valid(EndpointStepType.Output, this.props.task)) {
        this.props.onClickNext(this.props.context.activeStep + 1);
      }
    }
  };

  private onDrop = (ev: React.DragEvent<HTMLElement>) => {
    this.props.context.addDraggedPropertyToOutput(
      this.props.task,
      ev.dataTransfer.getData("text/plain")
    );
  };

  private getOrigin = () => {
    return [undefined, null, -1].includes(
      this.props.context.tasks[this.props.task].editingPropertyTokenOrigin
    )
      ? this.props.context.input
      : this.props.context.tasks[
          this.props.context.tasks[this.props.task]
            .editingPropertyTokenOrigin as number
        ].output;
  };

  private nextDisabled = () => {
    return (
      (this.props.context.tasks[this.props.task].output.modified === false &&
        this.props.context.stepValid(this.props.context.activeStep) ===
          false) ||
      this.props.context.allPreceedingStepsValid(
        this.props.context.activeStep
      ) === false ||
      this.props.context.tasks[this.props.task].mappingValid === false ||
      this.props.context.validationResult?.valid === false ||
      this.props.context.tasks[this.props.task].editingPropertyToken !==
        undefined
    );
  };

  private onClickEditPath = () => {
    this.props.context.tasks[this.props.task].editingPropertyToken =
      this.props.context.tasks[this.props.task].selectedPropertyToken;
    this.props.context.tasks[this.props.task].editingPropertyTokenOrigin = [
      undefined,
      null,
      -1,
    ].includes(
      this.props.context.tasks[this.props.task].addedPropertyOrigins[
        this.props.context.tasks[this.props.task].editingPropertyToken as string
      ]
    )
      ? this.props.context.tasks[this.props.task].addedProperties[
          this.props.context.tasks[this.props.task]
            .editingPropertyToken as string
        ] === undefined
        ? this.props.context.tasks[this.props.task].mappingSelectedDataOrigin
        : -1
      : this.props.context.tasks[this.props.task].addedPropertyOrigins[
          this.props.context.tasks[this.props.task]
            .editingPropertyToken as string
        ];
    this.props.context.tasks[this.props.task].output.viewingMode =
      ViewingMode.Tree;
    this.props.context.editedPath =
      this.props.context.tasks[this.props.task].addedProperties[
        this.props.context.tasks[this.props.task].editingPropertyToken as string
      ];

    const origin = this.getOrigin();

    if (this.props.context.editedPath) {
      if (
        origin.mode === DataFormat.JSON ||
        origin.mode === DataFormat.QueryString
      ) {
        this.props.context.editingPathMatches = JSONPath({
          json: origin.data,
          path: this.props.context.editedPath,
          resultType: "all",
        });
      } else {
        try {
          this.props.context.editingPathMatches = EvaluateXPath(
            this.props.context.editedPath,
            origin.data as Document
          );
          this.props.context.editingPathError = false;
        } catch (e) {
          console.log(e);
          this.props.context.editingPathError = true;
        }
      }
    }
  };

  public render() {
    const OutputComponent = this.getOutputComponent(
      this.props.context.tasks[this.props.task].behaviour
    ) as React.ComponentType<IOutputProps>;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            Add some properties to your output
          </Typography>
        </Grid>
        <OutputComponent
          appContext={this.props.appContext}
          classes={this.props.classes}
          context={this.props.context}
          onClickEditPath={this.onClickEditPath}
          onDrop={this.onDrop}
          task={this.props.task}
        />
        <ParsedOutputViewer
          appContext={this.props.appContext}
          context={this.props.context}
          classes={this.props.classes}
          task={this.props.task}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={
            (([Behaviour.Request, Behaviour.Redirect].includes(
              this.props.context.tasks[this.props.task].behaviour
            ) &&
              this.props.context.valid(
                EndpointStepType.Output,
                this.props.task
              ) === false) ||
              this.props.context.tasks[this.props.task].mappingValid ===
                false) &&
            this.props.context.tasks[this.props.task].output.loaded === true
          }
          className={`${this.props.classes.invalid} ${this.props.classes.snackbarInvalidTop}`}
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <span
            className={classNames(
              this.props.classes.margin,
              this.props.classes.message
            )}
          >
            <ErrorIcon />
            {this.props.context.tasks[this.props.task].mappingValid ? (
              <React.Fragment>
                Check required fields, and ensure that output is valid{" "}
                {DataFormatDisplayName(
                  this.props.context.tasks[this.props.task].output.mode
                )}
                .
              </React.Fragment>
            ) : (
              <React.Fragment>
                Mapping of output is currently invalid - check that all inserted
                tokens have associated paths
              </React.Fragment>
            )}

            <Button
              className={this.props.classes.invalid}
              onClick={() => this.props.context.updatePreview()}
            >
              <RefreshIcon />
            </Button>
          </span>
        </Snackbar>
        {this.props.context.tasks[this.props.task].editingPropertyToken ===
          undefined && (
          <React.Fragment>
            <Grid item xs={12}>
              <TaskFilters
                appContext={this.props.appContext}
                classes={this.props.classes}
                filters={this.props.context.tasks[this.props.task].filters}
                onAddToAll={(index) => {
                  if (
                    window.confirm(
                      "Are you sure that you wish to add this filter to all tasks?"
                    )
                  ) {
                    let filter =
                      this.props.context.tasks[this.props.task].filters[index];
                    this.props.context.tasks.forEach((task: TaskContext) => {
                      task.filters.some((f) => f.path === filter.path) ===
                        false &&
                        task.filters.push({
                          path: filter.path,
                          error: false,
                          dataOrigin: 0,
                        });
                    });
                  }
                }}
                onDelete={(index) =>
                  this.props.context.tasks[this.props.task].filters.splice(
                    index,
                    1
                  )
                }
                context={this.props.context}
              />
            </Grid>
            <Grid item xs={12}>
              <EndpointTests
                paths={
                  this.props.context.tasks[this.props.task].addedProperties
                }
                pathOrigins={
                  this.props.context.tasks[this.props.task].addedPropertyOrigins
                }
                canAdd={true}
                classes={this.props.classes}
                appContext={this.props.appContext}
                endpointName={this.props.context.endpointNameField.value}
                functions={this.props.context.tasks[this.props.task].functions}
                tests={this.props.context.tasks[this.props.task].tests}
                input={this.props.context.inputContent}
                inputMode={this.props.context.input.mode}
                mapType={
                  this.props.context.tasks[this.props.task].output.mapType
                }
                xsltParameters={this.props.context.tasks[
                  this.props.task
                ].xsltParameters.map((p) => {
                  return { name: p.name.content, value: p.value.content };
                })}
                output={this.props.context.tasks[this.props.task].getContent(
                  true
                )}
                outputMode={
                  this.props.context.tasks[this.props.task].output.mode
                }
                taskResults={EndpointHelpers.GetTaskResults(
                  this.props.context.tasks
                )}
                dataOrigin={
                  this.props.context.tasks[this.props.task].output.mapType ===
                  MapType.XSLT
                    ? this.props.context.tasks[this.props.task].output
                        .selectedDataOrigin
                    : this.props.context.tasks[this.props.task]
                        .mappingSelectedDataOrigin
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                id="output-previous"
                onClick={() =>
                  this.props.onClickNext(this.props.context.activeStep - 1)
                }
                variant="contained"
                color="default"
                disabled={
                  this.props.context.tasks[this.props.task]
                    .editingPropertyToken !== undefined
                }
                size="large"
                fullWidth
              >
                <PreviousIcon fontSize="large" /> Previous
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                id="output-next"
                onClick={this.onClickNext}
                variant="contained"
                color="primary"
                size="large"
                disabled={this.nextDisabled()}
                fullWidth
              >
                Next <NextIcon fontSize="large" />
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
