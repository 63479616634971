import { Palette } from "@material-ui/core/styles/createPalette";
import { observer } from "mobx-react";
import Please from "pleasejs";
import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { ICountPerEndpoint } from "../../Common/Interfaces/ICountPerEndpoint";

interface IProps {
  data: ICountPerEndpoint[];
  onClick: any;
  palette: Palette;
}

const EndpointPieChart: React.FC<IProps> = observer(
  ({ data, onClick, palette }) => {
    let chartColors = [
      palette.primary.main,
      palette.primary.dark,
      palette.primary.light,
    ];

    const getPieChartData = (endpointCounts: ICountPerEndpoint[]) => {
      chartColors = chartColors.concat(
        Please.make_color({
          colors_returned: endpointCounts.length - chartColors.length,
          hue: 95,
        })
      );

      let total = endpointCounts.reduce((a, b) => {
        return a + b.count;
      }, 0);
      return endpointCounts.map((d) => {
        return {
          name: d.endpointName,
          Count: d.count,
          fraction: d.count / total,
        };
      });
    };

    const chartData = getPieChartData(data);

    const renderLabel = (props: any) => {
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
        name,
        fill,
        payload,
      } = props;

      if (payload.payload.fraction < 0.01) {
        return null;
      }

      const RADIAN = Math.PI / 180;
      let radius = 20 + innerRadius + (outerRadius - innerRadius);
      let x = cx + radius * Math.cos(-midAngle * RADIAN);
      let y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          fill={fill}
        >
          {name} : {value}
        </text>
      );
    };

    return (
      <ResponsiveContainer>
        <PieChart>
          <Pie
            style={{ cursor: "pointer" }}
            onClick={onClick}
            dataKey="Count"
            innerRadius={100}
            data={chartData}
            fill={palette.primary.main}
            label={renderLabel}
            labelLine={true}
          >
            {chartData.map((entry, index) => (
              <Cell
                id={`pie-segment-${entry.name}`}
                key={entry.name}
                fill={chartColors[index % chartColors.length]}
              />
            ))}
          </Pie>
          <Legend verticalAlign="top" height={36} />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
);

export default EndpointPieChart;
