import QuillHelpers from "../../../Common/Helpers/QuillHelpers";
import ReactQuill from "react-quill";
import TextInputContext from "./TextInputContext";
import BaseEditorHelpers from "../../../Common/Helpers/BaseEditorHelpers";
import Constants from "../../../Common/Constants";

export default class QuillInputContext extends TextInputContext {
    
    public editorRef?: ReactQuill

    public addToken(propertyToken: string) {
        new QuillHelpers()
                .addTokenToOutput(
                    propertyToken,
                    this.cursorLocation as number,
                    this.editorRef as ReactQuill
                );
    }

    public getSelectedToken() : string | undefined {
        let line = (this.editorRef as ReactQuill).getEditor().getText();
            // Check if the cursor is in a token
        return new BaseEditorHelpers().getSelectedToken(
            line, this.cursorLocation as number, 
            Constants.TokenStart, Constants.TokenEnd);
    }
}