import "react-quill/dist/quill.snow.css";

import * as React from "react";

import { Button, Grid, TextField } from "@material-ui/core";

import FormatValidator from "../../Common/Helpers/FormatValidator";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import PropertyEditor from "../PathEditor/PropertyEditor";
import QuillWrapper from "../QuillWrapper/QuillWrapper";
import ReactQuill from "react-quill";
import { observer } from "mobx-react";

@observer
export default class FaxOutput extends React.Component<IOutputProps> {
  private task = this.props.context.tasks[this.props.context.activeTask];
  private fromRef = React.createRef<HTMLInputElement>();
  private toRef = React.createRef<HTMLInputElement>();
  private numberRef = React.createRef<HTMLInputElement>();
  private attachmentRef = React.createRef<HTMLInputElement>();
  private subjectRef = React.createRef<HTMLInputElement>();

  public render() {
    return (
      <Grid
        onDrop={this.props.onDrop}
        item
        md={6}
      >
        {this.task.editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.task.editingPropertyToken === undefined && (
            <React.Fragment>
              {this.task.output.loading ? (
                <Loading fullscreen={false} />
              ) : (
                <React.Fragment>
                  <Grid justify="flex-end" container>
                    <Grid item>
                      <div className={this.props.classes.emailToolbar}>
                        {this.task.selectedPropertyToken && (
                          <div className={this.props.classes.emailPathLink}>
                            <Button
                              className={this.props.classes.margin}
                              onClick={this.props.onClickEditPath}
                              variant={"text"}
                            >
                              <LinkIcon />
                            </Button>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="fax-from"
                        autoFocus={true}
                        label="From"
                        required={true}
                        inputRef={this.fromRef}
                        error={!this.task.faxFrom.content}
                        onChange={(e) =>
                          (this.task.faxFrom.content = e.target.value)
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.faxFrom)
                        }
                        onSelect={(_) =>
                          (this.task.faxFrom.cursorLocation =
                            this.fromRef.current &&
                            (this.fromRef.current.selectionStart as number))
                        }
                        placeholder="Sender"
                        value={this.task.faxFrom.content}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="fax-to"
                        autoFocus={true}
                        label="To"
                        inputRef={this.toRef}
                        required={true}
                        error={!this.task.faxTo.content}
                        onChange={(e) =>
                          (this.task.faxTo.content = e.target.value)
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.faxTo)
                        }
                        onSelect={(_) =>
                          (this.task.faxTo.cursorLocation =
                            this.toRef.current &&
                            (this.toRef.current.selectionStart as number))
                        }
                        placeholder="Receiver"
                        value={this.task.faxTo.content}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="fax-number"
                        autoFocus={true}
                        label="Number"
                        inputRef={this.numberRef}
                        required={true}
                        error={
                          !FormatValidator.faxNumber(
                            this.task.faxNumber.content
                          )
                        }
                        onChange={(e) =>
                          (this.task.faxNumber.content = e.target.value)
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.faxNumber)
                        }
                        onSelect={(_) =>
                          (this.task.faxNumber.cursorLocation =
                            this.numberRef.current &&
                            (this.numberRef.current.selectionStart as number))
                        }
                        placeholder="+441234567890"
                        value={this.task.faxNumber.content}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="fax-attachment"
                        inputRef={this.attachmentRef}
                        label="PDF attachment"
                        onChange={(e) =>
                          (this.task.faxAttachment.content = e.target.value)
                        }
                        onSelect={(_) =>
                          (this.task.faxAttachment.cursorLocation =
                            this.attachmentRef.current &&
                            (this.attachmentRef.current
                              .selectionStart as number))
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.faxAttachment)
                        }
                        error={!this.task.faxAttachment.content}
                        placeholder="pdf as base64"
                        fullWidth
                        value={this.task.faxAttachment.content}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="fax-subject"
                        label="Subject"
                        onChange={(e) =>
                          (this.task.faxSubject.content = e.target.value)
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.faxSubject)
                        }
                        inputRef={this.subjectRef}
                        onSelect={(_) =>
                          (this.task.faxSubject.cursorLocation =
                            this.subjectRef.current &&
                            (this.subjectRef.current.selectionStart as number))
                        }
                        error={!this.task.faxSubject}
                        placeholder="My subject"
                        required={true}
                        fullWidth
                        value={this.task.faxSubject.content}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <QuillWrapper
                        id="fax-body"
                        setRef={(ref) =>
                          (this.task.faxBody.editorRef = ref as ReactQuill)
                        }
                        style={{ height: "250px" }}
                        className={this.props.classes.marginTop}
                        defaultValue={this.task.faxBody.content}
                        onChange={(code) => (this.task.faxBody.content = code)}
                        onFocus={() =>
                          (this.task.activeTextInput = this.task.faxBody)
                        }
                        onChangeSelection={(range) => {
                          range !== null &&
                            !isNaN(range.index) &&
                            (this.task.faxBody.cursorLocation = range.index);
                        }}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </Grid>
    );
  }
}
