import * as React from "react";
import { observer } from "mobx-react";
import CronBuilder from "@aireglu/react-cron-builder";
import "@aireglu/react-cron-builder/dist/bundle.css";
import cronstrue from "cronstrue";
import MagicCronBuilderContext from "./MagicCronBuilder.Context";
import "./MagicCronBuilder.css";
import {
  Typography,
  Grid,
  withStyles,
  TextField,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {
  faMagic,
  faClock,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import CodeIcon from "@material-ui/icons/Code";
import { appStyles } from "../../App.Styles";
import { FieldState, FormState } from "formstate";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import Constants from "../../Common/Constants";
import { getScheduleDescription } from "../../Common/Helpers/JobHelpers";
import DateFnsUtils from "@date-io/date-fns";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

interface IProps extends IPublicProps {
  context: MagicCronBuilderContext;
}

interface IPublicProps {
  classes: any;
  startDate?: Date | null;
  endDate?: Date | null;
  manualStartDate?: string;
  manualEndDate?: string;
  expression: string;
  onChange: (expression: string) => void;
  onDateChange: (date: Date | null, isStartDate: boolean) => void;
  showResult?: boolean;
  handleShowDatesChange: () => void;
  handleManualDatesChange: () => void;
  onDateClear: (isStartDate: boolean) => void;
  isDynamicJobStartDate: boolean;
  isDynamicJobEndDate: boolean;
  showDates: boolean;
  showManualDates: boolean;
  task?: TaskContext;
  deleteOnJobExpire: boolean;
  handleDeleteOnJobExpireChange: () => void;
}

@observer
export class MagicCronBuilder extends React.Component<IProps> {
  private expressionRef = React.createRef<HTMLInputElement>();
  private startDateRef = React.createRef<HTMLInputElement>();
  private endDateRef = React.createRef<HTMLInputElement>();

  public componentDidMount() {
    if (this.props.task) {
      this.setInitialShowDates();
    }

    this.setUpdateButtonText();

    // Absolutely vile
    setInterval(() => {
      if (this.props.context.dynamicExpression === false) {
        this.props.context.description = cronstrue.toString(
          this.props.expression
        );
      } else {
        this.props.context.description = "Dynamic";
      }
    }, 1000);
  }

  private onChange = (expression: string) => {
    this.props.onChange(expression);
    this.props.context.description = getScheduleDescription(expression);
  };

  private onClearDynamic = () => {
    this.props.context.dynamicExpression = false;
    this.props.onChange("0 3 * * *");
    this.props.context.description = cronstrue.toString("0 3 * * *");
    this.props.task!.jobExpression.content = "0 3 * * *";
  };

  private isValidManualStartDate(date: string) {
    return dayjs(date).isValid()
      ? dayjs(this.props.task!.manualStartDate!.content).fromNow()
      : "Invalid date";
  }

  private isValidManualEndDate(date: string) {
    return dayjs(date).isValid()
      ? dayjs(this.props.task!.manualEndDate!.content).fromNow()
      : "Invalid date";
  }

  private setInitialShowDates() {
    if (this.props.task!.jobStartDate || this.props.task!.jobEndDate) {
      this.props.task!.showDates = true;
      this.props.task!.showManualDates = false;
    } else if (
      this.props.task?.manualStartDate.content !== "" ||
      this.props.task?.manualEndDate.content !== ""
    ) {
      this.props.task!.showDates = true;
      this.props.task!.showManualDates = true;
    } else {
      this.props.task!.showDates = false;
      this.props.task!.showManualDates = false;
    }
  }

  private expressionField = new FieldState(this.props.expression).validators(
    (val) => !FormatValidator.cron(val) && "Enter a valid cron expression"
  );
  private form = new FormState({
    expression: this.expressionField,
  });

  private setUpdateButtonText = () => {
    // Horrible hack - CronBuilder isn't particularly flexible
    const cronButton = document.getElementsByClassName(
      "cron-builder__action"
    )[0];
    if (cronButton) {
      cronButton.innerHTML = "Update";
      (cronButton as any).style.visibility = "visible";
    }
  };

  private handleTabClick = (selected: "wizard" | "cron") => {
    this.props.context.viewingMode = selected;
    if (selected === "wizard") {
      // Absolutely vile
      setTimeout(() => {
        this.setUpdateButtonText();
      }, 100);
    }
  };

  private onExpressionUpdate = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      this.props.task &&
      this.props.task!.jobExpression.content.includes(Constants.TokenStart) &&
      this.props.task!.jobExpression.content.includes(Constants.TokenEnd)
    ) {
      this.props.context.dynamicExpression = true;
      this.onChange(this.props.task!.jobExpression.content);
      this.handleTabClick("cron");
    } else {
      if (this.props.task) {
        this.expressionField.value = this.props.task!.jobExpression.content;
      }
      this.props.context.dynamicExpression = false;
      const validationResult = await this.form.validate();
      if (validationResult.hasError) {
        return;
      }
      this.onChange(validationResult.value.expression.value);
      this.handleTabClick("cron");
    }
  };

  private onDateUpdate = (isStartDate: boolean) => {
    let jobContent = isStartDate
      ? this.props.task!.manualStartDate
      : this.props.task!.manualEndDate!;
    isStartDate
      ? (this.props.task!.updatedStartDate = true)
      : (this.props.task!.updatedEndDate = true);

    if (
      this.props.task &&
      jobContent!.content.includes(Constants.TokenStart) &&
      jobContent!.content.includes(Constants.TokenEnd)
    ) {
      if (isStartDate) {
        this.props.task!.isDynamicJobStartDate = true;
        this.props.task.manualStartDate.content =
          this.startDateRef.current!.value;
      } else {
        this.props.task!.isDynamicJobEndDate = true;
        this.props.task.manualEndDate.content =
          this.startDateRef.current!.value;
      }
    } else {
      if (isStartDate) {
        this.props.task!.isDynamicJobStartDate = false;
      } else {
        this.props.task!.isDynamicJobEndDate = false;
      }
    }

    this.props.task!.jobStartDate = null;
    this.props.task!.jobEndDate = null;
  };

  public render() {
    return (
      <React.Fragment>
        <div className={this.props.classes.margin}>
          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <ToggleButtonGroup
              className={this.props.classes.margin}
              value={this.props.context.viewingMode}
              exclusive
            >
              <ToggleButton
                id="magic-wizard"
                onClick={() => this.handleTabClick("wizard")}
                value="wizard"
                disabled={this.props.context.dynamicExpression}
              >
                <FontAwesomeIcon icon={faMagic} />
              </ToggleButton>
              <ToggleButton
                id="magic-cron"
                onClick={() => this.handleTabClick("cron")}
                value="cron"
              >
                <CodeIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {
              <>
                <Grid>
                  <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="dates-checkbox"
                          checked={this.props.showDates}
                          onChange={() => {
                            this.props.handleShowDatesChange();
                          }}
                        />
                      }
                      label="Define Start/End Dates"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={this.props.endDate === undefined}
                          id="delete-onExpire-checkbox"
                          checked={this.props.deleteOnJobExpire}
                          onChange={() => {
                            this.props.handleDeleteOnJobExpireChange();
                          }}
                        />
                      }
                      label="Delete on expiry"
                  />
                  </Box>
                </Grid>
                {this.props.showDates ? (
                  <>
                    {this.props.task ? (
                      <>
                        <Grid>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="manual-dates-checkbox"
                                checked={this.props.task!.showManualDates}
                                onChange={() => {
                                  this.props.task!.handleManualDatesChange();
                                }}
                              />
                            }
                            label="Manually Enter Date Strings"
                          />
                        </Grid>
                      </>
                    ) : null}
                    {this.props.task && this.props.task!.showManualDates ? (
                      <>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <TextField
                              autoComplete="off"
                              fullWidth
                              id="manual-start-date"
                              label="Start Date"
                              onChange={(e) => {
                                this.props.task!.dateFieldChange(e, true);
                              }}
                              onSelect={(_) =>
                                (this.props.task!.manualStartDate!.cursorLocation =
                                  this.startDateRef.current &&
                                  (this.startDateRef.current
                                    .selectionStart as number))
                              }
                              onFocus={(_) =>
                                (this.props.task!.activeTextInput =
                                  this.props.task!.manualStartDate!)
                              }
                              placeholder="YYYY-MM-DDTHH:MM:SS"
                              value={this.props.task.manualStartDate.content}
                              inputRef={this.startDateRef}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              id="update-start-date"
                              onClick={(_) => this.onDateUpdate(true)}
                            >
                              Update
                            </Button>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <TextField
                              autoComplete="off"
                              fullWidth
                              id="manual-end-date"
                              label="End Date"
                              onChange={(e) => {
                                this.props.task!.dateFieldChange(e, false);
                              }}
                              onSelect={(_) =>
                                (this.props.task!.manualEndDate!.cursorLocation =
                                  this.endDateRef.current &&
                                  (this.endDateRef.current
                                    .selectionStart as number))
                              }
                              onFocus={(_) =>
                                (this.props.task!.activeTextInput =
                                  this.props.task!.manualEndDate!)
                              }
                              placeholder="YYYY-MM-DDTHH:MM:SS"
                              value={this.props.task.manualEndDate.content}
                              inputRef={this.endDateRef}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              id="update-end-date"
                              onClick={(_) => this.onDateUpdate(false)}
                            >
                              Update
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Paper
                            className={classNames(
                              this.props.classes.paper,
                              this.props.classes.headerTiles,
                              this.props.classes.margin
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              className={classNames(
                                this.props.classes.headerTileIcon,
                                this.props.classes.logoImg
                              )}
                            />
                            <Typography
                              className={this.props.classes.tileText}
                              style={{ display: "inline-block" }}
                            >
                              Job will start -{" "}
                              {this.props.task!.updatedStartDate
                                ? this.props.isDynamicJobStartDate
                                  ? "Dynamic"
                                  : this.isValidManualStartDate(
                                      this.props.task!.manualStartDate!.content
                                    )
                                : "No date provided"}{" "}
                              <br />
                              Job will finish -{" "}
                              {this.props.task!.updatedEndDate
                                ? this.props.isDynamicJobEndDate
                                  ? "Dynamic"
                                  : this.isValidManualEndDate(
                                      this.props.task!.manualEndDate!.content
                                    )
                                : "No date provided"}
                            </Typography>
                          </Paper>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid justify="space-around">
                            <DateTimePicker
                              className={this.props.classes.margin}
                              disablePast={true}
                              openTo="date"
                              maxDate={
                                this.props.endDate
                                  ? new Date(this.props.endDate as Date)
                                  : null
                              }
                              format="dd/MM/yyyy HH:mm"
                              label="Start Date"
                              views={["year", "month", "date"]}
                              value={
                                dayjs(this.props.startDate as Date).isValid()
                                  ? this.props.startDate
                                  : null
                              }
                              onChange={(d) => this.props.onDateChange(d, true)}
                            />
                            <Button
                              style={{ height: "5.5em" }}
                              onClick={(_) => this.props.onDateClear(true)}
                            >
                              Clear
                            </Button>
                          </Grid>
                          <Grid>
                            <DateTimePicker
                              className={this.props.classes.margin}
                              disablePast={true}
                              minDate={new Date(this.props.startDate as Date)}
                              readOnly={false}
                              format="dd/MM/yyyy HH:mm"
                              label="End Date"
                              views={["year", "month", "date"]}
                              value={
                                dayjs(this.props.endDate as Date).isValid()
                                  ? this.props.endDate
                                  : null
                              }
                              onChange={(d) =>
                                this.props.onDateChange(d, false)
                              }
                            />
                            <Button
                              style={{ height: "5.5em" }}
                              onClick={(_) => this.props.onDateClear(false)}
                            >
                              Clear
                            </Button>
                          </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid>
                          <Paper
                            className={classNames(
                              this.props.classes.paper,
                              this.props.classes.headerTiles,
                              this.props.classes.margin
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              className={classNames(
                                this.props.classes.headerTileIcon,
                                this.props.classes.logoImg
                              )}
                            />
                            <Typography className={this.props.classes.tileText}>
                              Job will start -{" "}
                              {this.props.startDate
                                ? dayjs(this.props.startDate as Date).fromNow()
                                : "No date provided"}{" "}
                              <br />
                              Job will finish -{" "}
                              {this.props.endDate
                                ? dayjs(this.props.endDate as Date).fromNow()
                                : "No date provided"}
                            </Typography>
                          </Paper>
                        </Grid>
                      </>
                    )}
                  </>
                ) : null}
              </>
            }
            {this.props.context.viewingMode === "wizard" ? (
              this.props.context.dynamicExpression === false && (
                <React.Fragment>
                  <Typography variant="caption">
                    Job has expired when end date is exceeded. <br />
                    **Please note when using the wizard below, times will be
                    UTC, not your local timezone. <br />
                    If BST, subtract one hour from intended time to get correct
                    UTC time**
                  </Typography>
                  <CronBuilder
                    cronExpression={this.props.expression}
                    onChange={this.onChange}
                    showResult={this.props.showResult}
                  />
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                {this.props.task ? (
                  <TextField
                    id="magic-expression-field"
                    style={{ width: "511px" }}
                    inputProps={{ style: { textAlign: "center" } }}
                    label="Expression"
                    onChange={(e) =>
                      (this.props.task!.jobExpression!.content = e.target.value)
                    }
                    onSelect={(_) =>
                      (this.props.task!.jobExpression.cursorLocation =
                        this.expressionRef.current &&
                        (this.expressionRef.current.selectionStart as number))
                    }
                    onFocus={(_) =>
                      (this.props.task!.activeTextInput =
                        this.props.task!.jobExpression)
                    }
                    placeholder="Enter a cron expression"
                    value={this.props.task!.jobExpression.content}
                    inputRef={this.expressionRef}
                    error={!this.props.task!.jobExpression.content}
                    variant="filled"
                  />
                ) : (
                  <TextField
                    id="magic-expression-field"
                    style={{ width: "511px" }}
                    inputProps={{ style: { textAlign: "center" } }}
                    label="Expression"
                    helperText={this.expressionField.error}
                    onChange={(e) =>
                      this.expressionField.onChange(e.target.value)
                    }
                    placeholder="Enter a cron expression"
                    value={this.expressionField.value}
                    error={this.expressionField.hasError}
                    variant="filled"
                  />
                )}
                <button
                  type="button"
                  disabled={this.expressionField.hasError}
                  onClick={this.onExpressionUpdate}
                  className="cron-builder__action"
                  style={{ visibility: "visible", width: "511px" }}
                >
                  Update
                </button>
              </React.Fragment>
            )}
            <Paper
              className={classNames(
                this.props.classes.paper,
                this.props.classes.headerTiles,
                this.props.classes.margin
              )}
            >
              <FontAwesomeIcon
                icon={faClock}
                className={this.props.classes.headerTileIcon}
              />
              <Typography
                className={this.props.classes.tileText}
                id="job-schedule"
              >
                Schedule: <em>{this.props.context.description}</em>
                {this.props.context.dynamicExpression && (
                  <Button onClick={this.onClearDynamic}>Clear</Button>
                )}
              </Typography>
            </Paper>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IPublicProps> {
    render() {
      const context = new MagicCronBuilderContext(this.props.expression);
      dayjs.extend(relativeTime); //to use .fromNow() with dayjs

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withContext(MagicCronBuilder)
);
