import * as React from "react";
import { observer } from "mobx-react";
import { Grid, NativeSelect, withStyles, Typography, FormHelperText } from "@material-ui/core";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { appStyles } from "../../App.Styles";
import EndpointSelectorContext from "./EndpointSelector.Context";
import DataFactory from "../../Common/Factories/DataFactory";
import SchemaService from "../../Common/Services/Schema.Service";
import NoIcon from "@material-ui/icons/NotInterested";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointInputTester from "../EndpointInputTester/EndpointInputTester";
import CursorLocation from "../EndpointWizard/CursorLocation";
import EndpointHelpers from "../../Common/Helpers/EndpointHelpers";
import Constants from "../../Common/Constants";

interface IProps extends IPublicProps {
  context: EndpointSelectorContext;
}

interface IPublicProps extends IComponentProps {
  onChange: (input: string, endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string) => void;
  onFocus?: () => void;
  onSelect?: (cursorLocation: CursorLocation | number) => void;
  endpoint: IEndpoint;
  endpointEnvironmentOrVersion: string;
  input: string;
  endpoints: IEndpoint[];
  validate?: boolean;
  endpointValid: (endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string) => boolean;
}

@observer
class EndpointSelector extends React.Component<IProps> {
  private onEndpointChange = (e: React.ChangeEvent<{ value: any }>) => {
    let endpoint = this.props.context.endpoints.find(
      (ep) => ep.name === e.target.value
    );
    if (!endpoint) {
      this.props.context.endpoint = undefined;
      return;
    }

    if (endpoint !== this.props.context.endpoint){
      this.props.context.endpointEnvironmentOrVersion = Constants.Production;
    }

    let endpointEnvironmentOrVersion = this.props.context.endpointEnvironmentOrVersion

    let endpointVersion = EndpointHelpers.GetProductionOrActiveVersion(endpoint, endpointEnvironmentOrVersion);  
    if (!endpointVersion) {
      if (endpointEnvironmentOrVersion === Constants.Production){
        alert(
          "This endpoint does not currently have a version deployed to production"
        );
      }  
      this.props.context.endpoint = undefined;
      return;
    }
    if (!this.props.endpointValid(endpoint, endpointEnvironmentOrVersion)) {
      return;
    }
    this.props.context.endpoint = endpoint;
    if (endpointVersion && endpointVersion.input) {
      this.props.context.input = endpointVersion.input;
      this.props.onChange(
        this.props.context.input,
        this.props.context.endpoint,
        endpointEnvironmentOrVersion
      );
    } else {
      new DataFactory(SchemaService.GenerateXml)
        .Generate(
          this.props.appContext.managementHost as string,
          endpointVersion!
        )
        .then((code) => {
          this.props.context.input = code;
          this.props.onChange(
            this.props.context.input,
            this.props.context.endpoint,
            this.props.context.endpointEnvironmentOrVersion
          );
        });
    }
  };

  private onEndpointEnvironmentOrVersionChange = (e: React.ChangeEvent<{ value: any }>) => {

    this.props.context.endpointEnvironmentOrVersion = e.target.value;

    let endpointVersion = EndpointHelpers.GetProductionOrActiveVersion(this.props.context.endpoint!, e.target.value);
    if (endpointVersion && endpointVersion.input) {
      this.props.context.input = endpointVersion.input;

      this.props.onChange(
        this.props.context.input,
        this.props.context.endpoint,
        this.props.context.endpointEnvironmentOrVersion
      );
    } else {
      new DataFactory(SchemaService.GenerateXml)
        .Generate(
          this.props.appContext.managementHost as string,
          endpointVersion!
        )
        .then((code) => {
          this.props.context.input = code;

          this.props.onChange(
            this.props.context.input,
            this.props.context.endpoint,
            this.props.context.endpointEnvironmentOrVersion
          );
        });
    }

  }

  private onInputChange = (input: string, _?: IEndpoint) => {

    this.props.context.input = input;

    this.props.onChange(input, this.props.context.endpoint, this.props.context.endpointEnvironmentOrVersion);
  };

  public render() {
    return (
        this.props.context.endpoints.length > 0  ?
            <React.Fragment>
                <div className={this.props.classes.margin}>
                    <Grid
                        item xs={12}
                        container
                        alignItems="center"
                        justify="space-between">
                        <NativeSelect
                            id="endpoint-select"
                            fullWidth
                            value={this.props.context.endpoint ? this.props.context.endpoint!.name : ""}
                            onChange={this.onEndpointChange}>
                            <option disabled value="">Select an endpoint</option>
                            {
                                this.props.context.endpoints.map((endpoint, index) => {
                                    return <option 
                                        key={index} 
                                        id={endpoint.name}
                                        value={endpoint.name}>{endpoint.name}</option>
                                })
                            }
                        </NativeSelect>
                    </Grid>
                    <Grid
                        item xs={12}
                        container
                        alignItems="center"
                        justify="space-between">
                        <NativeSelect
                            id="endpoint-version-select"
                            fullWidth
                            value={this.props.context.endpointEnvironmentOrVersion}
                            onChange={this.onEndpointEnvironmentOrVersionChange}>
                            <option value={Constants.Production} id={`version-${Constants.Production}`}>Select an endpoint version</option>
                            {
                              this.props.context.endpoint?.versions.filter(v => v.active) &&
                                this.props.context.endpoint?.versions.filter(v => v.active).map((version, index) => {
                                    return <option 
                                        key={index} 
                                        id={`version-${version.version.toString()}`}
                                        value={version.version.toString()}>{version.version}</option>
                                })
                            }
                        </NativeSelect>
                        <FormHelperText 
                          classes={this.props.classes}>
                            If an endpoint version is not selected, the version of the endpoint deployed to Production will be used
                        </FormHelperText>
                    </Grid>
                    {
                        this.props.context.endpoint && (
                            <EndpointInputTester
                                appContext={this.props.appContext}
                                endpoint={this.props.context.endpoint}
                                input={this.props.context.input}
                                onChange={this.onInputChange}
                                onSelect={this.props.onSelect}
                                onFocus={this.props.onFocus}
                                version={getVersion(this.props.context.endpoint, this.props.context.endpointEnvironmentOrVersion)}
                                validate={this.props.validate}/>
                        )
                    }
                </div>
            </React.Fragment> :
            <Typography className={this.props.classes.centerTextRel} variant="h5">
                <NoIcon fontSize="large" /> There are no endpoints for this job to run. Create an endpoint first!
            </Typography>
  
    );
  }
}

const getVersion = (endpoint: IEndpoint, endpointEnvironmentOrVersion: string) : number => {
  const endpointVersion = endpoint.versions.find(x => x.version === Number.parseInt(endpointEnvironmentOrVersion));
  if (endpointVersion){
    return endpointVersion.version
  }
  return endpoint.environments[Constants.Production];
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IPublicProps> {
    render() {
        const context = new EndpointSelectorContext(
        this.props.appContext,
        this.props.endpoints,
        this.props.endpoint,
        this.props.endpointEnvironmentOrVersion,
        this.props.validate,
        this.props.input
      );

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withContext(EndpointSelector)
);
