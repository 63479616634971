import { WebStorageStateStore } from "oidc-client";
import {
  AuthProvider,
  AuthProviderProps,
  useAuth,
  User,
  UserManager,
} from "oidc-react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Constants from "../../Common/Constants";
import { AuthenticationProvider } from "../../Common/Enums/AuthenticationProvider";
import Loading from "../Loading/Loading";
import { tryGetTenantPrefix } from "../../Common/TenancyHelpers";

interface Props {
  clientRootHost: string;
  settings: {
    scope: string | undefined;
    authority: string | undefined;
    client_id: string | undefined;
    extraQueryParams: { [key: string]: string | undefined };
  };
  setAuthToken: (token: string | undefined) => void;
  authenticationProvider: AuthenticationProvider;
}

export const AuthWrapper: React.FC<Props> = ({
  children,
  settings,
  clientRootHost,
  setAuthToken,
  authenticationProvider,
}) => {
  const { replace } = useHistory();
  const { search, pathname } = useLocation();
  const [initializing, setInitializing] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [silentRenewFailed, setSilentRenewFailed] = useState<boolean>(false);

  const urlParams = new URLSearchParams(search);
  const isCodeCallback = urlParams.has("code");

  const clientRoot = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  const tenantPrefix = tryGetTenantPrefix(
    new URL(clientRootHost),
    window.location.hostname,
  );

  const [userManager] = useState<UserManager>(
    new UserManager({
      ...settings,
      response_type: "code",
      redirect_uri: `${clientRoot}/oidc-signin`,
      automaticSilentRenew: false, // MAG-1250 disabled for now silent renew not supported in aireidentity IDP
      silent_redirect_uri: `${clientRoot}/silent-renew`,
      post_logout_redirect_uri: `${clientRoot}`,
      userStore: new WebStorageStateStore({
        store: window.localStorage
      }),
      acr_values: tenantPrefix ? `tenant:${tenantPrefix}` : undefined
    })
  );

  useEffect(() => {
    userManager.clearStaleState();
  }, [userManager]);

  const onSessionEnd = useCallback(() => {
    setIsLoggedIn(false);
    userManager.signoutRedirect();
  }, [userManager]);

  const onSilentRenewFailed = useCallback(
    () => setSilentRenewFailed(true),
    [setSilentRenewFailed]
  );

  useEffect(() => {
    userManager.events.addSilentRenewError(onSilentRenewFailed);
    userManager.events.addAccessTokenExpired(onSessionEnd);

    return () => {
      userManager.events.removeSilentRenewError(onSilentRenewFailed);
      userManager.events.removeAccessTokenExpired(onSessionEnd);
    };
  }, [userManager, onSessionEnd, onSilentRenewFailed]);

  const onSignIn = useCallback(
    (user: User | null) => {
      setIsLoggedIn(user !== null);

      if (user !== null) {
        if (
          user?.state?.targetUrl !== undefined &&
          pathname !== user?.state?.targetUrl
        ) {
          replace(user?.state?.targetUrl);
        } else {
          replace(window.location.pathname); // remove code query param
        }
        localStorage.setItem(Constants.authTokenName, user.access_token);
        setAuthToken(user.access_token);
      }
    },
    [replace, pathname, setAuthToken]
  );

  useEffect(() => {
    const signInSilent = async () => {
      try {
        if (!silentRenewFailed) {
          const user = await userManager.signinSilent();
          if (user === null) onSilentRenewFailed();

          onSignIn(user);
        }
      } catch (error) {
        onSilentRenewFailed();
      }
    };

    const getSession = async () => {
      try {
        const user = await userManager.getUser();
        onSignIn(user);

        if (user === null && !isCodeCallback) await signInSilent();
      } finally {
        setInitializing(false);
      }
    };

    if (!isCodeCallback) getSession();
    else setInitializing(false);
    //eslint-disable-next-line
  }, []);

  const oidcConfig: AuthProviderProps = {
    userManager: userManager,
    autoSignIn: false,
    onSignIn,
  };

  const loading =
    authenticationProvider !== AuthenticationProvider.Internal &&
    (initializing || (!isLoggedIn && isCodeCallback));

  return (
    <AuthProvider {...oidcConfig}>
      {loading ? <Loading fullscreen /> : children}
    </AuthProvider>
  );
};

export const useAuthentication = () => {
  const { userData, signIn, signOutRedirect, userManager, isLoading } =
    useAuth();

  const signOutAndRedirect = async () => {
    window.localStorage.clear();
    sessionStorage.clear();
    await userManager.removeUser();
    await userManager.signoutRedirect();
    await userManager.revokeAccessToken();
    await userManager.clearStaleState();
    signOutRedirect();
  };

  const refreshSession = () => {
    userManager.signinSilent().catch(() => signOutAndRedirect());
  };

  return {
    bearerToken: userData?.access_token,
    isLoggedIn: userData !== undefined && userData !== null,
    profile: userData?.profile,
    signInRedirect: signIn,
    refreshSession,
    signOut: signOutAndRedirect,
    loading: isLoading,
  };
};
