import { DataFormat } from "../Enums/DataFormat";
import Serializers from "./Serializers";

export default class DataFormatHelper {
  public static GetModeString = (mode: DataFormat): string => {
    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(mode)) {
      return "xml"; // no mode available for querystring
    } else if (
      [DataFormat.JSON, DataFormat.HL7FHIRJSON, DataFormat.Callback].includes(
        mode
      )
    ) {
      return "json";
    } else if ([DataFormat.HTML].includes(mode)) {
      return "html";
    } else {
      return "text";
    }
  };

  public static GetSchemaModeString = (mode: DataFormat): string => {
    if (
      [
        DataFormat.JSON,
        DataFormat.QueryString,
        DataFormat.HL7FHIRJSON,
        DataFormat.HL7v2,
        DataFormat.Callback,
      ].includes(mode)
    ) {
      return "json"; // querystring uses a json schema
    } else if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(mode)) {
      return "xml";
    }
    return "text";
  };

  public static GetDataFormatByContent(
    content: string
  ): DataFormat | undefined {
    if (Serializers.parseJsonIfValid(content)) {
      return DataFormat.JSON;
    } else if (Serializers.parseXmlIfValid(content)) {
      return DataFormat.XML;
    } else if (Serializers.parseUrlIfValid(content)) {
      return DataFormat.QueryString;
    } else if (Serializers.parseHL7v2IfValid(content)) {
      return DataFormat.HL7v2;
    }
  }

  public static GetContentType(format: DataFormat): string {
    switch (format) {
      case DataFormat.JSON:
        return "application/json";
      case DataFormat.HL7FHIRJSON:
        return "application/fhir+json";
      case DataFormat.HL7v2:
        return "application/hl7-v2";
      case DataFormat.HL7FHIRXML:
        return "application/fhir+xml";
      case DataFormat.XML:
        return "application/xml";
      case DataFormat.QueryString:
        return "application/x-www-form-urlencoded";
      case DataFormat.Text:
        return "text/plain";
      default:
        return "";
    }
  }

  public static IsSecret(input){
    if (typeof input !== 'string' || input.trim() === '') {
      return false;
    }
    const pattern = /^--\*.*?\*--$/;
  
      return pattern.test(input.trim());
  }
}
