import LoggingService from '../../Common/Services/Logging.Service';
import { ILoginResult } from './ILoginResult';
import { IAccountDetails } from './IAccountDetails';
import AppContext from '../../App.Context';
import { getToken } from '../../Common/Helpers/AuthenticationHelpers';

export class AccountService {
    public static Register = (
        apiHost: string,
        username: string,
        email: string,
        password: string,
        confirmPassword: string): Promise<any> => {
        const uri = `${apiHost}/Account/Register`;
        return fetch(uri, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                'username': username,
                'email': email,
                'password': password,
                'confirmPassword': confirmPassword
            })
        })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Login = (apiHost: string, email: string, password: string): Promise<ILoginResult> => {
        const uri = `${apiHost}/Account/Login`;
        return fetch(uri, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                'username': email,
                'password': password
            })
        })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static ForgotPassword = (apiHost: string, email: string) => {
        const uri = `${apiHost}/Account/ForgotPassword?email=${email}`;
        return fetch(uri, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        })
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static ResetPassword = (apiHost: string, email: string, password: string, code: string) => {
        const uri = `${apiHost}/Account/ResetPassword`;
        return fetch(uri, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                email: email,
                code: code,
                password: password
            })
        })
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static GetAccountDetails = (apiHost: string): Promise<IAccountDetails> => {
        const uri = `${apiHost}/Account/GetAccountDetails`;
        return fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            method: 'GET'
        }).then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static GetCompleteProfileDetails = (apiHost: string): Promise<IAccountDetails> => {
        const uri = `${apiHost}/Account/GetCompleteProfileDetails`;
        return fetch(uri, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`,
            },
            method: 'GET'
        }).then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static UpdateAccountDetails = (appContext: AppContext, data: IAccountDetails): Promise<string> => {
        const uri = `${appContext.managementHost}/Account/UpdateAccountDetails`;
        
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(data)
        })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static CompleteAccountProfile = (appContext: AppContext, data: IAccountDetails): Promise<Response> => {
        const uri = `${appContext.managementHost}/Account/CompleteAccountProfile`;
        
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then(response => response)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }
}