import { useCallback, useEffect, useState } from "react";
import Constants from "../Constants";
import { AuthenticationProvider } from "../Enums/AuthenticationProvider";
import { tryGetTenantPrefix } from "../TenancyHelpers";

/**
 * Hook listens to messages posted from a parent widow.
 * If origin is trusted and includes an auth token it will set it.
 * e.g useful for iFrames
 * @param host host of the parent window
 */
export const useParentWindowToken = (
  host: string = "",
  authProvider = AuthenticationProvider.Internal,
  tokenCallback: (token: string) => void
) => {
  let isInIFrame = window.location !== window.parent.location;
  const [isLoading, setIsLoading] = useState(isInIFrame);
  const source = "aireglu-client-v1";

  const handleIFrameMessage = useCallback(
    (event: MessageEvent) => {
      const originTenantPrefix = tryGetTenantPrefix(new URL(host), new URL(event.origin).hostname);

      if ((originTenantPrefix == null && event.origin !== host)) {
        return;
      }

      const protocol = host.startsWith("https") ? "https" : "http";
      const tenantedHost = originTenantPrefix == null ? host : host.replace(`${protocol}://`, `${protocol}://${originTenantPrefix}.`);

      if (event.origin !== tenantedHost && event.origin !== host) {
        return;
      }

      const hasProfile = () => {
        if (
          authProvider !== AuthenticationProvider.Internal &&
          event.data.userData === null
        ) {
          return false;
        }

        return true;
      };

      if (event.data.token && hasProfile()) {
        sessionStorage.setItem(Constants.authTokenName, event.data.token);
        sessionStorage.setItem(
          Constants.profile,
          JSON.stringify(event.data.userData)
        );

        window.parent.postMessage({ hasToken: true, source }, tenantedHost);
        tokenCallback(event.data.token);
      } else {
        window.parent.postMessage({ hasToken: false, source }, tenantedHost);
      }

      setIsLoading(false);
    },
    [host, authProvider, tokenCallback]
  );

  useEffect(() => {
    window.addEventListener("message", handleIFrameMessage);
    return () => window.removeEventListener("message", handleIFrameMessage);
  }, [handleIFrameMessage]);

  return { isLoading, useHeadless: isInIFrame };
};
