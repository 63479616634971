import jsf from 'json-schema-faker';
import { DataFormat } from "../Enums/DataFormat";
import Serializers from "../Helpers/Serializers";
import IEndpointVersion from "../Interfaces/IEndpointVersion";
import IEndpointXmlSchema from "../Interfaces/IEndpointXmlSchema";
import { pd } from "pretty-data";

export default class DataFactory {
    constructor(xmlGenerator: (managementHost: string, xmlSchemas: IEndpointXmlSchema[]) => Promise<string>) {
        this._xmlGenerator = xmlGenerator;
    }

    private readonly _xmlGenerator: (managementHost: string, xmlSchemas: IEndpointXmlSchema[]) => Promise<string>;

    public async Generate(managementHost: string, version: IEndpointVersion): Promise<string> {
        const mode = version.inputMode;
        if ([DataFormat.JSON, DataFormat.QueryString, DataFormat.HL7FHIRJSON, DataFormat.HL7v2].includes(mode)) {
            return await this.GenerateJSON(mode, version.schema!);
        } else if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(mode)) {
            return await this.GenerateXML(managementHost, version.xmlSchemas!);
        }
        return '';
    }

    public async GenerateJSON(mode: DataFormat, schemaString: string): Promise<string> {
        const schema = Serializers.parseJsonIfValid(schemaString);

        if(schema) {
            Object.keys(schema.properties).map(key => {
                // There's a bug in json-schema-generator that makes it crash if uniqueItems is true
                schema.properties[key].uniqueItems = false;

                // Just generate positive whole numbers
                if (schema.properties[key].type === "number") {
                    schema.properties[key].type = "integer";
                    schema.properties[key].minimum = 0;
                }
                return key;
            });

            return await jsf.resolve(schema).then((generated: {} | []) => {
                if (mode === DataFormat.QueryString) {
                    return Serializers.serializeUrlIfValid(generated) as string;
                } else {
                    return Serializers.serializeJsonIfValid(generated) as string;
                }
            });
        }
        return '';
    }

    public async GenerateXML(managementHost: string, xmlSchemas: IEndpointXmlSchema[]): Promise<string> {
        // could do with a library to do this clientside
        return await this._xmlGenerator(managementHost, xmlSchemas).then(schema => { return pd.xml(schema) });
    }

    public static IsGeneratable = (mode: DataFormat): boolean => {
        if (mode === DataFormat.HL7v2) {
            return false;
        } else {
            return true;
        }
    }
}