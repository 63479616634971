export default class WindowHelpers {
    public static ConfirmBeforeClose(e) {
        e = e || window.event;
        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Changes that you made may not be saved.';
        }
    
        // For Safari
        return 'Changes that you made may not be saved.';
    }
}