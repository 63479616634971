import { computed, observable } from "mobx";
import Constants from "../../../Common/Constants";
import BaseEditorHelpers from "../../../Common/Helpers/BaseEditorHelpers";
import CursorLocation from "../CursorLocation";

export default class TextInputContext {
  constructor(initialValue?: string) {
    this.content = initialValue || "";
  }

  public set content(value: string) {
    this._content = value !== null ? value : "";
  }

  @computed
  public get content() {
    return this._content;
  }

  @observable
  private _content: string = "";

  @observable
  public cursorLocation: number | CursorLocation | null = null;

  public addToken(propertyToken: string) {
    this.content =
      this.content.slice(0, this.cursorLocation as number) +
      propertyToken +
      this.content.slice(this.cursorLocation as number);
  }

  public getSelectedToken(): string | undefined {
    return new BaseEditorHelpers().getSelectedToken(
      this.content,
      this.cursorLocation as number,
      Constants.TokenStart,
      Constants.TokenEnd
    );
  }
}
