import * as React from "react";
import { Dot } from "recharts";

const EntpointLineChartDot = (props) => {
  const { payload } = props;
  const value = payload["name"];

  return <Dot {...props} name={`endpoint-linechart-dot-${value}`} />;
};

export default EntpointLineChartDot;
