import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, Input, InputLabel, MenuItem, Paper, Select, useTheme, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { observer } from 'mobx-react';
import * as React from 'react';
import { appStyles } from '../../App.Styles';
import DashboardContext from '../Dashboard/DashboardContext';

interface IProps {
    context: DashboardContext;
    classes: any;
    propertyFilterValue: string;
    renderPropertyFilter: (displayName: string, name: string) => JSX.Element;
    handlePropertyFilter: (event: React.ChangeEvent<{value: any}>) => void;
    removePropertyFilter: (filter: string) => void;
    toggleFilterSelect: () => void;
    setPropertyFilterValue: (string) => void;
}

const UsageDataFilters: React.FC<IProps> = observer(({ 
    context, 
    classes, 
    propertyFilterValue,
    renderPropertyFilter, 
    handlePropertyFilter, 
    removePropertyFilter, 
    toggleFilterSelect,
    setPropertyFilterValue
}) => {
    const theme = useTheme();
    return <Paper style={{padding: theme.spacing(1)}}>
        <FormControl fullWidth>
        <InputLabel shrink>Property Filters ({Object.keys(context.propertyFilters).length})</InputLabel>
            <Button onClick={toggleFilterSelect} >
                <FontAwesomeIcon icon={faCheckCircle}/> 
                <span className={classes.marginLeft}>Toggle All</span>
            </Button>
        </FormControl>
        <FormControl>
            <Select
                style={{minWidth: '200px'}}
                displayEmpty
                value={context.selectedPropertyFilter}
                onChange={handlePropertyFilter}
                input={<Input />}>
                <MenuItem value={''} disabled>
                    <span className={classes.marginLeft}>
                        {Object.keys(context.propertyFilters).length} applied
                    </span>
                </MenuItem>
                {renderPropertyFilter("Transaction ID", "transactionId")}
                {renderPropertyFilter("IP Address", "ip")}
                {renderPropertyFilter("Method", "method")}
            </Select> 
        </FormControl>
        {
            context.selectedPropertyFilter && (
                <React.Fragment>
                    <FormControl>
                        <Input 
                            className={classes.marginLeft}
                            onChange={e => setPropertyFilterValue(e.target.value)}
                            value={propertyFilterValue}/>
                    </FormControl>
                    <FormControl>
                        <Button
                            size="small"
                            className={classes.marginLeft}
                            onClick={() => removePropertyFilter(context.selectedPropertyFilter as string)}>
                            <DeleteIcon />
                        </Button>
                    </FormControl>
                </React.Fragment>
            )
        }
    </Paper>
})

export default withStyles(appStyles, { withTheme: true })(UsageDataFilters);