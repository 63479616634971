import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { withStyles, Button } from "@material-ui/core";
import { appStyles } from "../../App.Styles";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import { IJSONPathMatch } from "../../Common/Interfaces/IJSONPathMatch";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import IDictionary from "../../Common/Interfaces/IDictionary";
import PropertyHelpers from "../../Common/Helpers/PropertyHelpers";
import PathEditor from "./PathEditor";

interface IProps extends IComponentProps {
    context: EndpointWizardContext;
    task: number;
}

@observer
class PropertyEditor extends React.Component<IProps> {
    private onCancel = () => {
        const cancelTask = this.props.context.tasks[this.props.task];
        cancelTask.editingPropertyToken = undefined;
        cancelTask.editingPropertyTokenOrigin = undefined;
        this.props.context.editedPath = undefined;
        cancelTask.output.editingPathMatches = [];
        cancelTask.output.editingPathError = false;
    };

    private onSave = () => {
        const savingTask = this.props.context.tasks[this.props.task];
        if (
            savingTask.editingPropertyTokenOrigin !== -1 &&
            savingTask.editingPropertyTokenOrigin !== undefined
        ) {
            savingTask.addedPropertyOrigins[
                savingTask.editingPropertyToken as string
            ] = savingTask.editingPropertyTokenOrigin as number;
        } else {
            delete savingTask.addedPropertyOrigins[savingTask.editingPropertyToken as string];
        }
        (savingTask.addedProperties as IDictionary<string>)[
            savingTask.editingPropertyToken as string
        ] = this.props.context.editedPath as string;
        savingTask.output.modified = true;
        savingTask.mappingValid = PropertyHelpers.propertiesValid(
            savingTask.textInputContextFields.map(p => p.content),
            savingTask.addedProperties
        );
        savingTask.editingPropertyToken = undefined;
        savingTask.editingPropertyTokenOrigin = undefined;
        this.props.context.editedPath = undefined;
        savingTask.output.editingPathMatches = [];
        savingTask.output.editingPathError = false;
    };

    public render() {
        const origin = [undefined, null, -1].includes(
            this.props.context.tasks[this.props.task].editingPropertyTokenOrigin
        )
            ? this.props.context.input
            : this.props.context.tasks[
                  this.props.context.tasks[this.props.task].editingPropertyTokenOrigin as number
              ].output;
        return (
            <React.Fragment>
                <PathEditor
                    value={this.props.context.editedPath as string}
                    origin={origin}
                    editingPropertyToken={
                        this.props.context.tasks[this.props.task].editingPropertyToken as string
                    }
                    onChange={(path: string, matches: any[] | IJSONPathMatch[], error: boolean) => {
                        this.props.context.editingPathMatches = matches;
                        this.props.context.editingPathError = error;
                        this.props.context.editedPath = path;
                    }}
                    appContext={this.props.appContext}
                    onSave={() => {}}
                    onCancel={() => {}}
                />

                <Button
                    onClick={this.onCancel}
                    className={this.props.classes.margin}
                    variant={"outlined"}
                >
                    <CloseIcon /> Cancel
                </Button>
                <Button onClick={this.onSave} variant="contained" color="primary">
                    <SaveIcon /> Save
                </Button>
            </React.Fragment>
        );
    }
}

export default withStyles(appStyles, { withTheme: true })(PropertyEditor);
