import { observable } from "mobx";
import { AuthenticationType } from "../../../Common/Enums/AuthenticationType";
import { FieldState, FormStateLazy } from "formstate";
import FormatValidator from "../../../Common/Helpers/FormatValidator";

export default class AuthenticationContext {
    public constructor(type: AuthenticationType = 0) {
        this.type = type
    }

    @observable
    public editMode: boolean = false;

    @observable
    public type: AuthenticationType = AuthenticationType.APIKey;
    
    @observable
    public ipAddresses: string[] = [];

    public apiKeyField = new FieldState('').validators((val) =>
        this.type === AuthenticationType.APIKey &&
        !val && 
        'API key required. Click on the key to use secrets.');

    public usernameField = new FieldState('').validators((val) =>
        [AuthenticationType.JWT, AuthenticationType.Basic].includes(this.type) &&
        !val && 
        'Username required');

    public passwordField = new FieldState('').validators((val) =>
        [AuthenticationType.JWT, AuthenticationType.Basic].includes(this.type) &&
        !this.editMode &&
        !val && 
        'Password required');
    
    @observable
    public ipAdressFields: FieldState<string>[] = [this.newIpField()];
    
    @observable
    public form = new FormStateLazy(() => [...this.ipAdressFields, this.apiKeyField, this.usernameField, this.passwordField]);

    public clearIpAddresses() {
        this.ipAdressFields = [];
    }

    private resetIpAddresses() {
        this.clearIpAddresses();
        this.addIpAddress();
    }

    public resetFields() {
        this.apiKeyField.onChange('');
        this.usernameField.onChange('');
        this.passwordField.onChange('');
        this.resetIpAddresses();
    }
    
    private newIpField() : FieldState<string> {
        return new FieldState('').validators((val) =>
        this.type === AuthenticationType.IP &&
        !FormatValidator.ip(val)  && 
        'Valid IP or CIDR block required');
    }
    
    public addIpAddress(address? : string) {
        var field = this.newIpField();
        this.ipAdressFields.push(field);
        //triggering onChange to ensure validation fires
        field.onChange(address ? address : "");
    }
}