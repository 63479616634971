import * as React from "react";

import {
  IconButton,
  Input,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from "@material-ui/core";

import CodeIcon from "@material-ui/icons/Code";
import DragIcon from "@material-ui/icons/DragIndicator";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import FunctionHelpers from "../../Common/Helpers/FunctionHelpers";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IFunction from "../../Common/Interfaces/IFunction";
import ImportIcon from "@material-ui/icons/ArrowBack";
import MagicFunctionContext from "./MagicFunctionContext";
import { appStyles } from "../../App.Styles";
import { observer } from "mobx-react";

interface IProps extends IComponentProps {
  context: EndpointWizardContext;
  draggable: boolean;
  task: number;
  name: string;
  paramsName?: string;
  paramsValue?: string;
}

@observer
class MagicFunction extends React.Component<IProps> {
  private _context = new MagicFunctionContext(this.props.paramsValue || "");

  private onDragStart = (e: React.DragEvent<HTMLElement>, func: IFunction) => {
    e.stopPropagation();
    this.props.context.draggedFunction = func;
    this.props.context.draggedProperty = undefined;
    this.props.context.draggedSecret = undefined;
    e.dataTransfer.setData(
      "text/plain",
      FunctionHelpers.getFunctionToken(func.name, this._context.paramValue)
    );
  };

  private onClickDelete = (key: string) => {
    if (
      window.confirm(
        `Are you sure you want to delete '${key}', as this will not remove any instances of usage?`
      )
    ) {
      delete (this.props.context.tasks[this.props.task].functions as any)[key];
    }
  };

  private onClickImport = (name: string) => {
    this.props.context.tasks[
      this.props.context.activeTask
    ].activeTextInput!.addToken(
      FunctionHelpers.getFunctionToken(name, this._context.paramValue)
    );
  };

  public render() {
    return (
      <div
        draggable={this.props.draggable}
        onMouseDown={(e) => e.stopPropagation()}
        onDragStart={(e) =>
          this.onDragStart(e, {
            name: this.props.name,
            definition: this.props.paramsValue,
          })
        }
        className={this.props.draggable ? this.props.classes.draggable : null}
        id={this.props.name}
      >
        <ListItem>
          <ListItemIcon>
            {this.props.draggable ? <DragIcon /> : <CodeIcon />}
          </ListItemIcon>
          <ListItemText
            primary={
              <React.Fragment>
                {this.props.name}
                {this.props.paramsName && (
                  <span className={this.props.classes.marginLeft}>
                    <InputLabel>{this.props.paramsName}</InputLabel>
                    <Input
                      id={"function-input-" + this.props.name}
                      className={this.props.classes.marginLeft}
                      value={this._context.paramValue}
                      onChange={(e) =>
                        (this._context.paramValue = e.target.value)
                      }
                    />
                  </span>
                )}
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction id={"function-" + this.props.name}>
            {
              /**
               *  MAG-610
               *  Disable importing and dragging of tokens if map type is XSLT
               */
              this.props.context.isEditorTokenImportEnabled && (
                <IconButton onClick={() => this.onClickImport(this.props.name)}>
                  <ImportIcon />
                </IconButton>
              )
            }
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(MagicFunction);
