import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Loading from "../Loading/Loading";
import { useAuthentication } from "./AuthWrapper";

export const SignInCallback: React.FC = () => {
  const {
    isLoggedIn,
    signOut: logout,
    loading: authLoading,
    profile: user,
  } = useAuthentication();
  const { push } = useHistory();

  useEffect(() => {
    if (!authLoading) {
      push(`/`);
    }
  }, [push, authLoading]);

  useEffect(() => {
    if (isLoggedIn) {
      push((user && user.state && user.state.referrer) || "/");
    }
  }, [isLoggedIn, push, user]);

  useEffect(() => {
    if (!authLoading && !isLoggedIn) {
      logout();
      push("/login");
    }
  }, [authLoading, isLoggedIn, logout, push]);

  return <Loading fullscreen={false}></Loading>;
};
