import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@material-ui/core";
import BrushIcon from "@material-ui/icons/Brush";
import ErrorIcon from "@material-ui/icons/Error";
import PreviousIcon from "@material-ui/icons/NavigateBefore";
import NextIcon from "@material-ui/icons/NavigateNext";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import AceEditor from "react-ace";
import {
  DataFormat,
  DataFormatDisplayName,
} from "../../../Common/Enums/DataFormat";
import { DataSource } from "../../../Common/Enums/DataSource";
import { HttpMethod } from "../../../Common/Enums/HttpMethod";
import DataFormatHelper from "../../../Common/Helpers/DataFormatHelper";
import Prettifier from "../../../Common/Helpers/Prettifier";
import { FHIRR4Resource } from "../../../Common/HL7/4.0.0 (R4 Sequence)/Resource";
import IComponentProps from "../../../Common/Interfaces/IComponentProps";
import Help from "../../Help/Help";
import Loading from "../../Loading/Loading";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import { EndpointStepType } from "../EndpointStepType";
import ParsedInputViewer from "./ParsedInputViewer";
import "brace/theme/tomorrow";
import EndpointHelpers from "../../../Common/Helpers/EndpointHelpers";
import {
  generateInputDataFromSchema,
  validateSchemaDebounced,
} from "../ParsedViewerHelpers";
import { ViewingMode } from "../ViewingMode";

interface IProps extends IComponentProps {
  context: EndpointWizardContext;
  onClickNext: (nextIndex: number) => void;
  editMode: boolean;
}

@observer
export default class InputStep extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    if (
      this.props.editMode !== true &&
      this.props.context.method === HttpMethod.GET &&
      this.props.context.input.mode !== DataFormat.None
    ) {
      this.props.context.input.mode = DataFormat.QueryString;
      this.props.context.input.dataSource = DataSource.QueryString;
      this.props.context.initializeInputData(DataFormat.QueryString, false);
    }
  }

  private enableEditorControls(): boolean {
    return (
      this.props.context.input.mode !== DataFormat.None &&
      this.props.context.input.mode !== DataFormat.Stream &&
      this.props.context.input.mode !== DataFormat.Callback
    );
  }

  private showEditor(): boolean {
    return (
      this.props.context.input.mode !== DataFormat.None &&
      this.props.context.input.mode !== DataFormat.Stream
    );
  }

  private switchInputMode = async (mode: DataFormat) => {
    EndpointHelpers.confirmRegenerateSchema(
      this.props.context.editInitialLoad,
      () => {
        this.props.context
          .switchInputMode(mode)
          .then(() =>
            validateSchemaDebounced(this.props.context, this.props.appContext)
          );
        this.props.context.editInitialLoad = false;
      },
      () =>
        this.props.context
          .switchInputMode(mode)
          .then(() =>
            validateSchemaDebounced(this.props.context, this.props.appContext)
          )
    );
  };

  public UNSAFE_componentWillMount() {
    this.props.context.input.loaded = true;
    this.props.context.tasks.forEach(
      (task) =>
        (task.reinitializeOutput = !(this.props.editMode || task.output.loaded))
    );
  }

  public editInput = async (code: string) => {
    this.props.context.inputContent = code;
    this.handleInputChangeValidation();
  };

  public handleInputChangeValidation = async () => {
    this.props.context.setInputData().then(() => {
      let updateSchema = false;
      if (this.props.context.input.viewingMode === ViewingMode.Tree) {
        if (!this.props.context.confirmSyncSchema) {
          updateSchema = true;
        } else {
          EndpointHelpers.confirmRegenerateSchema(
            this.props.context.confirmSyncSchema,
            () => {
              updateSchema = true;
              this.props.context.confirmSyncSchema = false;
            },
            () => {
              this.props.context.input.viewingMode = ViewingMode.Preview;
            }
          );
        }
      }
      if (updateSchema) {
        this.updateSchema();
      } else {
        validateSchemaDebounced(this.props.context, this.props.appContext);
      }
    });
  };

  public updateSchema = async () => {
    return this.props.context
      .updateSchemaDebounced()
      .then(() =>
        validateSchemaDebounced(this.props.context, this.props.appContext)
      );
  };

  public render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            What does your data look like?
          </Typography>
        </Grid>
        <Grid item md={6}>
          <div className={this.props.classes.toggleContainer}>
            {
              <FormControl id="select-input-format" fullWidth={true}>
                <InputLabel>Format</InputLabel>
                <Select
                  id="input-format-select"
                  data-testid="input-format-select"
                  value={this.props.context.input.mode}
                  onChange={(e: React.ChangeEvent<{ value: any }>) =>
                    this.switchInputMode(
                      (DataFormat as any)[(DataFormat as any)[e.target.value]]
                    )
                  }
                >
                  {Object.keys(DataFormat)
                    .filter(
                      (key) =>
                        isNaN(Number(DataFormat[key as any])) &&
                        (DataFormat as any)[DataFormat[key as any]] !==
                          DataFormat.HTML &&
                        (DataFormat as any)[DataFormat[key as any]] !==
                          DataFormat.Text &&
                        (DataFormat as any)[DataFormat[key as any]] !==
                          DataFormat.Turtle
                    )
                    .map((key) => (
                      <MenuItem
                        disabled={
                          this.props.context.method === HttpMethod.GET && 
                          this.props.context.input.dataSource == DataSource.QueryString &&
                          ![DataFormat.QueryString, DataFormat.None].includes(
                            (DataFormat as any)[(DataFormat as any)[key as any]]
                          )
                        }
                        id={`input-format-${DataFormatDisplayName(
                          (DataFormat as any)[(DataFormat as any)[key as any]]
                        )}`}
                        key={key}
                        value={key}
                      >
                        {DataFormatDisplayName(
                          (DataFormat as any)[(DataFormat as any)[key as any]]
                        )}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            }
            {[DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
              this.props.context.input.mode
            ) && (
              <FormControl
                fullWidth={true}
                className={this.props.classes.margin}
              >
                <InputLabel>Resource</InputLabel>
                <Select
                  value={this.props.context.input.fhir.resource}
                  onChange={(e: React.ChangeEvent<{ value: any }>) =>
                    this.props.context.switchInputFHIRResource(
                      (FHIRR4Resource as any)[
                        (FHIRR4Resource as any)[e.target.value]
                      ],
                      this.props.context.input.mode
                    )
                  }
                >
                  {Object.keys(FHIRR4Resource)
                    .filter((key) => isNaN(Number(FHIRR4Resource[key as any])))
                    .map((key) => (
                      <MenuItem key={key} value={key}>
                        {(FHIRR4Resource as any)[key]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {this.props.context.input.mode === DataFormat.QueryString && (
              <FormControl
                fullWidth={true}
                className={classNames(
                  this.props.classes.margin,
                  this.props.classes.dataFromSelectors
                )}
              >
                <RadioGroup
                  data-testid="querystring-or-body"
                  row={true}
                  value={DataSource[this.props.context.input.dataSource]}
                  onChange={(e, value) => {
                    this.props.context.input.dataSource = DataSource[
                      value as any
                    ] as unknown as DataSource;
                  }}
                >
                  <FormControlLabel
                    value={DataSource[DataSource.Body]}
                    control={<Radio />}
                    label="Body"
                  />
                  <FormControlLabel
                    value={DataSource[DataSource.QueryString]}
                    control={<Radio />}
                    label="Querystring"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <Button
              disabled={!this.enableEditorControls()}
              className={this.props.classes.margin}
              variant="text"
              onClick={() =>
                (this.props.context.inputContent = Prettifier.prettify(
                  this.props.context.inputContent,
                  this.props.context.input.mode
                ))
              }
            >
              <BrushIcon />
            </Button>
            {(this.props.context.input.viewingMode === ViewingMode.Preview ||
              this.props.context.input.viewingMode ===
                ViewingMode.Validation) && (
              <Button
                id="generate-example-button"
                disabled={!this.enableEditorControls()}
                className={this.props.classes.margin}
                variant="text"
                onClick={() =>
                  generateInputDataFromSchema(
                    this.props.appContext,
                    this.props.context
                  )
                }
              >
                <AutorenewIcon />
              </Button>
            )}
            <FormControl fullWidth className={this.props.classes.margin}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="persist-example"
                    disabled={
                      this.props.context.generatable === false ||
                      !this.enableEditorControls()
                    }
                    onChange={() =>
                      (this.props.context.saveInput =
                        !this.props.context.saveInput)
                    }
                    checked={this.props.context.saveInput}
                  />
                }
                label={
                  <React.Fragment>
                    Persist example
                    <Help
                      classes={this.props.classes}
                      helpText={
                        <React.Fragment>
                          <p>
                            If selected, sample data entered in the input step
                            will be persisted for further editing. Otherwise,
                            sample data is automatically generated based on the
                            schema.
                          </p>
                          {this.props.context.generatable === false && (
                            <p>
                              <em>
                                This option is disabled because the chosen input
                                format does not support automatically generated
                                sample data.
                              </em>
                            </p>
                          )}
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                }
              />
            </FormControl>
          </div>
          <div>
            {this.props.context.input.loading ? (
              <Loading fullscreen={false} />
            ) : (
              this.showEditor() && (
                <AceEditor
                  name="input-editor"
                  mode={DataFormatHelper.GetModeString(
                    this.props.context.input.mode
                  )}
                  theme="tomorrow"
                  onChange={this.editInput}
                  fontSize={20}
                  showPrintMargin={true}
                  width="100%"
                  showGutter={true}
                  highlightActiveLine={true}
                  readOnly={!this.enableEditorControls()}
                  value={this.props.context.inputContent}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 4,
                  }}
                />
              )
            )}
          </div>
        </Grid>
        <ParsedInputViewer
          context={this.props.context}
          appContext={this.props.appContext}
          classes={this.props.classes}
        />
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <Snackbar
              id="validation-message"
              open={this.props.context.valid(EndpointStepType.Input) === false}
              className={`${this.props.classes.invalid} ${this.props.classes.snackbarInvalidBottom}`}
              style={{ width: "100%", maxWidth: "100%" }}
            >
              <span
                className={classNames(
                  this.props.classes.margin,
                  this.props.classes.message
                )}
              >
                <ErrorIcon />
                {this.props.context.input.error ||
                  "Input or schema is invalid " +
                    DataFormatDisplayName(this.props.context.input.mode) +
                    ", correct it to continue."}
              </span>
            </Snackbar>
            <Snackbar
              id="validation-message"
              open={this.props.context.validationResult?.valid === false}
              className={`${this.props.classes.invalid} ${this.props.classes.snackbarInvalidBottom}`}
              style={{ width: "100%", maxWidth: "100%" }}
            >
              <span
                className={classNames(
                  this.props.classes.margin,
                  this.props.classes.message
                )}
              >
                <ErrorIcon />
                Example given does not match schema
              </span>
            </Snackbar>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            id="input-previous"
            onClick={() =>
              this.props.onClickNext(this.props.context.activeStep - 1)
            }
            variant="contained"
            color="default"
            size="large"
            fullWidth
          >
            <PreviousIcon fontSize="large" /> Previous
          </Button>
        </Grid>
        <Grid item xs={6}>
          {this.props.context.error ? (
            <SnackbarContent
              className={this.props.classes.error}
              message={
                <span className={this.props.classes.message}>
                  <ErrorIcon />
                  There was a problem saving your input, please try again!
                </span>
              }
            />
          ) : null}
          <Button
            id="input-next"
            onClick={() =>
              this.props.onClickNext(this.props.context.activeStep + 1)
            }
            variant="contained"
            color="primary"
            size="large"
            disabled={
              this.props.context.allPreceedingStepsValid(
                this.props.context.activeStep + 1
              ) === false
            }
            fullWidth
          >
            Next <NextIcon fontSize="large" />
          </Button>
        </Grid>
      </Grid>
    );
  }
}
