import { observable } from "mobx";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import AppContext from "../../App.Context";
import EndpointService from "../../Common/Services/Endpoint.Service";
import DataFactory from "../../Common/Factories/DataFactory";
import SchemaService from "../../Common/Services/Schema.Service";

export default class EndpointContext {
    constructor(appContext: AppContext, name: string, version: number) {
        this.loading = true;
        EndpointService.Get(appContext, name)
            .then(endpoint => { 
                this.endpoint = endpoint;
                if (this.endpoint.versions.find(x => x.version === version)!.input) {
                    this.input = this.endpoint.versions.find(x => x.version === version)!.input as string;
                } else {
                    new DataFactory(SchemaService.GenerateXml)
                        .Generate(
                            appContext.managementHost as string, 
                            this.endpoint.versions.find(x => x.version === version)!)
                        .then(code => {
                            this.input = code;
                        });
                }
            })
            .finally(() => this.loading = false);
    }

    @observable
    public endpoint?: IEndpoint;

    @observable
    public loading: boolean = false;

    @observable
    public copied: boolean = false;

    @observable
    public includeEncrypted: boolean = false;

    @observable
    public export: string = '';

    @observable
    public exportFormat: string = 'JSON';

    @observable
    public input: string = '';

    @observable
    public result: string = '';

    @observable
    public resultStatus: number = 0;
}