import LoggingService from "../../Common/Services/Logging.Service";
import AppContext from "../../App.Context";
import IRepository from "../../Common/IRepository";
import { getToken } from "../../Common/Helpers/AuthenticationHelpers";

export default class RepositoriesService {
  private CallGetRepositories = (appContext: AppContext): Promise<string> => {
    const uri = `${appContext.managementHost}/Repository`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.text())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static GetEndpointNames = (
    appContext: AppContext
  ): Promise<string> => {
    const uri = `${appContext.managementHost}/Repository/EndpointNames`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public GetRepositories(appContext: AppContext): Promise<IRepository[]> {
    return this.CallGetRepositories(appContext).then((result) => {
      const repositories: IRepository[] = JSON.parse(result, this.reviver);
      return repositories;
    });
  }

  private reviver(key: string, value: string) {
    if (["added", "synched", "nextSync"].includes(key)) {
      return new Date(value);
    }

    return value;
  }
}
