import { observable } from "mobx";
import { FieldState, FormState } from "formstate";
import { FormEvent } from "react";
import BaseContext from "../../Common/BaseContext";
import AppContext from "../../App.Context";
import SecretService from "./Secret.Service";
import ISecret from "../../Common/Interfaces/ISecret";
import SecretsService from "../Secrets/Secrets.Service";
import IWithErrorsResponse from "../../Common/Interfaces/IWithErrorsResponse";

export default class SecretContext extends BaseContext {
    constructor(appContext: AppContext) {
        super(appContext);
        this.loading = true;
        new SecretsService().Get(appContext)
            .then(secrets => this._secrets = secrets)
            .finally(() => this.loading = false);
    }

    private _secrets: ISecret[] = [];

    public keyField = new FieldState('')
        .validators((val) => 
            (!val && 'Enter a secret name') ||
            (this._secrets.some(s => s.key === val) && 'A secret with this name already exists') || 
            //eslint-disable-next-line no-useless-escape
            (!RegExp('^[a-zA-Z\-]+$').test(val) && 'Secret name containing letters and dashes (-) only required'))

    public valueField = new FieldState('').validators((val) => !val && 'Enter a secret value');

    @observable
    public redirect: boolean = false;

    @observable
    public loading: boolean = false;

    @observable
    public showValue: boolean = false;

    form = new FormState({
        key: this.keyField,
        value: this.valueField
    });

    
    @observable
    public response?: IWithErrorsResponse;


    public onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const validationResult = await this.form.validate();
        
        if (validationResult.hasError) {            
            return false;
        }
        
        this.loading = true;
        this.response = await SecretService.Save(this.AppContext,
        {
            key: validationResult.value.key.value,
            value: validationResult.value.value.value
        });
        this.loading = false;
        if (this.response && this.response!.status < 400) {
            this.redirect = true;
        }
    };
}