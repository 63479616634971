import { Chip, Switch, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { appStyles } from "../../App.Styles";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import Help from "../Help/Help";
import EndpointsContext from "./Endpoints.Context";

interface IProps {
  context: EndpointsContext;
  endpoint: IEndpoint;
  classes: any;
  setActive: (endpoint: IEndpoint, value: boolean) => void;
}
const EndpointsActiveSwitch: React.FC<IProps> = observer(
  ({ context, endpoint, classes, setActive }) => {
    return (
      <React.Fragment>
        <Chip
          label={
            <React.Fragment>
              <Switch
                id={`${endpoint.name}-active-mode`}
                checked={
                  context.selectedVersions.find(
                    (v) => v.endpointName === endpoint.name
                  )!.endpointVersion.active
                }
              />
              <Help
                classes={classes}
                helpText="Switch to enable/disable endpoint version"
              />
            </React.Fragment>
          }
          clickable
          onClick={() =>
            onClickActive(
              !context.selectedVersions.find(
                (v) => v.endpointName === endpoint.name
              )!.endpointVersion.active,
              endpoint,
              context.selectedVersions.find(
                (v) => v.endpointName === endpoint.name
              )!.endpointVersion.version,
              setActive
            )
          }
        />
      </React.Fragment>
    );
  }
);
const onClickActive = async (
  value: boolean,
  endpoint: IEndpoint,
  version: number,
  setActive: (endpoint: IEndpoint, value: boolean) => void
) => {
  if (
    value === true ||
    window.confirm(
      `Are you sure you want to disable '${endpoint.name}' version ${version}?`
    )
  ) {
    setActive(endpoint, value);
  }
};

export default withStyles(appStyles, { withTheme: true })(
  EndpointsActiveSwitch
);
