import { Behaviour } from "../Enums/Behaviour";

export default class BehaviourHelpers {
  public static GetDisplayName(behaviour: Behaviour, postfix?: string) {
    switch (behaviour) {
      case Behaviour.Email:
        return "Email";
      case Behaviour.MESH:
        return "MESH Message";
      case Behaviour.Request:
        return "HTTP Request";
      case Behaviour.ResponseHandler:
        return "HTTP Response Handler";
      case Behaviour.SMS:
        return "SMS";
      case Behaviour.Job:
        return "Job";
      case Behaviour.Fax:
        return "Fax";
      case Behaviour.Response:
        return "HTTP Response";
      case Behaviour.Redirect:
        return "HTTP Redirect";
      case Behaviour.Mapping:
        return "Mapping";
      case Behaviour.PDF:
        return "PDF";
      case Behaviour.Adapter:
        return `Adapter: ${postfix}`;
      case Behaviour.SFTP:
        return "SFTP";
      case Behaviour.JWT:
        return "Create JWT";
      case Behaviour.AireAudit:
        return "AireAudit";
      default:
        return behaviour;
    }
  }
}
