import AppContext from '../../App.Context';
import LoggingService from '../../Common/Services/Logging.Service';
import { getToken } from '../Helpers/AuthenticationHelpers';
import IAdapterDefinition from '../Interfaces/IAdapterDefinition';

export default class AdaptersService {
    public CallGet = (appContext: AppContext): Promise<string> => {
        const uri = `${appContext.managementHost}/Adapter`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public Get(appContext: AppContext): Promise<IAdapterDefinition[]> {
        return this.CallGet(appContext)
            .then(result => {
                return JSON.parse(result)
            }).catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }
}