export enum MANAGED_MEDIA_TYPE {
    PDF = "application/pdf",
    JSON = "application/json",
    XML = "application/xml",
    FHIR_JSON = "application/fhir+json",
    FHIR_XML = "application/fhir+xml",
    HL7_V2 = "application/hl7-v2",
    QUERY_STRING = "application/x-www-form-urlencoded",
    TEXT = "text/plain",
    TURTLE = "application/ld+json",
    HTML = "text/html",
    CSS = "text/css",
    XSLT = "application/xslt+xml",
    XML_SCHEMA = "application/schema+xml",
    JSON_SCHEMA = "application/schema+json",
    PROBLEM_JSON = "application/problem+json",
    GRAPHQL = "application/graphql",
    GRAPHQL_JSON = "application/graphql+json",
    GRAPHQL_RESPONSE_JSON = "application/graphql-response+json"
}

export enum IMAGE_MEDIA_TYPE {
    JPEG = "image/jpeg",
    PNG = "image/png",
    SVG = "image/svg+xml",
    WEBP = "image/webp"
}

export const NON_STREAMABLE_CONTENT_TYPES: string[] = [
    MANAGED_MEDIA_TYPE.JSON,
    MANAGED_MEDIA_TYPE.XML,
    MANAGED_MEDIA_TYPE.FHIR_JSON,
    MANAGED_MEDIA_TYPE.HL7_V2,
    MANAGED_MEDIA_TYPE.QUERY_STRING,
    MANAGED_MEDIA_TYPE.TEXT,
    MANAGED_MEDIA_TYPE.TURTLE,
    MANAGED_MEDIA_TYPE.FHIR_XML,
    MANAGED_MEDIA_TYPE.HTML,
    MANAGED_MEDIA_TYPE.CSS,
    MANAGED_MEDIA_TYPE.XSLT,
    MANAGED_MEDIA_TYPE.XML_SCHEMA,
    MANAGED_MEDIA_TYPE.JSON_SCHEMA,
    MANAGED_MEDIA_TYPE.PROBLEM_JSON,
    MANAGED_MEDIA_TYPE.GRAPHQL,
    MANAGED_MEDIA_TYPE.GRAPHQL_JSON,
    MANAGED_MEDIA_TYPE.GRAPHQL_RESPONSE_JSON
];

export function isStreamableContentType(mediaType?: string): boolean {
    if (!mediaType) return false;

    for (const nonStreamable of NON_STREAMABLE_CONTENT_TYPES) {
        const regex = new RegExp(`^${nonStreamable.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}([+;].*)?$`, 'i');
        if (regex.test(mediaType)) {
            return false;
        }
    }

    return true;
}

export function isImageMediaType(mediaType?: string): boolean {
    if (!mediaType) return false;

    return Object.values(IMAGE_MEDIA_TYPE).includes(mediaType as IMAGE_MEDIA_TYPE);
}

export function isOtherStreamMediaType(mediaType?: string): boolean {
    if (!mediaType) return false;
    
    return !isImageMediaType(mediaType) && isStreamableContentType(mediaType);
}