import { FieldState, FormState } from 'formstate';
import { observable } from "mobx";
import { FormEvent } from "react";
import BaseContext from "../../Common/BaseContext";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import { AccountService } from "./Account.Service";

export default class RegisterContext extends BaseContext {
    public username = new FieldState('');

    public email = new FieldState('')
        .validators(val => !FormatValidator.email(val) && 'Valid email required');

    public password = new FieldState('')
        .validators(val => !FormatValidator.password(val) && 'Passwords must be at least 8 characters and contain at 3 of 4 of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)');

    public confirmPassword = new FieldState('')
        .validators(val => val !== this.password.value && "Password and confimation must match");

    @observable
    public loading = false;

    @observable
    public registered = false;

    @observable
    public registerErrors: string[] = [];

    form = new FormState({
        username: this.username,
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword
    });
  
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const validationResult = await this.form.validate();

        if (validationResult.hasError) {
            return false;
        }

        this.loading = true;

        await AccountService.Register(this.AppContext.managementHost as string, 
                                      validationResult.value.username.value, 
                                      validationResult.value.email.value, 
                                      validationResult.value.password.value,
                                      validationResult.value.confirmPassword.value)
            .then(result => {
                if (!result.errors) {
                    this.registered = true;
                } else {
                    this.registerErrors = Object.keys(result.errors).map(key => result.errors[key].join(' '));
                }
            })
            .catch(() => this.registerErrors = ["There was problems creating your account, please try again"])
            .finally(() => this.loading = false);
    };
}