import { Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto' as 'auto',
            marginRight: 'auto' as 'auto',
        },
    },
    link: {
        margin: theme.spacing(1),
        textDecoration: 'none'
    },
    primaryText: {
        color: theme.palette.primary.main,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    error: {
        backgroundColor: theme.palette.error.main,
        margin: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    formError: {
        color: theme.palette.error.main
    }
});