import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, withStyles } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { observer } from "mobx-react";
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { appStyles } from '../../App.Styles';

interface IProps {
    classes: any;
    setRedirect: (boolean) => void;
}

const anchorRef = React.createRef<HTMLDivElement>();

const EndpointsAddButton: React.FC<IProps> = observer(({ classes, setRedirect }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Grid item xs={12}>
            <ButtonGroup
                variant="outlined"
                color="primary"
                style={{width:'100%'}}
                ref={anchorRef}>
                <Button
                    id="add-endpoint"
                    style={{width:'100%'}}
                    color="default"
                    size="large"
                    onClick={() => setRedirect(true)}>
                    <AddIcon fontSize="large" />
                </Button>
                <Button
                    id="endpoint-menu"
                    color="default"
                    size="large"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                placement='left'
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                <MenuList>
                                    <MenuItem>
                                        <Link 
                                            id="endpoint-import"
                                            to="/Endpoint/Import" 
                                            className={classes.link}>
                                            <FontAwesomeIcon icon={faFileImport}/> Import</Link>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Grid>
    )
})

export default withStyles(appStyles, { withTheme: true })(EndpointsAddButton);