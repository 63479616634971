import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { appStyles } from "../../App.Styles";
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import JobContext from "./Job.Context";
import { Redirect, withRouter, RouteComponentProps } from "react-router";
import Help from "../Help/Help";
import Loading from "../Loading/Loading";
import SaveIcon from "@material-ui/icons/Save";
import MagicCronBuilder from "../MagicCronBuilder/MagicCronBuilder";
import EndpointSelector from "../EndpointSelector/EndpointSelector";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointService from "../../Common/Services/Endpoint.Service";
import EndpointHelpers from "../../Common/Helpers/EndpointHelpers";
import { DataFormat } from "../../Common/Enums/DataFormat";

interface IProps extends IComponentProps {
  context: JobContext;
}

@observer
export class Job extends React.Component<IProps> {
  private onEndpointChange = (input: string, endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string) => {
    if (input) {
      this.props.context.input = input;
    } else {
      this.props.context.input = " ";
    }
    if (endpoint) {
      this.props.context.endpoint = endpoint;
    }
    if (endpointEnvironmentOrVersion){
      this.props.context.endpointEnvironmentOrVersion = endpointEnvironmentOrVersion;
    }
    this.props.context.form.validate();
  };

  private onDateChange = (date: Date | null, isStartDate: boolean) => {
    if (date) {
      if (isStartDate) {
        this.props.context.startDate = date;
      } else {
        this.props.context.endDate = date;
      }
    }
  };

  private onDateClear = (isStartDate: boolean) => {
    if (isStartDate) {
      this.props.context.startDate = null;
    } else {
      this.props.context.endDate = null;
    }
  };

  private handleShowDatesChange = () => {
    this.props.context.showDates = !this.props.context.showDates;
  };

  private handleDeleteOnJobExpireChange = () => {
    this.props.context.deleteOnJobExpire = !this.props.context.deleteOnJobExpire;
  }

  private endpointValid = (endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string): boolean => {
    if (!endpoint || !endpointEnvironmentOrVersion) {
      return false;
    }
  
    const version = EndpointHelpers.GetProductionOrActiveVersion(endpoint, endpointEnvironmentOrVersion)
    if (!version) {
      return false;
    }
    if (version.inputMode === DataFormat.Stream) {
      alert("Stream endpoints are currently not supported for jobs");
      return false;
    }
    return true;
  };

  private handleManualDatesChange = () => {};

  public render() {
    return this.props.context.redirect ? (
      <Redirect to="/Jobs" />
    ) : (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              {this.props.context.editingId ? "Edit" : "Schedule"} a job{" "}
              <Help
                classes={this.props.classes}
                helpText="Jobs can be scheduled to run an endpoint whenever you like"
              />
            </Typography>
          </Grid>
          {this.props.context.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <form
              className={this.props.classes.form}
              noValidate
              autoComplete="off"
              onSubmit={this.props.context.onSubmit}
            >
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="job-name"
                    autoFocus
                    disabled={!!this.props.context.editingId}
                    label="Job Name"
                    onChange={(e) =>
                      this.props.context.nameField.onChange(e.target.value)
                    }
                    placeholder="Enter a name"
                    value={this.props.context.nameField.value}
                    error={this.props.context.nameField.hasError}
                    variant="filled"
                  />
                  <p>{this.props.context.nameField.error}</p>
                </Grid>
                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={this.props.classes.marginLeft}
                        onChange={() =>
                          (this.props.context.disableOnFailure =
                            !this.props.context.disableOnFailure)
                        }
                        checked={this.props.context.disableOnFailure}
                      />
                    }
                    label="Disable on failure"
                  />
                  <Help
                    classes={this.props.classes}
                    helpText="If selected, the job will be disabled if the endpoint it calls returns a non-success status code"
                  />
                </Grid>
                <Grid id="jobendpoint-input-editor" item md={6} style={{ backgroundColor: "white" }}>
                  <EndpointSelector
                    endpoint={this.props.context.endpoint as IEndpoint}
                    endpoints={this.props.context.endpoints}
                    endpointEnvironmentOrVersion={this.props.context.endpointEnvironmentOrVersion}
                    input={this.props.context.input}
                    appContext={this.props.appContext}
                    onChange={this.onEndpointChange}
                    endpointValid={this.endpointValid}
                  />
                </Grid>
                <Grid item md={6} style={{ backgroundColor: "white" }}>
                  <MagicCronBuilder
                    expression={this.props.context.schedule}
                    onChange={(s) => (this.props.context.schedule = s)}
                    onDateClear={this.onDateClear}
                    onDateChange={this.onDateChange}
                    handleShowDatesChange={this.handleShowDatesChange}
                    handleManualDatesChange={this.handleManualDatesChange}
                    startDate={this.props.context.startDate}
                    showDates={this.props.context.showDates}
                    showManualDates={false}
                    isDynamicJobStartDate={false}
                    isDynamicJobEndDate={false}
                    endDate={this.props.context.endDate}
                    showResult={false}
                    handleDeleteOnJobExpireChange={this.handleDeleteOnJobExpireChange}
                    deleteOnJobExpire={this.props.context.deleteOnJobExpire}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <p>{this.props.context.saveError}</p>
                <Button
                  className={this.props.classes.marginTop}
                  type="submit"
                  id="job-save"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={this.props.context.shouldButtonBeDisabled()}
                  fullWidth
                >
                  {
                    <React.Fragment>
                      <SaveIcon fontSize="large" /> Save
                    </React.Fragment>
                  }
                </Button>
              </Grid>
            </form>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps & RouteComponentProps> {
    render() {
      const context = new JobContext(
        this.props.appContext,
        EndpointService.Get,
        (this.props.match.params as any)["id"]
      );

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withRouter(withContext(Job))
);
