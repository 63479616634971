export default class BaseEditorHelpers {
  public getSelectedToken(
    line: string,
    cursorLocation: number,
    tokenStart: string,
    tokenEnd: string
  ): string | undefined {
    if (line) {
      const tokenIndex = line.indexOf(tokenStart);
      if (tokenIndex > -1) {
        const nearestTokenStart = line.lastIndexOf(tokenStart, cursorLocation);
        const nearestTokenEnd =
          line.indexOf(tokenEnd, nearestTokenStart + 1) + tokenEnd.length;
        if (
          nearestTokenStart > -1 &&
          cursorLocation - nearestTokenStart <
            nearestTokenEnd - nearestTokenStart &&
          cursorLocation - nearestTokenStart > 0
        ) {
          return line.substring(
            nearestTokenStart + tokenStart.length,
            line.indexOf(tokenEnd, nearestTokenStart)
          );
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  }

  public replaceSpaceWithDashOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.charCode === 32) {
      e.preventDefault();

      const targetInput = e.target as HTMLInputElement;

      targetInput.value =
        targetInput.value.slice(0, targetInput.selectionStart ?? 0) +
        "-" +
        targetInput.value.slice(targetInput.selectionStart ?? 0 + 1);
    }
  };
}
