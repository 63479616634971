import React from "react";
import { observer } from "mobx-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import TrackedTextField from "../Input/TrackedTextField";
import { AuditableObjectType } from "../../Common/Enums/Audit";

interface IProps {
  task: TaskContext;
}

const AireAuditAuditableObjectControls: React.FC<IProps> = observer(
  ({ task }) => {
    const isValid = task.aireAudit.auditableObjects.every((auditableObject) =>
      auditableObject.isValid()
    );

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center" style={{ gap: 8 }}>
            {!isValid && <ErrorIcon color="error" />}
            <Typography variant="h6">
              Auditable Objects ({task.aireAudit.auditableObjects.length})
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <Box>
              {task.aireAudit.auditableObjects.length === 0 && (
                <Typography>No auditable objects have been added</Typography>
              )}
              {task.aireAudit.auditableObjects.map((auditObject, index) => (
                <Box key={index}>
                  <Box
                    display="flex"
                    marginTop={2}
                    marginBottom={2}
                    style={{ gap: 8 }}
                  >
                    <TrackedTextField
                      context={auditObject.objectId}
                      label="Object Identifier"
                      placeholder="Object Identifier"
                      required
                      fullWidth
                      variant="outlined"
                      error={!auditObject.objectId.content}
                      id={`auditable-object-objectId-${index}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ flex: 1 }}
                      onFocus={(_) =>
                        (task.activeTextInput = auditObject.objectId)
                      }
                    />
                    <FormControl variant="outlined">
                      <InputLabel id={`auditable-object-type-${index}-label`}>
                        Object Type
                      </InputLabel>
                      <Select
                        id={`auditable-object-type-${index}`}
                        labelId={`auditable-object-type-${index}-label`}
                        label="Object Type"
                        variant="outlined"
                        value={auditObject.type}
                        onChange={(e) => {
                          auditObject.type = e.target
                            .value as AuditableObjectType;
                        }}
                        style={{ minWidth: 200 }}
                      >
                        <MenuItem value={AuditableObjectType.SystemObject}>
                          System Object
                        </MenuItem>
                        <MenuItem value={AuditableObjectType.Organization}>
                          Organization
                        </MenuItem>
                        <MenuItem value={AuditableObjectType.Person}>
                          Person
                        </MenuItem>
                        <MenuItem value={AuditableObjectType.Other}>
                          Other
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <IconButton
                      onClick={() =>
                        task.aireAudit.removeAuditableObject(index)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Box marginBottom={2} marginRight={8}>
                    <Typography style={{ marginBottom: 8 }}>
                      Object Details ({auditObject.details.length})
                    </Typography>
                    {auditObject.details.length === 0 && (
                      <Typography style={{ marginBottom: 8 }}>
                        No object details specified
                      </Typography>
                    )}
                    {auditObject.details.map((detail, detailIndex) => (
                      <Box
                        key={detailIndex}
                        display="flex"
                        style={{ gap: 8 }}
                        marginBottom={2}
                      >
                        <TrackedTextField
                          context={detail.type}
                          label="Type"
                          placeholder="Type"
                          required
                          fullWidth
                          variant="outlined"
                          error={!detail.type.content}
                          id={`auditable-object-objectId-${index}-detail-type-${detailIndex}`}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ flex: 1 }}
                          onFocus={(_) => (task.activeTextInput = detail.type)}
                        />
                        <TrackedTextField
                          context={detail.value}
                          label="Value"
                          placeholder="Value"
                          required
                          fullWidth
                          variant="outlined"
                          error={!detail.value.content}
                          id={`auditable-object-objectId-${index}-detail-value-${detailIndex}`}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ flex: 1 }}
                          onFocus={(_) => (task.activeTextInput = detail.value)}
                        />
                        <IconButton
                          onClick={() => auditObject.removeDetail(detailIndex)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <Button
                      variant="contained"
                      color="primary"
                      id={`add-audit-auditable-object-detail-${index}`}
                      onClick={() => auditObject.addDetail()}
                    >
                      <AddIcon /> Add Object Detail
                    </Button>
                  </Box>
                  <Divider style={{ marginTop: 16 }} />
                </Box>
              ))}
            </Box>
            <Box width="100%" marginTop={2}>
              <Button
                variant="text"
                fullWidth
                id="add-audit-auditable-object"
                onClick={() => task.aireAudit.addAuditableObject()}
              >
                <AddIcon /> Add Auditable Object
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default AireAuditAuditableObjectControls;
