import { observable } from "mobx";
import ITest from "./ITest";

export default class EndpointTestsContext {
    @observable
    public activeTest?: ITest;

    @observable
    public loading: boolean = false;

    @observable
    public splitView: boolean = true;
}