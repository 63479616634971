import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SecretIcon from "@material-ui/icons/VpnKey";
import { observer } from "mobx-react";
import * as React from "react";
import { appStyles } from "../../App.Styles";
import { AuthenticationType } from "../../Common/Enums/AuthenticationType";
import AuthenticationTypeHelpers from "../../Common/Helpers/AuthenticationTypeHelpers";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import AuthenticationContext from "../EndpointWizard/Context/AuthenticationContext";
import Help from "../Help/Help";
import MagicMenu from "../MagicMenu/MagicMenu";
import AuthenticationMethodsContext from "./AuthenticationMethodsContext";
import MappablePasswordSetter from "./MappablePasswordSetter";

interface IProps extends IComponentProps {
  authenticationMethods: AuthenticationContext[];
  editMode: boolean;
}

@observer
class AuthenticationMethods extends React.Component<IProps> {
  private _context = new AuthenticationMethodsContext(
    this.props.authenticationMethods.length !== 0
      ? this.props.authenticationMethods[0]
      : undefined,
    this.props.appContext
  );

  private availableMethods = () => {
    let arr = Object.keys(AuthenticationType)
      .filter((key) => isNaN(Number(AuthenticationType[key as any])))
      .filter(
        (key) =>
          !this.props.authenticationMethods
            .map((obj) => obj.type)
            .includes(Number(key))
      )
      .map((key) => Number(key));
    if (this._context.activeMethod) {
      arr.push(Number(this._context.activeMethod.type));
    }
    return arr;
  };

  private addMethod = () => {
    const methodType = this.availableMethods()[0];
    const newMethod = new AuthenticationContext(methodType);
    this._context.activeMethod = newMethod;
    this.props.authenticationMethods.push(newMethod);
  };

  private removeMethod = (method: number) => {
    this.props.authenticationMethods.splice(method, 1);
  };

  private toggleActiveMethod = (method: AuthenticationContext) => {
    if (this._context.activeMethod === method) {
      this._context.activeMethod = undefined;
    } else {
      this._context.activeMethod = method;
    }
  };

  private setMethodType = (
    event: React.ChangeEvent<{ value: any }>,
    authenticationContext: AuthenticationContext
  ) => {
    authenticationContext.resetFields();
    authenticationContext.type = (AuthenticationType as any)[
      (AuthenticationType as any)[event.target.value]
    ];
  };

  public render() {
    return (
      <React.Fragment>
        <Typography align="center" variant="h5">
          Authentication
        </Typography>
        {this.props.authenticationMethods.length === 0 && (
          <Typography align="center">
            No authentication methods have been added. This endpoint will be publicly accessible.
          </Typography>
        )}
        {this.props.authenticationMethods.map((method, index) => {
          return (
            <Accordion
              key={index}
              expanded={this._context.activeMethod === method}
              onChange={() => this.toggleActiveMethod(method)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="space-between"
                  >
                    <Typography variant="h6" color="primary">
                      Authentication Method {index + 1}:{" "}
                      {AuthenticationTypeHelpers.GetDisplayName(method.type)}
                    </Typography>
                    <IconButton
                      className={this.props.classes.margin}
                      onClick={() => this.removeMethod(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <FormControl id={`authentication-select-${index}`}>
                    <FormLabel>Authentication Type</FormLabel>
                    <Select
                      value={
                        (AuthenticationType as any)[
                        AuthenticationType[method.type]
                        ]
                      }
                      onChange={(e) => this.setMethodType(e, method)}
                    >
                      {Object.keys(AuthenticationType)
                        .filter((key) =>
                          isNaN(Number(AuthenticationType[key as any]))
                        )
                        .map((key) => (
                          <MenuItem
                            id={`${AuthenticationType[key as any]
                              }-authentication`}
                            key={key}
                            disabled={
                              true
                                ? !this.availableMethods().includes(Number(key))
                                : false
                            }
                            value={key}
                          >
                            {AuthenticationTypeHelpers.GetDisplayName(
                              (AuthenticationType as any)[
                              (AuthenticationType as any)[key as any]
                              ]
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                    {method.type === AuthenticationType.APIKey && (
                      <Help
                        classes={this.props.classes}
                        helpText="Specify an API Key for authentication. This key should be passed in the 'X-Api-Key' HTTP header"
                      />
                    )}
                    {method.type === AuthenticationType.InternalOnly && (
                      <Help
                        classes={this.props.classes}
                        helpText="Internal Only endpoints can only be called from other endpoints"
                      />
                    )}
                    {method.type === AuthenticationType.JWT && (
                      <Help
                        classes={this.props.classes}
                        helpText="JWT authentication is supported by POSTing an authentication request to [endpoint-url]/Authenticate, then including the resulting token with subsequent requests. An authentication request looks like { 'username': [username], 'password': [password] }. An environment or version of the endpoint must be provided for the authentication flow to work, eg. host/user/endpoint/1/Authenticate or host/user/endpoint/Production/Authenticate. The resulting token value should then be added as a Bearer Authorization header. "
                      />
                    )}
                    {method.type === AuthenticationType.IP && (
                      <Help
                        classes={this.props.classes}
                        helpText="Provide IP or CIDR blocks which are allowed to call this endpoint"
                      />
                    )}
                    {method.type === AuthenticationType.Basic && (
                      <Help
                        classes={this.props.classes}
                        helpText="Basic auth is provided in the Authorization header as 'Basic ['username:password' base64]'"
                      />
                    )}
                  </FormControl>
                  {[AuthenticationType.APIKey].includes(method.type) && (
                    <React.Fragment>
                      <Input
                        className={this.props.classes.marginTop}
                        autoComplete="off"
                        onChange={(e) =>
                          method.apiKeyField.onChange(e.target.value)
                        }
                        placeholder="Enter an API Key"
                        value={method.apiKeyField.value}
                        error={method.apiKeyField.hasError}
                        type="outlined"
                        endAdornment={
                          this._context.secrets.length > 0 && (
                            <Tooltip
                              title="Click to use secrets"
                              placement="right"
                              arrow
                            >
                              <InputAdornment position="end">
                                <MagicMenu
                                  title="Secrets"
                                  icon={<SecretIcon color="primary" />}
                                  items={this._context.secrets.map(
                                    (s) => s.key
                                  )}
                                  onClick={(e) =>
                                    method.apiKeyField.onChange(`--*${e}?*--`)
                                  }
                                />
                              </InputAdornment>
                            </Tooltip>
                          )
                        }
                      />
                      <p>{method.apiKeyField.error}</p>
                    </React.Fragment>
                  )}
                  {[AuthenticationType.JWT, AuthenticationType.Basic].includes(
                    method.type
                  ) && (
                      <React.Fragment>
                        <Input
                          className={this.props.classes.marginTop}
                          autoComplete="off"
                          onChange={(e) =>
                            method.usernameField.onChange(e.target.value)
                          }
                          placeholder="Enter a username"
                          value={method.usernameField.value}
                          error={method.usernameField.hasError}
                        />
                        <p>{method.usernameField.error}</p>
                        <MappablePasswordSetter
                          passwordField={method.passwordField}
                        />
                        <p>{method.passwordField.error}</p>
                      </React.Fragment>
                    )}
                  {[AuthenticationType.IP].includes(method.type) && (
                    <>
                      {method.ipAdressFields.map((field, index) => {
                        return (
                          <React.Fragment key={`ip-${index}`}>
                            <Input
                              className={this.props.classes.marginTop}
                              autoComplete="off"
                              onChange={(e) => field.onChange(e.target.value)}
                              placeholder="Enter an IP address or CIDR block"
                              value={field.value}
                              error={field.hasError}
                              type="outlined"
                              endAdornment={
                                index > 0 ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        method.ipAdressFields.splice(index, 1)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ) : null
                              }
                            />
                            <p>{field.error}</p>
                          </React.Fragment>
                        );
                      })}
                      <IconButton onClick={() => method.addIpAddress()}>
                        <AddIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Button
          id="add-authentication"
          variant={"text"}
          fullWidth={true}
          onClick={this.addMethod}
          disabled={this.availableMethods().length === 1 ? true : false}
        >
          <AddIcon />
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(
  AuthenticationMethods
);
