import { createBrowserHistory, History } from "history";
import { observable } from "mobx";
import Constants from "./Common/Constants";
import { AppService } from "./App.Service";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import ApolloClientFactory from "./Common/Factories/ApolloClientFactory";
import { AccountService } from "./Components/Account/Account.Service";
import { IAccountDetails } from "./Components/Account/IAccountDetails";
import { AuthenticationProvider } from "./Common/Enums/AuthenticationProvider";
import IAdapterDefinition from "./Common/Interfaces/IAdapterDefinition";
import IContextResult from "./Common/Interfaces/IContextResult";
import AdaptersService from "./Common/Services/Adapters.Service";
import AllowedMeshMailboxesService from "./Common/Services/AllowedMeshMailboxes.Service";
import { getToken } from "./Common/Helpers/AuthenticationHelpers";

export default class AppContext {
  constructor({
    identityAuthority = "",
    identityScope = "",
    managementHost = "",
    runtimeHost = "",
    clientV1Host = "",
    clientV2Host = "",
    authenticationProvider = AuthenticationProvider.Internal,
    identityClientId = "",
    permissionsClaimType = "",
    aireAuditEnabled = false
  }) {
      this.identityAuthority = identityAuthority;
      this.identityScope = identityScope;
      this.managementHost = managementHost;
      this.runtimeHost = runtimeHost;
      this.clientV1Host = clientV1Host;
      this.clientV2Host = clientV2Host;
      this.authenticationProvider = authenticationProvider;
      this.identityClientId = identityClientId;
      this.permissionsClaimType = permissionsClaimType;
      this.aireAuditEnabled = aireAuditEnabled;

      this.apolloClient = this.managementHost ? 
        ApolloClientFactory.Get(
        this.managementHost,
        getToken()
      ) : undefined;

      if (this.loggedIn) {
        this.contextPromise = AppService.Context(this);
        this.contextPromise.then((result) => {
          this.handleContext(result);
          AccountService.GetAccountDetails(this.managementHost).then(
            (accountDetails) => {
              this.accountDetails = accountDetails;
            }
          );
        });
        this.loadAdapters();
        this.loadMeshMailboxes();
      }
  }

  @observable
  public identityAuthority: string = "";

  @observable
  public identityScope: string = "";

  @observable
  public contextPromise?: Promise<IContextResult>;

  @observable
  public loggedIn: boolean = getToken() !== null;

  @observable
  public username?: string;

  @observable
  public adapters?: IAdapterDefinition[];

  @observable
  public allowedMeshMailboxes?: string[];

  @observable
  public accountDetails?: IAccountDetails;

  @observable
  public anchorEl: HTMLElement | undefined;

  @observable
  public history: History<any> = createBrowserHistory();

  @observable
  public managementHost: string = "";

  @observable
  public runtimeHost: string = "";
  
  @observable
  public permissionsClaimType: string = "";

  @observable
  public apolloClient?: ApolloClient<NormalizedCacheObject>;

  @observable
  public profileComplete: boolean = true;

  @observable
  public authenticationProvider: AuthenticationProvider =
    AuthenticationProvider.Internal;

  @observable
  public clientV1Host: string = "";
  
  @observable
  public clientV2Host: string = "";

  @observable
  public identityClientId: string = "";

  @observable
  public aireAuditEnabled: boolean = false;

  public loadAdapters = () => {
    new AdaptersService().Get(this).then((adapters) => {
      this.adapters = adapters;
    });
  };

  public loadMeshMailboxes = () => {
    AllowedMeshMailboxesService.Get(this).then((mailboxes) => {
      this.allowedMeshMailboxes = mailboxes;
    });
  };

  public logOut = () => {
    this.loggedIn = false;
    localStorage.removeItem(Constants.authTokenName);
    localStorage.removeItem(Constants.profile);
    this.history.push("/Login");
    window.history.go();
  };

  public reloadContext() {
    AppService.Context(this).then((result) => {
      this.handleContext(result);
    });
  }

  public redirectToHome() {
    this.history.push("/Endpoints");
  }

  public handleContext(context: IContextResult) {
    this.profileComplete = context.profileComplete;
    if (context.profileComplete) {
      this.username = context.username;

      AccountService.GetAccountDetails(this.managementHost).then(
        (accountDetails) => {
          this.accountDetails = accountDetails;
        }
      );
    } else {
      this.history.push("/CompleteProfile");
    }
  }
}
