import { Checkbox, FormControlLabel, TableCell, TableRow, withStyles } from '@material-ui/core';
import { observer } from "mobx-react";
import * as React from 'react';
import { EndpointType } from '../../Common/Enums/EndpointType';
import IEndpoint from '../../Common/Interfaces/IEndpoint';
import EndpointsContext from './Endpoints.Context';
import { styles } from './Endpoints.Styles';

interface IProps {
    classes: any;
    endpoints: IEndpoint[]
    context: EndpointsContext
    setShowRepository: (boolean) => void;
}

const EndpointsTableHeader: React.FC<IProps> = observer(({ classes, endpoints, context, setShowRepository }) => {
    return (
        <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Version Enabled</TableCell>
            <TableCell className={classes.center}>Staging</TableCell>
            <TableCell className={classes.center}>Production</TableCell>
            <TableCell align="right">
                {
                    endpoints.some(e => e.type === EndpointType.Repository) && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={() => setShowRepository(!context.showRepositoryEndpoints)}
                                    checked={context.showRepositoryEndpoints}
                                />
                            }
                            label="Show repository endpoints" />
                    )
                }
            </TableCell>
        </TableRow>
    )
})

export default withStyles(styles, { withTheme: true })(EndpointsTableHeader);