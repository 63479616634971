import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { withStyles, Grid, TextField } from "@material-ui/core";
import { appStyles } from "../../App.Styles";
import { DataFormat } from "../../Common/Enums/DataFormat";
import AutoSuggest from "../AutoSuggest/AutoSuggest";
import Serializers from "../../Common/Helpers/Serializers";
import { IJSONPathMatch } from "../../Common/Interfaces/IJSONPathMatch";
import { JSONPath } from "jsonpath-plus";
import { observable } from "mobx";
import { EvaluateXPath } from "../../Common/Helpers/PathEvaluators";
import EndpointData from "../EndpointWizard/Context/Step.Context";

interface IProps extends IComponentProps {
  editingPropertyToken: string;
  origin: EndpointData;
  value: string;
  onSave: (path: string) => void;
  onCancel: () => void;
  onChange: (
    path: string,
    matches: any[] | IJSONPathMatch[],
    error: boolean
  ) => void;
}

@observer
class PathEditor extends React.Component<IProps> {
  @observable
  private editedPath: string = "";

  public constructor(props: any) {
    super(props);
    this.editedPath = this.props.value;
    this.updatePath(this.editedPath);
  }

  private updatePath = (path: string) => {
    if (
      [
        DataFormat.JSON,
        DataFormat.HL7FHIRJSON,
        DataFormat.QueryString,
        DataFormat.HL7v2,
        DataFormat.Callback,
      ].includes(this.props.origin.mode)
    ) {
      try {
        this.props.origin.editingPathMatches = JSONPath({
          json: this.props.origin.data,
          path: path,
          resultType: "all",
        });
        this.props.origin.editingPathError = false;
      } catch (e) {
        console.log(e);
        this.props.origin.editingPathMatches = [];
        this.props.origin.editingPathError = true;
      }
    } else {
      try {
        this.props.origin.editingPathMatches = EvaluateXPath(
          path,
          this.props.origin.data as Document
        );
        this.props.origin.editingPathError = false;
      } catch (e) {
        console.log(e);
        this.props.origin.editingPathMatches = [];
        this.props.origin.editingPathError = true;
      }
    }

    this.props.onChange(
      path,
      this.props.origin.editingPathMatches,
      this.props.origin.editingPathError
    );
    this.editedPath = path;
  };

  private onPathEditorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.updatePath(event.target.value);
  };

  private onSuggestionsClick = (updatedPath: string) => {
    this.updatePath(updatedPath);
  };

  private getEditingPathValue = (): string => {
    let value = "";
    if (this.props.origin.mode === DataFormat.XML) {
      if (Array.isArray(this.props.origin.editingPathMatches)) {
        (this.props.origin.editingPathMatches as any[]).forEach((match) => {
          const itemString =
            Serializers.serializeXmlIfValid(match as Document) || String(match);
          if (itemString) {
            value += itemString.trim();
          }
        });
      } else {
        value =
          Serializers.serializeXmlIfValid(
            this.props.origin.editingPathMatches as Document
          ) || String(this.props.origin.editingPathMatches);
      }
    } else {
      if (this.props.origin.editingPathMatches) {
        (this.props.origin.editingPathMatches as IJSONPathMatch[]).forEach(
          (match) => {
            const itemString = Serializers.serializeJsonIfValid(match.value);
            if (itemString) {
              value += itemString.trim();
            }
          }
        );
      }
    }
    return value;
  };

  public render() {
    return (
      <React.Fragment>
        <Grid
          className={this.props.classes.marginTop}
          container
          justify="space-between"
        >
          <Grid item xs={12}>
            <AutoSuggest
              appContext={this.props.appContext}
              classes={this.props.classes}
              matches={this.props.origin.editingPathMatches}
              editedPath={this.editedPath}
              editingPropertyToken={this.props.editingPropertyToken}
              placeHolder={`${
                this.props.origin.mode === DataFormat.XML ? "XPath" : "JSONPath"
              } expression`}
              onChange={this.onPathEditorChange}
              onClick={this.onSuggestionsClick}
              mode={this.props.origin.mode}
            />
            <TextField
              label="Value"
              className={this.props.classes.marginTop}
              value={this.getEditingPathValue()}
              fullWidth
              disabled
              multiline
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <p>
              {this.props.origin.editingPathMatches &&
              this.props.origin.editingPathMatches.length === 0 &&
              this.editedPath
                ? this.props.origin.editingPathError
                  ? `${
                      this.props.origin.mode === DataFormat.XML
                        ? "XPath"
                        : "JSONPath"
                    } parsing error`
                  : "No match found"
                : `Supply a ${
                    this.props.origin.mode === DataFormat.XML
                      ? "XPath"
                      : "JSONPath"
                  } expression to select the property`}
            </p>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(PathEditor);
