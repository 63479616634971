import React, { useEffect, useState } from "react";
import {
  Step,
  StepButton,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import BehaviourStep from "./Steps/TasksStep";
import { EndpointStepType } from "./EndpointStepType";
import EndpointWizardContext from "./Context/EndpointWizard.Context";
import EndpointWizardContextFactory from "./Context/EndpointWizardContextFactory";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import InputStep from "./Input/InputStep";
import Loading from "../Loading/Loading";
import OutputStep from "./Output/OutputStep";
import PreviewDeployStep from "./Steps/PreviewDeployStep";
import WindowHelpers from "../../Common/Helpers/WindowHelpers";
import ReadOnlyAlert from "./ReadOnlyAlert";
import { observer } from "mobx-react";
import { styles } from "./EndpointWizard.Styles";

interface IProps extends IComponentProps {
  editMode?: boolean;
  readOnly?: boolean;
  newVersionMode?: boolean;
}

const EndpointWizard: React.FunctionComponent<IProps> = observer(
  ({ editMode = false, readOnly = false, appContext, newVersionMode = false, classes }) => {
    const [context, setContext] = useState<EndpointWizardContext | undefined>();
    const { name, versionId } = useParams<{ name: string, versionId: string }>();
    let derivedVersion: number | undefined;

    if (newVersionMode) {
      const query = new URLSearchParams(useLocation().search);
      derivedVersion =
        parseInt(query.get("derivedVersion") as string) || undefined;
    }

    useEffect(() => {
      window.onbeforeunload = WindowHelpers.ConfirmBeforeClose;

      if (editMode) {
        new EndpointWizardContextFactory(appContext)
          .Edit(
            name,
            parseInt(versionId as string, 10),
            newVersionMode,
            readOnly,
            derivedVersion
          )
          .then(setContext);
      } else {
        setContext(new EndpointWizardContextFactory(appContext).Create());
      }

      return function cleanup() {
        window.onbeforeunload = null;
      };
    }, [
      editMode,
      appContext,
      name,
      versionId,
      newVersionMode,
      derivedVersion,
      setContext,
    ]);

    if (!context) {
      return <Loading fullscreen={false} />;
    }

    const renderStep = (): JSX.Element | undefined => {
      switch (context.steps[context!.activeStep].type) {
        case EndpointStepType.Behaviour:
          return (
            <BehaviourStep
              appContext={appContext}
              context={context}
              classes={classes}
              editMode={editMode}
              onClickNext={onClickNext}
            />
          );
        case EndpointStepType.Input:
          return (
            <InputStep
              appContext={appContext}
              context={context}
              classes={classes}
              editMode={editMode}
              onClickNext={onClickNext}
            />
          );
        case EndpointStepType.Output:
          return (
            <OutputStep
              appContext={appContext}
              context={context}
              classes={classes}
              onClickNext={onClickNext}
              task={context.activeTask}
              key={context.activeTask}
            />
          );
        case EndpointStepType.PreviewDeploy:
          return (
            <PreviewDeployStep
              appContext={appContext}
              context={context}
              classes={classes}
              editMode={editMode}
              onClickNext={onClickNext}
            />
          );
      }
    };

    const onClickNext = (index: number) => {
      if (context.allPreceedingStepsValid(index)) {
        context.activeTask =
          index > 1 && index < context.steps.length - 1 ? index - 2 : 0; // bit nasty
          context.activeStep = index;
      }      
    };

    return (
      <React.Fragment>
        <Stepper alternativeLabel nonLinear>
          {context.steps.map((step, index) => {
            const isCompleted = context.stepValid(index) && 
              context.allPreceedingStepsValid(index) && 
              context.activeStep !== index &&
              context.readOnly === false;
            
            return (
              <Step key={index} active={context.activeStep === index}>
                <StepButton
                  onClick={() => onClickNext(index)}
                  completed={isCompleted}
                  className={
                    context.allPreceedingStepsValid(index)
                      ? context.activeStep === index
                        ? classes.currentStepperLink
                        : ""
                      : classes.disabledStepperLink
                  }
                >
                  <StepLabel>
                    {context.activeStep === index ? (
                      <b>{step.label}</b>
                    ) : (
                      step.label
                    )}
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        {context.readOnly && <ReadOnlyAlert/>}
        {renderStep()}
      </React.Fragment>
    );
  }
);

export default withStyles(styles, { withTheme: true })(EndpointWizard);
