import { observable } from "mobx";
import AppContext from "../../App.Context";
import RepositoriesService from "./Repositories.Service";
import IRepository from "../../Common/IRepository";

export default class RepositoriesContext {
    constructor(appContext: AppContext) {
        this.GetRepositories(appContext);
    }

    @observable
    public loading: boolean = false;

    @observable
    public repositories: IRepository[] = [];

    public GetRepositories(appContext: AppContext) {
        this.loading = true;
        new RepositoriesService()
            .GetRepositories(appContext)
            .then(repositories => this.repositories = repositories)
            .finally(() => this.loading = false);
    }
}