import { TableCell, TablePagination, TableRow } from '@material-ui/core';
import { observer } from "mobx-react";
import * as React from 'react';
import IEndpoint from '../../Common/Interfaces/IEndpoint';
import EndpointsContext from './Endpoints.Context';

interface IProps {
    endpoints: IEndpoint[];
    context: EndpointsContext;
    handleChangePage: (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const EndpointsTableFooter: React.FC<IProps> = observer(({ endpoints, context, handleChangePage, handleChangeRowsPerPage }) => {
    return (
        <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TablePagination
                SelectProps={{
                    SelectDisplayProps: { id: "endpoints-pagination" },
                    MenuProps: { MenuListProps: { id: "pagination-options" } }
                }}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={endpoints.length}
                rowsPerPage={context.rowsPerPage}
                page={context.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableRow>
    )
})

export default EndpointsTableFooter;