import * as React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Grid,
  Chip,
  Avatar,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { observer } from "mobx-react";
import IFilter from "./IFilter";
import { DataFormat } from "../../Common/Enums/DataFormat";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import PathEditor from "../PathEditor/PathEditor";
import { IJSONPathMatch } from "../../Common/Interfaces/IJSONPathMatch";
import { observable } from "mobx";
import { DataOriginSelect } from "../DataOriginSelect/DataOriginSelect";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";

interface IProps extends IComponentProps {
  filters: IFilter[];
  onDelete: (index: number) => void;
  onAddToAll: (index: number) => void;
  context: EndpointWizardContext;
}

@observer
export default class TaskFilters extends React.Component<IProps> {
  @observable
  private snackbarOpen: boolean = false;

  private getOrigin = (origin?: number) => {
    return [undefined, null, -1].includes(origin)
      ? this.props.context.input
      : this.props.context.tasks[origin as number].output;
  };

  @observable
  private getSamplePath(originIndex?: number) {
    const origin = this.getOrigin(originIndex);
    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(origin.mode)) {
      let document = (origin.data as Document).documentElement;
      let textContent = document.textContent;
      let path = "";
      while (document.children.length > 0) {
        document = document.children.item(0) as HTMLElement;
        textContent = document.textContent;
        path = `${path}/${document.nodeName}`;
      }
      return `${path}/text() = '${textContent}'`;
    }

    if (
      [
        DataFormat.JSON,
        DataFormat.HL7FHIRJSON,
        DataFormat.QueryString,
        DataFormat.HL7v2,
      ].includes(origin.mode)
    ) {
      let property = origin.data as any;

      while (typeof property[Object.keys(property)[0]] === "object") {
        if (property.constructor !== Array) {
          property = property[Object.keys(property)[0]];
        } else if (property.constructor === Array) {
          property = property[0];
        }
      }

      const value = property[Object.keys(property)[0]];
      return `$..[?(@property === '${Object.keys(property)[0]}' && @ === ${
        typeof value === "number" ? value : "'" + value + "'"
      })]`;
    }
  }

  private addFilter = () => {
    this.props.filters.push({
      path: this.getSamplePath(),
      error: true,
    } as IFilter);
  };

  public render() {
    return (
      <React.Fragment>
        <Typography align="center" variant="h6">
          Filters
        </Typography>
        <div style={{ width: "100%" }} id="task-filters">
          {this.props.filters.map((filter, index) => {
            return (
              <Accordion
                key={index}
                id={`filter-panel-${index}`}
                className="task-filter-panel"
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justify="space-between"
                    >
                      <div style={{ width: "100px" }}>
                        <Chip
                          id={`filter-status-${index}`}
                          avatar={
                            <Avatar
                              className={classNames(
                                filter.error && this.props.classes.invalid
                              )}
                            >
                              {filter.error ? <DoneIcon /> : <ErrorIcon />}
                            </Avatar>
                          }
                          className={classNames(
                            this.props.classes.margin,
                            filter.error && this.props.classes.invalid
                          )}
                          clickable={false}
                          label={filter.error ? "Invalid" : "Valid"}
                          color={filter.error ? "default" : "primary"}
                        />
                      </div>
                      <Typography>
                        <FontAwesomeIcon icon={faFilter} />
                        <span style={{ marginLeft: "10px" }}>
                          Filter based on input data.
                        </span>
                      </Typography>
                      <Grid>
                        <Tooltip title="Add filter to all tasks">
                          <IconButton
                            className={this.props.classes.margin}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.snackbarOpen = true;
                              this.props.onAddToAll(index);
                            }}
                          >
                            <LibraryAddIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          className={this.props.classes.margin}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.onDelete(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <DataOriginSelect
                        value={
                          [undefined, null, -1].includes(filter.dataOrigin)
                            ? -1
                            : (filter.dataOrigin as number)
                        }
                        tasks={this.props.context.tasks}
                        taskIndex={this.props.context.activeTask}
                        filter={filter}
                        onChange={(o) =>
                          (filter.path = this.getSamplePath(o) || "")
                        }
                      />
                      <PathEditor
                        value={filter.path}
                        origin={this.getOrigin(filter.dataOrigin)}
                        editingPropertyToken="filter"
                        onChange={(
                          path: string,
                          matches: any[] | IJSONPathMatch[],
                          error: boolean
                        ) => {
                          filter.error = error;
                          filter.path = path;
                        }}
                        appContext={this.props.appContext}
                        onSave={() => {}}
                        onCancel={() => {}}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        <Button variant={"text"} fullWidth={true} onClick={this.addFilter}>
          <AddIcon />
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.snackbarOpen}
          onClose={() => (this.snackbarOpen = false)}
          autoHideDuration={3000}
          message={<span>Filter added to all tasks</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => (this.snackbarOpen = false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}
