import { AppAbilities } from "./AppAbilities";

export function getAbilityFromString(abilityString: String): AppAbilities {
  if (abilityString.includes(":")) {
    let action = abilityString.split(":")[0];
    let subject = abilityString.split(":")[1];
    return [action, subject] as AppAbilities;
  }
  throw new Error(`invalid permission string: ${abilityString}`);
}
