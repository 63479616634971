export enum Behaviour {
  Request,
  ResponseHandler,
  MESH,
  Email,
  SMS,
  Job,
  Fax,
  Response,
  Redirect,
  Mapping,
  PDF,
  Adapter,
  SFTP,
  JWT,
  AireAudit,
}
