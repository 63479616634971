import AppContext from "../App.Context";

export const tryGetTenantPrefix = (configuredHost: URL, currentHostName: string) => {
    return configuredHost.hostname.toLowerCase() === currentHostName.toLowerCase()
      ? null
      : currentHostName.split(".")[0].toLowerCase();
  };

export const applyTenancyToV2Origin = (appContext: AppContext): string => {
    const hostName = window.location.hostname;
    const tenant = tryGetTenantPrefix(new URL(appContext.clientV1Host), hostName);

    const protocol = appContext.clientV2Host.startsWith("https") ? "https" : "http";

    return (tenant !== null)
            ? appContext.clientV2Host.replace(`${protocol}://`, `${protocol}://${tenant}.`)
            : appContext.clientV2Host;
};