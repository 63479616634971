import { observable } from "mobx";
import { FHIRVersion } from "../../../Common/HL7/FHIRVersion";
import IFHIRValidationResult from "../../../Common/Interfaces/IValidationResult";

export default class FHIRContext {
    @observable
    public version: FHIRVersion = FHIRVersion["4.0.0 (R4 Sequence)"]

    @observable
    public resource: string = "Patient";

    @observable
    public validationResult?: IFHIRValidationResult;
}