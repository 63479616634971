import * as React from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  DataFormat,
  DataFormatDisplayName,
} from "../../Common/Enums/DataFormat";

import AceEditor from "react-ace";
import BrushIcon from "@material-ui/icons/Brush";
import CursorLocation from "../EndpointWizard/CursorLocation";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import EndpointHelpers from "../../Common/Helpers/EndpointHelpers";
import Help from "../Help/Help";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import Prettifier from "../../Common/Helpers/Prettifier";
import PropertyEditor from "../PathEditor/PropertyEditor";
import RequestOutputProperties from "../RequestOutput/RequestOutputProperties";
import { ResponseHandlerType } from "../../Common/Enums/ResponseHandlerType";
import { observer } from "mobx-react";

@observer
export default class ResponseHandlerOutput extends React.Component<IOutputProps> {
  private readonly task = this.props.context.tasks[
    this.props.context.activeTask
  ];
  private toRef = React.createRef<HTMLInputElement>();

  public editInput = (code: string) => {
    EndpointHelpers.confirmRegenerateSchema(
      this.props.context.tasks[this.props.task].editInitialLoad,
      () => {
        this.props.context.tasks[
          this.props.task
        ].responseHandlerContent.content = code;
        this.props.context.tasks[this.props.task].editInitialLoad = false;
        this.props.context.tasks[this.props.task].output.modified = false;
      },
      () => {
        this.props.context.tasks[
          this.props.task
        ].responseHandlerContent.content = code;
        this.props.context.tasks[this.props.task].output.modified = false;
      }
    );
  };

  public handleSwitchFormat = (e: React.ChangeEvent<{ value: any }>) => {
    this.switchFormat((DataFormat as any)[(DataFormat as any)[e.target.value]]);
  };

  public switchFormat = (format: DataFormat) => {
    EndpointHelpers.confirmRegenerateSchema(
      this.props.context.tasks[this.props.task].editInitialLoad,
      () => {
        this.props.context.switchOutputMode(
          this.props.context.input.mode,
          (DataFormat as any)[(DataFormat as any)[format]],
          true,
          this.props.context.activeTask
        );
        this.props.context.tasks[this.props.task].editInitialLoad = false;
      },
      () => {
        this.props.context.switchOutputMode(
          this.props.context.input.mode,
          (DataFormat as any)[(DataFormat as any)[format]],
          true,
          this.props.context.activeTask
        );
      }
    );
  };

  public switchResponse = (e: React.ChangeEvent<{ value: any }>) => {
    this.task.responseHandlerTypeField.onChange(
      (ResponseHandlerType as any)[ResponseHandlerType[e.target.value]]
    );
    this.switchFormat(this.props.context.tasks[this.props.task].output.mode);
  };

  public typeFormatter(key: string) {
    if (ResponseHandlerType[key] === "TransparentForward")
      return "Transparent Forward";
    return ResponseHandlerType[key];
  }

  private setAceEditorContent = () => {
    return this.task.responseHandlerTypeField.value ===
      ResponseHandlerType.TransparentForward
      ? "--*{response}*--"
      : this.props.context.tasks[this.props.task].responseHandlerContent
          .content;
  };

  public render() {
    const isStreamFormat = this.props.context.tasks[this.props.task].output.mode === DataFormat.Stream;
    return (
      <Grid
        item
        md={6}
        onDrop={this.props.onDrop}
      >
        {this.props.context.tasks[this.props.task].editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.props.context.tasks[this.props.task].editingPropertyToken ===
            undefined && (
            <React.Fragment>
              <div className={this.props.classes.toggleContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth={true}
                      id="response-handler-type-select"
                    >
                      <InputLabel>Response Handler Type</InputLabel>
                      <Select
                        value={this.task.responseHandlerTypeField.value}
                        onChange={this.switchResponse}
                      >
                        {Object.keys(ResponseHandlerType)
                          .filter((key: string) =>
                            isNaN(Number(ResponseHandlerType[key]))
                          )
                          .map((key: string) => (
                            <MenuItem
                              id={`response-handler-type-option-${ResponseHandlerType[key]}`}
                              key={key}
                              value={key}
                            >
                              {this.typeFormatter(key)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <InputLabel>Format</InputLabel>
                      <Select
                        value={
                          this.props.context.tasks[this.props.task].output.mode
                        }
                        onChange={this.handleSwitchFormat}
                      >
                        {Object.keys(DataFormat)
                          .filter(
                            (key) =>
                              [
                                DataFormat.JSON.toString(),
                                DataFormat.XML.toString(),
                                DataFormat.Stream.toString()
                              ].includes(key) &&
                              [DataFormat.None, DataFormat.HTML].includes(
                                (DataFormat as any)[DataFormat[key as any]]
                              ) === false
                          )
                          .map((key) => (
                            <MenuItem key={key} value={key}>
                              {DataFormatDisplayName(
                                (DataFormat as any)[
                                  (DataFormat as any)[key as any]
                                ]
                              )}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {this.props.context.tasks[this.props.task]
                  .selectedPropertyToken && (
                  <Button
                    className={this.props.classes.margin}
                    onClick={this.props.onClickEditPath}
                    variant={"text"}
                  >
                    <LinkIcon />
                  </Button>
                )}
                <Button
                  className={this.props.classes.margin}
                  variant="text"
                  onClick={() =>
                    (this.props.context.tasks[
                      this.props.task
                    ].responseHandlerContent.content = Prettifier.prettify(
                      this.props.context.tasks[this.props.task]
                        .responseHandlerContent.content,
                      this.props.context.tasks[this.props.task].output.mode
                    ))
                  }
                >
                  <BrushIcon />
                </Button>
              </div>
              <div>
                {!(
                  this.task.responseHandlerTypeField.value ===
                  ResponseHandlerType.Receive
                ) && (
                  <Grid className={this.props.classes.margin} container>
                    <RequestOutputProperties
                      task={this.props.context.tasks[this.props.task]}
                      classes={this.props.classes}
                      context={this.props.context}
                    />
                    <Grid item md={4} className={this.props.classes.margin}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="retain-response"
                            onChange={() =>
                              (this.task.retainStatusCode = !this.task
                                .retainStatusCode)
                            }
                            checked={this.task.retainStatusCode}
                          />
                        }
                        label="Retain response status code"
                      />
                      <Help
                        classes={this.props.classes}
                        helpText="If selected, the response code of the request will be passed back as the response code of this endpoint. Note: this is superseded by retaining the status code of the response handler"
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
              {this.props.context.tasks[this.props.task].output.loading ? (
                <Loading fullscreen={false} />
              ) : (
                !isStreamFormat && <AceEditor
                  mode={DataFormatHelper.GetModeString(
                    this.props.context.tasks[this.props.task].output.mode
                  )}
                  theme="tomorrow"
                  onChange={this.editInput}
                  fontSize={20}
                  readOnly={
                    this.task.responseHandlerTypeField.value ===
                    ResponseHandlerType.TransparentForward
                  }
                  onCursorChange={(e) =>
                    (this.props.context.tasks[
                      this.props.task
                    ].responseHandlerContent.cursorLocation = new CursorLocation(
                      e.getCursor().row,
                      e.getCursor().column
                    ))
                  }
                  onFocus={(_) =>
                    (this.props.context.tasks[
                      this.props.task
                    ].activeTextInput = this.props.context.tasks[
                      this.props.task
                    ].responseHandlerContent)
                  }
                  showPrintMargin={true}
                  width="100%"
                  showGutter={true}
                  highlightActiveLine={true}
                  value={this.setAceEditorContent()}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 4,
                  }}
                />
              )}
            </React.Fragment>
          )}
      </Grid>
    );
  }
}
