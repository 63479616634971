import { observable } from "mobx";
import ISecret from "../../Common/Interfaces/ISecret";
import SecretsService from "../Secrets/Secrets.Service";
import AppContext from "../../App.Context";
import AuthenticationContext from "../EndpointWizard/Context/AuthenticationContext";

export default class AuthenticationMethodsContext {
    constructor(activeMethod: AuthenticationContext | undefined, appContext: AppContext) {
        this.activeMethod = activeMethod;
        this.getSecrets(appContext);
    }

    @observable
    public activeMethod?: AuthenticationContext;

    @observable
    public loading: boolean = false;

    @observable
    public secrets: ISecret[] = [];

    public getSecrets(appContext: AppContext) {
        new SecretsService().Get(appContext)
            .then(secrets => this.secrets = secrets)
            .finally(() => this.loading = false);
    }
}