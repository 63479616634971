import { Ability } from "@casl/ability";
import * as casl from "@casl/react";
import React, { createContext, useEffect } from "react";
import { AuthenticationProvider } from "../../Common/Enums/AuthenticationProvider";
import { useGetProfile } from "../../Common/Hooks/useGetProfile";

import { AppAbility, AppAbilities } from "./AppAbilities";
import { defineAbilitiesFor, setAllPermissions } from "./UserAbilities";

interface IProps {
  authenticationProviderType: AuthenticationProvider;
  permissionsClaimType: string;
}

const ability = new Ability<AppAbilities>([]);

export const AbilityContext = createContext<AppAbility>(ability);

export const AbilityContextProvider: React.FC<IProps> = ({
  children,
  authenticationProviderType,
  permissionsClaimType,
}) => {
  const profile = useGetProfile();

  useEffect(() => {
    if (
      authenticationProviderType === AuthenticationProvider.AireIdentityByTenant
    ) {
      const { rules } = defineAbilitiesFor(profile!, permissionsClaimType);
      ability.update(rules);
    } else {
      const { rules } = setAllPermissions();
      ability.update(rules);
    }
  }, [authenticationProviderType, permissionsClaimType, profile]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbility = () => casl.useAbility(AbilityContext);

export const Can = casl.createContextualCan<AppAbility>(
  AbilityContext.Consumer
);
