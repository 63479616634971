import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const AccessDenied = () => {
  return (
    <Box >
      <Typography variant="h2">Access Denied</Typography>
      <Typography>Sorry, you don't have the necessary permissions to view this page.</Typography>
    </Box>
  )
}