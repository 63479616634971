import { Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    badge: {
        top: 5,
        right: 3,
    },
    paper: {
        height: 140,
        width: 100,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerText: {
        position: 'absolute' as 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    centerTextRel: {
        position: 'relative' as 'relative',
        transform: 'translate(35%, 300%)',
    },
    invalid: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
    },
    snackbarInvalidTop: {
        top: 0,
    },
    snackbarInvalidBottom: {
        bottom: 0
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
    padding: {
        padding: theme.spacing(1)
    },
    dataFromSelectors: {
        verticalAlign: 'middle'
    },
    invalidChip: {
        backgroundColor: 'red',
        color: 'white'
    },
    propsColumn: {
        backgroundColor: '#eeeeee',
    },
    disabledStepperLink: {
        cursor: 'not-allowed'
    },
    currentStepperLink: {
        cursor: 'default'
    },
    draggedOver: {
        border: '2px solid',
        borderColor: 'green',
    },
    container: {
        flexGrow: 1,
        position: 'relative' as 'relative',
    },
    paperDownshift: {
        position: 'absolute' as 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    dataTreeContainer: {
        height:'500px', 
        overflow: 'auto'
    },
    dataTreeSearch: {
        marginTop:'5px'
    },
    toggleContainer: {
        height: 56,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: theme.palette.background.default,
    },
    dataTree: {
        width: '100%',
        backgroundColor: 'white',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    draggable: {
        cursor: 'move'
    },
    selected: {
        backgroundColor: theme.palette.primary.light,
    },
    emailToolbar: { 
        display: 'inline-flex', 
        width: '100%' 
    },
    emailPathLink: { 
        alignSelf: 'center' 
    }
});