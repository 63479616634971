import * as React from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import onClickOutside from "react-onclickoutside";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Fade,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Popper from "@material-ui/core/Popper";
import Alert from "@material-ui/lab/Alert";
import {
  DataFormat,
  DataFormatDisplayName,
} from "../../Common/Enums/DataFormat";
import LayersIcon from "@material-ui/icons/Layers";
import DragIcon from "@material-ui/icons/DragIndicator";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportIcon from "@material-ui/icons/ArrowBack";
import TickIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DashIcon from "@material-ui/icons/Remove";
import { depthOf } from "../../Common/Helpers/ObjectHelpers";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import classNames from "classnames";
import { IJSONPathMatch } from "../../Common/Interfaces/IJSONPathMatch";
import PropertyHelpers from "../../Common/Helpers/PropertyHelpers";
import { MapType } from "../../Common/Enums/MapType";
import DataTreeContext from "./DataTreeContext";
import Prettifier from "../../Common/Helpers/Prettifier";
import AceInputContext from "../EndpointWizard/Context/AceInputContext";
import TaskContext from "../EndpointWizard/Context/Task.Context";

interface IProps extends IComponentProps {
  data: {} | [] | Document;
  mode: DataFormat;
  context: EndpointWizardContext;
  draggable?: boolean;
  deletable: boolean;
  importable: boolean;
  previewable: boolean;
  dataOrigin?: number;
  searchTerm?: string;
  id?: string;
}

@observer
class DataTree extends React.Component<IProps> {
  private readonly _context = new DataTreeContext();

  @observable
  private snackbarOpen: boolean = false;

  private onClick = (e: React.MouseEvent, key: string) => {
    e.stopPropagation();
    this.props.context.draggableKey = key;
  };

  private getFullPropertyPath(
    element: Element | Attr | string,
    parentIsArray: boolean,
    parentPath: string,
    xPathIdx?: number
  ): { key: string; path: string } {
    if (element instanceof Element) {
      const task = this.props.context.tasks[this.props.context.activeTask];
      const elementHasChildren = element.childElementCount > 0;

      const propertyPath = PropertyHelpers.getPathToProperty(
        `${this.getPathForElement(element, xPathIdx)}${
          task.output.mapType === MapType.Path && !elementHasChildren
            ? "/text()"
            : ""
        }`,
        parentIsArray,
        this.props.mode,
        parentPath
      );

      return { key: element.localName, path: propertyPath };
    } else if (element instanceof Attr) {
      const propertyPath = PropertyHelpers.getPathToProperty(
        `@${element.name}`,
        parentIsArray,
        this.props.mode,
        parentPath
      );

      return { key: element.name, path: propertyPath };
    } else {
      const propertyPath = PropertyHelpers.getPathToProperty(
        element,
        parentIsArray,
        this.props.mode,
        parentPath
      );
      return { key: element, path: propertyPath };
    }
  }

  private onDragStart = (
    e: React.DragEvent,
    element: Element | Attr | string,
    parentIsArray: boolean,
    parentPath: string,
    xPathIdx?: number,
    currentElements: string[] = []
  ) => {
    e.stopPropagation();

    this.displayWarning(element, currentElements);

    this.props.context.draggedFunction = undefined;
    const { key, path } = this.getFullPropertyPath(
      element,
      parentIsArray,
      parentPath,
      xPathIdx
    );
    this.props.context.draggedProperty = {
      name: key,
      path: path,
      origin: this.props.dataOrigin,
    };
    e.dataTransfer.setData(
      "text/plain",
      this.getPropertyToken(
        this.props.context.tasks[this.props.context.activeTask],
        key,
        this.props.context.draggedProperty.path,
        true
      )
    );
  };

  private onClickImport = (
    element: Element | Attr | string,
    parentIsArray: boolean,
    parentPath: string,
    xPathIdx: number = 1,
    currentElements: string[] = []
  ) => {
    const task = this.props.context.tasks[this.props.context.activeTask];

    this.displayWarning(element, currentElements);

    const { key, path } = this.getFullPropertyPath(
      element,
      parentIsArray,
      parentPath,
      xPathIdx
    );

    if (task.activeTextInput) {
      task.activeTextInput.addToken(
        this.getPropertyToken(task, key, path, false)
      );
    }
  };

  private displayWarning = (
    element: Element | Attr | string,
    currentElements: string[] = []
  ) => {
    if (element instanceof Element && !!currentElements.length) {
      const elementHasChildren = element.childElementCount > 0;
      const elementName = element.localName;

      const containsDuplicates =
        currentElements!.filter((el) => el === elementName).length > 1;

      if (!elementHasChildren && containsDuplicates) {
        this.snackbarOpen = true;
      }
    }
  };

  private getPropertyToken = (
    task: TaskContext,
    key: string,
    propertyPath: string,
    isDrag: boolean
  ) => {
    let propertyToken: string;
    if (
      task.output.mapType === MapType.Path ||
      this.props.context.tasks[this.props.context.activeTask]
        .activeTextInput instanceof
        AceInputContext ===
        false
    ) {
      const uniqueProperty = task.getUniquePropertyTokenName(key);
      if (isDrag === false) {
        task.setAddedProperty(
          uniqueProperty,
          propertyPath,
          this.props.dataOrigin
        );
      }
      propertyToken = PropertyHelpers.getPropertyToken(uniqueProperty);
    } else {
      propertyToken = `<xsl:value-of select="${propertyPath}"/>`;
    }

    return propertyToken;
  };

  private onClickPreview = (
    event: React.MouseEvent<HTMLElement>,
    data: string,
    format: DataFormat
  ) => {
    if ([DataFormat.HL7FHIRXML, DataFormat.XML].includes(format)) {
      this._context.preview = Prettifier.prettify(data, format);
    } else {
      this._context.preview = Prettifier.prettify(JSON.stringify(data), format);
    }
    this._context.previewAnchorEl = event.currentTarget;
  };

  public handleClickOutside = () => {
    this._context.previewAnchorEl = undefined;
  };

  private isMatch(itemName?: string, searchTerm?: string): boolean {
    var search = searchTerm ?? "";
    var item = itemName ?? "";
    return (
      search.length > 0 && // Must have search term
      item.toLowerCase().includes(search.toLowerCase())
    );
  }

  private isAnyChildASearchMatch = (
    data: {} | [] | Document,
    searchTerm: string
  ) => {
    if (data && typeof data === "object") {
      // We are looking for child items, so only objects are useful
      var keyArray = Array.from(Object.keys(data));
      if (
        keyArray.some(
          (key) =>
            this.isMatch(key, searchTerm) ||
            this.isAnyChildASearchMatch((data as any)[key], searchTerm)
        )
      )
        return true;
    }
    return false;
  };

  private isAnyChildElementASearchMatch = (
    data: Element,
    searchTerm: string
  ) => {
    if (data) {
      var elementArray = Array.from(data.children);
      if (
        elementArray.some(
          (e) =>
            this.isMatch(e!.localName, searchTerm) ||
            (e && this.isAnyChildElementASearchMatch(e, searchTerm))
        )
      )
        return true;

      var attributeArray = Array.from(data.attributes);
      if (attributeArray.some((a) => this.isMatch(a?.localName, searchTerm)))
        return true;
    }
    return false;
  };

  private renderDataRecursively = (
    data: {} | [] | Document,
    root: boolean,
    parentIsArray: boolean,
    parentIsSearchMatch: boolean,
    pathToProperty?: string
  ) => {
    return (
      <List
        id={this.props.id}
        disablePadding
        className={this.props.classes.dataTree}
        dense={this.isDense()}
      >
        {data
          ? Object.keys(data).map((key, index) => {
              var nodeIsSearchMatch =
                parentIsSearchMatch || this.isMatch(key, this.props.searchTerm);

              if (
                !nodeIsSearchMatch &&
                !!this.props.searchTerm &&
                !this.isAnyChildASearchMatch(
                  (data as any)[key],
                  this.props.searchTerm
                )
              )
                return "";

              if (
                (data as any)[key] &&
                typeof (data as any)[key] === "object"
              ) {
                return (
                  <div
                    key={index}
                    draggable={
                      this.props.draggable &&
                      this.props.context.draggableKey === key
                    }
                    onMouseDown={(e) => this.onClick(e, key)}
                    onDragStart={(e) =>
                      this.onDragStart(
                        e,
                        key,
                        parentIsArray,
                        pathToProperty as string
                      )
                    }
                    className={classNames(
                      root === false ? this.props.classes.nested : null,
                      this.props.draggable ? this.props.classes.draggable : null
                    )}
                  >
                    <ListItem
                      className={
                        this.props.context.tasks[this.props.context.activeTask]
                          .editingPropertyToken &&
                        (
                          this.props.context
                            .editingPathMatches as IJSONPathMatch[]
                        ).some(
                          (x) =>
                            x.parentProperty === key &&
                            x.value === (data as any)[key]
                        )
                          ? this.props.classes.selected
                          : null
                      }
                    >
                      {this.props.context.tasks[this.props.context.activeTask]
                        .editingPropertyToken !== undefined ? (
                        (
                          this.props.context
                            .editingPathMatches as IJSONPathMatch[]
                        ).some(
                          (x) =>
                            x.parentProperty === key &&
                            x.value === (data as any)[key]
                        ) ? (
                          <ListItemIcon>
                            <TickIcon />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon>
                            <DashIcon />
                          </ListItemIcon>
                        )
                      ) : this.props.draggable ? (
                        <ListItemIcon>
                          <DragIcon />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon>
                          <LayersIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={parentIsArray ? `[${key}]` : key}
                        secondary={
                          (data as any)[key].constructor === Array
                            ? `${DataFormatDisplayName(this.props.mode)} Array`
                            : `${DataFormatDisplayName(this.props.mode)} Object`
                        }
                      />
                      <ListItemSecondaryAction>
                        {this.props.previewable && (
                          <IconButton
                            onClick={(event) =>
                              this.onClickPreview(
                                event,
                                (data as any)[key],
                                this.props.mode
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )}
                        {this.props.importable && (
                          <IconButton
                            id={`import-property-${key}`}
                            onClick={() =>
                              this.onClickImport(
                                key,
                                parentIsArray,
                                pathToProperty as string
                              )
                            }
                          >
                            <ImportIcon />
                          </IconButton>
                        )}
                        {this.props.deletable && (
                          <IconButton
                            onClick={() => this.removeJsonProperty(data, key)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    {this.renderDataRecursively(
                      (data as any)[key],
                      false,
                      (data as any)[key].constructor === Array,
                      nodeIsSearchMatch,
                      PropertyHelpers.getPathToProperty(
                        key,
                        parentIsArray,
                        this.props.mode,
                        pathToProperty
                      )
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    className={classNames(
                      root === false ? this.props.classes.nested : null,
                      this.props.draggable ? this.props.classes.draggable : null
                    )}
                    draggable={
                      this.props.draggable &&
                      this.props.context.draggableKey === key
                    }
                    onMouseDown={(e) => this.onClick(e, key)}
                    onDragStart={(e) =>
                      this.onDragStart(
                        e,
                        key,
                        parentIsArray,
                        pathToProperty as string
                      )
                    }
                    key={index}
                  >
                    <ListItem
                      className={
                        this.props.context.tasks[this.props.context.activeTask]
                          .editingPropertyToken &&
                        (
                          this.props.context
                            .editingPathMatches as IJSONPathMatch[]
                        ).some(
                          (x) =>
                            x.parentProperty === key &&
                            x.value === (data as any)[key]
                        )
                          ? this.props.classes.selected
                          : null
                      }
                    >
                      {this.props.context.tasks[this.props.context.activeTask]
                        .editingPropertyToken !== undefined ? (
                        (
                          this.props.context
                            .editingPathMatches as IJSONPathMatch[]
                        ).some(
                          (x) =>
                            x.parentProperty === key &&
                            x.value === (data as any)[key]
                        ) ? (
                          <ListItemIcon>
                            <TickIcon />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon>
                            <DashIcon />
                          </ListItemIcon>
                        )
                      ) : this.props.draggable ? (
                        <ListItemIcon>
                          <DragIcon />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon>
                          <LayersIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={parentIsArray ? `[${key}]` : key}
                        secondary={`${
                          [
                            DataFormat.JSON,
                            DataFormat.HL7FHIRJSON,
                            DataFormat.Callback,
                          ].includes(this.props.mode)
                            ? `${DataFormatDisplayName(this.props.mode)} ${
                                parentIsArray ? "Array Item" : "Property"
                              }`
                            : this.props.mode === DataFormat.HL7v2
                            ? "HL7v2 Field Value"
                            : `QueryString ${
                                parentIsArray ? "Array Item" : "Property"
                              }`
                        }`}
                      />
                      <ListItemSecondaryAction>
                        {this.props.previewable && (
                          <IconButton
                            onClick={(event) =>
                              this.onClickPreview(
                                event,
                                (data as any)[key],
                                this.props.mode
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )}
                        {this.props.importable && (
                          <IconButton
                            id={`import-property-${key}`}
                            onClick={() =>
                              this.onClickImport(
                                key,
                                parentIsArray,
                                pathToProperty as string
                              )
                            }
                          >
                            <ImportIcon />
                          </IconButton>
                        )}
                        {this.props.deletable && (
                          <IconButton
                            onClick={() =>
                              parentIsArray
                                ? this.removeJsonProperty(data, key, true)
                                : this.removeJsonProperty(data, key)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                );
              }
            })
          : null}
      </List>
    );
  };

  private getPathForElement(element: Element, xPathIdx: number = 1): string {
    if (element) {
      return element.namespaceURI
        ? `*[local-name()="${element.localName}" and namespace-uri()='${element.namespaceURI}'][${xPathIdx}]`
        : `${element.localName}[${xPathIdx}]`;
    }
    return "";
  }

  @action
  private renderXml = (data: Document) => {
    if (data) {
      const path = this.getPathForElement(data.documentElement);

      return (
        <List
          disablePadding
          dense={this.isDense()}
          className={this.props.classes.dataTree}
        >
          {data.documentElement && (
            <div
              className={classNames(
                this.props.draggable ? this.props.classes.draggable : null
              )}
              draggable={this.props.draggable}
              onMouseDown={(e) =>
                this.onClick(e, data.documentElement.localName)
              }
              onDragStart={(e) =>
                this.onDragStart(e, data.documentElement, false, path)
              }
            >
              <ListItem
                className={this.getSelectedXmlNodeClass(data.documentElement)}
              >
                {this.getXmlNodeIcon(data)}
                <ListItemText
                  primary={data.documentElement.localName}
                  secondary={`${DataFormatDisplayName(
                    this.props.mode
                  )} Element`}
                />
                <ListItemSecondaryAction>
                  {this.props.previewable && (
                    <IconButton
                      onClick={(event) =>
                        this.onClickPreview(
                          event,
                          data.documentElement.innerHTML,
                          this.props.mode
                        )
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  )}
                  {this.props.importable && (
                    <IconButton
                      id={`import-property-${data.documentElement.localName}`}
                      onClick={() =>
                        this.onClickImport(data.documentElement, false, "")
                      }
                    >
                      <ImportIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              {this.renderXmlRecursively(
                data.documentElement,
                false,
                PropertyHelpers.getXMLPathToProperty(
                  data.documentElement,
                  false,
                  ""
                )
              )}
            </div>
          )}
        </List>
      );
    }
  };

  private renderXmlRecursively = (
    data: Element,
    parentIsSearchMatch: boolean,
    pathToProperty?: string
  ) => {
    const currentElements = Array.from(data.children).map(
      (node) => node.localName
    );

    const isElementsUnique =
      !!currentElements.length &&
      currentElements.length === new Set(currentElements).size;

    return (
      <List
        disablePadding
        dense={this.isDense()}
        className={this.props.classes.dataTree}
      >
        {this.renderXmlAttributes(data.attributes, pathToProperty as string)}

        {Array.from({ length: data.childElementCount }, (v, k) => k).map(
          (value) => {
            var element = data.children.item(value);
            const elementName = element!.localName;

            let xPathIdx = PropertyHelpers.generateXPathIdx(
              value,
              elementName,
              currentElements
            );

            var nodeIsSearchMatch =
              parentIsSearchMatch ||
              this.isMatch(elementName, this.props.searchTerm);

            if (
              !nodeIsSearchMatch &&
              !!this.props.searchTerm &&
              !!element &&
              !this.isAnyChildElementASearchMatch(
                element,
                this.props.searchTerm
              )
            )
              return "";

            const importId = `${pathToProperty}-${this.getPathForElement(
              data.children.item(value)!,
              xPathIdx
            )}`
              //eslint-disable-next-line no-useless-escape
              .replace(/[\/]+/g, "-")
              //eslint-disable-next-line no-useless-escape
              .replace(/[\[\]']+/g, "");

            return (
              <div
                draggable={this.props.draggable}
                onDragStart={(e) =>
                  this.onDragStart(
                    e,
                    data.children.item(value)!,
                    false,
                    pathToProperty as string,
                    xPathIdx,
                    currentElements
                  )
                }
                key={value}
                onMouseDown={(e) =>
                  this.onClick(
                    e,
                    this.getPathForElement(data.children.item(value)!, xPathIdx)
                  )
                }
                className={classNames(
                  this.props.classes.nested,
                  this.props.draggable ? this.props.classes.draggable : null
                )}
              >
                <ListItem
                  className={this.getSelectedXmlNodeClass(
                    data.children.item(value) as Element
                  )}
                >
                  {this.getXmlNodeIcon(data.children.item(value) as Element)}
                  <ListItemText
                    primary={elementName}
                    secondary={`${DataFormatDisplayName(
                      this.props.mode
                    )} Element`}
                  />
                  <ListItemSecondaryAction>
                    {this.props.previewable && (
                      <IconButton
                        onClick={(event) =>
                          this.onClickPreview(
                            event,
                            data.children.item(value)!.innerHTML,
                            this.props.mode
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                    {this.props.importable && (
                      <IconButton
                        id={`import-element${importId}`}
                        onClick={() =>
                          this.onClickImport(
                            data.children.item(value)!,
                            false,
                            pathToProperty as string,
                            xPathIdx,
                            currentElements
                          )
                        }
                      >
                        <ImportIcon />
                      </IconButton>
                    )}
                    {this.props.deletable && (
                      <IconButton
                        onClick={() => this.removeXmlElement(data, value)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {this.renderXmlRecursively(
                  data.children.item(value) as Element,
                  nodeIsSearchMatch,
                  PropertyHelpers.getXMLPathToProperty(
                    data.children.item(value)!,
                    false,
                    pathToProperty,
                    isElementsUnique,
                    xPathIdx
                  )
                )}
              </div>
            );
          }
        )}
      </List>
    );
  };

  private renderXmlAttributes = (
    attributes: NamedNodeMap,
    pathToProperty: string
  ) => {
    return (
      attributes.length > 0 &&
      Array.from({ length: attributes.length }, (v, k) => k).map((value) => {
        return (
          <ListItem
            draggable={this.props.draggable}
            onMouseDown={(e) => this.onClick(e, attributes.item(value)!.name)}
            onDragStart={(e) =>
              this.onDragStart(
                e,
                attributes.item(value)!,
                false,
                pathToProperty as string
              )
            }
            className={classNames(
              this.props.classes.nested,
              this.getSelectedXmlAttributeClass(attributes, value)
            )}
            key={value}
          >
            {this.getXmlAttributeIcon(attributes, value)}
            <ListItemText
              primary={attributes.item(value)!.name}
              secondary={`${DataFormatDisplayName(this.props.mode)} Attribute`}
            />
            <ListItemSecondaryAction>
              {this.props.previewable && (
                <IconButton
                  onClick={(event) =>
                    this.onClickPreview(
                      event,
                      attributes.item(value)!.value,
                      this.props.mode
                    )
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              )}
              {this.props.importable && (
                <IconButton
                  onClick={() =>
                    this.onClickImport(
                      attributes.item(value)!,
                      false,
                      pathToProperty as string
                    )
                  }
                >
                  <ImportIcon />
                </IconButton>
              )}
              {this.props.deletable && (
                <IconButton
                  onClick={() =>
                    this.removeXmlAttribute(
                      attributes,
                      attributes.item(value)!.name
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })
    );
  };

  private isXmlNodeSelected = (
    compareNode: Element,
    selectedNode: Document | Element
  ) => {
    // check if the node text has been selected, or the node itself
    if (selectedNode.nodeType === 3) {
      // 3 is TEXT_NODE
      return (
        compareNode.nodeName === selectedNode.parentNode!.nodeName &&
        compareNode.textContent === selectedNode.parentNode!.textContent
      );
    } else {
      return (
        compareNode.nodeName === selectedNode!.nodeName &&
        compareNode.innerHTML === (selectedNode as Element)!.innerHTML
      );
    }
  };

  private getSelectedXmlNodeClass = (compareNode: Element) => {
    if (Array.isArray(this.props.context.editingPathMatches)) {
      if (
        this.props.context.tasks[this.props.context.activeTask]
          .editingPropertyToken &&
        (this.props.context.editingPathMatches as HTMLElement[]).some((x) =>
          this.isXmlNodeSelected(compareNode, x)
        )
      ) {
        return this.props.classes.selected;
      }
    }
  };

  private getSelectedXmlAttributeClass = (
    attributes: NamedNodeMap,
    attributeIndex: number
  ) => {
    if (Array.isArray(this.props.context.editingPathMatches)) {
      if (
        this.props.context.tasks[this.props.context.activeTask]
          .editingPropertyToken &&
        (this.props.context.editingPathMatches as Attr[]).some(
          (x) =>
            x.nodeName === attributes.item(attributeIndex)!.name &&
            x.value === attributes.item(attributeIndex)!.value
        )
      )
        return this.props.classes.selected;
    }
  };

  private getXmlNodeIcon = (compareNode: Document | Element) => {
    if (
      compareNode &&
      Array.isArray(this.props.context.editingPathMatches) &&
      this.props.context.tasks[this.props.context.activeTask]
        .editingPropertyToken !== undefined
    ) {
      return (this.props.context.editingPathMatches as HTMLElement[]).some(
        (x) => this.isXmlNodeSelected(x, compareNode)
      ) ? (
        <ListItemIcon>
          <TickIcon />
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <DashIcon />
        </ListItemIcon>
      );
    } else {
      return this.props.draggable ? (
        <ListItemIcon>
          <DragIcon />
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
      );
    }
  };

  private getXmlAttributeIcon = (
    attributes: NamedNodeMap,
    attributeIndex: number
  ) => {
    if (
      Array.isArray(this.props.context.editingPathMatches) &&
      this.props.context.tasks[this.props.context.activeTask]
        .editingPropertyToken !== undefined
    ) {
      return (this.props.context.editingPathMatches as Attr[]).some(
        (x) =>
          x.nodeName === attributes.item(attributeIndex)!.name &&
          x.value === attributes.item(attributeIndex)!.value
      ) ? (
        <ListItemIcon>
          <TickIcon />
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <DashIcon />
        </ListItemIcon>
      );
    } else {
      return this.props.draggable ? (
        <ListItemIcon>
          <DragIcon />
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
      );
    }
  };

  private removeJsonProperty = (
    data: any,
    key: string,
    isArrayItem: boolean = false
  ) => {
    if (isArrayItem) {
      (data as any).splice(key, 1);
    } else {
      delete (data as any)[key];
    }
    this.props.context.updateInputContent();
  };

  private removeXmlElement = (
    data: Element | Document,
    index: number = 0,
    root: boolean = false
  ) => {
    if (root) {
      data = new Document();
    } else {
      data.removeChild(data.childNodes.item(index));
    }
    this.props.context.updateInputContent();
  };

  private removeXmlAttribute = (
    attributes: NamedNodeMap,
    attribute: string
  ) => {
    attributes.removeNamedItem(attribute);
    this.props.context.updateInputContent();
  };

  private isDense = (): boolean => {
    //todo: not working properly
    if (this.props.mode === DataFormat.JSON) {
      return depthOf(this.props.data) >= 5;
    } else if (this.props.mode === DataFormat.XML) {
      return false; //todo: xml
    }
    return false; // must be QueryString, which is flat
  };

  private handleSnackBarClose = (): void => {
    this.snackbarOpen = false;
  };

  public render() {
    return (
      <React.Fragment>
        {[DataFormat.XML, DataFormat.HL7FHIRXML].includes(this.props.mode)
          ? this.renderXml(this.props.data as Document)
          : this.renderDataRecursively(this.props.data, true, false, false)}
        <Popper
          style={{ zIndex: 1000 }}
          open={this._context.previewAnchorEl !== undefined}
          anchorEl={this._context.previewAnchorEl}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography className={this.props.classes.margin}>
                  {this._context.preview!.split("\n").map((i, key) => {
                    return <div key={key}>{i}</div>;
                  })}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
        <Snackbar
          id="snackbar-xpath-index-info"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.snackbarOpen}
          onClose={this.handleSnackBarClose}
          autoHideDuration={10000}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
          <Alert severity="info" onClose={this.handleSnackBarClose}>
            The XPath generated contains an index. This relies on consistent
            ordering which might not be appropriate for your use case. You may
            need to edit the path to contain a more specific predicate.
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

export default onClickOutside(DataTree);
