import "react-quill/dist/quill.snow.css";

import * as React from "react";

import { Button, Grid, TextField } from "@material-ui/core";

import FormatValidator from "../../Common/Helpers/FormatValidator";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import PropertyEditor from "../PathEditor/PropertyEditor";
import { observer } from "mobx-react";

const SftpOutput: React.FC<IOutputProps> = observer(
  ({ context, task, onDrop, onClickEditPath, classes, appContext }) => {
    const urlRef = React.createRef<HTMLInputElement>();
    const usernameRef = React.createRef<HTMLInputElement>();
    const passwordRef = React.createRef<HTMLInputElement>();
    const filenameRef = React.createRef<HTMLInputElement>();
    const bodyRef = React.createRef<HTMLInputElement>();
    const portRef = React.createRef<HTMLInputElement>();

    return (
      <Grid item md={6} onDrop={onDrop}>
        {context.tasks[task].editingPropertyToken && (
          <PropertyEditor
            appContext={appContext}
            context={context}
            task={task}
          />
        )}
        {context.tasks[task].editingPropertyToken === undefined && (
          <React.Fragment>
            {context.tasks[task].output.loading ? (
              <Loading fullscreen={false} />
            ) : (
              <React.Fragment>
                <div className={classes.emailToolbar}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="sftp-url"
                        label="Url"
                        onChange={(e) =>
                          (context.tasks[task].sftpUrl.content = e.target.value)
                        }
                        placeholder="www.server.com"
                        inputRef={urlRef}
                        onSelect={(_) =>
                          (context.tasks[task].sftpUrl.cursorLocation =
                            urlRef.current &&
                            (urlRef.current.selectionStart as number))
                        }
                        error={!context.tasks[task].sftpUrl.content}
                        onFocus={(_) =>
                          (context.tasks[task].activeTextInput =
                            context.tasks[task].sftpUrl)
                        }
                        required={true}
                        fullWidth
                        value={context.tasks[task].sftpUrl.content}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="sftp-port"
                        label="Port"
                        onChange={(e) =>
                          (context.tasks[task].sftpPort.content =
                            e.target.value)
                        }
                        placeholder="22"
                        inputRef={portRef}
                        onSelect={(_) =>
                          (context.tasks[task].sftpPort.cursorLocation =
                            portRef.current &&
                            (portRef.current.selectionStart as number))
                        }
                        error={
                          !FormatValidator.port(
                            context.tasks[task].sftpPort.content
                          )
                        }
                        onFocus={(_) =>
                          (context.tasks[task].activeTextInput =
                            context.tasks[task].sftpPort)
                        }
                        required={true}
                        fullWidth
                        value={context.tasks[task].sftpPort.content}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="sftp-filename"
                        label="Filename"
                        onChange={(e) =>
                          (context.tasks[task].sftpFilename.content =
                            e.target.value)
                        }
                        placeholder="name.dat"
                        inputRef={filenameRef}
                        onSelect={(_) =>
                          (context.tasks[task].sftpFilename.cursorLocation =
                            urlRef.current &&
                            (urlRef.current.selectionStart as number))
                        }
                        error={
                          !FormatValidator.filename(
                            context.tasks[task].sftpFilename.content
                          )
                        }
                        onFocus={(_) =>
                          (context.tasks[task].activeTextInput =
                            context.tasks[task].sftpFilename)
                        }
                        required={true}
                        fullWidth
                        value={context.tasks[task].sftpFilename.content}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="sftp-username"
                        label="Username"
                        onChange={(e) =>
                          (context.tasks[task].sftpUsername.content =
                            e.target.value)
                        }
                        placeholder="username"
                        inputRef={usernameRef}
                        onSelect={(_) =>
                          (context.tasks[task].sftpUsername.cursorLocation =
                            urlRef.current &&
                            (urlRef.current.selectionStart as number))
                        }
                        onFocus={(_) =>
                          (context.tasks[task].activeTextInput =
                            context.tasks[task].sftpUsername)
                        }
                        required={true}
                        fullWidth
                        value={context.tasks[task].sftpUsername.content}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MappablePasswordSetter
                        passwordField={context.tasks[task].sftpPassword}
                        onFocus={(_) => {
                          context.tasks[task].activeTextInput =
                            context.tasks[task].sftpPassword;
                        }}
                        inputRef={passwordRef}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  {context.tasks[task].selectedPropertyToken && (
                    <div className={classes.emailPathLink}>
                      <Button
                        className={classes.margin}
                        onClick={onClickEditPath}
                        variant={"text"}
                      >
                        <LinkIcon />
                      </Button>
                    </div>
                  )}
                </div>
                <TextField
                  id="sftp-content"
                  label="File (Base 64)"
                  multiline
                  style={{ height: "100%", width: "100%" }}
                  rows="23"
                  value={context.tasks[task].sftpBody.content}
                  inputRef={bodyRef}
                  onSelect={(_) =>
                    (context.tasks[task].sftpBody.cursorLocation =
                      bodyRef.current &&
                      (bodyRef.current.selectionStart as number))
                  }
                  onChange={(e) =>
                    (context.tasks[task].sftpBody.content = e.target.value)
                  }
                  onFocus={(_) =>
                    (context.tasks[task].activeTextInput =
                      context.tasks[task].sftpBody)
                  }
                  className={classes.marginTop}
                  variant="filled"
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Grid>
    );
  }
);

export default SftpOutput;
