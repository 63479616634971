import * as React from "react";
import { observer } from "mobx-react";
import ReactQuill, { Quill } from "react-quill";
import { appStyles } from "../../App.Styles";
import { withStyles } from "@material-ui/core";
const Delta = Quill.import("delta");
const Break = Quill.import("blots/break");
const Embed = Quill.import("blots/embed");

class SmartBreak extends Break {
  length() {
    return 1;
  }
  value() {
    return "\n";
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

Quill.register(SmartBreak, true);

const modules = {
  clipboard: {
    matchers: [
      [
        "BR",
        () => {
          let newDelta = new Delta();
          newDelta.insert({ break: "" });
          return newDelta;
        },
      ],
    ],
    matchVisual: false,
  },
  keyboard: {
    bindings: {
      linebreak: {
        key: 13,
        shiftKey: true,
        handler: function (range) {
          SmartBreak.blotName = "break";
          SmartBreak.tagName = "BR";
          const quill: Quill = (this as any).quill;
          const currentLeaf = quill.getLeaf(range.index)[0];
          const nextLeaf = quill.getLeaf(range.index + 1)[0];
          quill.insertEmbed(range.index, "break", true, "user");
          if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
            quill.insertEmbed(range.index, "break", true, "user");
          }
          quill.setSelection(range.index + 1, "silent");
        },
      },
    },
  },
};

interface IProps {
  setRef: (ref: ReactQuill) => void;
  id: string;
  style?: any;
  className?: string;
  defaultValue?: string;
  onChange: (code: string) => void;
  onFocus: () => void;
  onChangeSelection: (range: any) => void;
}

const QuillWrapper: React.FC<IProps> = observer(
  ({
    setRef,
    id,
    style,
    className,
    defaultValue,
    onChange,
    onFocus,
    onChangeSelection,
  }) => {
    return (
      <ReactQuill
        modules={modules}
        id={id}
        theme="snow"
        ref={setRef}
        style={style}
        className={className}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onChangeSelection={onChangeSelection}
      />
    );
  }
);

export default withStyles(appStyles, { withTheme: true })(QuillWrapper);
