import { AuthenticationType } from "../Enums/AuthenticationType";

export default class AuthenticationTypeHelpers {
    public static GetDisplayName(type: AuthenticationType) {
        switch (type) {
            case AuthenticationType.APIKey:
                return "API Key";
            case AuthenticationType.InternalOnly:
                return "Internal Only";
            case AuthenticationType.JWT:
                return "JWT";
            case AuthenticationType.IP:
                return "IP Address/Range";
            case AuthenticationType.Basic:
                return "Basic Auth"
            case AuthenticationType.ClientCredentials:
                return "Client Credentials"
            default:
                return type;
        }
    }
}