import "react-quill/dist/quill.snow.css";
import { Alert } from "@material-ui/lab";

import * as React from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";

import AceEditor from "react-ace";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import CursorLocation from "../EndpointWizard/CursorLocation";
import EmailOutputSmtp from "./EmailOutputSmtp";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import Help from "../Help/Help";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import PropertyEditor from "../PathEditor/PropertyEditor";
import QuillWrapper from "../QuillWrapper/QuillWrapper";
import ReactQuill from "react-quill";
import { observer } from "mobx-react";

@observer
export default class EmailOutput extends React.Component<IOutputProps> {
  private readonly task =
    this.props.context.tasks[this.props.context.activeTask];
  private subjectRef = React.createRef<HTMLInputElement>();
  private toRef = React.createRef<HTMLInputElement>();
  private bccRef = React.createRef<HTMLInputElement>();
  private fromRef = React.createRef<HTMLInputElement>();
  private attachmentRef = React.createRef<HTMLInputElement>();
  private attachmentMimeRef = React.createRef<HTMLInputElement>();
  private archivenameRef = React.createRef<HTMLInputElement>();
  private attachmentFilenameRef = React.createRef<HTMLInputElement>();
  private plaintextBodyRef = React.createRef<HTMLInputElement>();
  private archivePasswordRef = React.createRef<HTMLInputElement>();

  private fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.task.attachmentIsFile = true;
    const file = event.target.files![0];

    if (file) {
      this.task.emailAttachmentFilename.content = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent: ProgressEvent) => {
        const fileReader = readerEvent.target as FileReader;
        this.task.emailAttachment.content = (
          fileReader.result as string
        ).substring("data:application/pdf;base64,".length);
        this.task.emailAttachmentMime.content = file.type;
      };
    }
  };

  private clearAttachmentFile = () => {
    this.task.emailAttachment.content = "";
    this.task.emailAttachmentFilename.content = "";
    this.task.emailAttachmentMime.content = "";
    this.task.attachmentIsFile = undefined;
  };

  private attachmentBytesHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.task.attachmentIsFile = event.target.value === "" ? undefined : false;
    this.task.emailAttachment.content = event.target.value;
  };

  private showFrom = (): boolean => {
    return (
      this.props.appContext.accountDetails?.useCustomSmtp ||
      this.task.emailUseCustomSmtp
    );
  };

  private showEmailIgAlert = (): boolean => {
    let tokenRegex = /--\*.+\*--/;

    return (
      this.task.emailTo.content.includes(";") ||
      tokenRegex.test(this.task.emailTo.content) ||
      (this.task.emailTo.content !== "" && this.task.emailBcc.content !== "")
    );
  };

  private showNoRecipientsError = (): boolean => {
    return (
      this.task.emailTo.content === "" && this.task.emailBcc.content === ""
    );
  };

  public render() {
    return (
      <Grid item md={6}>
        {this.task.editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.task.editingPropertyToken === undefined && (
          <React.Fragment>
            {this.task.output.loading ? (
              <Loading fullscreen={false} />
            ) : (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      id="email-to"
                      onDrop={this.props.onDrop}
                      autoFocus={true}
                      label="To"
                      inputRef={this.toRef}
                      error={
                        !FormatValidator.optionalEmail(
                          this.task.emailTo.content
                        ) || this.showNoRecipientsError()
                      }
                      onChange={(e) =>
                        (this.task.emailTo.content = e.target.value)
                      }
                      onSelect={(_) =>
                        (this.task.emailTo.cursorLocation =
                          this.toRef.current &&
                          (this.toRef.current.selectionStart as number))
                      }
                      onFocus={(_) => {
                        this.task.activeTextInput = this.task.emailTo;
                      }}
                      placeholder="test@example.com"
                      value={this.task.emailTo.content}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="email-bcc"
                      onDrop={this.props.onDrop}
                      label="BCC"
                      inputRef={this.bccRef}
                      error={
                        !FormatValidator.optionalEmail(
                          this.task.emailBcc.content
                        ) || this.showNoRecipientsError()
                      }
                      onChange={(e) =>
                        (this.task.emailBcc.content = e.target.value)
                      }
                      onSelect={(_) =>
                        (this.task.emailBcc.cursorLocation =
                          this.bccRef.current &&
                          (this.bccRef.current.selectionStart as number))
                      }
                      onFocus={(_) => {
                        this.task.activeTextInput = this.task.emailBcc;
                      }}
                      placeholder="test-bcc@example.com"
                      value={this.task.emailBcc.content}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {this.showNoRecipientsError() && (
                    <Grid item xs={12}>
                      <FormHelperText className={this.props.classes.marginLeft}>
                        You must enter at least one address in either the To or
                        BCC field
                      </FormHelperText>
                    </Grid>
                  )}
                  {this.showEmailIgAlert() && (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        All recipients in the To field will see each others'
                        addresses, and recipients in the BCC field will see any
                        addresses in the To field. This presents a potential IG
                        risk. Please contact support@airelogic.com if you
                        require support with this.
                      </Alert>
                    </Grid>
                  )}
                  {this.showFrom() && (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          id="email-from"
                          onDrop={this.props.onDrop}
                          label="From"
                          inputRef={this.fromRef}
                          error={
                            !FormatValidator.email(this.task.emailFrom.content)
                          }
                          onChange={(e) =>
                            (this.task.emailFrom.content = e.target.value)
                          }
                          onSelect={(_) =>
                            (this.task.emailFrom.cursorLocation =
                              this.fromRef.current &&
                              (this.fromRef.current.selectionStart as number))
                          }
                          onFocus={(_) => {
                            this.task.activeTextInput = this.task.emailFrom;
                          }}
                          placeholder="Used with custom SMTP"
                          value={this.task.emailFrom.content}
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={6}></Grid>
                    </>
                  )}

                  <Grid item xs={6}>
                    <div className={this.props.classes.emailToolbar}>
                      <TextField
                        id="email-subject"
                        onDrop={this.props.onDrop}
                        label="Subject"
                        inputRef={this.subjectRef}
                        onChange={(e) =>
                          (this.task.emailSubject.content = e.target.value)
                        }
                        onSelect={(_) =>
                          (this.task.emailSubject.cursorLocation =
                            this.subjectRef.current &&
                            (this.subjectRef.current.selectionStart as number))
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.emailSubject)
                        }
                        error={!this.task.emailSubject.content}
                        placeholder="My subject"
                        required={true}
                        fullWidth
                        value={this.task.emailSubject.content}
                        variant="outlined"
                      />

                      {this.task.selectedPropertyToken && (
                        <div className={this.props.classes.emailPathLink}>
                          <Button
                            className={this.props.classes.margin}
                            onClick={this.props.onClickEditPath}
                            variant={"text"}
                          >
                            <LinkIcon />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={this.props.classes.marginLeft}
                        onChange={() => {
                          this.task.isHtmlBody = !this.task.isHtmlBody;
                          this.task.output.modified = true;
                        }}
                        checked={this.task.isHtmlBody}
                      />
                    }
                    label="HTML body"
                  />
                  <Grid item xs={2}></Grid>
                  {!this.task.attachmentIsFile && (
                    <Grid
                      item
                      xs={this.task.attachmentIsFile === undefined ? 4 : 8}
                    >
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Attachment content as base64</InputLabel>
                        <Input
                          id="email-attachment"
                          type="text"
                          inputRef={this.attachmentRef}
                          value={this.task.emailAttachment.content}
                          onDrop={this.props.onDrop}
                          onChange={this.attachmentBytesHandler}
                          onSelect={(_) =>
                            (this.task.emailAttachment.cursorLocation =
                              this.attachmentRef.current &&
                              (this.attachmentRef.current
                                .selectionStart as number))
                          }
                          onFocus={(_) =>
                            (this.task.activeTextInput =
                              this.task.emailAttachment)
                          }
                          placeholder="Base64 Content"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton onClick={this.clearAttachmentFile}>
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {this.task.attachmentIsFile === undefined && (
                    <Grid item xs={1}>
                      <Typography align="center">or..</Typography>
                    </Grid>
                  )}
                  {(this.task.attachmentIsFile === true ||
                    this.task.attachmentIsFile === undefined) && (
                    <Grid
                      item
                      xs={this.task.attachmentIsFile === undefined ? 3 : 8}
                    >
                      <InputLabel htmlFor="attachmentFile">
                        Attachment file
                      </InputLabel>
                      <Input
                        className={this.props.classes.margin}
                        name="attachmentFile"
                        onChange={this.fileChangeHandler}
                        disableUnderline
                        type="file"
                      />
                      {this.task.emailAttachment.content && (
                        <Button onClick={this.clearAttachmentFile}>
                          <CloseIcon /> Clear
                        </Button>
                      )}
                    </Grid>
                  )}
                  <Grid item md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.props.classes.marginLeft}
                          onChange={() =>
                            (this.task.archiveAttachment =
                              !this.task.archiveAttachment)
                          }
                          checked={this.task.archiveAttachment}
                        />
                      }
                      label="Archive attachment"
                    />
                    <Help
                      classes={this.props.classes}
                      helpText="If selected, the attachment will be zipped into a compressed archive"
                    />
                    <br />
                    {this.task.isHtmlBody && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={this.props.classes.marginLeft}
                            onChange={() => {
                              this.task.isRawHtml = !this.task.isRawHtml;
                              this.task.output.modified = true;
                            }}
                            checked={this.task.isRawHtml}
                          />
                        }
                        label="Raw HTML"
                        id="raw-html"
                      />
                    )}
                  </Grid>
                  {this.task.emailAttachment.content &&
                    !this.task.attachmentIsFile && (
                      <React.Fragment>
                        <Grid item xs={6}>
                          <TextField
                            id="email-attachmentmime"
                            inputRef={this.attachmentMimeRef}
                            label="Attachment MIME Type"
                            onChange={(e) =>
                              (this.task.emailAttachmentMime.content =
                                e.target.value)
                            }
                            onSelect={(_) =>
                              (this.task.emailAttachmentMime.cursorLocation =
                                this.attachmentMimeRef.current &&
                                (this.attachmentMimeRef.current
                                  .selectionStart as number))
                            }
                            onFocus={(_) =>
                              (this.task.activeTextInput =
                                this.task.emailAttachmentMime)
                            }
                            onDrop={this.props.onDrop}
                            error={!this.task.emailAttachmentMime.content}
                            placeholder="MIME"
                            fullWidth
                            value={this.task.emailAttachmentMime.content}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="email-attachmentfilename"
                            inputRef={this.attachmentFilenameRef}
                            label="Attachment File Name"
                            onChange={(e) =>
                              (this.task.emailAttachmentFilename.content =
                                e.target.value)
                            }
                            onSelect={(_) =>
                              (this.task.emailAttachmentFilename.cursorLocation =
                                this.attachmentFilenameRef.current &&
                                (this.attachmentFilenameRef.current
                                  .selectionStart as number))
                            }
                            onFocus={(_) =>
                              (this.task.activeTextInput =
                                this.task.emailAttachmentFilename)
                            }
                            onDrop={this.props.onDrop}
                            error={!this.task.emailAttachmentFilename.content}
                            placeholder="Filename"
                            fullWidth
                            value={this.task.emailAttachmentFilename.content}
                            variant="outlined"
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  {this.task.archiveAttachment && (
                    <React.Fragment>
                      <Grid item xs={6}>
                        <TextField
                          id="email-archivename"
                          inputRef={this.archivenameRef}
                          label="Archive Filename"
                          onChange={(e) =>
                            (this.task.archiveFilename.content = e.target.value)
                          }
                          onSelect={(_) =>
                            (this.task.archiveFilename.cursorLocation =
                              this.archivenameRef.current &&
                              (this.archivenameRef.current
                                .selectionStart as number))
                          }
                          onFocus={(_) =>
                            (this.task.activeTextInput =
                              this.task.archiveFilename)
                          }
                          onDrop={this.props.onDrop}
                          error={!this.task.archiveFilename.content}
                          placeholder="Filename.zip"
                          fullWidth
                          value={this.task.archiveFilename.content}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MappablePasswordSetter
                          passwordField={this.task.archivePassword}
                          onDrop={this.props.onDrop}
                          onFocus={(_) => {
                            this.task.activeTextInput =
                              this.task.archivePassword;
                          }}
                          inputRef={this.archivePasswordRef}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                <EmailOutputSmtp {...this.props} />
                <br />
                <div onDrop={this.props.onDrop}>
                  {this.task.isHtmlBody ? (
                    <>
                      {this.task.isRawHtml ? (
                        <AceEditor
                          onChange={(code) => {
                            this.task.emailBodyHtmlRaw.content = code;
                            this.task.output.modified = true;
                          }}
                          value={this.task.emailBodyHtmlRaw.content}
                          mode="html"
                          showGutter={true}
                          onFocus={(_) =>
                            (this.task.activeTextInput =
                              this.task.emailBodyHtmlRaw)
                          }
                          onCursorChange={(e) =>
                            (this.task.emailBodyHtmlRaw.cursorLocation =
                              new CursorLocation(
                                e.getCursor().row,
                                e.getCursor().column
                              ))
                          }
                          width="100%"
                          highlightActiveLine={true}
                          fontSize={20}
                          theme="tomorrow"
                          name="input-editor"
                          setOptions={{
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 4,
                          }}
                        />
                      ) : (
                        <>
                          <QuillWrapper
                            id="email-body"
                            setRef={(ref) =>
                              (this.task.emailBodyHtml.editorRef =
                                ref as ReactQuill)
                            }
                            style={{ height: "446px" }}
                            className={this.props.classes.marginTop}
                            defaultValue={this.task.emailBodyHtml.content}
                            onChange={(code) => {
                              this.task.emailBodyHtml.content = code;
                              this.task.output.modified = true;
                            }}
                            onFocus={() =>
                              (this.task.activeTextInput =
                                this.task.emailBodyHtml)
                            }
                            onChangeSelection={(range) => {
                              range !== null &&
                                !isNaN(range.index) &&
                                (this.task.emailBodyHtml.cursorLocation =
                                  range.index);
                            }}
                          />
                          <br />
                        </>
                      )}
                    </>
                  ) : (
                    <TextField
                      label="Message"
                      multiline
                      style={{ height: "100%", width: "100%" }}
                      rows="23"
                      value={this.task.emailBodyPlain.content}
                      inputRef={this.plaintextBodyRef}
                      onSelect={(_) =>
                        (this.task.emailBodyPlain.cursorLocation =
                          this.plaintextBodyRef.current &&
                          (this.plaintextBodyRef.current
                            .selectionStart as number))
                      }
                      onChange={(e) => {
                        this.task.emailBodyPlain.content = e.target.value;
                        this.task.output.modified = true;
                      }}
                      onFocus={(_) =>
                        (this.task.activeTextInput = this.task.emailBodyPlain)
                      }
                      className={this.props.classes.marginTop}
                      variant="filled"
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Grid>
    );
  }
}
