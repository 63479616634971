import { Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({

    loading: {
        padding: 0,
        margin: 0,
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        zindex: 100,
        height: '100%',
        width: '100%',
        backgroundColor: '#fafafa',
        opacity: 0.4
    },
    loadingFullScreen: {
        position: 'fixed' as 'fixed'
    },
    loadingContent: {
        margin: 'auto',
        textAlign: 'center' as 'center'
    },
    loadingImg:{
        height: '200px',
        width: '200px',
        borderRadius: '5px'
    }
});