export const depthOf = (object: {} | []): number => {
    var level = 1;
    var key;
    for(key in object) {
        if (!object.hasOwnProperty(key)) continue;

        if(typeof (object as any)[key] === 'object'){
            var depth = depthOf((object as any)[key]) + 1;
            level = Math.max(depth, level);
        }
    }
    return level;
}

export const getByKey = (o: any, path: string) => path.split('.').reduce((o = {}, key) => o[key], o);