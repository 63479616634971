import React from "react";
import { observer } from "mobx-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import TrackedTextField from "../Input/TrackedTextField";

interface IProps {
  task: TaskContext;
}

const AireAuditActorControls: React.FC<IProps> = observer(({ task }) => {
  const auditActors = task.aireAudit.auditActors;
  const isValid = auditActors.every((actor) => actor.isValid());

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          {!isValid && <ErrorIcon color="error" />}
          <Typography variant="h6">Actors ({auditActors.length})</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Box>
            {auditActors.length === 0 && (
              <Typography color="error">
                At least one actor is required
              </Typography>
            )}
            {auditActors.map((actor, index) => {
              const isValid = actor.isValid();
              return (
                <Box key={index}>
                  <Box
                    display="flex"
                    marginTop={2}
                    marginBottom={2}
                    style={{ gap: 8 }}
                  >
                    <TrackedTextField
                      context={actor.userId}
                      label="User Id"
                      placeholder="User Id"
                      required={false}
                      fullWidth
                      variant="outlined"
                      id={`actor-user-id-${index}`}
                      error={!isValid}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ flex: 1 }}
                      onFocus={(_) => (task.activeTextInput = actor.userId)}
                    />
                    <TrackedTextField
                      context={actor.alternativeUserId}
                      label="Alternative User Id"
                      placeholder="Alternative User Id"
                      required={false}
                      fullWidth
                      variant="outlined"
                      id={`actor-alternative-user-id-${index}`}
                      error={!isValid}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ flex: 1 }}
                      onFocus={(_) =>
                        (task.activeTextInput = actor.alternativeUserId)
                      }
                    />
                    <TrackedTextField
                      context={actor.userName}
                      label="User Name"
                      placeholder="User Name"
                      required={false}
                      fullWidth
                      variant="outlined"
                      id={`actor-user-name-${index}`}
                      error={!isValid}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ flex: 1 }}
                      onFocus={(_) => (task.activeTextInput = actor.userName)}
                    />
                    <IconButton
                      onClick={() => task.aireAudit.removeAuditActor(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  {!isValid && (
                    <Typography color="error">
                      At least one field must be filled in
                    </Typography>
                  )}
                  <Divider style={{ marginTop: 16 }} />
                </Box>
              );
            })}
          </Box>
          <Box width="100%">
            <Button
              variant="text"
              fullWidth
              id="add-audit-actor"
              onClick={() => task.aireAudit.addAuditActor()}
            >
              <AddIcon /> Add Actor
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default AireAuditActorControls;
