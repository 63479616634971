import { observable } from "mobx";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";

export default class CertificateContext {
    constructor(name?: string, base64?: string, password?: string) {
        if(DataFormatHelper.IsSecret(base64)){
            this.mappableCert = new TextInputContext(base64);
            this.name = '';
        } else {
            this.mappableCert = new TextInputContext('');
        }

        this.name = name || '';
        this.base64 = base64 || '';
        this.password = new TextInputContext(password) || new TextInputContext('');
    }

    @observable
    public name: string = '';

    @observable
    public base64: string = '';

    @observable
    public mappableCert: TextInputContext = new TextInputContext();

    @observable
    public password: TextInputContext = new TextInputContext();

}