import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FieldState } from "formstate";
import { observer } from "mobx-react-lite";
import React, { RefObject, useState } from "react";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";

const usePasswordStyles = makeStyles({
  margin: {
    margin: 0,
  },
});

interface IProps {
  passwordField: TextInputContext | FieldState<string>;
  onDrop?: (ev: React.DragEvent<HTMLElement>) => void;
  inputRef?: RefObject<HTMLInputElement>;
  onFocus?: (_: any) => void;
  variant?: "filled" | "standard" | "outlined" | undefined;
  fullWidth?: boolean;
  infoTextSize?: 'small' | 'medium' | 'large';
  hideInfoText?: boolean;
}
const MappablePasswordSetter: React.FC<IProps> = observer(
  ({
    passwordField,
    onDrop,
    inputRef,
    onFocus,
    variant = "standard",
    fullWidth = false,
    infoTextSize = "medium",
    hideInfoText
  }) => {
    const [changingPassword, setChangingPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const passwordClasses = usePasswordStyles();

    const getInfoTextVariant = () => {
      switch (infoTextSize) {
        case 'small':
          return 'caption';
        case 'large':
          return 'body1';
        default:
          return 'body2';
      }
    };

    const renderPasswordField = () => {
      if (passwordField instanceof TextInputContext) {
        return (
          <TextField
            id="password"
            onDrop={onDrop}
            label="Password"
            inputRef={inputRef}
            onChange={(e) => (passwordField.content = e.target.value)}
            onSelect={(_) =>
              (passwordField.cursorLocation =
                inputRef!.current &&
                (inputRef!.current.selectionStart as number))
            }
            onFocus={onFocus}
            placeholder="Enter a Password"
            value={passwordField.content}
            fullWidth={fullWidth}
            variant={variant as any}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      }
      if (passwordField instanceof FieldState) {
        return (
          <TextField
            id="password"
            label="Password"
            inputRef={inputRef}
            onChange={(e) => passwordField.onChange(e.target.value)}
            placeholder="Enter a Password"
            value={passwordField.value}
            error={passwordField.hasError}
            fullWidth={fullWidth}
            variant={variant as any}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      }
    };

    if (!changingPassword) {
      return (
        <React.Fragment>
          <Button
            id="set-new-password-button"
            variant="contained"
            onClick={() => setChangingPassword(true)}
          >
            Set new password
          </Button>
          {hideInfoText && (
            <Typography variant={getInfoTextVariant()} color="textSecondary">
              Password is stored securely and cannot be viewed
            </Typography>
          )}
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{renderPasswordField()}</React.Fragment>;
    }
  }
);

export default MappablePasswordSetter;
