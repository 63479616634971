import CursorLocation from "../../Components/EndpointWizard/CursorLocation";
import BaseEditorHelpers from "./BaseEditorHelpers";

export default class AceEditorHelpers extends BaseEditorHelpers {
    public addTokenToOutput(
        token: string,
        cursorLocation: CursorLocation,
        code: string,
    ) {
        
        const lines = code.split('\n')
        let row = 0;
        let column = 0;
        if (cursorLocation) {
            row = cursorLocation.row;
            column = cursorLocation.column;
        }
        let line = lines[row]
        if (line.indexOf(token, column) !== 0) {
            line = line.slice(0, column)
                + token
                + line.slice(column)
            lines[row] = line;
            code = lines.join('\n')
        }
        return code;
    }
}