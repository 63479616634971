import React from "react";
import { Grid } from "@material-ui/core";
import IOutputProps from "../EndpointWizard/IOutputProps";
import PropertyEditor from "../PathEditor/PropertyEditor";
import { observer } from "mobx-react";
import { useDraggableToken } from "../../Common/Hooks/useDraggableToken";
import AireAuditProperties from "./AireAuditProperties";

const AireAuditOutput: React.FC<IOutputProps> = observer(
  ({ context, onDrop, appContext, task, classes, onClickEditPath }) => {
    useDraggableToken({ context, task });

    return (
      <Grid item md={6} onDrop={onDrop}>
        {context.tasks[task].editingPropertyToken ? (
          <PropertyEditor
            appContext={appContext}
            context={context}
            task={task}
          />
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                context.isEditorTokenImportEnabled = true;
              }}
            >
              <AireAuditProperties
                task={context.tasks[task]}
                classes={classes}
                context={context}
                onDrop={onDrop}
                onClickEditPath={onClickEditPath}
              />
            </div>
          </React.Fragment>
        )}
      </Grid>
    );
  }
);

export default AireAuditOutput;
