import { JwtAlgorithm } from "../Enums/Algorithm";
import { JwtDataType } from "../Enums/JwtDataType";

export default class JwtHelpers {
  public static GetAlgorithmDisplayName(algorithm: JwtAlgorithm) {
    switch (algorithm) {
      case JwtAlgorithm.RS256:
        return "rs256";
      case JwtAlgorithm.RS512:
        return "rs512";
      case JwtAlgorithm.None:
        return "none";
      case JwtAlgorithm.AES128HS256:
        return "aes128-hs256";
      default:
        return algorithm;
    }
  }

  public static GetValueTypeDisplayName(type: JwtDataType) {
    switch (type) {
      case JwtDataType.number:
        return "Number";
      case JwtDataType.text:
        return "String";
      case JwtDataType.json:
        return "JSON"
      default:
        return type;
    }
  }

  public static GetJWTDataTypeFromNumber = (typeNumber: number): JwtDataType => JwtDataType[
      JwtDataType[typeNumber]
    ];
  
  public static GetJWTAlgorithmFromNumber = (algorithmNumber: number): JwtAlgorithm => JwtAlgorithm[
      JwtAlgorithm[algorithmNumber]
    ];
}
