export const defaultTheme = {
    palette: {
        primary: {
            light: '#85bb5c',
            main: '#558b2f',
            dark: '#255d00',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffff52',
            main: '#ffd600',
            dark: '#c7a500',
            contrastText: '#000',
        },
    },
    overrides: {
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: '#ffd600'
                },
            }
        }
    }
};

export const iFrameTheme = {
    palette: {
        primary: {
            light: '#85bb5c',
            main: '#110326',
            dark: '#1565c0',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffff52',
            main: '#1565c0', 
            dark: '#c7a500',
            contrastText: '#000',
        },
    },
    overrides: {
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: '#1565c0'
                },
            }
        }
    }
};