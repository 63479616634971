import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import Loading from "../Loading/Loading";
import { useAuthentication } from "./AuthWrapper";

export const OIDCLogin: React.FC = () => {
  const { isLoggedIn, signInRedirect: signIn, loading } = useAuthentication();
  const location = useLocation<{ referrer: string }>();
  const referrer = (location.state && location.state.referrer) || "";

  const doLogin = async () => {
    await signIn(referrer);
  };

  if (isLoggedIn) return <Redirect to={referrer} />;
  if (loading) return <Loading fullscreen={false}></Loading>;

  doLogin();
  return (
    <button onClick={doLogin}>Click here if you are not redirected.</button>
  );
};
