import { Grid, TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import ReceiverContext from "./Receiver.Context";

interface IProps extends IComponentProps {
  context: ReceiverContext;
}

const SMSReceiverProperties: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    return (
      <Grid container spacing={2} className={classes.marginTop}>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Destination Number"
            id="destination-number"
            onChange={(e) =>
              context.destinationNumberField.onChange(e.target.value)
            }
            placeholder="Enter a destination number"
            value={context.destinationNumberField.value}
            error={context.destinationNumberField.hasError}
            variant="outlined"
          />
          <p>{context.destinationNumberField.error}</p>
        </Grid>
        <Grid item md={6}>
          <MappablePasswordSetter
            passwordField={context.passwordField}
            fullWidth={true}
            variant="outlined"
          />
          <p>{context.passwordField.error}</p>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Source Number"
            id="source-number"
            onChange={(e) => context.sourceNumberField.onChange(e.target.value)}
            placeholder="Enter a source number"
            value={context.sourceNumberField.value}
            error={context.sourceNumberField.hasError}
            variant="outlined"
          />
          <p>{context.sourceNumberField.error}</p>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Message Pattern"
            id="message-pattern"
            onChange={(e) =>
              context.messagePatternField.onChange(e.target.value)
            }
            placeholder="Enter a message pattern"
            value={context.messagePatternField.value}
            error={context.messagePatternField.hasError}
            variant="outlined"
          />
          <p>{context.messagePatternField.error}</p>
        </Grid>
      </Grid>
    );
  }
);

export default SMSReceiverProperties;
