import { CssBaseline } from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { observer, useLocalStore } from "mobx-react";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import AppContext from "./App.Context";
import { appStyles } from "./App.Styles";
import { defaultTheme, iFrameTheme } from "./App.Theme";
import Constants from "./Common/Constants";
import { AuthenticationProvider } from "./Common/Enums/AuthenticationProvider";
import ApolloClientFactory from "./Common/Factories/ApolloClientFactory";
import { useParentWindowToken } from "./Common/Hooks/useParentWindowToken";
import { AuthWrapper } from "./Components/Authentication/AuthWrapper";
import Loading from "./Components/Loading/Loading";
import MagicAppBar from "./Components/MagicAppBar/MagicAppBar";
import { Routes } from "./Components/Routing/Routes";

interface IProps {
  classes: any;
  config: any;
}

const App: React.FC<IProps> = observer(({ classes, config }) => {
  // must first add token and profile to local store if in headless mode
  const { isLoading, useHeadless } = useParentWindowToken(
    config.clientV2Host,
    config.authenticationProvider,
    tokenCallback
  );
  const context = useLocalStore(() => new AppContext(config));

  const theme = useHeadless
    ? createMuiTheme(iFrameTheme)
    : createMuiTheme(defaultTheme);

  function tokenCallback(token) {
    context.apolloClient = ApolloClientFactory.Get(
      context.managementHost,
      token ? token : null
    );
    context.loggedIn = true;
    context.reloadContext();
    context.loadAdapters();
    context.loadMeshMailboxes();
    context.redirectToHome();
  }

  const clientHost = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;

  const renderAuthContextProvider = (children: JSX.Element) => {
    return (
      context.identityClientId && (
        <AuthWrapper
          clientRootHost={context.clientV1Host}
          settings={{
            scope: context.identityScope,
            authority: context.identityAuthority,
            client_id: context.identityClientId,
            extraQueryParams: {},
          }}
          setAuthToken={(token) => {
            if (
              context.authenticationProvider === AuthenticationProvider.Internal
            ) {
              return;
            }
            !!token
              ? localStorage.setItem(Constants.authTokenName, token)
              : localStorage.removeItem(Constants.authTokenName);
            if (token) {
              tokenCallback(token);
            }
          }}
          authenticationProvider={context.authenticationProvider}
        >
          {children}
        </AuthWrapper>
      )
    );
  };

  if (isLoading) {
    return <Loading fullscreen />;
  }

  return (
    <React.Fragment>
      {context.managementHost && (
        <div className={classes.root}>
          <BrowserRouter>
            {renderAuthContextProvider(
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {context.apolloClient ? (
                  <ApolloProvider client={context.apolloClient}>
                    <Route
                      path="/:active?"
                      render={() => {
                        return useHeadless ? (
                          <></>
                        ) : (
                          <MagicAppBar appContext={context} />
                        );
                      }}
                    />
                    <main className={classes.content}>
                      {!context.profileComplete && (
                        <Redirect push to="/CompleteProfile" />
                      )}
                      <Routes context={context} classes={classes}/>
                    </main>
                  </ApolloProvider>
                ) : (
                  <Loading fullscreen={false} />
                )}
              </MuiThemeProvider>
            )}
          </BrowserRouter>
        </div>
      )}
    </React.Fragment>
  );
});

export default withStyles(appStyles)(App);
