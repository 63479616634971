import * as React from "react";
import { List, withStyles } from "@material-ui/core";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import MagicFunction from "../MagicFunction/MagicFunction";
import { appStyles } from "../../App.Styles";
import { observer } from "mobx-react";

interface IProps extends IComponentProps {
  context: EndpointWizardContext;
  draggable: boolean;
  task: number;
}

@observer
class FunctionSelector extends React.Component<IProps> {
  public render() {
    return (
      <React.Fragment>
        <List disablePadding className={this.props.classes.dataTree}>
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="DateTime"
            paramsName="Format"
            paramsValue="ddMMyyyyhh:ss"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="UUID"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="Random"
            paramsName="Min/Max"
            paramsValue="1,10"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="OID"
            paramsName="Prefix"
            paramsValue="2.25"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="Base64Encode"
            paramsName="Parameters"
            paramsValue="String To Encode"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="Base64Decode"
            paramsName="Parameters"
            paramsValue="String To Decode"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="UrlEncode"
            paramsName="String To Encode"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="Raw"
            paramsName="String To Decode"
          />
          <MagicFunction
            appContext={this.props.appContext}
            context={this.props.context}
            draggable={this.props.draggable}
            task={this.props.task}
            name="JWT"
            paramsName="Key,Claims JSON Object"
            paramsValue='RSAkey,{\"iss\":\"value\"}'
          />
        </List>
      </React.Fragment>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(FunctionSelector);
