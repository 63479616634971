import { observable } from "mobx";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import { MapType } from "../../../Common/Enums/MapType";
import { DataSource } from "../../../Common/Enums/DataSource";
import FHIRContext from "./FHIR.Context";
import { ViewingMode } from "../ViewingMode";
import { IJSONPathMatch } from "../../../Common/Interfaces/IJSONPathMatch";

export default class StepContext {
  @observable
  public data: {} | [] | Document = {};

  @observable
  public mode: DataFormat = DataFormat.JSON;

  @observable
  public viewingMode: ViewingMode = ViewingMode.Tree;

  @observable
  public dataSource: DataSource = DataSource.Body;

  @observable
  public mapType: MapType = MapType.Path;

  @observable
  public loaded?: boolean = false;

  @observable
  public fhir: FHIRContext = new FHIRContext();

  @observable
  public loading: boolean = false;

  @observable
  public modified: boolean = false;

  @observable
  public error: string | null = null;

  @observable
  public previewXsdNamespace?: string;

  @observable
  public selectedDataOrigin: number = -1;

  @observable
  public response?: string;

  @observable
  public responseFormat: DataFormat = DataFormat.JSON;

  @observable
  public editingPathMatches: IJSONPathMatch[] | any[] = [];

  @observable
  public editingPathError: boolean = false;

  @observable
  public searchTerm?: string;
}
