import {
  Accordion,
  Grid,
  TextField,
  AccordionSummary,
  AccordionDetails,
  CardContent,
  Divider,
  Typography,
  Button,
  IconButton,
  Input,
  CardHeader,
} from "@material-ui/core";
import React, { useRef } from "react";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react-lite";
import TrackedTextField from "../Input/TrackedTextField";

interface IProps {
  task: TaskContext;
}

const HeaderProperties: React.FC<IProps> = observer(({ task }) => {
  return (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              Headers ({task.headers.length})
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            {task.headers.map((header, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={12}>
                    <Card key={index}>
                      <CardHeader
                        title={
                          <TextField
                            label="Header"
                            onChange={(e) => (header.name = e.target.value)}
                            placeholder="Header"
                            required
                            fullWidth
                            value={header.name}
                            error={
                              !header.name ||
                              task.headers.filter((h) => h.name === header.name)
                                .length > 1
                            }
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onFocus={(_) => {
                              task.activeTextInput = null;
                            }}
                          />
                        }
                        action={
                          <IconButton
                            onClick={() => task.headers.splice(index, 1)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      ></CardHeader>
                      <Divider></Divider>
                      <CardContent>
                        {header.values.map((input, valueIndex) => (
                          <React.Fragment key={valueIndex}>
                            <TrackedTextField
                              context={input}
                              label="Value"
                              placeholder="Enter a value"
                              required
                              error={!input.content}
                              id={`header-${index}-value-${valueIndex}`}
                              onFocus={(_) => {
                                task.activeTextInput = input;
                              }}
                            />
                            <IconButton
                              onClick={() =>
                                header.values.splice(valueIndex, 1)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </React.Fragment>
                        ))}
                        <IconButton
                          onClick={() =>
                            header.values.push(new TextInputContext())
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="text"
                fullWidth
                onClick={() =>
                  task.headers.push({
                    name: "",
                    values: [new TextInputContext()],
                  })
                }
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});

export default HeaderProperties;
