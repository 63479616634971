import { observable } from "mobx";
import IEndpointError from "../../Common/Interfaces/IEndpointError";
import dayjs, { Dayjs } from "dayjs";

export default class EndpointErrorContext {

    @observable
    public errors : IEndpointError[] = [];

    @observable
    public total: number = 0;
    
    @observable
    public page: number = 0;

    @observable
    public rowsPerPage: number = 10;

    @observable
    public orderBy: string = "timestamp";

    @observable
    public orderDesc: boolean = true;

    @observable
    public startDate: Dayjs = dayjs().hour(0).minute(0).subtract(3, "day");

    @observable
    public endDate: Dayjs = dayjs().hour(23).minute(59);
}