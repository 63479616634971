import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import {
  withStyles,
  Grid,
  Button,
  Typography,
  TextField,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import BackIcon from "@material-ui/icons/ArrowBack";
import { Redirect, withRouter, RouteComponentProps } from "react-router";
import RepositoryContext from "./Repository.Context";
import { appStyles } from "../../App.Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading/Loading";
import ValidationViewer from "../ValidationViewer/ValidationViewer";
import IValidationResult from "../../Common/Interfaces/IValidationResult";
import Help from "../Help/Help";
import RepositoryHelp from "../RepositoryHelp/RepositoryHelp";
import { Link } from "react-router-dom";

interface IProps extends IComponentProps, RouteComponentProps {
  context: RepositoryContext;
}

@observer
export class Repository extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this._context = props.context;
  }

  private readonly _context: RepositoryContext;

  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this._context.urlModified = true;
    this._context.repositoryUrlField.onChange(e.target.value);
  };

  public render() {
    return this._context.redirect ? (
      <Redirect to="/Repositories" />
    ) : (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              {this._context.editing ? "Sync" : "Add a"} repository{" "}
              <Help
                classes={this.props.classes}
                helpText={<RepositoryHelp />}
              />
            </Typography>
          </Grid>
          {this._context.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <form
              className={this.props.classes.form}
              noValidate
              autoComplete="off"
              onSubmit={this._context.onSubmit}
            >
              <Grid container spacing={2}>
                {this._context.retrievalResult &&
                this._context.retrievalResult.endpoints &&
                this._context.retrievalResult.success ? (
                  <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <React.Fragment>
                      <Typography
                        align="center"
                        variant="h5"
                        className={this.props.classes.marginTop}
                        style={{ width: "100%" }}
                      >
                        Successfully detected endpoints:
                      </Typography>
                      <List>
                        {Object.keys(
                          this._context.retrievalResult!.endpoints
                        ).map((key, index) => {
                          return (
                            <ListItem alignItems="center" key={index}>
                              <ListItemIcon>
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="lg"
                                  color="green"
                                />
                              </ListItemIcon>
                              <List>
                                <ListItem>
                                  <ListItemText>
                                    <b>Name:</b>{" "}
                                    {
                                      this._context.retrievalResult!.endpoints[
                                        key
                                      ].name
                                    }
                                  </ListItemText>
                                </ListItem>
                                <ListItem>
                                  <ListItemText>
                                    <b>Versions:</b>{" "}
                                    {
                                      this._context.retrievalResult!.endpoints[
                                        key
                                      ].versions.length
                                    }
                                  </ListItemText>
                                </ListItem>
                              </List>
                            </ListItem>
                          );
                        })}
                      </List>
                    </React.Fragment>
                  </Grid>
                ) : (
                  !this._context.editing && 
                    !this._context.retrievalResult && (
                    <React.Fragment>
                      <Grid item xs={5}>
                        <TextField
                          id="repository-url"
                          fullWidth
                          autoFocus
                          disabled={this._context.editing}
                          label="Repository URL"
                          onChange={this.onChange}
                          placeholder="Enter a repository URL"
                          value={this._context.repositoryUrlField.value}
                          InputProps={{
                            readOnly: this._context.editing,
                          }}
                          error={this._context.repositoryUrlField.hasError}
                          variant="filled"
                        />
                        <p>{this._context.repositoryUrlField.error}</p>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="repository-branch"
                          fullWidth
                          autoFocus
                          disabled={this._context.editing}
                          label="Repository Branch"
                          onChange={({target: { value }}) => {
                            this._context.repositoryBranchField.onChange(
                              value
                            )
                          }}
                          placeholder="Enter a repository branch"
                          value={this._context.repositoryBranchField.value}
                          InputProps={{
                            readOnly: this._context.editing,
                          }}
                          error={this._context.repositoryBranchField.hasError}
                          variant="filled"
                        />
                        <p>{this._context.repositoryBranchField.error}</p>
                      </Grid>
                      <Grid item md={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={this.props.classes.marginLeft}
                              onChange={() =>
                                (this.props.context.automaticallySync = !this
                                  .props.context.automaticallySync)
                              }
                              checked={this.props.context.automaticallySync}
                            />
                          }
                          label="Automatically sync"
                        />
                        <Help
                          classes={this.props.classes}
                          helpText="If selected, the repository endpoints will be automatically synced from the master branch"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="error-email"
                          fullWidth
                          disabled={this._context.editing}
                          label="Error Email"
                          onChange={(e) =>
                            this._context.errorEmailField.onChange(
                              e.target.value
                            )
                          }
                          placeholder="An email address to report sync failures to"
                          value={this._context.errorEmailField.value}
                          InputProps={{
                            readOnly: this._context.editing,
                          }}
                          error={this._context.errorEmailField.hasError}
                          variant="filled"
                        />
                        <p>{this._context.errorEmailField.error}</p>
                      </Grid>
                      <Grid item xs={12} />
                      <Grid item md={6}>
                        <FormControl fullWidth>
                          <FormLabel>
                            Authentication Method
                            <Help
                              classes={this.props.classes}
                              helpText="The authentiction method to access this remote git repository"
                            />
                          </FormLabel>
                          <Select
                            id="authentication-method-select"
                            className={this.props.classes.padding}
                            label="Authentication Method"
                            fullWidth
                            value={this.props.context.authentication}
                            onChange={(e) =>
                              (this.props.context.authentication = e.target
                                .value as "None" | "Credentials" | "SSH")
                            }
                          >
                            <MenuItem value={"None"}>None</MenuItem>
                            <MenuItem id="authentication-item-credentials" value={"Credentials"}>
                              Credentials
                            </MenuItem>
                            <MenuItem id="authentication-item-ssh" value={"SSH"}>SSH</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        {this.props.context.authentication ===
                          "Credentials" && (
                          <React.Fragment>
                            <TextField
                              id="repository-username"
                              fullWidth
                              autoComplete="off"
                              disabled={this._context.editing}
                              label="Repository Username"
                              onChange={(e) =>
                                this.props.context.repositoryUserNameField.onChange(
                                  e.target.value
                                )
                              }
                              placeholder="Enter a repository Username"
                              value={
                                this._context.repositoryUserNameField.value
                              }
                              InputProps={{
                                readOnly: this._context.editing,
                              }}
                              error={
                                this._context.repositoryUserNameField.hasError
                              }
                              variant="filled"
                            />
                            <p>{this._context.repositoryUserNameField.error}</p>
                            {!this._context.editing && (
                              <React.Fragment>
                                <TextField
                                  id="repository-password"
                                  fullWidth
                                  autoComplete="new-password"
                                  type="password"
                                  label="Repository Password"
                                  onChange={(e) =>
                                    this.props.context.repositoryPasswordField.onChange(
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter a repository Password"
                                  value={
                                    this._context.repositoryPasswordField.value
                                  }
                                  error={
                                    this._context.repositoryPasswordField
                                      .hasError
                                  }
                                  variant="filled"
                                />
                                <p>
                                  {this._context.repositoryPasswordField.error}
                                </p>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        {this.props.context.authentication === "SSH" &&
                          !this._context.editing && (
                            <React.Fragment>
                              <TextField
                                id="repository-sshkey"
                                fullWidth
                                multiline
                                rows={10}
                                label="Repository SSH Key"
                                onChange={(e) =>
                                  this.props.context.repositorySshKeyField.onChange(
                                    e.target.value
                                  )
                                }
                                placeholder="Enter a repository SSH Key"
                                value={
                                  this._context.repositorySshKeyField.value
                                }
                                error={
                                  this._context.repositorySshKeyField.hasError
                                }
                                variant="filled"
                              />
                              <p>{this._context.repositorySshKeyField.error}</p>
                            </React.Fragment>
                          )}
                      </Grid>
                    </React.Fragment>
                  )
                )}
                {this._context.retrievalResult && (
                  <React.Fragment>
                    {this._context.retrievalResult.conflicts &&
                      Object.keys(this._context.retrievalResult.conflicts)
                        .length > 0 && (
                        <Grid
                          item
                          xs={
                            this._context.validationResult &&
                            this._context.validationResult.messages.length > 0
                              ? 6
                              : 12
                          }
                          container
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <React.Fragment>
                            <Typography
                              align="center"
                              variant="h5"
                              className={this.props.classes.marginTop}
                              style={{ width: "100%" }}
                            >
                              Conflicts
                            </Typography>
                            <List>
                              {Object.keys(
                                this._context.retrievalResult!.conflicts
                              ).map((key, index) => {
                                return (
                                  <ListItem alignItems="center" key={index}>
                                    <ListItemIcon>
                                      <FontAwesomeIcon
                                        icon={faBan}
                                        size="lg"
                                        color="red"
                                      />
                                    </ListItemIcon>
                                    <List>
                                      <ListItem alignItems="center">
                                        <ListItemText>
                                          <b>Name:</b> {key}
                                        </ListItemText>
                                      </ListItem>
                                      <ListItem alignItems="center">
                                        <ListItemText>
                                          <b>Conflict:</b>{" "}
                                          {
                                            this._context.retrievalResult!
                                              .conflicts[key]
                                          }
                                        </ListItemText>
                                      </ListItem>
                                    </List>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </React.Fragment>
                        </Grid>
                      )}
                    {this._context.validationResult &&
                      this._context.validationResult.messages.length > 0 && (
                        <Grid
                          item
                          xs={
                            this._context.retrievalResult.conflicts &&
                            Object.keys(this._context.retrievalResult.conflicts)
                              .length > 0
                              ? 6
                              : 12
                          }
                          style={{ backgroundColor: "#eeeeee" }}
                          container
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <React.Fragment>
                            <Typography
                              align="center"
                              variant="h5"
                              className={this.props.classes.marginTop}
                              style={{ width: "100%" }}
                            >
                              Errors
                            </Typography>
                            <ValidationViewer
                              appContext={this.props.appContext}
                              locationLabel="Item"
                              validationResult={
                                this._context
                                  .validationResult as IValidationResult
                              }
                            />
                          </React.Fragment>
                        </Grid>
                      )}
                  </React.Fragment>
                )}
                <Grid item xs={12}>
                  {this._context.retrievalResult ? (
                    <Link
                      to="/Repositories"
                      className={this.props.classes.link}
                    >
                      <Button
                        id="repository-back"
                        className={this.props.classes.marginTop}
                        type="button"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                      >
                        {
                          <React.Fragment>
                            <BackIcon fontSize="large" /> Back to repositories
                          </React.Fragment>
                        }
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      id="repository-test-save"
                      className={this.props.classes.marginTop}
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      {
                        <React.Fragment>
                          <SaveIcon fontSize="large" /> Test & Save
                        </React.Fragment>
                      }
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps & RouteComponentProps> {
    render() {
      const context = new RepositoryContext(
        this.props.appContext,
        (this.props.match.params as any)["id"]
      );

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withRouter(withContext(Repository))
);
