import { useEffect } from "react";
import { History } from "history";

export const useParentWindowNavigation = (history: History, host: string) => {
    const handleMessage = (event:MessageEvent) => {
        if (event.origin === host.trim()){
            history.push(event.data.path)
        }
    };
    
    useEffect(() => {
    window.addEventListener("message", handleMessage);

    return (() => window.removeEventListener("message", handleMessage))
    }, []);

}