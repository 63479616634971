import AppContext from "../../App.Context";
import { getToken } from "../../Common/Helpers/AuthenticationHelpers";
import IVariable from "../../Common/Interfaces/IVariable";
import LoggingService from "../../Common/Services/Logging.Service";

export default class VariableService {
    public static Save = (appContext: AppContext, data: IVariable, create :boolean) : Promise<Response> => {
        const uri = `${appContext.managementHost}/Variable`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: create ? 'POST' : 'PATCH',
                body: JSON.stringify(data)
            })
            .then(response => response)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Get = (appContext: AppContext, name: string): Promise<IVariable> => {
        const uri = `${appContext.managementHost}/Variable/${name}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

}