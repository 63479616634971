import {
  Accordion,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import LinkIcon from "@material-ui/icons/Link";
import {
  ActionType,
  EventIdentifierType,
  OutcomeIndicator,
} from "../../Common/Enums/Audit";
import AireAuditActorControls from "./AireAuditActorControls";
import AireAuditAuditableObjectControls from "./AireAuditAuditableObjectControls";
import AireAuditEventTypeControls from "./AireAuditEventTypeControls";

interface IProps {
  task: TaskContext;
  context: EndpointWizardContext;
  classes: any;
  onDrop: (ev: React.DragEvent<HTMLElement>) => void;
  onClickEditPath: () => void;
}

const AireAuditProperties: React.FC<IProps> = observer(
  ({ task, classes, context, onDrop, onClickEditPath }) => {
    const auditSourceRef = useRef<HTMLInputElement>();

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="audit-source"
            onDrop={onDrop}
            label="Audit Source"
            inputRef={auditSourceRef}
            onSelect={(_) =>
              (task.aireAudit.auditSource.cursorLocation =
                (auditSourceRef.current?.selectionStart as number) || null)
            }
            onChange={(e) =>
              (task.aireAudit.auditSource.content = e.target.value)
            }
            error={!task.aireAudit.auditSource.content}
            onFocus={(_) => (task.activeTextInput = task.aireAudit.auditSource)}
            value={task.aireAudit.auditSource.content}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="audit-event-identifier-type-label">
              Event Identifier Type
            </InputLabel>
            <Select
              label="Event Identifier Type"
              labelId="audit-event-identifier-type-label"
              variant="outlined"
              value={task.aireAudit.eventIdentifierType}
              onChange={(e) => {
                task.aireAudit.eventIdentifierType = e.target
                  .value as EventIdentifierType;
              }}
            >
              <MenuItem value={EventIdentifierType.ProvisioningEvent}>
                Provisioning Event
              </MenuItem>
              <MenuItem value={EventIdentifierType.MedicationEvent}>
                Medication Event
              </MenuItem>
              <MenuItem value={EventIdentifierType.ResourceAssignment}>
                Resource Assignment
              </MenuItem>
              <MenuItem value={EventIdentifierType.CareEpisode}>
                Care Episode
              </MenuItem>
              <MenuItem value={EventIdentifierType.CareProtocol}>
                Care Protocol
              </MenuItem>
              <MenuItem value={EventIdentifierType.Disclosure}>
                Disclosure
              </MenuItem>
              <MenuItem value={EventIdentifierType.CDTPatientSearchActivity}>
                Patient Search Activity
              </MenuItem>
              <MenuItem value={EventIdentifierType.ApplicationActivity}>
                Application Activity
              </MenuItem>
              <MenuItem value={EventIdentifierType.AuditLogUsed}>
                Audit Log Used
              </MenuItem>
              <MenuItem value={EventIdentifierType.BeginTransferInstances}>
                Begin Transfer Instances
              </MenuItem>
              <MenuItem value={EventIdentifierType.DicomInstancesAccessed}>
                Instances Accessed
              </MenuItem>
              <MenuItem value={EventIdentifierType.DicomInstancesTransffered}>
                Instances Transffered
              </MenuItem>
              <MenuItem value={EventIdentifierType.StudyDeleted}>
                Study Deleted
              </MenuItem>
              <MenuItem value={EventIdentifierType.Export}>Export</MenuItem>
              <MenuItem value={EventIdentifierType.Import}>Import</MenuItem>
              <MenuItem value={EventIdentifierType.NetworkActivity}>
                Network Activity
              </MenuItem>
              <MenuItem value={EventIdentifierType.OrderRecord}>
                Order Record
              </MenuItem>
              <MenuItem value={EventIdentifierType.PatientRecord}>
                Patient Record
              </MenuItem>
              <MenuItem value={EventIdentifierType.ProcedureRecord}>
                Procedure Record
              </MenuItem>
              <MenuItem value={EventIdentifierType.Query}>Query</MenuItem>
              <MenuItem value={EventIdentifierType.SecurityAlert}>
                Security Alert
              </MenuItem>
              <MenuItem value={EventIdentifierType.UserAuthentication}>
                User Authentication
              </MenuItem>
              <MenuItem value={EventIdentifierType.EmergencyOverrideStarted}>
                Emergency Override Started
              </MenuItem>
              <MenuItem value={EventIdentifierType.UseOfRestrictedFunction}>
                Use of restricted function
              </MenuItem>
              <MenuItem value={EventIdentifierType.Login}>Login</MenuItem>
              <MenuItem value={EventIdentifierType.Logout}>Logout</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          {task.selectedPropertyToken && (
            <Button
              className={classes.margin}
              onClick={onClickEditPath}
              variant="text"
            >
              <LinkIcon />
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="audit-outcome-indicator-label">
              Outcome Indicator
            </InputLabel>
            <Select
              label="Outcome Indicator"
              labelId="audit-outcome-indicator-label"
              variant="outlined"
              value={task.aireAudit.outcomeIndicator}
              onChange={(e) => {
                task.aireAudit.outcomeIndicator = e.target
                  .value as OutcomeIndicator;
              }}
            >
              <MenuItem value={OutcomeIndicator.Success}>Success</MenuItem>
              <MenuItem value={OutcomeIndicator.MinorFailure}>
                Minor Failure
              </MenuItem>
              <MenuItem value={OutcomeIndicator.SeriousFailure}>
                Serious Failure
              </MenuItem>
              <MenuItem value={OutcomeIndicator.CriticalFailure}>
                Critical Failure
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="audit-action-type-label">Action Type</InputLabel>
            <Select
              label="Action Type"
              labelId="audit-action-type-label"
              variant="outlined"
              value={task.aireAudit.actionType}
              onChange={(e) => {
                task.aireAudit.actionType = e.target.value as ActionType;
              }}
            >
              <MenuItem value={ActionType.Create}>Create</MenuItem>
              <MenuItem value={ActionType.Read}>Read</MenuItem>
              <MenuItem value={ActionType.Update}>Update</MenuItem>
              <MenuItem value={ActionType.Delete}>Delete</MenuItem>
              <MenuItem value={ActionType.Execute}>Execute</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <AireAuditEventTypeControls task={task} />
        </Grid>
        <Grid item xs={12}>
          <AireAuditActorControls task={task} />
        </Grid>
        <Grid item xs={12}>
          <AireAuditAuditableObjectControls task={task} />
        </Grid>
      </Grid>
    );
  }
);

export default AireAuditProperties;
