import AppContext from "../../App.Context";
import IJob from "../../Common/Interfaces/IJob";
import LoggingService from "../../Common/Services/Logging.Service";
import { getToken } from "../../Common/Helpers/AuthenticationHelpers";

export default class JobService {
    public static Save = (appContext: AppContext, data: IJob, id?: string) : Promise<Response>  => {
        const uri = `${appContext.managementHost}/Job`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: id ? 'PATCH' : 'POST',
                body: JSON.stringify(data)
            })
            .then(response => response)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Get = (appContext: AppContext, id: string): Promise<IJob> => {
        const uri = `${appContext.managementHost}/Job/${id}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Delete = (appContext: AppContext, id: string): Promise<boolean> => {
        const uri = `${appContext.managementHost}/Job/${id}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then(response => response.ok)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }
}