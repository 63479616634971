import LoggingService from "./Logging.Service";
import IEndpointVersion from "../Interfaces/IEndpointVersion";
import IEndpointInputValidationResult from "../Interfaces/IEndpointInputValidationResult";
import IEndpointXmlSchema from "../Interfaces/IEndpointXmlSchema";
import { getToken } from "../Helpers/AuthenticationHelpers";

export default class SchemaService {
    public static GenerateXsd = (managementHost: string, data: Document): Promise<string> => {
        const uri = `${managementHost}/Schema/GenerateXsd`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/xml'
            },
            method: 'POST',
            body: new XMLSerializer().serializeToString(data)
        })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static GenerateXml = (managementHost: string, data: IEndpointXmlSchema[]): Promise<string> => {
        const uri = `${managementHost}/Schema/GenerateXml`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data.map(d => d.schema))
        })
            .then(response => {
                return response.status === 200 || response.status === 400 ? response.text() : '';
            })
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public static Validate = (managementHost: string, endpointVersion: IEndpointVersion, input: string): Promise<IEndpointInputValidationResult> => {
        const uri = `${managementHost}/Schema/Validate`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                endpointVersion,
                input
            })
        })
            .then(response => response.json())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }
}