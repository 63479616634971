import { observable, action, computed } from "mobx";
import { EndpointType } from "../../Common/Enums/EndpointType";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import ISelectedVersion from "./ISelectedVersion";
import debounce from 'lodash.debounce';
import localForage from 'localforage';

export default class EndpointsContext {
    
    constructor() {
        localForage.getItem('rowsperpage').then((value) => 
            {if(value!= null) 
                {this.rowsPerPage = value as number}     
            })
        }
    @observable
    public loading: boolean = false;

    @observable
    public selectedVersions: ISelectedVersion[] = [];

    @observable 
    public showRepositoryEndpoints: boolean = true;

    @action
    public selectLastVersion(endpoint: IEndpoint) {
        if (endpoint.versions) {
            this.selectedVersions.push({
                endpointName: endpoint.name,
                endpointGroup: endpoint.group,
                endpointVersion: endpoint.versions.slice().sort(v => v.version).reverse()[0],
                environments: Object.keys(endpoint.environments)
            });
        }
    }

    @computed
    get filteredEndpoints() {
        return this.endpoints.filter(e => e.name.toUpperCase().includes(this.submittedSearchedEndpoint.toUpperCase()))
            .filter(e => this.filterEndpointType(e));
    } 

    private updateSearchTerm() {
        this.submittedSearchedEndpoint = this.searchedEndpoint;
    }

    public setSearch = debounce(() => this.updateSearchTerm(), 500, {leading : true, trailing:true});

    private filterEndpointType(e: IEndpoint) : Boolean
    {
        if(!this.showRepositoryEndpoints)
        {
            return e.type === EndpointType.Wizard;
        }
        return true;
    }


    @computed 
    get pagedEndpoints() {
        return this.filteredEndpoints.slice(
            this.page * this.rowsPerPage, 
            this.page * this.rowsPerPage + this.rowsPerPage)
    }

    @observable
    public page: number = 0;

     @observable
    public rowsPerPage: number = 10;

    @observable
    public menuOpen: boolean = false;

    @observable
    public redirect: boolean = false;

    @observable
    public endpoints: IEndpoint[] = [];

    @observable
    public searchedEndpoint: string = "";
    
    @observable
    public submittedSearchedEndpoint: string = "";
    
}