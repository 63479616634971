import { AttachmentLocation } from "../Enums/AttachmentLocation";

export default class AttachmentLocationHelpers {
    public static GetDisplayName(location: AttachmentLocation) {
        switch (location) {
            case AttachmentLocation.After:
                return "After body"
            case AttachmentLocation.Before:
                return "Before body"
        }
    }
}