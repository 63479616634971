import {
  Button,
  CircularProgress,
  Snackbar,
  TableCell,
  TablePagination,
  TableRow,
  useTheme,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import DebugIcon from "@material-ui/icons/BugReport";
import { Alert } from "@material-ui/lab";
import { ApolloQueryResult } from "apollo-client";
import { loader } from "graphql.macro";
import { observer } from "mobx-react";
import * as React from "react";
import { useMutation } from "react-apollo";
import UsageDataTableContext from "./UsageDataTableContext";
import { Can } from "../Authorisation/AbilityContext";

interface IProps {
  context: UsageDataTableContext;
  refetch: () => Promise<ApolloQueryResult<any>>;
  handleChangePage: (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const UsageDataTableFooter: React.FC<IProps> = observer(
  ({ context, refetch, handleChangePage, handleChangeRowsPerPage }) => {
    const theme = useTheme();
    const [deleteAllTransactional, { loading, error }] = useMutation(
      deleteMutation,
      {
        onCompleted: refetch,
      }
    );

    return (
      <TableRow>
        <TableCell colSpan={3}>
          <Can I="delete" a="Transactional">
            <Button
              variant="contained"
              style={{ marginRight: theme.spacing(2), color: grey[800] }}
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you want to delete debug data for all transactions?`
                  )
                ) {
                  deleteAllTransactional();
                }
              }}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    marginRight: theme.spacing(),
                    height: "24px",
                    width: "24px",
                  }}
                />
              ) : (
                <DebugIcon style={{ marginRight: theme.spacing() }} />
              )}
              Delete All Debug Data
            </Button>
          </Can>
        </TableCell>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          count={context.total}
          rowsPerPage={context.rowsPerPage}
          page={context.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Snackbar open={error?.message != null}>
          <Alert severity="error">Debug Delete Error: {error?.message}</Alert>
        </Snackbar>
      </TableRow>
    );
  }
);

const deleteMutation = loader(
  "../../GraphQL/Mutations/delete-all-transactional.graphql"
);
export default UsageDataTableFooter;
