import {
  faCheck,
  faCodeBranch,
  faMagic,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Chip,
  FormControl,
  IconButton,
  NativeSelect,
  TableCell,
  TableRow,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NothingIcon from "@material-ui/icons/Remove";
import DeployIcon from "@material-ui/icons/Send";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { observer } from "mobx-react";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import AppContext from "../../App.Context";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointService from "../../Common/Services/Endpoint.Service";
import Help from "../Help/Help";
import EndpointsContext from "./Endpoints.Context";
import { styles } from "./Endpoints.Styles";
import EndpointsDebugSwitch from "./EndpointsDebugSwitch";
import EndpointsActiveSwitch from "./EndpointsActiveSwitch";
import { Can, useAbility } from "../Authorisation/AbilityContext";

interface IProps {
  classes: any;
  context: EndpointsContext;
  appContext: AppContext;
  endpoint: IEndpoint;
  endpointIndex: number;
  isRepositoryEndpoint: boolean;
  refetch: () => void;
  onVersionChange: (
    e: React.ChangeEvent<{ value: any }>,
    endpoint: IEndpoint
  ) => void;
  setPersistTransactionData: (endpoint: IEndpoint, value: boolean) => void;
  onClickDelete: () => void;
  setActive: (endpoint: IEndpoint, value: boolean) => void;
}

const copyText: React.RefObject<HTMLElement> = React.createRef();

const EndpointsTableRow: React.FC<IProps> = observer(
  ({
    classes,
    context,
    appContext,
    endpoint,
    endpointIndex,
    isRepositoryEndpoint,
    refetch,
    onVersionChange,
    setPersistTransactionData,
    onClickDelete,
    setActive,
  }) => {
    const ability = useAbility();

    return (
      <TableRow id={`endpoint-row-${endpoint.name}`} key={endpointIndex}>
        <TableCell>
          <Link
            className={
              context.selectedVersions.some(
                (v) => v.endpointName === endpoint.name
              ) === false
                ? classes.hidden
                : classes.clickableHeaderStyle
            }
            to={
              context.selectedVersions.some(
                (v) => v.endpointName === endpoint.name
              )
                ? `/Endpoint/${endpoint.name}/${
                    context.selectedVersions.find(
                      (v) => v.endpointName === endpoint.name
                    )!.endpointVersion.version
                  }/View/${isRepositoryEndpoint ? "Repository" : "Magic"}`
                : ""
            }
          >
            <Chip
              avatar={
                <Avatar>
                  {isRepositoryEndpoint ? (
                    <FontAwesomeIcon icon={faCodeBranch} />
                  ) : (
                    <FontAwesomeIcon icon={faMagic} />
                  )}
                </Avatar>
              }
              label={endpoint.name}
              clickable
            />
          </Link>
        </TableCell>
        <TableCell>
          <FormControl className={classes.formControl}>
            <NativeSelect
              id={`${endpoint.name}-version-select`}
              value={getSelectedVersion(context, endpoint) || ""}
              onChange={(e) => onVersionChange(e, endpoint)}
              name="version"
            >
              <option disabled value="">
                Select a version
              </option>
              {endpoint.versions.map((endpointVersion, index) => {
                return (
                  <option
                    id={`${endpoint.name}-version-${endpointVersion.version}`}
                    key={index}
                    value={endpointVersion.version}
                  >
                    {endpointVersion.version}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <Help
            classes={classes}
            helpText="Multiple different endpoint versions can be accessed by appending the version number to the path. E.g. '/user/endpoint/1'. 
                                Deploying to production will set the specified version to be accessible without appending a version number."
          />
        </TableCell>
        <TableCell>
          <b ref={copyText}>{endpoint.url}</b>
          <CopyToClipboard text={endpoint.url ?? ""}>
            <Tooltip title="Copy" aria-label={"Copy" + endpoint.name}>
              <IconButton>
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
        </TableCell>
        <TableCell>
          {isRepositoryEndpoint === false && (
            <Can I="edit" a="Endpoint">
              <EndpointsActiveSwitch
                context={context}
                endpoint={endpoint}
                setActive={setActive}
              />
            </Can>
          )}
        </TableCell>
        <TableCell className={classes.center}>
          {"Staging" in endpoint.environments ? (
            <React.Fragment>
              {getSelectedVersion(context, endpoint) === undefined ||
              getSelectedVersion(context, endpoint) ===
                endpoint.environments["Staging"] ? (
                <React.Fragment>
                  <FontAwesomeIcon icon={faCheck} size="lg" color="green" />{" "}
                  {endpoint.environments["Staging"]}
                </React.Fragment>
              ) : (
                !isRepositoryEndpoint && (
                  <IconButton
                    disabled={ability.cannot("edit", "Endpoint")}
                    onClick={() =>
                      onClickDeploy(
                        appContext,
                        endpoint.id as string,
                        getSelectedVersion(context, endpoint) as number,
                        ["Staging"],
                        refetch
                      )
                    }
                    color="primary"
                  >
                    <DeployIcon />
                  </IconButton>
                )
              )}
            </React.Fragment>
          ) : getSelectedVersion(context, endpoint) === undefined ||
            getSelectedVersion(context, endpoint) ===
              endpoint.environments["Staging"] ? (
            <NothingIcon />
          ) : isRepositoryEndpoint ? (
            <FontAwesomeIcon icon={faCodeBranch} />
          ) : (
            <IconButton
              onClick={() =>
                onClickDeploy(
                  appContext,
                  endpoint.id as string,
                  getSelectedVersion(context, endpoint) as number,
                  ["Staging"],
                  refetch
                )
              }
              color="primary"
            >
              <DeployIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell className={classes.center}>
          {"Production" in endpoint.environments ? (
            <React.Fragment>
              {getSelectedVersion(context, endpoint) === undefined ||
              getSelectedVersion(context, endpoint) ===
                endpoint.environments["Production"] ? (
                <React.Fragment>
                  <FontAwesomeIcon icon={faCheck} size="lg" color="green" />{" "}
                  {endpoint.environments["Production"]}
                </React.Fragment>
              ) : isRepositoryEndpoint ? (
                <FontAwesomeIcon
                  icon={faCodeBranch}
                  className={classes.centerText}
                />
              ) : (
                <IconButton
                  onClick={() =>
                    onClickDeploy(
                      appContext,
                      endpoint.id as string,
                      getSelectedVersion(context, endpoint) as number,
                      ["Production"],
                      refetch
                    )
                  }
                  disabled={ability.cannot("edit", "Endpoint")}
                  color="primary"
                >
                  <DeployIcon />
                </IconButton>
              )}
            </React.Fragment>
          ) : getSelectedVersion(context, endpoint) === undefined ||
            getSelectedVersion(context, endpoint) ===
              endpoint.environments["Production"] ? (
            <NothingIcon />
          ) : isRepositoryEndpoint ? (
            <FontAwesomeIcon
              icon={faCodeBranch}
              className={classes.centerText}
            />
          ) : (
            <IconButton
              onClick={() =>
                onClickDeploy(
                  appContext,
                  endpoint.id as string,
                  getSelectedVersion(context, endpoint) as number,
                  ["Production"],
                  refetch
                )
              }
              color="primary"
            >
              <DeployIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell align="right">
          <Link
            className={
              context.selectedVersions.some(
                (v) => v.endpointName === endpoint.name
              ) === false
                ? classes.hidden
                : ""
            }
            to={
              context.selectedVersions.some(
                (v) => v.endpointName === endpoint.name
              )
                ? `/Endpoint/${endpoint.name}/${
                    context.selectedVersions.find(
                      (v) => v.endpointName === endpoint.name
                    )!.endpointVersion.version
                  }/View/${isRepositoryEndpoint ? "Repository" : "Magic"}`
                : ""
            }
          >
            <Tooltip title="View" aria-label={"View " + endpoint.name}>
              <IconButton
                id={`${endpoint.name}-view`}
                className={classes.margin}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Link>
          {isRepositoryEndpoint === false && (
            <React.Fragment>
              <Can I="create" a="Endpoint">
                <Link
                  to={
                    context.selectedVersions.some(
                      (v) => v.endpointName === endpoint.name
                    )
                      ? `/Endpoint/${endpoint.name}/${
                          getLatestVersion(endpoint) + 1
                        }/New?derivedVersion=${
                          context.selectedVersions.find(
                            (v) => v.endpointName === endpoint.name
                          )!.endpointVersion.version
                        }`
                      : `/Endpoint/${endpoint.name}/${
                          getLatestVersion(endpoint) + 1
                        }/New`
                  }
                >
                  <Tooltip
                    title="Create new version"
                    aria-label={"Create a new version of " + endpoint.name}
                  >
                    <IconButton
                      id={`${endpoint.name}-new-version`}
                      className={classes.margin}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Can>
              <Can I="delete" a="Endpoint">
                <Tooltip title="Delete" aria-label={"Delete " + endpoint.name}>
                  <IconButton
                    className={classes.margin}
                    id={`${endpoint.name}-delete`}
                    onClick={onClickDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Can>
              <Can I="edit" a="Endpoint">
                <Link
                  className={
                    context.selectedVersions.some(
                      (v) => v.endpointName === endpoint.name
                    ) === false
                      ? classes.hidden
                      : null
                  }
                  to={
                    context.selectedVersions.some(
                      (v) => v.endpointName === endpoint.name
                    )
                      ? `/Endpoint/${endpoint.name}/${
                          context.selectedVersions.find(
                            (v) => v.endpointName === endpoint.name
                          )!.endpointVersion.version
                        }`
                      : ""
                  }
                >
                  <Tooltip title="Edit" aria-label={"Edit " + endpoint.name}>
                    <IconButton
                      id={`${endpoint.name}-edit`}
                      className={classes.margin}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              </Can>
              <Can I="edit" a="Endpoint">
                <EndpointsDebugSwitch
                  context={context}
                  endpoint={endpoint}
                  setPersistTransactionData={setPersistTransactionData}
                />
              </Can>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
    );
  }
);

const getSelectedVersion = (
  context: EndpointsContext,
  endpoint: IEndpoint
): number | undefined => {
  return context.selectedVersions.some((v) => v.endpointName === endpoint.name)
    ? context.selectedVersions.find((v) => v.endpointName === endpoint.name)!
        .endpointVersion.version
    : undefined;
};

const getLatestVersion = (endpoint: IEndpoint) => {
  return Math.max(...endpoint.versions.map((v) => v.version), 0);
};

const onClickDeploy = async (
  appContext: AppContext,
  endpointId: string,
  version: number,
  environments: string[],
  refetch: () => void
) => {
  const confirmed = window.confirm(
    `Are you sure you want to deploy version ${version} to ${environments}?`
  );
  if (confirmed) {
    await EndpointService.Deploy(appContext, version, endpointId, environments);
    await refetch();
  }
};

export default withStyles(styles, { withTheme: true })(EndpointsTableRow);
