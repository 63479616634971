import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box, Container } from "@material-ui/core";

const ReadOnlyAlert = () => {
  return (
    <Container>
      <Box css={{ marginY: 2 }}>
        <Alert severity="warning">
          <AlertTitle>Read Only</AlertTitle>
          Viewing an endpoint in read only mode. You will not be able to save any changes.
        </Alert>
      </Box>
    </Container>
  )
} 

export default ReadOnlyAlert;