import * as React from 'react';
import HelpContext from './HelpContext';
import { observer } from 'mobx-react';
import { IconButton, Popper, Fade, Paper, Typography, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CopyToClipboard from 'react-copy-to-clipboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import useOnClickOutside from 'use-onclickoutside';


interface IPublicProps {
    classes: any;
    helpText: JSX.Element | string;
    context?: HelpContext;
    displayIcon?: () => JSX.Element;
    copyableValue?: string;
}

interface IProps extends IPublicProps {
    context: HelpContext;
}

const styles = {
    paper: {
        maxWidth: '600pt'
    },
    body: {
        display:'flex', 
        backgroundColor: '#F0F0F0'
    },
    title: {
        flex: 1
    }
}

const Help: React.FC<IProps> = observer(({ classes, helpText, displayIcon, copyableValue, context }) => {
    
    const ref = React.useRef(null);
    const onClickHelp = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        context.helpAnchorEl = event.currentTarget;
    }

    const handleClickOutside = () => {
        context.helpAnchorEl = undefined;
    }
    
    useOnClickOutside(ref, handleClickOutside);


    return (
        <React.Fragment>
            <IconButton 
                onClick={onClickHelp}>
                {displayIcon ? displayIcon() : <HelpIcon />}
            </IconButton>
            <Popper
                style={{zIndex: 1000}}
                open={context.helpAnchorEl !== undefined} 
                anchorEl={context.helpAnchorEl} 
                transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper ref={ref} style={styles.paper}>
                        <div style={styles.body}>
                            <Typography style={styles.title}/>
                            {
                                copyableValue && (
                                    <CopyToClipboard text={(copyableValue)}>
                                        <Tooltip title="Copy to clipboard">
                                            <IconButton size="small">
                                                <AssignmentIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </CopyToClipboard>
                                )
                            }
                            <Tooltip title="Close window">
                                <IconButton size="small" onClick={handleClickOutside}>
                                    <CloseIcon/>
                                </IconButton>   
                            </Tooltip> 
                        </div> 
                        <div className={classes.margin}>
                            {helpText}
                        </div>
                    </Paper>
                </Fade>
                )}
            </Popper>
        </React.Fragment>
    )

});

const withContext = (WrappedComponent: React.ComponentType<any>) => {
    class HOC extends React.Component<IPublicProps> {
        render() {
            const context = new HelpContext();

            return (
                <WrappedComponent
                    {...this.props}
                    context={context} />
            );
        }
    }
    return HOC;
}

export default withContext(Help);