import { Avatar, Badge, Chip, Grid, Button } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import ViewListIcon from "@material-ui/icons/ViewList";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "brace/mode/json";
import "brace/mode/xml";
import "brace/theme/tomorrow";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import AceEditor from "react-ace";
import AppContext from "../../../App.Context";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import DataFormatHelper from "../../../Common/Helpers/DataFormatHelper";
import IFHIRValidationResult from "../../../Common/Interfaces/IValidationResult";
import DataTree from "../../DataTree/DataTree";
import Loading from "../../Loading/Loading";
import ValidationViewer from "../../ValidationViewer/ValidationViewer";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import { EndpointStepType } from "../EndpointStepType";
import { ViewingMode } from "../ViewingMode";
import { XSDPreviewNamespaceSelect } from "../../XSDPreviewNamespaceSelect/XSDPreviewNamespaceSelect";
import {
  getPreviewSchema,
  updateSchema,
  validateExampleSchema,
} from "../ParsedViewerHelpers";
import AutorenewIcon from "@material-ui/icons/Autorenew";

interface IProps {
  appContext: AppContext;
  context: EndpointWizardContext;
  classes: any;
}

const ParsedInputViewer: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    const generateSchema = async () => {
      context
        .updateInputSchema()
        .then(() => validateExampleSchema(context, appContext));
    };

    const getNumberOfValidationErrors = () => {
      return context.validationResult!.messages.length;
    };

    const enableControls = () => {
      return (
        context.input.mode !== DataFormat.None &&
        context.input.mode !== DataFormat.Stream &&
        context.input.mode !== DataFormat.Callback
      );
    };

    const displayPreviewOption = () => {
      return (
        context.input.mode !== DataFormat.None &&
        context.input.mode !== DataFormat.Stream
      );
    };

    return (
      <Grid item md={6}>
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup value={context.input.viewingMode} exclusive>
            <ToggleButton
              onClick={() => (context.input.viewingMode = ViewingMode.Tree)}
              value="Tree"
            >
              <ViewListIcon />
            </ToggleButton>
            {displayPreviewOption() && (
              <ToggleButton
                id="preview"
                onClick={() =>
                  (context.input.viewingMode = ViewingMode.Preview)
                }
                value="Preview"
              >
                <VisibilityIcon />
              </ToggleButton>
            )}
            {context.input.fhir.validationResult &&
              context.input.mode !== DataFormat.XML && (
                <ToggleButton
                  onClick={() =>
                    (context.input.viewingMode = ViewingMode.Validation)
                  }
                  value="Validation"
                  id="validation-toggle"
                >
                  <Badge
                    badgeContent={
                      context.input.fhir.validationResult.messages.length
                    }
                    className={classes.badge}
                    color="primary"
                  >
                    <ErrorIcon />
                  </Badge>
                </ToggleButton>
              )}
            {context.validationResult && !context.validationResult?.valid && (
              <ToggleButton
                onClick={() =>
                  (context.input.viewingMode = ViewingMode.Validation)
                }
                value="Validation"
                id="validation-toggle"
              >
                <Badge
                  badgeContent={getNumberOfValidationErrors()}
                  className={classes.badge}
                  color={
                    context.validationResult!.messages.filter((e) => e.severity)
                      .length > 0
                      ? "error"
                      : "secondary"
                  }
                >
                  <ErrorIcon />
                </Badge>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {(context.input.viewingMode === ViewingMode.Preview ||
            context.input.viewingMode === ViewingMode.Validation) && (
            <Button
              id="generate-schema-button"
              disabled={!enableControls()}
              className={classes.margin}
              variant="text"
              onClick={generateSchema}
            >
              <AutorenewIcon />
            </Button>
          )}
          <Chip
            avatar={
              <Avatar
                className={classNames(
                  context.valid(EndpointStepType.Input) === false &&
                    classes.invalid
                )}
              >
                {context.valid(EndpointStepType.Input) ? (
                  <DoneIcon />
                ) : (
                  <ErrorIcon htmlColor="#fff" />
                )}
              </Avatar>
            }
            className={classNames(
              classes.margin,
              context.valid(EndpointStepType.Input) === false && classes.invalid
            )}
            label={context.valid(EndpointStepType.Input) ? "Valid" : "Invalid"}
            color={
              context.valid(EndpointStepType.Input) ? "primary" : undefined
            }
          />
          {context.input.viewingMode === ViewingMode.Preview &&
            !context.input.error &&
            [DataFormat.XML, DataFormat.HL7FHIRXML].includes(
              context.input.mode
            ) && (
              <XSDPreviewNamespaceSelect
                endpointData={context.input}
                schemas={context.xmlInputSchemas}
              />
            )}
        </div>

        <div
          className={classNames(classes.propsColumn, classes.dataTreeContainer)}
        >
          {context.input.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <React.Fragment>
              {context.input.viewingMode === ViewingMode.Tree &&
                !context.input.error && (
                  <DataTree
                    appContext={appContext}
                    classes={classes}
                    context={context}
                    deletable={false}
                    draggable={false}
                    importable={false}
                    previewable={false}
                    data={context.input.data}
                    mode={context.input.mode}
                  />
                )}
              {context.input.viewingMode === ViewingMode.Preview &&
                !context.input.error && (
                  <div>
                    <AceEditor
                      mode={DataFormatHelper.GetSchemaModeString(
                        context.input.mode
                      )}
                      onChange={(schema: string) => {
                        updateSchema(appContext, schema, context);
                      }}
                      theme="tomorrow"
                      fontSize={20}
                      showPrintMargin={true}
                      width="100%"
                      showGutter={true}
                      highlightActiveLine={false}
                      value={getPreviewSchema(context)}
                      readOnly={!enableControls()}
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{
                        highlightGutterLine: false,
                      }}
                    />
                  </div>
                )}
              {context.input.viewingMode === ViewingMode.Validation && (
                <ValidationViewer
                  appContext={appContext}
                  locationLabel="Location"
                  validationResult={
                    [DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(
                      context.input.mode
                    )
                      ? (context.input.fhir
                          .validationResult as IFHIRValidationResult)
                      : context.validationResult!
                  }
                />
              )}
            </React.Fragment>
          )}
        </div>
      </Grid>
    );
  }
);

export default ParsedInputViewer;
