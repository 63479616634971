import { faClock, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Divider,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  withStyles,
} from "@material-ui/core";
import { observer } from "mobx-react";
import IJob from "../../Common/Interfaces/IJob";
import MagicFriendlyDate from "../MagicFriendlyDate/MagicFriendlyDate";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import AceEditor from "react-ace";
import { DataFormat } from "../../Common/Enums/DataFormat";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import { Link } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import * as React from "react";
import dayjs from "dayjs";
import { Can } from "../Authorisation/AbilityContext";

interface IProps {
  job: IJob;
  onClickDelete: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    job: IJob
  ) => void;
  onClickActive: (job: IJob) => void;
  classes: any;
}

const isPastEndDate = (job: IJob) => {
  if (job.endDate && job.next) {
    if (new Date(job.endDate).getTime() < job.next.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const JoblistJob: React.FC<IProps> = observer(
  ({ classes, job, onClickActive, onClickDelete }) => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Avatar className={classes.margin}>
            <FontAwesomeIcon icon={faClock} size="lg" />
          </Avatar>
          <Grid container justify="space-between">
            <Grid item xs={3}>
              <Typography className={classes.margin}>{job.name}</Typography>
              <Typography color="textSecondary" className={classes.margin}>
                Added: {dayjs(job.added).format("DD/MM/YYYY, HH:mm")}
              </Typography>
            </Grid>
            <Grid container item xs={3} alignContent="center">
              <Typography>
                <b>Last run:</b> <MagicFriendlyDate date={job.last as Date} />
              </Typography>
            </Grid>
            <Grid container item xs={3} alignContent="center">
              <Typography>
                <b>Next run:</b>{" "}
                {job.active ? (
                  isPastEndDate(job) ? (
                    "Past Job End Date"
                  ) : (
                    <MagicFriendlyDate date={job.next as Date} />
                  )
                ) : (
                  <em>Inactive</em>
                )}{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                onClick={(e) => e.stopPropagation()}
                control={
                  <Can I="edit" a="Job">
                    <Switch
                      onChange={() => onClickActive(job)}
                      checked={job.active}
                    />
                  </Can>
                }
                label={job.active ? "Active" : "Inactive"}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <Can I="view" a="Job">
          <AccordionDetails>
            <Avatar className={classes.margin}>
              <FontAwesomeIcon icon={faGlobe} size="lg" />
            </Avatar>
            <Grid container>
              <Grid item xs={3}>
                <Typography>{job.endpointName}</Typography>
              </Grid>
              <Grid item xs={9}>
                <AceEditor
                  className={classNames(
                    classes.margin,
                    classes.jobPreviewEditor
                  )}
                  mode={DataFormatHelper.GetModeString(
                    job.inputFormat as DataFormat
                  )}
                  theme="tomorrow"
                  fontSize={20}
                  showPrintMargin={true}
                  height="200px"
                  width="100%"
                  readOnly={true}
                  showGutter={true}
                  highlightActiveLine={false}
                  value={job.input}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 4,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Can>
        <Divider />
        <AccordionActions>
          <Can I="edit" a="Job">
            <Link to={`/Job/${job.id}`}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
          </Can>
          <Can I="delete" a="Job">
            <IconButton
              onClick={(e) => onClickDelete(e, job)}
              id={`${job.name}-delete`}
            >
              <DeleteIcon />
            </IconButton>
          </Can>
        </AccordionActions>
      </Accordion>
    );
  }
);

export default withStyles(appStyles, { withTheme: true })(JoblistJob);
