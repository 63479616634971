import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Paper, Select, useTheme, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { appStyles } from '../../App.Styles';
import { LoggableAction } from '../../Common/Enums/LoggableAction';
import LoggableActionHelpers from '../../Common/Helpers/LoggableActionHelpers';

interface IProps {
    actions: LoggableAction[];
    classes: any;
    handleActionFilter: (event: React.ChangeEvent<{ value: any; }>) => void;
    toggleActionSelect: () => void;
}

const UsageDataActions: React.FC<IProps> = observer(({ actions, classes, handleActionFilter, toggleActionSelect }) => {
    const theme = useTheme();
    return <Paper style={{padding: theme.spacing(1)}}>
    <FormControl fullWidth>
        <InputLabel shrink>Actions</InputLabel>
        <Button onClick={toggleActionSelect} >
            <FontAwesomeIcon icon={faCheckCircle}/> 
            <span className={classes.marginLeft}>Toggle All</span>
        </Button>
    </FormControl>
    <FormControl fullWidth>
        <Select
            multiple
            value={actions}
            onChange={e => handleActionFilter(e)}
            input={<Input />}
            renderValue={selected => `${(selected as number[]).length} selected`}>
            {Object.keys(LoggableAction)
                .filter(key => isNaN(Number(LoggableAction[key as any])))
                .map((key) => (
                    <MenuItem 
                        key={key} 
                        value={Number(key)}>
                        <Checkbox checked={actions.some(e => e === (LoggableAction as any)[LoggableAction[key as any]])} />
                        <ListItemText primary={LoggableActionHelpers.GetDisplayName(
                            (LoggableAction as any)[(LoggableAction as any)[key as any]])} />
                    </MenuItem>
            ))}
        </Select>
    </FormControl>
</Paper>
})

export default withStyles(appStyles, { withTheme: true })(UsageDataActions);