import "react-quill/dist/quill.snow.css";

import { Button, Grid, TextField } from "@material-ui/core";
import React, { useRef } from "react";

import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import MESHOutputProperties from "./MESHOutputProperties";
import PropertyEditor from "../PathEditor/PropertyEditor";
import { observer } from "mobx-react";

const MESHOutput: React.FC<IOutputProps> = observer(
  ({
    context,
    onDrop,
    appContext,
    task,
    classes,
    onClickEditPath,
  }) => {
    const bodyRef = useRef<HTMLInputElement>();

    return (
      <Grid item md={6} onDrop={onDrop}>
        {context.tasks[task].editingPropertyToken && (
          <PropertyEditor
            appContext={appContext}
            context={context}
            task={task}
          />
        )}
        {context.tasks[task].editingPropertyToken === undefined && (
            <React.Fragment>
              {context.tasks[task].output.loading ? (
                <Loading fullscreen={false} />
              ) : (
                <React.Fragment>
                  <MESHOutputProperties
                    task={context.tasks[task]}
                    classes={classes}
                    appContext={appContext}
                  />
                  {context.tasks[task].selectedPropertyToken && (
                    <div className={classes.emailPathLink}>
                      <Button
                        fullWidth
                        className={classes.margin}
                        onClick={onClickEditPath}
                        variant={"text"}
                      >
                        <LinkIcon />
                      </Button>
                    </div>
                  )}
                  <TextField
                    label="Message"
                    multiline
                    style={{ height: "100%", width: "100%" }}
                    rows="27"
                    value={context.tasks[task].meshBody.content}
                    inputRef={bodyRef}
                    onSelect={(_) =>
                      (context.tasks[task].meshBody.cursorLocation =
                        (bodyRef.current?.selectionStart as number) || null)
                    }
                    onChange={(e) =>
                      (context.tasks[task].meshBody.content = e.target.value)
                    }
                    onFocus={(_) =>
                      (context.tasks[task].activeTextInput =
                        context.tasks[task].meshBody)
                    }
                    variant="filled"
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </Grid>
    );
  }
);

export default MESHOutput;
