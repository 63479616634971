import "brace/mode/json";
import "brace/mode/xml";
import "brace/theme/tomorrow";

import {
  Avatar,
  Badge,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useRef } from "react";
import { getPreviewSchema, updateSchema } from "../ParsedViewerHelpers";

import AceEditor from "react-ace";
import AppContext from "../../../App.Context";
import { Behaviour } from "../../../Common/Enums/Behaviour";
import { Cancel } from "@material-ui/icons";
import CodeIcon from "@material-ui/icons/Code";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import DataFormatHelper from "../../../Common/Helpers/DataFormatHelper";
import { DataOriginSelect } from "../../DataOriginSelect/DataOriginSelect";
import DataTree from "../../DataTree/DataTree";
import DoneIcon from "@material-ui/icons/Done";
import EndpointData from "../Context/Step.Context";
import { EndpointStepType } from "../EndpointStepType";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import ErrorIcon from "@material-ui/icons/Error";
import FunctionSelector from "../../FunctionSelector/FunctionSelector";
import IValidationResult from "../../../Common/Interfaces/IValidationResult";
import Loading from "../../Loading/Loading";
import { MapType } from "../../../Common/Enums/MapType";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ResponseHandlerType } from "../../../Common/Enums/ResponseHandlerType";
import Search from "@material-ui/icons/Search";
import SecretIcon from "@material-ui/icons/VpnKey";
import SecretSelector from "../../SecretSelector/SecretSelector";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import UnknownIcon from "@material-ui/icons/Help";
import ValidationViewer from "../../ValidationViewer/ValidationViewer";
import VariableIcon from "@material-ui/icons/AttachMoney";
import VariableSelector from "../../VariableSelector/VariableSelector";
import ViewListIcon from "@material-ui/icons/ViewList";
import { ViewingMode } from "../ViewingMode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { XSDPreviewNamespaceSelect } from "../../XSDPreviewNamespaceSelect/XSDPreviewNamespaceSelect";
import classNames from "classnames";
import { observer } from "mobx-react";

interface IProps {
  appContext: AppContext;
  context: EndpointWizardContext;
  classes: any;
  task: number;
}

const GetDataOriginEndpointData = (
  task: number,
  context: EndpointWizardContext
): EndpointData => {
  if (
    [undefined, null, -1].includes(
      context.tasks[task].editingPropertyTokenOrigin
    ) === false
  ) {
    return context.tasks[
      context.tasks[task].editingPropertyTokenOrigin as number
    ].output;
  } else if (context.tasks[task].editingPropertyTokenOrigin === -1) {
    return context.input;
  } else if (context.tasks[task].mappingSelectedDataOrigin === -1) {
    return context.input;
  } else {
    return context.tasks[context.tasks[task].mappingSelectedDataOrigin].output;
  }
};

const ParsedOutputViewer: React.FC<IProps> = observer(
  ({ classes, context, appContext, task }) => {
    const isReceivingResponseHandler =
      context.tasks[task].behaviour === Behaviour.ResponseHandler &&
      context.tasks[task].responseHandlerTypeField.value ===
        ResponseHandlerType.Receive;
    const searchRef = useRef<HTMLInputElement>(null);
    return (
      <Grid item md={6}>
        <div className={classes.toggleContainer}>
          {context.tasks[task].editingPropertyToken === undefined && (
            <React.Fragment>
              <ToggleButtonGroup
                value={context.tasks[task].output.viewingMode}
                exclusive
              >
                <ToggleButton
                  onClick={() =>
                    (context.tasks[task].output.viewingMode = ViewingMode.Tree)
                  }
                  value="Tree"
                >
                  <ViewListIcon />
                </ToggleButton>
                {(context.tasks[task].output.mapType === MapType.Path ||
                  context.tasks[task].output.mapType === MapType.XSLT) && (
                  <ToggleButton
                    id="button-functions"
                    onClick={() =>
                      (context.tasks[task].output.viewingMode =
                        ViewingMode.Functions)
                    }
                    value="Functions"
                  >
                    <CodeIcon />
                  </ToggleButton>
                )}
                <ToggleButton
                  id="button-preview"
                  onClick={() =>
                    (context.tasks[task].output.viewingMode =
                      ViewingMode.Preview)
                  }
                  value="Preview"
                >
                  <VisibilityIcon />
                </ToggleButton>
                {context.tasks[task].output.fhir.validationResult && (
                  <ToggleButton
                    onClick={() =>
                      (context.tasks[task].output.viewingMode =
                        ViewingMode.Validation)
                    }
                    value="Validation"
                  >
                    <Badge
                      badgeContent={
                        context.tasks[task].output.fhir.validationResult!
                          .messages.length
                      }
                      className={
                        context.tasks[task].output.fhir.validationResult!
                          .messages.length
                          ? classes.badge
                          : null
                      }
                      color="primary"
                    >
                      <ErrorIcon htmlColor="#fff" />
                    </Badge>
                  </ToggleButton>
                )}
                <ToggleButton
                  onClick={() =>
                    (context.tasks[task].output.viewingMode =
                      ViewingMode.Secrets)
                  }
                  value="Secrets"
                >
                  <SecretIcon />
                </ToggleButton>
                <ToggleButton
                  onClick={() =>
                    (context.tasks[task].output.viewingMode =
                      ViewingMode.Variables)
                  }
                  value="Variables"
                >
                  <VariableIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Chip
                id="output-valid-chip"
                avatar={
                  <Avatar
                    className={classNames(
                      context.valid(EndpointStepType.Output, task) === false &&
                        context.tasks[task].output.modified === false &&
                        classes.invalid
                    )}
                  >
                    {context.tasks[task].output.modified ? (
                      <UnknownIcon />
                    ) : context.valid(EndpointStepType.Output, task) ? (
                      <DoneIcon />
                    ) : (
                      <ErrorIcon htmlColor="#fff" />
                    )}
                  </Avatar>
                }
                className={classNames(
                  classes.margin,
                  context.valid(EndpointStepType.Output, task) === false &&
                    context.tasks[task].output.modified === false &&
                    classes.invalid
                )}
                clickable={context.tasks[task].output.modified}
                onClick={
                  context.tasks[task].output.modified
                    ? () => context.updatePreview()
                    : undefined
                }
                onDelete={
                  context.tasks[task].output.modified
                    ? () => context.updatePreview()
                    : undefined
                }
                deleteIcon={<RefreshIcon aria-label="Check Validity" />}
                label={
                  <React.Fragment>
                    {context.tasks[task].output.modified ? (
                      <em className={classes.margin}>Modified</em>
                    ) : context.valid(EndpointStepType.Output, task) ? (
                      "Valid"
                    ) : (
                      "Invalid"
                    )}
                  </React.Fragment>
                }
                color={
                  context.tasks[task].output.modified
                    ? "default"
                    : context.valid(EndpointStepType.Output, task)
                    ? "primary"
                    : undefined
                }
              />
            </React.Fragment>
          )}
          {context.tasks[task].output.viewingMode === ViewingMode.Preview &&
            !context.tasks[task].output.error &&
            [DataFormat.XML, DataFormat.HL7FHIRXML].includes(
              context.tasks[task].output.mode
            ) && (
              <XSDPreviewNamespaceSelect
                endpointData={context.tasks[task].output}
                schemas={context.tasks[task].responseHandlerXmlSchemas}
              />
            )}
          {context.tasks[task].output.viewingMode === ViewingMode.Tree &&
            isReceivingResponseHandler === false && (
              <DataOriginSelect
                tasks={context.tasks}
                taskIndex={task}
                editPathMode={
                  context.tasks[task].editingPropertyTokenOrigin !== undefined
                }
                value={
                  context.tasks[task].editingPropertyTokenOrigin === undefined
                    ? context.tasks[task].mappingSelectedDataOrigin
                    : (context.tasks[task].editingPropertyTokenOrigin as number)
                }
              />
            )}
        </div>
        <div
          className={classNames(classes.propsColumn, classes.dataTreeContainer)}
        >
          {context.loading || context.tasks[task].output.loading ? (
            <Loading fullscreen={false} />
          ) : (
            <React.Fragment>
              {context.tasks[task].output.viewingMode === ViewingMode.Tree && (
                <div>
                  <TextField
                    className={classNames(classes.dataTreeSearch)}
                    placeholder="search term"
                    label="Search"
                    fullWidth
                    variant="outlined"
                    inputRef={searchRef}
                    onChange={(e) =>
                      (context.input.searchTerm = e.target.value)
                    }
                    value={context.input.searchTerm}
                    id="inputTreeSearch"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => (context.input.searchTerm = "")}
                          >
                            {context.input.searchTerm ? <Cancel /> : null}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              {context.tasks[task].output.viewingMode === ViewingMode.Tree && (
                <DataTree
                  id="inputTree"
                  appContext={appContext}
                  classes={classes}
                  context={context}
                  searchTerm={context.input.searchTerm}
                  data={GetDataOriginEndpointData(task, context).data}
                  dataOrigin={
                    context.tasks[task].editingPropertyTokenOrigin === undefined
                      ? context.tasks[task].mappingSelectedDataOrigin
                      : context.tasks[task].editingPropertyTokenOrigin
                  }
                  draggable={
                    context.tasks[task].editingPropertyToken === undefined &&
                    isReceivingResponseHandler === false
                  }
                  deletable={false}
                  importable={
                    context.tasks[task].editingPropertyToken === undefined &&
                    isReceivingResponseHandler === false
                  }
                  previewable={isReceivingResponseHandler === false}
                  mode={GetDataOriginEndpointData(task, context).mode}
                />
              )}
              {context.tasks[task].output.viewingMode ===
                ViewingMode.Functions && (
                <FunctionSelector
                  appContext={appContext}
                  context={context}
                  classes={classes}
                  draggable={
                      context.isEditorTokenImportEnabled
                  }
                  task={task}
                />
              )}
              {context.tasks[task].output.viewingMode ===
                ViewingMode.Secrets && (
                <SecretSelector
                  appContext={appContext}
                  context={context}
                  classes={classes}
                  // MAG-610
                  draggable={context.isEditorTokenImportEnabled}
                  task={task}
                />
              )}
              {context.tasks[task].output.viewingMode ===
                ViewingMode.Variables && (
                <VariableSelector
                  appContext={appContext}
                  context={context}
                  classes={classes}
                  // MAG-610
                  draggable={context.isEditorTokenImportEnabled}
                  task={task}
                />
              )}
              {context.tasks[task].output.viewingMode ===
                ViewingMode.Validation && (
                <ValidationViewer
                  appContext={appContext}
                  locationLabel="Location"
                  validationResult={
                    context.tasks[task].output.fhir
                      .validationResult as IValidationResult
                  }
                />
              )}
              {context.tasks[task].output.viewingMode === ViewingMode.Preview &&
                ([
                  Behaviour.Request,
                  Behaviour.Job,
                  Behaviour.Response,
                  Behaviour.Mapping,
                  Behaviour.ResponseHandler,
                  Behaviour.Redirect,
                ].includes(context.tasks[task].behaviour) ? (
                  isReceivingResponseHandler ? (
                    <AceEditor
                      mode={DataFormatHelper.GetModeString(
                        context.tasks[task].output.mode
                      )}
                      onChange={(schema: string) =>
                        updateSchema(appContext, schema, context, task)
                      }
                      theme="tomorrow"
                      fontSize={20}
                      showPrintMargin={true}
                      width="100%"
                      showGutter={true}
                      highlightActiveLine={false}
                      value={getPreviewSchema(context, task)}
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{
                        highlightGutterLine: false,
                      }}
                    />
                  ) : (
                    <AceEditor
                      name="output-editor"
                      mode={DataFormatHelper.GetModeString(
                        context.tasks[task].output.mode
                      )}
                      theme="tomorrow"
                      fontSize={20}
                      showPrintMargin={true}
                      width="100%"
                      showGutter={true}
                      highlightActiveLine={false}
                      value={context.tasks[task].preview}
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{
                        readOnly: true,
                        highlightGutterLine: false,
                      }}
                    />
                  )
                ) : (
                  <>
                    {context.tasks[task].isRawHtml ? (
                      <AceEditor
                        name="output-editor"
                        mode="html"
                        theme="tomorrow"
                        fontSize={20}
                        showPrintMargin={true}
                        width="100%"
                        showGutter={true}
                        readOnly
                        highlightActiveLine={false}
                        value={context.tasks[task].preview}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          readOnly: true,
                          highlightGutterLine: false,
                        }}
                      />
                    ) : (
                      <TextField
                        id="preview-text"
                        label="Message"
                        multiline
                        style={{ width: "100%" }}
                        rows="23"
                        value={context.tasks[task].preview}
                        className={classes.marginTop}
                        variant="filled"
                      />
                    )}
                  </>
                ))}
            </React.Fragment>
          )}
        </div>
      </Grid>
    );
  }
);

export default ParsedOutputViewer;
