import * as React from "react";
import { observer } from "mobx-react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { styles } from "./Account.Styles";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Input,
  Paper,
  Avatar,
  Typography,
  SnackbarContent,
} from "@material-ui/core";
import { Redirect, withRouter, RouteComponentProps } from "react-router";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import Loading from "../Loading/Loading";
import ResetContext from "./Reset.Context";

@observer
class ResetPassword extends React.Component<
  IComponentProps & RouteComponentProps
> {
  private _context: ResetContext = new ResetContext(
    this.props.appContext,
    this.props.location.search.match("email=(.+)&code=(.+)")
  );

  public render() {
    return (
      <React.Fragment>
        {this._context.redirect ? (
          <Redirect to="/Login" />
        ) : this._context.loading ? (
          <Loading fullscreen={true} />
        ) : (
          <div className={this.props.classes.main}>
            <Paper className={this.props.classes.paper}>
              <Avatar className={this.props.classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <form
                className={this.props.classes.form}
                noValidate
                autoComplete="off"
                onSubmit={this._context.onSubmit}
              >
                {this._context.providePasswordMode ? (
                  <React.Fragment>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        name="password"
                        type="password"
                        id="password"
                        value={this._context.password.value}
                        error={this._context.password.hasError}
                        onChange={(e) =>
                          this._context.password.onChange(e.target.value)
                        }
                      />
                    </FormControl>
                    {this._context.password.hasError ? (
                      <SnackbarContent
                        className={this.props.classes.error}
                        message={
                          <span className={this.props.classes.message}>
                            <ErrorIcon />
                            {this._context.password.error}
                          </span>
                        }
                      />
                    ) : null}
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="password">
                        Confirm Password
                      </InputLabel>
                      <Input
                        name="confirmPassword"
                        type="password"
                        id="confirmPassword"
                        value={this._context.confirmPassword.value}
                        error={this._context.confirmPassword.hasError}
                        onChange={(e) =>
                          this._context.confirmPassword.onChange(e.target.value)
                        }
                      />
                    </FormControl>
                    {this._context.confirmPassword.hasError ? (
                      <SnackbarContent
                        className={this.props.classes.error}
                        message={
                          <span className={this.props.classes.message}>
                            <ErrorIcon />
                            {this._context.confirmPassword.error}
                          </span>
                        }
                      />
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel>Email</InputLabel>
                      <Input
                        autoFocus
                        value={this._context.email.value}
                        error={this._context.email.hasError}
                        onChange={(e) =>
                          this._context.email.onChange(e.target.value)
                        }
                      />
                    </FormControl>
                    {this._context.email.hasError ? (
                      <SnackbarContent
                        className={this.props.classes.error}
                        message={
                          <span className={this.props.classes.message}>
                            <ErrorIcon />
                            {this._context.email.error}
                          </span>
                        }
                      />
                    ) : null}
                  </React.Fragment>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={this.props.classes.submit}
                >
                  Reset Password
                </Button>
              </form>
            </Paper>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(ResetPassword)
);
