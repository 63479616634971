import AppContext from "../../App.Context";
import LoggingService from "../../Common/Services/Logging.Service";
import { getToken } from "../Helpers/AuthenticationHelpers";

export default class AllowedMeshMailboxesService {
  public static Get = (appContext: AppContext): Promise<string[]> => {
    const uri = `${appContext.managementHost}/MeshAllowList`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };
}
