import * as React from 'react';
import { observer } from 'mobx-react';
import onClickOutside from "react-onclickoutside";
import { TextField, MenuItem, Paper } from '@material-ui/core';
import Downshift from 'downshift';
import IComponentProps from '../../Common/Interfaces/IComponentProps';
import { IJSONPathMatch } from '../../Common/Interfaces/IJSONPathMatch';
import AutoSuggestContext from './AutoSuggest.Context';
import { DataFormat } from '../../Common/Enums/DataFormat';
import PropertyHelpers from '../../Common/Helpers/PropertyHelpers';
import XmlHelpers from '../../Common/Helpers/XmlHelpers';

interface IProps extends IComponentProps {
    matches: IJSONPathMatch[] | any[];
    mode: DataFormat;
    editedPath: string;
    editingPropertyToken: string;
    placeHolder: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (updated: string) => void;
}

@observer
class AutoSuggest extends React.Component<IProps> {
    private _context = new AutoSuggestContext();
    private _suggestPopupRef: string = 'autosuggest-popup';

    public handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {
        // react-onclickoutside doesn't really work for relative elements
        if (!(e.target as Element).closest(`#${this._suggestPopupRef}`)) {
            this._context.pathSuggestionsDismissed = true;
        }
    }

    private renderSuggestion(suggestion: string, index: number, isArrayItem: boolean) {
        const editingPath: string = this.props.editedPath as string;
        if (editingPath) {
            const updatedPath = PropertyHelpers.getPathToProperty(suggestion, isArrayItem, this.props.mode, editingPath);
            return (
                <MenuItem
                    key={index}
                    component="div"
                    onClick={() => this.props.onClick(updatedPath)}>
                    {suggestion}
                </MenuItem>
            );
        }
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this._context.pathSuggestionsDismissed = false;
        this.props.onChange(e);
    }

    private getJSONSuggestions(matches: IJSONPathMatch[]): IJSONSuggestion[] {
        let suggestions: IJSONSuggestion[] = []

        // We only want to provide suggestions when a single parent has been narrowed to
        if (matches.length === 1) {
            if (typeof matches[0].value === 'object' && matches[0].value !== null) {
                Object.keys(matches[0].value).forEach((value) => {
                    const suggestion: IJSONSuggestion = {
                        value: value,
                        isArrayItem: matches[0].value.constructor === Array
                    }
                    suggestions = suggestions.concat(suggestion);
                });
            }
        }
        return suggestions;
    }

    private getXMLSuggestions(matches: HTMLElement[]): string[] {
        let suggestions: string[] = []

        // We only want to provide suggestions when a single parent has been narrowed to
        if (matches.length === 1) {
            if ((matches as HTMLElement[])[0].childElementCount > 0) {
                // get the child nodes with the same nodename - we want to create indexed paths to a specific node
                const duplicateNodes = XmlHelpers.GetDuplicateChildNodeNames((matches as HTMLElement[])[0]);

                Array.from({ length: (matches as HTMLElement[])[0].childElementCount }, (v, k) => k)
                .forEach((value, index) => {
                    const nodeName = (matches as HTMLElement[])[0].children.item(value)!.nodeName;
                    // add an indexed path if there are duplicate nodes of the same name
                    if (duplicateNodes.includes(nodeName)) {
                        suggestions.push(`${nodeName}[${index + 1}]`)
                    } else {
                        suggestions.push(nodeName);
                    }
                })
            }
            if ((matches as HTMLElement[])[0].childElementCount === 0) {
                suggestions.push('text()');
            }
            if ((matches as HTMLElement[])[0].attributes) {
                Array.from({ length: (matches as HTMLElement[])[0].attributes.length }, (v, k) => k)
                    .forEach((value) => {
                        suggestions.push(`@${(matches as HTMLElement[])[0].attributes.item(value)!.name}`)
                    })
            }
        }

        return suggestions;
    }

    public render() {        
        return (
            <React.Fragment>
                <TextField
                    autoFocus={true}
                    error={!this.props.matches || this.props.matches.length === 0}
                    label={`Path: ${this.props.editingPropertyToken}`}
                    onFocus={() => this._context.pathSuggestionsDismissed = false}
                    onChange={this.onChange}
                    placeholder={this.props.placeHolder}
                    value={this.props.editedPath || ''}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {
                    this.props.matches && this.props.matches.length > 0 &&
                        this._context.pathSuggestionsDismissed === false ?
                        <Downshift>
                            {() => (
                                <div id={this._suggestPopupRef} className={this.props.classes.container}>
                                    <Paper className={this.props.classes.paperDownshift} square>
                                        {
                                            this.props.mode === DataFormat.JSON ||
                                            this.props.mode === DataFormat.QueryString ?
                                                this.getJSONSuggestions((this.props.matches as IJSONPathMatch[]))
                                                    .map((value: IJSONSuggestion, index: number) =>
                                                        this.renderSuggestion(value.value, index, value.isArrayItem)) :
                                                null
                                        }
                                        {
                                            this.props.mode === DataFormat.XML ?
                                                this.getXMLSuggestions((this.props.matches as HTMLElement[]))
                                                    .map((suggestion: string, index: number) =>
                                                        this.renderSuggestion(suggestion, index, false)) :
                                                null
                                        }
                                    </Paper>
                                </div>
                            )}
                        </Downshift> : null
                }
            </React.Fragment>
        )
    }
}

interface IJSONSuggestion {
    value: string;
    isArrayItem: boolean;
}

export default onClickOutside(AutoSuggest);