import * as React from "react";
import { observer } from "mobx-react";
import {
  Badge,
  Chip,
  Avatar,
  withStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import AceEditor from "react-ace";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import { appStyles } from "../../App.Styles";
import ErrorIcon from "@material-ui/icons/Error";
import classNames from "classnames";
import DoneIcon from "@material-ui/icons/Done";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import ValidationViewer from "../ValidationViewer/ValidationViewer";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointInputTesterContext from "./EndpointInputTester.Context";
import CursorLocation from "../EndpointWizard/CursorLocation";

interface IProps extends IPublicProps {
  context: EndpointInputTesterContext;
}

interface IPublicProps extends IComponentProps {
  onChange: (input: string, endpoint?: IEndpoint) => void;
  onFocus?: () => void;
  onSelect?: (cursorLocation: CursorLocation | number) => void;
  endpoint: IEndpoint;
  input: string;
  version: number;
  validate?: boolean;
}

@observer
class EndpointInputTester extends React.Component<IProps> {
  public render() {
    return (
      <React.Fragment>
        {this.props.context.endpointVersion &&
          (this.props.context.endpointVersion!.schema ||
            this.props.context.endpointVersion!.xmlSchemas) && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify="space-between"
              >
                <ToggleButtonGroup
                  value={this.props.context.viewingMode}
                  exclusive
                  className={this.props.classes.margin}
                >
                  <ToggleButton
                    onClick={() => (this.props.context.viewingMode = "input")}
                    value="input"
                  >
                    <FontAwesomeIcon icon={faKeyboard} />
                  </ToggleButton>
                  {this.props.validate !== false && (
                    <ToggleButton
                      onClick={() =>
                        (this.props.context.viewingMode = "validation")
                      }
                      value="validation"
                    >
                      <Badge
                        badgeContent={
                          this.props.context.validationResult &&
                          this.props.context.validationResult.messages.length
                        }
                        className={
                          this.props.context.validationResult &&
                          this.props.context.validationResult.messages.length
                            ? this.props.classes.badge
                            : null
                        }
                        color="primary"
                      >
                        <ErrorIcon />
                      </Badge>
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
                {this.props.validate !== false && (
                  <Chip
                    avatar={
                      <Avatar
                        className={classNames(
                          !this.props.context.validationResult ||
                            (this.props.context.validationResult.valid ===
                              false &&
                              this.props.classes.invalid)
                        )}
                      >
                        {this.props.context.validationResult &&
                        this.props.context.validationResult.valid ? (
                          <DoneIcon />
                        ) : (
                          <ErrorIcon />
                        )}
                      </Avatar>
                    }
                    className={classNames(
                      this.props.classes.margin,
                      !this.props.context.validationResult ||
                        (this.props.context.validationResult.valid === false &&
                          this.props.classes.invalid)
                    )}
                    label={
                      this.props.context.validationResult &&
                      this.props.context.validationResult.valid
                        ? "Valid"
                        : "Invalid"
                    }
                    color={
                      this.props.context.validationResult &&
                      this.props.context.validationResult.valid
                        ? "primary"
                        : undefined
                    }
                  />
                )}
              </Grid>
            </React.Fragment>
          )}
        {(this.props.context.endpointVersion!.schema ||
          this.props.context.endpointVersion!.xmlSchemas) &&
          (this.props.context.viewingMode === "input" ? (
            <AceEditor
              className={this.props.classes.marginTop}
              mode={DataFormatHelper.GetModeString(
                this.props.context.endpointVersion!.inputMode
              )}
              theme="tomorrow"
              onChange={(code) => {
                this.props.context.input = code;
                this.props.onChange(code);
              }}
              onFocus={(_) => (this.props.onFocus ? this.props.onFocus() : {})}
              onCursorChange={(e) =>
                this.props.onSelect
                  ? this.props.onSelect(
                      new CursorLocation(
                        e.getCursor().row,
                        e.getCursor().column
                      )
                    )
                  : {}
              }
              fontSize={20}
              showPrintMargin={true}
              width="100%"
              showGutter={true}
              highlightActiveLine={true}
              value={this.props.context.input}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 4,
              }}
            />
          ) : this.props.context.validationResult &&
            this.props.context.validationResult.messages.length > 0 ? (
            <ValidationViewer
              appContext={this.props.appContext}
              locationLabel="Item"
              validationResult={this.props.context.validationResult}
            />
          ) : (
            <Typography
              className={this.props.classes.centerTextRel}
              variant="h5"
            >
              <FontAwesomeIcon icon={faThumbsUp} /> Looks good!
            </Typography>
          ))}
      </React.Fragment>
    );
  }
}

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IPublicProps> {
    render() {
      const context = new EndpointInputTesterContext(
        this.props.appContext,
        this.props.endpoint,
        this.props.input,
        this.props.version
      );

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(
  withContext(EndpointInputTester)
);
