import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
  Divider,
  TableSortLabel,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { loader } from "graphql.macro";
import { observer, useLocalStore } from "mobx-react";
import dayjs from "dayjs";
import React from "react";
import { useQuery } from "react-apollo";
import { Link } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import Loading from "../Loading/Loading";
import EndpointErrorContext from "./EndpointErrorContext";

interface IProps {}

const EndpointErrors: React.FC<IComponentProps> = observer((props) => {
  const context: EndpointErrorContext = useLocalStore(
    () => new EndpointErrorContext()
  );
  const { loading } = useQuery(errorQuery, {
    variables: {
      startDate: context.startDate,
      endDate: context.endDate,
      offset: context.page * context.rowsPerPage,
      limit: context.rowsPerPage,
      orderBy: context.orderBy,
      orderAscending: !context.orderDesc,
    },
    onCompleted: (data) => {
      context.total = data.endpointErrors.total;
      context.errors = data.endpointErrors.errors;
    },
  });

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    context.page = newPage;
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    context.rowsPerPage = parseInt(event.target.value, 10);
    context.page = 0;
  };

  const sort = (
    locator: string,
    setOrder: boolean,
    transformer: (input: any) => any
  ) => {
    context.orderBy = locator;
    if (setOrder) {
      context.orderDesc = !context.orderDesc;
    }
    context.page = 0;
  };

  if (loading) return <Loading fullscreen={false} />;

  return (
    <>
      <Grid container justify="center">
        <Grid
          item
          xs={8}
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
          className={props.classes.marginTop}
        >
          <Grid item xs={4}>
            <TextField
              id="datetime-from"
              label="Date From"
              type="datetime-local"
              defaultValue={context.startDate}
              value={context.startDate.format("YYYY-MM-DDTHH:mm")}
              onChange={(event) =>
                (context.startDate = dayjs(event.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="datetime-to"
              label="Date to"
              type="datetime-local"
              defaultValue={context.endDate.format("YYYY-MM-DDTHH:mm")}
              onChange={(event) =>
                (context.endDate = dayjs(event.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid item xs={8} className={props.classes.marginTop}>
          <Paper>
            <Table>
              <TableHead>
                <TableCell>
                  <TableSortLabel
                    active={context.orderBy === "timestamp"}
                    direction={context.orderDesc ? "desc" : "asc"}
                    onClick={() => sort("timestamp", true, dayjs)}
                  >
                    Timestamp
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={context.orderBy === "endpoint"}
                    direction={context.orderDesc ? "desc" : "asc"}
                    onClick={() => sort("endpoint", true, dayjs)}
                  >
                    Endpoint
                  </TableSortLabel>
                </TableCell>
                <TableCell>View Detail</TableCell>
              </TableHead>
              <TableBody>
                {context.errors.map((endpointError, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {dayjs(endpointError.timestamp).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </TableCell>
                    <TableCell>{endpointError.endpoint}</TableCell>
                    <TableCell>
                      <Link to={() => `/Error/${endpointError.id}`}>
                        <Tooltip title="View" aria-label={"View Error"}>
                          <IconButton id={`${endpointError.id}-view`}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={context.total}
                    rowsPerPage={context.rowsPerPage}
                    page={context.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});

const errorQuery = loader("../../GraphQL/Queries/errors.graphql");
export default withStyles(appStyles, { withTheme: true })(EndpointErrors);
