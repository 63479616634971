import { FieldState, FormState } from 'formstate';
import { observable } from "mobx";
import { FormEvent } from "react";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import {AccountService } from "./Account.Service";
import AppContext from '../../App.Context';
import { IAccountDetails } from './IAccountDetails';
import { AuthenticationProvider } from '../../Common/Enums/AuthenticationProvider';
export default class CompleteProfileContext {
    
    constructor(appContext: AppContext) {
        this.loading = true;
        this.appContext = appContext;
        AccountService.GetCompleteProfileDetails(appContext.managementHost)
            .then(accountDetails => {
                this.usernameField.value = accountDetails.userName || '';
                this.emailField.value = accountDetails.email || '';
                this.passwordField.value = accountDetails.smtpDetails.password;
                this.useCustomSmtpField.value = accountDetails.useCustomSmtp;
                this.smtpUsernameField.value = accountDetails.smtpDetails.username;
                this.defaultFromField.value = accountDetails.smtpDetails.defaultFrom;
                this.useSSLField.value = accountDetails.smtpDetails.useSSL;
                this.hostField.value = accountDetails.smtpDetails.host;
                this.portField.value = accountDetails.smtpDetails.port;
            })
            .finally(() => this.loading = false);            
            this.disableUsername = appContext.authenticationProvider === AuthenticationProvider.AireIdentityByTenant;
    }

    private appContext: AppContext;

    @observable
    public loading: boolean = false;

    @observable
    public responseError: string = "";

    @observable
    public disableUsername: boolean = false; 



    public passwordField = new FieldState('');
    public smtpUsernameField = new FieldState('').validators((val)=> this.useCustomSmtpField.value && !val && 'Enter a username');
    public usernameField = new FieldState('');
    public defaultFromField = new FieldState('').validators((val) => (val && !FormatValidator.email(val)) && 'Enter a valid email address');
    public emailField = new FieldState('').validators((val) => (val && !FormatValidator.email(val)) && 'Enter a valid email address');
    public useCustomSmtpField = new FieldState<boolean>(false);
    public useSSLField = new FieldState<boolean>(true);
    public hostField = new FieldState('').validators((val) => this.useCustomSmtpField.value && !val && 'Enter a host');
    public portField = new FieldState('').validators((val)=> this.useCustomSmtpField.value && !val && 'Enter a port');

    form = new FormState({
        username: this.usernameField,
        smtp: this.smtpUsernameField,
        smtpUsername: this.smtpUsernameField,
        password: this.passwordField,
        useCustomSmtp: this.useCustomSmtpField,
        useSSL: this.useSSLField,
        host: this.hostField,
        port: this.portField,
        defaultFrom: this.defaultFromField
    });

    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.loading = true;

        const validationResult = await this.form.validate();
        
        if (validationResult.hasError) {  
            this.loading = false;          
            return false;
        }

        const accountDetails: IAccountDetails = {
            userName: validationResult.value.username.value,
            useCustomSmtp: validationResult.value.useCustomSmtp.value,
            smtpDetails: {
                host: validationResult.value.host.value,
                port: validationResult.value.port.value,
                username: validationResult.value.smtpUsername.value,
                password: validationResult.value.password.value,
                useSSL: validationResult.value.useSSL.value,
                defaultFrom: validationResult.value.defaultFrom.value,
                portString: ""
            }
        };
        
        AccountService.CompleteAccountProfile(this.appContext, accountDetails)
            .then(async response => {
                if(response.ok)
                {
                this.appContext.accountDetails = accountDetails;
                this.appContext.reloadContext();
                this.appContext.redirectToHome();
                }
                else if (response.status === 409) {
                    this.responseError = "Username already taken"
                }
                else{
                    this.responseError = "Error saving profile"
                }
            })
            .finally(() => this.loading = false);
    };

}