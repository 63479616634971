import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import NextIcon from "@material-ui/icons/NavigateNext";
import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { HttpMethod } from "../../../Common/Enums/HttpMethod";
import IComponentProps from "../../../Common/Interfaces/IComponentProps";
import AuthenticationMethods from "../../AuthenticationMethods/AuthenticationMethods";
import Help from "../../Help/Help";
import Tasks from "../../Tasks/Tasks";
import EndpointWizardContext from "../Context/EndpointWizard.Context";
import BaseEditorHelpers from "../../../Common/Helpers/BaseEditorHelpers";

interface IProps extends IComponentProps {
  context: EndpointWizardContext;
  onClickNext: (nextIndex: number) => void;
  editMode: boolean;
}

@observer
export default class TasksStep extends React.Component<IProps> {
  private HandlePress = (e: React.KeyboardEvent) => {
    if (e.charCode === 32) {
      e.preventDefault();

      const targetInput = e.target as HTMLInputElement;

      targetInput.value =
        targetInput.value.slice(0, targetInput.selectionStart ?? 0) +
        "-" +
        targetInput.value.slice(targetInput.selectionStart ?? 0 + 1);
    }
  };

  private editorHelpers = new BaseEditorHelpers();

  public render() {
    const variantProps =
      !this.props.editMode && this.props.context.version === 1
        ? { variant: "outlined" as "outlined" }
        : { variant: "filled" as "filled" };
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            What should your endpoint do?
          </Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            id="endpoint-name"
            label="Endpoint Name"
            placeholder="Endpoint Name"
            onChange={(e) =>
              this.props.context.endpointNameField.onChange(e.target.value)
            }
            onKeyPress={(e) =>
              this.editorHelpers.replaceSpaceWithDashOnKeyPress(e)
            }
            autoFocus
            className={this.props.classes.marginTop}
            value={this.props.context.endpointNameField.value}
            fullWidth
            disabled={
              !(!this.props.editMode && this.props.context.version === 1)
            }
            error={this.props.context.endpointNameField.hasError}
            InputLabelProps={{
              shrink: true,
            }}
            {...variantProps}
          />
          <p>{this.props.context.endpointNameField.error}</p>
        </Grid>
        <Grid item md={12}>
          <TextField
            id="group-name"
            label="Group Name"
            placeholder="Group Name"
            onChange={(e) =>
              this.props.context.endpointGroupField.onChange(e.target.value)
            }
            className={this.props.classes.marginTop}
            value={this.props.context.endpointGroupField.value}
            fullWidth
            error={this.props.context.endpointGroupField.hasError}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <p>{this.props.context.endpointGroupField.error}</p>
        </Grid>
        <Grid item md={12}>
          <TextField
            label="Error Email"
            placeholder="An email address to send error reports to"
            onChange={(e) =>
              this.props.context.errorEmailField.onChange(e.target.value)
            }
            className={this.props.classes.marginTop}
            value={this.props.context.errorEmailField.value}
            fullWidth
            error={this.props.context.errorEmailField.hasError}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box style={{ display: "flex" }}>
            <p>{this.props.context.errorEmailField.error}</p>
            <Help
              classes={this.props.classes}
              helpText="Entering this will store exception data on error (input validation failure, exception, or endpoint returning non success status code). If the endpoint version has Debug turned on then input data will also be stored. Additionally, you have the ability to map in a variable for the error email (--*$MY_VARIABLE*--)."
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <FormControl
            className={classNames(
              this.props.classes.margin,
              this.props.classes.dataFromSelectors
            )}
          >
            <FormLabel>
              HTTP Method
              <Help
                classes={this.props.classes}
                helpText="The HTTP verb that will be used to call this endpoint"
              />
            </FormLabel>
            <Select
              value={this.props.context.method}
              onChange={(e) => {
                this.props.context.method = (HttpMethod as any)[
                  e.target.value as any
                ] as unknown as HttpMethod;
              }}
            >
              {Object.keys(HttpMethod)
                .filter((key) => isNaN(Number((HttpMethod as any)[key as any])))
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {(HttpMethod as any)[(HttpMethod as any)[key as any]]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <AuthenticationMethods
            appContext={this.props.appContext}
            authenticationMethods={this.props.context.authenticationMethods}
            editMode={this.props.context.editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <Tasks
            appContext={this.props.appContext}
            editable={true}
            editInitialLoad={this.props.context.editInitialLoad}
            tasks={this.props.context.tasks}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                (this.props.context.stopOnTaskFailure =
                  !this.props.context.stopOnTaskFailure)
                }
                checked={this.props.context.stopOnTaskFailure}
              />
            }
            label={
              <React.Fragment>
                Stop On Task Failure
                <Help
                  classes={this.props.classes}
                  helpText="If enabled, execution of endpoint tasks will stop if one task fails. The response from the failing task will then be retained if retaining is enabled"
                />
              </React.Fragment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            id="tasks-next"
            onClick={() =>
              this.props.onClickNext(this.props.context.activeStep + 1)
            }
            variant="contained"
            color="primary"
            size="large"
            disabled={
              this.props.context.allPreceedingStepsValid(
                this.props.context.activeStep + 1
              ) === false
            }
            fullWidth
          >
            Next <NextIcon fontSize="large" />
          </Button>
        </Grid>
      </Grid>
    );
  }
}
