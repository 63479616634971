import { useAuthentication } from "../../Components/Authentication/AuthWrapper";
import Constants from "../Constants";

/**
 * @returns profile | null
 */
export const useGetProfile = () => {
    const isHeadless = window.location !== window.parent.location;
    const { profile } = useAuthentication();

    if(!isHeadless) return profile
    const sessionProfile = sessionStorage.getItem(Constants.profile);
    if (sessionProfile) return JSON.parse(sessionProfile);

    return null;
}