import * as React from "react";
import { observer } from "mobx-react";
import { loader } from "graphql.macro";
import Loading from "../Loading/Loading";
import AppContext from "../../App.Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import {
  Query,
  graphql,
  DataProps,
  MutateProps,
  MutationFunction,
} from "react-apollo";
import {
  Popper,
  Fade,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import AceEditor from "react-ace";
import "brace/mode/json";
import Prettifier from "../../Common/Helpers/Prettifier";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import { DataFormat } from "../../Common/Enums/DataFormat";
import CopyToClipboard from "react-copy-to-clipboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import useOnClickOutside from "use-onclickoutside";

interface IProps {
  appContext: AppContext;
  classes: any;
  id: string;
  anchorEl?: HTMLElement;
  onDelete: () => void;
  onClose: () => void;
}

const expandContent = (str: string) => {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};

const expandProperties = (data: any, props: string[]) => {
  props.forEach((prop) => {
    if (data[prop]) {
      data[prop] = expandContent(data[prop]);
    }
  });
  return data;
};

const getContent = (transactionalData: any) => {
  if (!transactionalData) {
    return "Data not currently available";
  }
  let out = transactionalData.value;
  let data = expandContent(transactionalData.value);
  if (data) {
    data = expandProperties(data, ["Content", "Result", "Input", "Output"]);
    out = JSON.stringify(data);
  }
  return Prettifier.prettify(
    out,
    DataFormatHelper.GetDataFormatByContent(out) || DataFormat.JSON
  );
};

const onClickDelete = async (
  event: React.MouseEvent<HTMLButtonElement>,
  id?: string,
  onDelete?: () => void,
  mutate?: MutationFunction,
  close?: () => void
) => {
  if (
    id &&
    onDelete &&
    mutate &&
    close &&
    window.confirm("Are you sure you want to delete this transactional data?")
  ) {
    event.preventDefault();
    event.stopPropagation();
    mutate({
      variables: {
        id: id,
      },
    });
    await onDelete();
    close();
  }
};

const TransactionContextViewerContent: React.FC<
  Partial<DataProps<{}, {}>> & Partial<MutateProps<{}, {}> & IProps>
> = observer(
  ({ appContext, classes, id, anchorEl, onDelete, mutate, onClose }) => {
    const theme = useTheme();
    const ref = React.useRef(null);
    useOnClickOutside(ref, onClose!);

    return (
      <Query
        query={loader("../../GraphQL/Queries/transactional.graphql")}
        variables={{ id }}
      >
        {(result) => {
          const { loading, error, data } = result;
          if (loading) {
            return <Loading fullscreen={false} />;
          }
          if (error) {
            return (
              <FontAwesomeIcon
                className={classes.centerText}
                icon={faFrown}
                size="10x"
                color={theme.palette.error.main}
              />
            );
          }
          return (
            <Popper
              style={{ zIndex: 1000, width: "50%" }}
              open={anchorEl !== undefined}
              anchorEl={anchorEl}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper ref={ref}>
                    <div
                      style={{ display: "flex", backgroundColor: "#F0F0F0" }}
                    >
                      <Typography style={{ flex: 1 }} />
                      <Tooltip title="Delete transactional data">
                        <IconButton
                          size="small"
                          onClick={(e) =>
                            onClickDelete(e, id, onDelete, mutate, onClose)
                          }
                          disabled={!data.transactional}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <CopyToClipboard text={getContent(data.transactional)}>
                        <Tooltip title="Copy to clipboard">
                          <IconButton
                            size="small"
                            disabled={!data.transactional}
                          >
                            <AssignmentIcon />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                      <Tooltip title="Close window">
                        <IconButton size="small" onClick={onClose}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <AceEditor
                      className={classes.jobPreviewEditor}
                      mode="json"
                      theme="tomorrow"
                      fontSize={20}
                      height="200px"
                      width="100%"
                      readOnly={true}
                      showGutter={true}
                      highlightActiveLine={false}
                      value={getContent(data.transactional)}
                      setOptions={{
                        useWorker: false,
                        showLineNumbers: true,
                        tabSize: 4,
                      }}
                    />
                  </Paper>
                </Fade>
              )}
            </Popper>
          );
        }}
      </Query>
    );
  }
);

const mutation = loader("../../GraphQL/Mutations/delete-transactional.graphql");
export default graphql(mutation)(TransactionContextViewerContent);
