import * as React from 'react';
import { Typography } from '@material-ui/core';
import { faFileCode, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class RepositoryHelp extends React.Component {
    public render() {
        return (
            <React.Fragment>
                <Typography>You can bring-your-own-storage by using any public GIT repository.</Typography>
                <Typography>The repository should follow a structure such as:</Typography>
                <p><FontAwesomeIcon icon={faFolder} size="lg"/> <b>Endpoints</b></p>
                <p style={{marginLeft: '20px'}}><FontAwesomeIcon icon={faFolder} size="lg"/> <b>MyJsonEndpoint</b></p>
                <p style={{marginLeft: '40px'}}><FontAwesomeIcon icon={faFileCode} size="lg"/> endpoint.json</p>
                <p style={{marginLeft: '40px'}}><FontAwesomeIcon icon={faFolder} size="lg"/> Functions</p>
                <p style={{marginLeft: '60px'}}><FontAwesomeIcon icon={faFileCode} size="lg"/> myFunction.js</p>
                <p style={{marginLeft: '20px'}}><b><FontAwesomeIcon icon={faFolder} size="lg"/> MyYamlEndpoint</b></p>
                <p style={{marginLeft: '40px'}}><FontAwesomeIcon icon={faFileCode} size="lg"/> endpoint.yml</p>
                <Typography><em>Hint:</em> Instead of including Versions.Functions inline in the JSON/YML file, you can reference an external script file:</Typography>
                <p>Version.FunctionLocations: {'{ "helloWorld": "Functions/getData.js" }'}</p>
            </React.Fragment>
        )
    }
}