import {
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react";
import React from "react";
import MappablePasswordSetter from "../AuthenticationMethods/MappablePasswordSetter";
import IOutputProps from "../EndpointWizard/IOutputProps";

const EmailOutputSmtp: React.FC<IOutputProps> = observer(
  ({ context, task, onDrop, onClickEditPath, classes, appContext }) => {
    const hostRef = React.createRef<HTMLInputElement>();
    const portRef = React.createRef<HTMLInputElement>();
    const userRef = React.createRef<HTMLInputElement>();
    const passwordRef = React.createRef<HTMLInputElement>();

    const emailTask = context.tasks[task];

    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          id="custom-smtp-panel"
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Custom SMTP</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl margin="normal" required fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="use-custom-smtp"
                      checked={emailTask.emailUseCustomSmtp}
                      onChange={() =>
                        (emailTask.emailUseCustomSmtp =
                          !emailTask.emailUseCustomSmtp)
                      }
                    />
                  }
                  label="Use custom SMTP"
                />
              </FormControl>
            </Grid>
            {emailTask.emailUseCustomSmtp && (
              <React.Fragment>
                <Grid item xs={4}>
                  <FormControl margin="normal" required fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="use-ssl"
                          checked={emailTask.emailSmtpUseSSL}
                          onChange={() =>
                            (emailTask.emailSmtpUseSSL =
                              !emailTask.emailSmtpUseSSL)
                          }
                        />
                      }
                      label="Use SSL for SMTP"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="smtp-host"
                    onDrop={onDrop}
                    label="Host"
                    error={
                      emailTask.emailUseCustomSmtp &&
                      !emailTask.emailSmtpHost.content
                    }
                    inputRef={hostRef}
                    onChange={(e) =>
                      (emailTask.emailSmtpHost.content = e.target.value)
                    }
                    onSelect={(_) =>
                      (emailTask.emailSmtpHost.cursorLocation =
                        hostRef.current &&
                        (hostRef.current.selectionStart as number))
                    }
                    onFocus={(_) => {
                      emailTask.activeTextInput = emailTask.emailSmtpHost;
                    }}
                    placeholder="Server"
                    value={emailTask.emailSmtpHost.content}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="smtp-port"
                    onDrop={onDrop}
                    label="Port"
                    inputRef={portRef}
                    onChange={(e) =>
                      (emailTask.emailSmtpPort.content = e.target.value)
                    }
                    onSelect={(_) =>
                      (emailTask.emailSmtpPort.cursorLocation =
                        portRef.current &&
                        (portRef.current.selectionStart as number))
                    }
                    onFocus={(_) => {
                      emailTask.activeTextInput = emailTask.emailSmtpPort;
                    }}
                    placeholder="Port"
                    value={emailTask.emailSmtpPort.content}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="smtp-user"
                    onDrop={onDrop}
                    label="Username"
                    inputRef={userRef}
                    onChange={(e) =>
                      (emailTask.emailSmtpUsername.content = e.target.value)
                    }
                    onSelect={(_) =>
                      (emailTask.emailSmtpUsername.cursorLocation =
                        userRef.current &&
                        (userRef.current.selectionStart as number))
                    }
                    onFocus={(_) => {
                      emailTask.activeTextInput = emailTask.emailSmtpUsername;
                    }}
                    placeholder="Username"
                    value={emailTask.emailSmtpUsername.content}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MappablePasswordSetter
                    passwordField={emailTask.emailSmtpPassword}
                    onDrop={onDrop}
                    onFocus={(_) => {
                      emailTask.activeTextInput = emailTask.emailSmtpPassword;
                    }}
                    inputRef={passwordRef}
                    fullWidth={true}
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default EmailOutputSmtp;
