import React from "react";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Grid,
  Chip,
  Avatar,
  FormLabel,
  IconButton,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Switch,
} from "@material-ui/core";
import { observer } from "mobx-react";
import EndpointTestsContext from "./EndpointTestsContext";
import ITest from "./ITest";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import AceEditor from "react-ace";
import Prettifier from "../../Common/Helpers/Prettifier";
import Loading from "../Loading/Loading";
import classNames from "classnames";
import RefreshIcon from "@material-ui/icons/Refresh";
import UnknownIcon from "@material-ui/icons/Help";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import ReactDiffViewer from "react-diff-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import { Dictionary } from "lodash";
import { DataFormat } from "../../Common/Enums/DataFormat";
import { MapType } from "../../Common/Enums/MapType";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import EndpointService from "../../Common/Services/Endpoint.Service";
import IXSLTParameter from "../../Common/Interfaces/IXSLTParameter";
import { IMappableTaskResult } from "../../Common/Interfaces/IMappableTaskResult";

interface IProps extends IComponentProps {
  endpointName: string;
  tests: ITest[];
  canAdd: boolean;
  input: string;
  output: string;
  paths: Dictionary<string>;
  pathOrigins: Dictionary<number>;
  functions: {};
  inputMode: DataFormat;
  outputMode: DataFormat;
  mapType: MapType;
  xsltParameters: IXSLTParameter[];
  taskResults: IMappableTaskResult[];
  dataOrigin: number;
}

@observer
export default class EndpointTests extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.runAllTests();
  }

  private _context = new EndpointTestsContext();

  private runAllTests = () => {
    this.props.tests.forEach((test) => {
      this.runTest(test);
    });
  };

  private addTest = () => {
    const newTest = {
      id: this.props.tests.length + 1,
      name: "New Test",
      input: this.props.input,
      output: "",
      actual: "",
      modified: false,
      active: true,
    };
    this.props.tests.push(newTest);
    this._context.activeTest = newTest;
    this.runTest(this.props.tests.find((t) => t.id === newTest.id) as ITest);
  };

  private toggleActiveTest = (test: ITest) => {
    if (this._context.activeTest === test) {
      this._context.activeTest = undefined;
    } else {
      this._context.activeTest = test;
    }
  };

  private onChange = (test: ITest, value: string, isInput: boolean) => {
    test.modified = true;
    if (isInput) {
      test.input = value;
    } else {
      test.output = value;
    }
  };

  private runTest = (test: ITest, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    this._context.loading = true;
    EndpointService.Preview(
      this.props.appContext,
      {
        input: test.input,
        output: this.props.output,
        paths: this.props.paths,
        pathOrigins: this.props.pathOrigins,
        functions: this.props.functions,
        inputFormat: this.props.inputMode,
        outputFormat: this.props.outputMode,
        mapType: this.props.mapType,
        xsltParameters: this.props.xsltParameters,
        taskResults: this.props.taskResults,
        dataOrigin: this.props.dataOrigin,
      },
      this.props.endpointName,
      1
    )
      .then((result) => {
        test.actual = Prettifier.prettify(result, this.props.outputMode);
        test.modified = false;
      })
      .finally(() => {
        this._context.loading = false;
      });
  };

  private isTestFailing = (test: ITest): boolean =>
    test.active !== false && test.output !== test.actual;

  public render() {
    return (
      <React.Fragment>
        <Typography align="center" variant="h6">
          Tests
        </Typography>
        {this._context.loading ? (
          <Loading fullscreen={false} />
        ) : (
          <div style={{ width: "100%" }}>
            {this.props.tests.map((test, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={this._context.activeTest === test}
                  onChange={() => this.toggleActiveTest(test)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        container
                        alignItems="center"
                        justify="flex-start"
                      >
                        <Chip
                          avatar={
                            <Avatar
                              className={classNames(
                                test.output !== test.actual &&
                                  test.modified === false &&
                                  this.props.classes.invalid
                              )}
                            >
                              {test.modified ? (
                                <UnknownIcon />
                              ) : test.output === test.actual ? (
                                <DoneIcon />
                              ) : (
                                <ErrorIcon />
                              )}
                            </Avatar>
                          }
                          className={classNames(
                            this.props.classes.margin,
                            test.output !== test.actual &&
                              test.modified === false &&
                              this.props.classes.invalid
                          )}
                          clickable={test.modified}
                          onClick={
                            test.modified
                              ? (e) => this.runTest(test, e)
                              : undefined
                          }
                          onDelete={
                            test.modified
                              ? (e) => this.runTest(test, e)
                              : undefined
                          }
                          deleteIcon={<RefreshIcon />}
                          label={
                            <React.Fragment>
                              {test.modified ? (
                                <em className={this.props.classes.margin}>
                                  Modified
                                </em>
                              ) : test.output === test.actual ? (
                                "Pass"
                              ) : (
                                "Fail"
                              )}
                            </React.Fragment>
                          }
                          color={
                            test.modified
                              ? "default"
                              : test.output === test.actual
                              ? "primary"
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        alignItems="center"
                        justify="center"
                      >
                        <Typography>
                          <FontAwesomeIcon icon={faFlask} />
                          <span style={{ marginLeft: "10px" }}>
                            {this.props.endpointName} Test {test.id}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        alignItems="center"
                        justify="flex-end"
                      >
                        <FormControlLabel
                          onClick={(e) => e.stopPropagation()}
                          control={
                            <Switch
                              onChange={(e, value) => (test.active = value)}
                              checked={test.active !== false}
                            />
                          }
                          label="Active"
                        />
                        <IconButton
                          className={this.props.classes.margin}
                          onClick={() => this.props.tests.splice(index, 1)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item md={6}>
                        <FormLabel className={this.props.classes.margin}>
                          Test Input
                        </FormLabel>
                        <AceEditor
                          className={this.props.classes.margin}
                          mode={DataFormatHelper.GetModeString(
                            this.props.inputMode
                          )}
                          theme="tomorrow"
                          onChange={(code) => this.onChange(test, code, true)}
                          fontSize={20}
                          showPrintMargin={true}
                          width="100%"
                          showGutter={true}
                          highlightActiveLine={true}
                          value={test.input}
                          editorProps={{ $blockScrolling: true }}
                          setOptions={{
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 4,
                          }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormLabel className={this.props.classes.margin}>
                          Expected Output
                        </FormLabel>
                        <AceEditor
                          className={this.props.classes.margin}
                          mode={DataFormatHelper.GetModeString(
                            this.props.outputMode
                          )}
                          theme="tomorrow"
                          onChange={(code) => this.onChange(test, code, false)}
                          fontSize={20}
                          showPrintMargin={true}
                          width="100%"
                          showGutter={true}
                          highlightActiveLine={true}
                          value={test.output}
                          editorProps={{ $blockScrolling: true }}
                          setOptions={{
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 4,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <FormLabel>Output Diff</FormLabel>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this._context.splitView}
                              onChange={(event) =>
                                (this._context.splitView = event.target.checked)
                              }
                            />
                          }
                          label="Split View"
                        />
                        <ReactDiffViewer
                          oldValue={test.output}
                          newValue={test.actual}
                          splitView={this._context.splitView}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className={this.props.classes.marginTop}
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          onClick={(e) => this.runTest(test, e)}
                        >
                          <FontAwesomeIcon
                            className={this.props.classes.margin}
                            icon={faFlask}
                          />{" "}
                          Run Test
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        )}
        {this.props.canAdd && (
          <Button variant={"text"} fullWidth={true} onClick={this.addTest}>
            <AddIcon />
          </Button>
        )}
        <Snackbar
          open={this.props.tests.some(this.isTestFailing)}
          className={`${this.props.classes.invalid} ${this.props.classes.snackbarInvalidBottom}`}
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <span
            className={classNames(
              this.props.classes.margin,
              this.props.classes.message
            )}
          >
            <ErrorIcon />
            There{" "}
            {this.props.tests.filter(this.isTestFailing).length > 1
              ? "are"
              : "is"}{" "}
            {this.props.tests.filter(this.isTestFailing).length} active test
            {this.props.tests.filter(this.isTestFailing).length > 1
              ? "s"
              : ""}{" "}
            which{" "}
            {this.props.tests.filter(this.isTestFailing).length > 1
              ? "are"
              : "is"}{" "}
            not passing.
            <Button
              className={this.props.classes.invalid}
              onClick={() => this.runAllTests()}
            >
              <RefreshIcon />
            </Button>
          </span>
        </Snackbar>
      </React.Fragment>
    );
  }
}
