import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  Theme,
  withStyles,
} from "@material-ui/core";
import { loader } from "graphql.macro";
import { observer, Observer } from "mobx-react";
import * as React from "react";
import { Query } from "react-apollo";
import { appStyles } from "../../App.Styles";
import { LoggableAction } from "../../Common/Enums/LoggableAction";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import DashboardContext from "../Dashboard/DashboardContext";
import { faFilter, faFrown } from "@fortawesome/free-solid-svg-icons";
import UsageDataTableContext from "./UsageDataTableContext";
import Loading from "../Loading/Loading";
import TransactionViewer from "../TransactionViewer/TransactionViewer";
import IUsageDataPoint from "../../Common/Interfaces/IUsageDataPoint";
import { ApolloQueryResult } from "apollo-client";
import UsageDataTableFooter from "./UsageDataTableFooter";
import UsageDataTableHeader from "./UsageDataTableHeader";
import UsageDataFilters from "./UsageDataFilters";
import UsageDataActions from "./UsageDataActions";
import UsageDataTableRow from "./UsageDataTableRow";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Dayjs } from "dayjs";

interface IProps extends IComponentProps {
  context: DashboardContext;
  setQuery: (
    startDate: Dayjs,
    endDate: Dayjs,
    endpoints: string[],
    actions: LoggableAction[],
    transactionId?: string
  ) => void;
  theme: Theme;
}

@observer
class UsageDataTable extends React.Component<IProps> {
  private _context = new UsageDataTableContext(
    this.props.context,
    this.props.context.propertyFilters.hasOwnProperty("transactionId")
  );

  private handleActionFilter = (event: React.ChangeEvent<{ value: any }>) => {
    this._context.transactionMode = false;
    this.props.setQuery(
      this.props.context.startDate,
      this.props.context.endDate,
      this.props.context.includeEndpoints as string[],
      this.props.context.actions
    );
    this.props.context.actions = event.target
      .value as unknown as LoggableAction[];
    this.props.context.refetchUsageTableData &&
      this.props.context.refetchUsageTableData({
        startDate: this.props.context.startDate,
        endDate: this.props.context.endDate,
        endpoints: this.props.context.includeEndpoints,
        actions: this.props.context.actions,
        transactionId: this.props.context.propertyFilters["transactionId"],
        method: this.props.context.propertyFilters["method"],
        ip: this.props.context.propertyFilters["ip"],
      });
  };

  private handlePropertyFilter = (event: React.ChangeEvent<{ value: any }>) => {
    this.props.context.selectedPropertyFilter = event.target.value;
    if (this.props.context.propertyFilters[event.target.value] === undefined) {
      this.props.context.propertyFilters[event.target.value] = "";
    }
    this._context.propertyFilterValue =
      this.props.context.propertyFilters[event.target.value];
  };

  private handleFilterLinkClick = (
    event: React.MouseEvent,
    value: string,
    filter: string
  ) => {
    event.preventDefault();
    if (filter === "transactionId") {
      this.props.context.actions = [
        LoggableAction.EndpointAuthentication,
        LoggableAction.EndpointAuthenticationFailure,
        LoggableAction.EndpointException,
        LoggableAction.EndpointInvocation,
        LoggableAction.EndpointManagementAuthenticationFailure,
        LoggableAction.EndpointTaskFailure,
        LoggableAction.EndpointTaskSkipped,
        LoggableAction.EndpointTaskSuccess,
        LoggableAction.EndpointValidationFailure,
        LoggableAction.NonExistantEndpointInvocation,
        LoggableAction.EndpointTaskBegins,
        LoggableAction.EndpointResponds,
        LoggableAction.EmailMessageReceived,
        LoggableAction.ReceiverException,
        LoggableAction.EmailAttachmentExtractionError,
        LoggableAction.EndpointValidationSuccess,
        LoggableAction.EndpointTaskValuesResolved,
        LoggableAction.EndpointTimeElapsedRecorded
      ];
      this._context.transactionMode = true;
    }
    this.props.context.propertyFilters[filter] = value;
    this._context.propertyFilterValue = value;
    this.props.context.selectedPropertyFilter = filter;
    this.props.setQuery(
      this.props.context.startDate,
      this.props.context.endDate,
      this.props.context.includeEndpoints as string[],
      this.props.context.actions,
      value
    );
  };

  private renderPropertyFilter = (displayName: string, name: string) => {
    return (
      <MenuItem value={name}>
        {this.props.context.propertyFilters[name] !== undefined && (
          <FontAwesomeIcon icon={faFilter} size="xs" color="green" />
        )}
        <span className={this.props.classes.marginLeft}>{displayName}</span>
      </MenuItem>
    );
  };

  private removePropertyFilter = (filter: string) => {
    if (filter === "transactionId") {
      this._context.transactionMode = false;
      this.props.setQuery(
        this.props.context.startDate,
        this.props.context.endDate,
        this.props.context.includeEndpoints as string[],
        this.props.context.actions
      );
    }
    delete this.props.context.propertyFilters[filter];
    this.props.context.selectedPropertyFilter = "";
  };

  private handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    this._context.page = newPage;
  };

  private handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this._context.rowsPerPage = parseInt(event.target.value, 10);
    this._context.page = 0;
  };

  private toggleActionSelect = () => {
    this._context.transactionMode = false;
    this.props.setQuery(
      this.props.context.startDate,
      this.props.context.endDate,
      this.props.context.includeEndpoints as string[],
      this.props.context.actions
    );
    const allActions = Object.keys(LoggableAction)
      .filter((key) => isNaN(Number(LoggableAction[key as any])))
      .map((key) => (LoggableAction as any)[(LoggableAction as any)[key]])
      .filter((action) => action !== null);
    if (this.props.context.actions.length === allActions.length) {
      this.props.context.actions = [];
    } else {
      this.props.context.actions = allActions;
    }
  };

  private toggleFilterSelect = () => {
    if (Object.keys(this.props.context.propertyFilters).length === 4) {
      this.props.context.propertyFilters = {};
    } else {
      this.props.context.propertyFilters = {
        transactionId:
          this.props.context.propertyFilters["transactionId"] || "",
        ip: this.props.context.propertyFilters["ip"] || "",
        method: this.props.context.propertyFilters["method"] || "",
      };
    }
  };

  private sort = (locator: string, setOrder: boolean) => {
    this._context.orderBy = locator;
    if (setOrder) {
      this._context.orderDesc = !this._context.orderDesc;
    }
    this._context.page = 0;
  };

  private onDeleteTransactionalContext = (
    point: IUsageDataPoint,
    refetch: () => Promise<ApolloQueryResult<any>>
  ) => {
    this._context.points[
      this._context.points.findIndex((p) => p.id === point.id)
    ].properties.transactionContext = "";
    refetch();
  };

  public render() {
    const { theme } = this.props;
    return (
      <Query
        query={loader("../../GraphQL/Queries/usagedatatable.graphql")}
        variables={{
          startDate: this.props.context.startDate,
          endDate: this.props.context.endDate,
          endpoints: this.props.context.includeEndpoints,
          actions: this.props.context.actions,
          transactionId: this.props.context.propertyFilters["transactionId"],
          method: this.props.context.propertyFilters["method"],
          ip: this.props.context.propertyFilters["ip"],
          offset: this._context.page * this._context.rowsPerPage,
          limit: this._context.rowsPerPage,
          orderBy: this._context.orderBy,
          orderAscending: !this._context.orderDesc,
        }}
      >
        {(result) => {
          const { loading, error, data, refetch } = result;
          if (loading || !data) {
            return <Loading fullscreen={true} />;
          }
          if (error) {
            return (
              <FontAwesomeIcon
                className={this.props.classes.centerText}
                icon={faFrown}
                size="10x"
                color={theme.palette.error.main}
              />
            );
          }
          this.props.context.refetchUsageTableData = refetch;
          this._context.points = data.usagedata.dataPoints;
          this._context.total = data.usagedata.total;

          return (
            <Observer>
              {() => (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <UsageDataActions
                      actions={this.props.context.actions}
                      handleActionFilter={this.handleActionFilter}
                      toggleActionSelect={this.toggleActionSelect}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <UsageDataFilters
                      context={this.props.context}
                      propertyFilterValue={this._context.propertyFilterValue}
                      renderPropertyFilter={this.renderPropertyFilter}
                      handlePropertyFilter={this.handlePropertyFilter}
                      removePropertyFilter={this.removePropertyFilter}
                      toggleFilterSelect={this.toggleFilterSelect}
                      setPropertyFilterValue={(value) =>
                        (this._context.propertyFilterValue = value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <Button
                        variant="contained"
                        style={{ margin: theme.spacing(2) }}
                        onClick={() => {
                          if (this.props.context.refetchUsageTableData) {
                            this.props.context.refetchUsageTableData();
                          }
                        }}
                      >
                        <RefreshIcon style={{ marginRight: theme.spacing() }} />
                        Refresh
                      </Button>
                      <Table>
                        <TableHead>
                          <UsageDataTableHeader
                            context={this._context}
                            sort={this.sort}
                          />
                        </TableHead>
                        <TableBody>
                          {this._context.points.map((point, index) => (
                            <UsageDataTableRow
                              showTaskBehavior={this._context.displayTaskBehaviourColumn()}
                              context={this.props.appContext}
                              point={point}
                              index={index}
                              refetch={refetch}
                              onDeleteTransactionalContext={
                                this.onDeleteTransactionalContext
                              }
                              handleFilterLinkClick={this.handleFilterLinkClick}
                            />
                          ))}
                        </TableBody>
                        <TableFooter>
                          <UsageDataTableFooter
                            context={this._context}
                            refetch={refetch}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={
                              this.handleChangeRowsPerPage
                            }
                          />
                        </TableFooter>
                      </Table>
                    </Paper>
                  </Grid>
                  {this._context.transactionMode &&
                    this._context.points.some(
                      (p) => !p.properties.transactionId
                    ) === false && (
                      <Grid item xs={12}>
                        <TransactionViewer
                          theme={theme}
                          appContext={this.props.appContext}
                          classes={this.props.classes}
                          points={this._context.points}
                          transactionId={
                            this.props.context.propertyFilters["transactionId"]
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              )}
            </Observer>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(UsageDataTable);
