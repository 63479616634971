import { faCheckCircle, faExclamationCircle, faTimesCircle, faRandom, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, TableCell, TableRow, withStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ApolloQueryResult } from 'apollo-client';
import { observer } from 'mobx-react';
import dayjs from "dayjs";
import * as React from 'react';
import AppContext from '../../App.Context';
import { appStyles } from '../../App.Styles';
import LoggableActionHelpers from '../../Common/Helpers/LoggableActionHelpers';
import IUsageDataPoint from '../../Common/Interfaces/IUsageDataPoint';
import Help from '../Help/Help';
import TransactionContextViewer from '../TransactionContextViewer/TransactionContextViewer';
import UsageDataPreview from './UsageDataPreview';

interface IProps {
    context: AppContext;
    point: IUsageDataPoint;
    index: number;
    classes: any;
    refetch: () => Promise<ApolloQueryResult<any>>;
    onDeleteTransactionalContext: (point: IUsageDataPoint, refetch: () => Promise<ApolloQueryResult<any>>) => void;
    handleFilterLinkClick: (event: React.MouseEvent, value: string, filter: string) => void;
    showTaskBehavior: boolean
}

const UsageDataTableRow: React.FC<IProps> = observer(({ context, point, index, classes, refetch, onDeleteTransactionalContext, handleFilterLinkClick, showTaskBehavior }) => {
    const itemContext = JSON.parse(point.item.context);

    const truncateLabel = (label, maxLength = 25) =>
        label.length > maxLength ? label.slice(0, maxLength) + '...' : label;

    return <TableRow key={index} id={`usage-row-${index}`}>
        <TableCell>
            {point.level === "Info" && <FontAwesomeIcon size="lg" icon={faCheckCircle} color="green" />}
            {point.level === "Warn" && <FontAwesomeIcon size="lg" icon={faExclamationCircle} color="orange" />}
            {point.level === "Error" && <FontAwesomeIcon size="lg" icon={faTimesCircle} color="red" />}
        </TableCell>
        <TableCell component="th" scope="row">
            {dayjs(point.timestamp).format("YYYY-MM-DD HH:mm:ss")}
        </TableCell>
        <TableCell>{point.properties.ip}</TableCell>
        <TableCell>{point.properties.endpoint}</TableCell>
        <TableCell>
            <FontAwesomeIcon color="gray" className={`${classes.marginRight}`} title={point.properties.isAsync ? "Async invocation" : "HTTP method"} size="lg" icon={point.properties.isAsync ? faRandom : faArrowRight} />
            {`${point.properties.method}`}
        </TableCell>
        <TableCell>
            {LoggableActionHelpers.GetDisplayName(point.item.action)}
            {(!!JSON.parse(point.item.data) || !!JSON.parse(point.item.context)) && (
                <Help
                    classes={classes}
                    helpText={<UsageDataPreview data={Object.assign({}, JSON.parse(point.item.data), JSON.parse(point.item.context))} />}
                    displayIcon={() => <VisibilityIcon />}
                    copyableValue={JSON.stringify(Object.assign({}, JSON.parse(point.item.data), JSON.parse(point.item.context)))} />
            )}
            {
                point.properties.transactionContext && (
                    <TransactionContextViewer
                        appContext={context}
                        classes={classes}
                        id={point.properties.transactionContext}
                        onDelete={() => onDeleteTransactionalContext(point, refetch)} />
                )
            }
        </TableCell>
        {showTaskBehavior ? (
            <TableCell>
                {(itemContext?.Behaviour || "") + (itemContext?.["Task Label"] ? ` (${truncateLabel(itemContext["Task Label"])})` : "")}
            </TableCell>
        ) : null}
        <TableCell align="right">
            <Link
                onClick={(e: React.MouseEvent) => handleFilterLinkClick(e, point.properties.transactionId, "transactionId")}
                className={classes.link}
                id={`transaction-id-${index}`}>
                {point.properties.transactionId}
            </Link>
        </TableCell>
    </TableRow>
})

export default withStyles(appStyles, { withTheme: true })(UsageDataTableRow);