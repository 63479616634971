import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AuthenticationType } from '../../Common/Enums/AuthenticationType';
import { Behaviour } from '../../Common/Enums/Behaviour';
import AuthenticationTypeHelpers from '../../Common/Helpers/AuthenticationTypeHelpers';
import BehaviourHelpers from '../../Common/Helpers/BehaviourHelpers';

interface IItemProps {
    property: string;
    index: number; 
    value: string | [] | {};
}

interface IPreviewProps {
    data: {};
}

const UsageDataPreview: React.FC<IPreviewProps> = observer(({ data }) => {
    return (
        <React.Fragment>
            {
                Object.keys(data).map((key, index) => 
                    <UsageDataPreviewItem 
                        property={key} 
                        index={index} 
                        value={(data as any)[key]} />
                )
            }
        </React.Fragment>
    );
})

const UsageDataPreviewItem: React.FC<IItemProps> = observer(({ property, index, value }) => {
    switch (property) {
        case 'AuthenticationType':
            return <React.Fragment key={index}>
                <Typography>
                    <b>Authentication Type: </b>
                    {AuthenticationTypeHelpers.GetDisplayName((AuthenticationType as any)[value as string])}
                </Typography>    
            </React.Fragment>
        case 'Behaviour':
                return <React.Fragment key={index}>
                    <Typography>
                        <b>Behaviour: </b>
                        {BehaviourHelpers.GetDisplayName((Behaviour as any)[value as string])}
                    </Typography>    
                </React.Fragment>
        case 'Errors':
            return <React.Fragment key={index}>
                {
                    (value as []).map(item => 
                        Object.keys(item).map((key, index) => 
                            <UsageDataPreviewItem 
                                property={key} 
                                index={index} 
                                value={(item as any)[key]} />
                        )
                    )
                }
            </React.Fragment>
        default:
            return <Typography key={index}><b>{property}: </b>{String(value)}</Typography>
    }
})

export default UsageDataPreview;