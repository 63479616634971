import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react";
import React from "react";
import AppContext from "../../App.Context";
import { DataFormat } from "../../Common/Enums/DataFormat";
import { JobAction } from "../../Common/Enums/JobAction";
import EndpointHelpers from "../../Common/Helpers/EndpointHelpers";
import { getScheduleDescription } from "../../Common/Helpers/JobHelpers";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import EndpointSelector from "../EndpointSelector/EndpointSelector";
import EndpointWizardContext from "../EndpointWizard/Context/EndpointWizard.Context";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import MagicCronBuilder from "../MagicCronBuilder/MagicCronBuilder";

interface IProps {
  task: TaskContext;
  context: EndpointWizardContext;
  classes: any;
  appContext: AppContext;
  onDrop: (ev: React.DragEvent<HTMLElement>) => void;
}

const JobOutputProperties: React.FC<IProps> = observer(
  ({ task, classes, context, appContext, onDrop }) => {
    const onEndpointChange = (inputContent: string, endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string) => {
      task.jobContent.content = inputContent;
      if (endpoint && endpointEnvironmentOrVersion) {
        task.jobEndpoint = endpoint;
        task.jobEndpointEnvironmentOrVersion = endpointEnvironmentOrVersion;
        
        var endpointVersion = endpoint.versions.find(x => endpointEnvironmentOrVersion === "Production"? endpoint.environments["Production"] : x.version === Number.parseInt(endpointEnvironmentOrVersion)) 
        task.output.mode = endpointVersion!.inputMode;
      }
    };

    const endpointValid = (endpoint?: IEndpoint, endpointEnvironmentOrVersion?: string): boolean => {
      if (!endpoint || !endpointEnvironmentOrVersion) {
        return false;
      }
      const version = EndpointHelpers.GetProductionOrActiveVersion(endpoint!, endpointEnvironmentOrVersion!);
      if (!version) {
        return false;
      }
      if (version.inputMode === DataFormat.Stream) {
        alert("Stream endpoints are currently not supported for jobs");
        return false;
      }
      return true;
    };

    return (
      <Grid container className={classes.margin}>
        <Accordion style={{ width: "100%" }} id="job-schedule-expansion">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Job Schedule: {getScheduleDescription(task.jobSchedule)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <MagicCronBuilder
                  startDate={task.jobStartDate}
                  endDate={task.jobEndDate}
                  manualStartDate={task.manualStartDate.content}
                  manualEndDate={task.manualEndDate.content}
                  expression={task.jobSchedule}
                  onChange={(s) => (task.jobSchedule = s)}
                  onDateChange={task.onDateChange}
                  showDates={task.showDates}
                  showManualDates={task.showManualDates}
                  onDateClear={task.onDateClear}
                  handleShowDatesChange={task.handleShowDatesChange}
                  handleManualDatesChange={task.handleManualDatesChange}
                  isDynamicJobStartDate={task.isDynamicJobStartDate}
                  isDynamicJobEndDate={task.isDynamicJobEndDate}
                  showResult={false}
                  task={task}
                  handleDeleteOnJobExpireChange={task.handleDeleteOnJobExpireChange}
                  deleteOnJobExpire={task.deleteOnJobExpire}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {task.jobAction === JobAction.Create && (
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="expansion-endpoint"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Endpoint:{" "}
                    {(task.jobEndpoint as IEndpoint)
                      ? (task.jobEndpoint as IEndpoint).name
                      : "Select endpoint"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} onDrop={onDrop}>
                  <EndpointSelector
                    endpoint={task.jobEndpoint as IEndpoint}
                    endpoints={context.endpoints}
                    endpointEnvironmentOrVersion={task.jobEndpointEnvironmentOrVersion}
                    input={task.jobContent.content}
                    onFocus={() => (task.activeTextInput = task.jobContent)}
                    onSelect={(cursorLocation) =>
                      (task.jobContent.cursorLocation = cursorLocation)
                    }
                    appContext={appContext}
                    onChange={onEndpointChange}
                    endpointValid={endpointValid}
                    validate={false}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    );
  }
);

export default JobOutputProperties;
