import * as React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Help from "../Help/Help";
import IOutputProps from "../EndpointWizard/IOutputProps";
import { JobAction } from "../../Common/Enums/JobAction";
import JobOutputProperties from "./JobOutputProperties";
import LinkIcon from "@material-ui/icons/Link";
import NoIcon from "@material-ui/icons/NotInterested";
import PropertyEditor from "../PathEditor/PropertyEditor";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { observer } from "mobx-react";

@observer
export default class JobOutput extends React.Component<IOutputProps> {
  private task = this.props.context.tasks[this.props.task];
  private nameRef = React.createRef<HTMLInputElement>();

  public render() {
    return (
      <Grid item md={6}>
        {this.task.editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.task.editingPropertyToken === undefined &&
          (this.props.context.endpoints.length > 0 ? (
            <React.Fragment>
              <Grid
                className={this.props.classes.margin}
                item
                container
                xs={12}
                alignItems="center"
                justify="center"
              >
                <ToggleButtonGroup
                  exclusive
                  value={JobAction[this.task.jobAction]}
                  onChange={(e, value) =>
                    (this.task.jobAction = JobAction[
                      value as any
                    ] as unknown as JobAction)
                  }
                >
                  <ToggleButton
                    id="create-job"
                    value={JobAction[JobAction.Create]}
                  >
                    Create
                  </ToggleButton>
                  <ToggleButton
                    id="remove-job"
                    value={JobAction[JobAction.Remove]}
                  >
                    Remove
                  </ToggleButton>
                  <ToggleButton
                    id="update-job"
                    value={JobAction[JobAction.UpdateSchedule]}
                  >
                    Update Schedule
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid
                container
                className={this.props.classes.margin}
                alignItems="center"
              >
                <Grid item xs={this.task.selectedPropertyToken ? 6 : 8}>
                  <TextField
                    id="job-name"
                    fullWidth
                    autoComplete="off"
                    autoFocus
                    label="Name"
                    inputRef={this.nameRef}
                    onSelect={(_) =>
                      (this.task.jobName.cursorLocation =
                        this.nameRef.current &&
                        (this.nameRef.current.selectionStart as number))
                    }
                    onChange={(e) =>
                      (this.task.jobName.content = e.target.value)
                    }
                    onFocus={(_) =>
                      (this.task.activeTextInput = this.task.jobName)
                    }
                    onDrop={this.props.onDrop}
                    placeholder="Enter a name"
                    value={this.task.jobName.content}
                    error={!this.task.jobName.content}
                    variant="filled"
                  />
                </Grid>
                {this.task.jobAction === JobAction.Create && (
                  <Grid item md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.props.classes.marginLeft}
                          onChange={() =>
                            (this.task.jobDisableOnFailure =
                              !this.task.jobDisableOnFailure)
                          }
                          checked={this.task.jobDisableOnFailure}
                        />
                      }
                      label="Disable on failure"
                    />
                    <Help
                      classes={this.props.classes}
                      helpText="If selected, the job will be disabled if the endpoint it calls returns a non-success status code"
                    />
                  </Grid>
                )}
                {this.task.selectedPropertyToken && (
                  <Grid item xs={2}>
                    <Button
                      onClick={this.props.onClickEditPath}
                      variant={"text"}
                    >
                      <LinkIcon />
                    </Button>
                  </Grid>
                )}
              </Grid>
              {this.task.jobAction !== JobAction.Remove && (
                <JobOutputProperties
                  task={this.task}
                  context={this.props.context}
                  appContext={this.props.appContext}
                  onDrop={this.props.onDrop}
                  classes={this.props.classes}
                />
              )}
            </React.Fragment>
          ) : (
            <Typography className={this.props.classes.centerText} variant="h5">
              <NoIcon fontSize="large" /> There are no endpoints for this job to
              run
            </Typography>
          ))}
      </Grid>
    );
  }
}
