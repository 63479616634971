import AppContext from '../../App.Context';
import { getToken } from '../../Common/Helpers/AuthenticationHelpers';
import IVariable from '../../Common/Interfaces/IVariable';
import LoggingService from '../../Common/Services/Logging.Service';

export default class VariablesService {
    public CallGet = (appContext: AppContext): Promise<string> => {
        const uri = `${appContext.managementHost}/Variable`;
        return fetch(uri,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            })
            .then(response => response.text())
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

    public Get(appContext: AppContext): Promise<IVariable[]> {
        return this.CallGet(appContext)
            .then(result => {
                return JSON.parse(result)
            });
    }

    public static Delete = (appContext: AppContext, id: string): Promise<boolean> => {
        const uri = `${appContext.managementHost}/Variable/${id}`;
        return fetch(uri, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then(response => response.ok)
            .catch(error => {
                LoggingService.Log(error);
                throw error;
            });
    }

}