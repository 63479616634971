import { observable } from "mobx";
import ISecret from "../../Common/Interfaces/ISecret";
import SecretsService from "../Secrets/Secrets.Service";
import AppContext from "../../App.Context";

export default class SecretSelectorContext {
    constructor(appContext: AppContext) {
        this.getSecrets(appContext);        
    }

    @observable
    public loading: boolean = false;

    @observable
    public secrets: ISecret[] = [];

    public getSecrets(appContext: AppContext) {
        new SecretsService().Get(appContext)
            .then(secrets => this.secrets = secrets)
            .finally(() => this.loading = false);
    }
}