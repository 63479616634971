import LoggingService from "../../Common/Services/Logging.Service";
import AppContext from "../../App.Context";
import IRepository from "../../Common/IRepository";
import IRepositoryRetrievalResult from "./IRepositoryRetrievalResult";
import { getToken } from "../../Common/Helpers/AuthenticationHelpers";

export default class RepositoryService {
  public static Save = (
    appContext: AppContext,
    data: IRepository,
    id?: string
  ): Promise<IRepositoryRetrievalResult> => {
    const uri = `${appContext.managementHost}/Repository/${id ? id : ""}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: id ? "PATCH" : "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Delete = (
    appContext: AppContext,
    id: string
  ): Promise<boolean> => {
    const uri = `${appContext.managementHost}/Repository/${id}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    })
      .then((response) => response.ok)
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Sync = (
    appContext: AppContext,
    id: string
  ): Promise<IRepositoryRetrievalResult> => {
    const uri = `${appContext.managementHost}/Repository/Sync/${id}`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };
}
