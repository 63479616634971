import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, withStyles } from '@material-ui/core';
import ImportIcon from '@material-ui/icons/ArrowBack';
import DragIcon from '@material-ui/icons/DragIndicator';
import SecretIcon from '@material-ui/icons/VpnKey';
import { observer } from 'mobx-react';
import * as React from 'react';
import { appStyles } from '../../App.Styles';
import SecretHelpers from '../../Common/Helpers/SecretHelpers';
import IComponentProps from '../../Common/Interfaces/IComponentProps';
import ISecret from '../../Common/Interfaces/ISecret';
import EndpointWizardContext from '../EndpointWizard/Context/EndpointWizard.Context';
import SecretSelectorContext from './SecretSelector.Context';

interface IProps extends IComponentProps {
    context: EndpointWizardContext;
    draggable: boolean;
    task: number;
}

@observer
class SecretSelector extends React.Component<IProps> {
    private _context = new SecretSelectorContext(this.props.appContext);

    private onClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    private onDragStart = (
        e: React.DragEvent<HTMLElement>,
        secret: ISecret) => {
        e.stopPropagation();
        this.props.context.draggedSecret = secret;
        this.props.context.draggedFunction = undefined;
        this.props.context.draggedProperty = undefined;
        e.dataTransfer.setData("text/plain", SecretHelpers.getSecretToken(secret.key));
    }

    private onClickImport = (key: string) => {
        this.props.context.tasks[this.props.context.activeTask].activeTextInput!.addToken(SecretHelpers.getSecretToken(key));
    }

    public render() {
        return (
            <React.Fragment>
                <List
                    disablePadding
                    className={this.props.classes.dataTree}>
                    {
                        Object.keys(this._context.secrets).map((key, index) => {
                            return (
                                <div
                                    key={index}
                                    draggable={this.props.draggable}
                                    onMouseDown={(e) => this.onClick(e)}
                                    onDragStart={(e) => this.onDragStart(e, (this._context.secrets as any)[key])}
                                    className={this.props.draggable ? this.props.classes.draggable : null}>
                                    <ListItem>
                                        <ListItemIcon>
                                            {
                                                this.props.draggable ?
                                                    <DragIcon /> :
                                                    <SecretIcon />
                                            }
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={((this._context.secrets as any)[key] as ISecret).key}
                                            secondary={'Secret'} />
                                        <ListItemSecondaryAction>
                                            {
                                                // MAG-610
                                                this.props.context.isEditorTokenImportEnabled && (
                                                    <IconButton onClick={() => this.onClickImport(((this._context.secrets as any)[key] as ISecret).key)}>
                                                        <ImportIcon />
                                                    </IconButton>
                                                )
                                            }                                
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </div>
                            )
                        })
                    }
                </List>
            </React.Fragment>
        )
    }
}

export default withStyles(appStyles, { withTheme: true })(SecretSelector);