import Constants from "../Constants";
import { DataFormat } from "../Enums/DataFormat";
import IDictionary from "../Interfaces/IDictionary";

export default class PropertyHelpers {
    public static getPropertyToken = (key: string) => {
        return `${Constants.TokenStart}${key}${Constants.TokenEnd}`
    }

    public static getKey = (propertyToken: string) => {
        return propertyToken
            .replace(Constants.TokenStart, '')
            .replace(Constants.TokenEnd, '')
    }

    public static getPathToProperty = (key: string, isArrayItem: boolean, mode: DataFormat, parentPath?: string) => {
        if (mode === DataFormat.XML) {
            const pathPrefix = `${parentPath && parentPath.endsWith('/') ? '' : '/'}`
            const keyString = isArrayItem ? `${pathPrefix}[${key}]` : `${pathPrefix}${key}`
            if (parentPath) {
                return `${parentPath}${keyString}`;
            }
            return keyString
        } else {
            const pathPrefix = `${parentPath && parentPath.endsWith('.') ? '' : '.'}`
            const keyString = isArrayItem ? `[${key}]` : `${pathPrefix}${key}`
            if (parentPath) {
                return `${parentPath}${keyString}`;
            }
            return `$${keyString}`
        }
    }

    public static getXMLPathToProperty = (element: Element, isArrayItem: boolean, parentPath?: string, isUniqueNode: boolean = true, xPathIdx?: number) => {
        let key =  element.namespaceURI ? `*[local-name()="${element.localName}" and namespace-uri()='${element.namespaceURI}']` : element.localName;

        if (!isUniqueNode && xPathIdx !== undefined){
            key = `${key}[${xPathIdx}]`
        }

        const pathPrefix = `${parentPath && parentPath.endsWith('/') ? '' : '/'}`
        const keyString = isArrayItem ? `${pathPrefix}[${key}]` : `${pathPrefix}${key}`

        if (parentPath) {
            return `${parentPath}${keyString}`;
        }
        return keyString

    }

    public static getKeys = (content: string): string[] => {
        //regex ignores tokens from from response handler response data, secrets and functions
        const tokens = content.match(
            new RegExp(
                `${Constants.EscapedTokenStart}((?!{response}|\\S+?\\?|\\S+?\\(.*?\\)|\\$\\S+?).+?)${Constants.EscapedTokenEnd}`,
                'g'
            )
        );
        if (tokens) {
            return (tokens as []).map(t => PropertyHelpers.getKey(t));
        } 

        return [];
    }

    public static propertiesValid = (properties: string[], addedProperties: IDictionary<string>): boolean => {
        return properties
            .every(property =>
                !property ||
                PropertyHelpers
                    .getKeys(property)
                    .some(k => addedProperties[k] === undefined) === false);
    }

    public static generateXPathIdx = (index: number, elementName: string, elements: string[]) => {
        const ContainingDuplicates: boolean = elements.length !== new Set(elements).size;

        if (ContainingDuplicates){
            // generate array of indexes for items which matches elementName
            const duplicateIndexes = elements.reduce((targetIdxs: number[], currValue: string, idx: number ) => {
                if (currValue === elementName){
                    targetIdxs.push(idx)
                };
                return targetIdxs;
            }, []);
            
            return duplicateIndexes.indexOf(index) + 1;
        }

        // xPath not zero indexed
        return 1;
    }
}