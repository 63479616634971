import { Theme } from "@material-ui/core";

export const appStyles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    margin: theme.spacing(1),
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "black",
  },
  active: {
    color: theme.palette.secondary.main,
  },
  whiteText: {
    color: "white",
  },
  logoImg: {
    height: "1.5em",
    width: "1.5em",
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  invalid: {
    backgroundColor: theme.palette.error.main,
    color: "white",
  },
  badge: {
    top: 5,
    right: 3,
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    backgroundColor: "#558b2f",
    color: "white",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  dragging: {
    borderWidth: "1px",
    borderColour: "red",
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },
  selectedText: {
    color: theme.palette.primary.light,
  },
  centerText: {
    position: "absolute" as "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  centerTextRel: {
    position: "relative" as "relative",
    transform: "translate(35%, 300%)",
  },
  jobPreviewEditor: {
    overflow: "auto" as "auto",
  },
  headerTiles: {
    overflowX: "hidden" as "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `5px solid ${theme.palette.secondary.main}`,
  },
  headerTileIcon: {
    fontSize: 40,
    color: theme.palette.primary.main,
    paddingRight: 5,
  },
  tileText: {
    fontSize: 20,
    color: theme.palette.grey["400"],
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center" as "center",
    color: theme.palette.text.secondary,
  },
  draggable: {
    cursor: "move",
  },
  draggingOver: {
    backgroundColor: `gainsboro`,
  },
  invalidDrag: {
    backgroundColor: theme.palette.error.light,
  },
  alignRight: {
    textAlign: "right" as "right",
  },
  smallPadding: {
    padding: "2px",
  },
  toolTip: {
    padding: theme.spacing(1),
    textAlign: "center" as "center",
    color: theme.palette.text.secondary,
  },
  formError: {
    color: theme.palette.error.main,
  },
  alignLeft: {
    textAlign: "left" as "left",
  },
});
