import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, withStyles } from '@material-ui/core';
import ImportIcon from '@material-ui/icons/ArrowBack';
import DragIcon from '@material-ui/icons/DragIndicator';
import SecretIcon from '@material-ui/icons/VpnKey';
import { observer, useLocalStore } from 'mobx-react';
import * as React from 'react';
import { appStyles } from '../../App.Styles';
import VariableHelpers from '../../Common/Helpers/VariableHelpers';
import IComponentProps from '../../Common/Interfaces/IComponentProps';
import IVariable from '../../Common/Interfaces/IVariable';
import EndpointWizardContext from '../EndpointWizard/Context/EndpointWizard.Context';
import VariableSelectorContext from './VariableSelector.Context';

interface IProps extends IComponentProps {
    context: EndpointWizardContext;
    draggable: boolean;
    task: number;
}

const VariableSelector: React.FC<IProps> = observer(({ context, draggable, task, appContext, classes }) => {

    const variableContext = useLocalStore(() => new VariableSelectorContext(appContext));

    const onClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    const onDragStart = (
        e: React.DragEvent<HTMLElement>,
        variable: IVariable) => {
        e.stopPropagation();
        context.draggedSecret = undefined;
        context.draggedFunction = undefined;
        context.draggedProperty = undefined;
        context.draggedVariable = variable;
        e.dataTransfer.setData("text/plain", VariableHelpers.getVariableToken(variable.name));
    }

    const onClickImport = (key: string) => {
        context.tasks[context.activeTask].activeTextInput!.addToken(VariableHelpers.getVariableToken(key));
    }

    return (
        <React.Fragment>
            <List
                disablePadding
                className={classes.dataTree}>
                {
                    Object.keys(variableContext.variables).map((key, index) => {
                        return (
                            <div
                                key={index}
                                draggable={draggable}
                                onMouseDown={(e) => onClick(e)}
                                onDragStart={(e) => onDragStart(e, (variableContext.variables as any)[key])}
                                className={draggable ? classes.draggable : null}>
                                <ListItem>
                                    <ListItemIcon>
                                        {
                                            draggable ?
                                                <DragIcon /> :
                                                <SecretIcon />
                                        }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={((variableContext.variables as any)[key] as IVariable).name}
                                        secondary={'Variable'} />
                                    <ListItemSecondaryAction>
                                        {
                                            // MAG-610
                                            context.isEditorTokenImportEnabled && (
                                                <IconButton onClick={() => onClickImport(((variableContext.variables as any)[key] as IVariable).name)}>
                                                    <ImportIcon />
                                                </IconButton>
                                            )
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        )
                    })
                }
            </List>
        </React.Fragment>
    )
})

export default withStyles(appStyles, { withTheme: true })(VariableSelector);