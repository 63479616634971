import { useEffect } from "react";
import { MapType } from "../../Common/Enums/MapType";

/**
 * MAG- 610
 * Disable importing and dragging of tokens if map type is XSLT
 * This does not affect data tree properties
 */
export const useDraggableToken = ({ context, task }) => {
  const mapType = context.tasks[task].output.mapType;

  useEffect(() => {
    function dropHandlerOverride(e) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      alert("For XSLT, please use parameters for tokenized expressions.");
    }

    if (mapType === MapType.XSLT && !context.draggedProperty) {
      document
        .querySelector("#brace-editor")
        ?.addEventListener("drop", dropHandlerOverride, true);
    }

    return () => {
      document
        .querySelector("#brace-editor")
        ?.removeEventListener("drop", dropHandlerOverride, true);
    };
  }, [mapType, context.draggedProperty, context.tasks, task]);
};
