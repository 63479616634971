import { Paper, Typography, useTheme } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { ICountPerDay } from "../../Common/Interfaces/ICountPerDay";
import dayjs, { Dayjs } from "dayjs";
import EndpointLineChartDot from "./EndpointLineChartDot";

interface IProps {
  data: ICountPerDay[];
  onClick: any;
  scaleUnit: "hour" | "day";
  startDate: Dayjs;
  endDate: Dayjs;
  classes: any;
  id: string;
}

const EndpointLineChart: React.FC<IProps> = observer(
  ({ data, onClick, scaleUnit, startDate, endDate, classes, id }) => {
    const theme = useTheme();

    const renderLineTooltip = (
      props: TooltipProps<any, any>,
      context?: any
    ): any => {
      if (props.payload?.length === 0) {
        return null;
      }
      let label =
        scaleUnit === "hour"
          ? `${dayjs(props.label).format("HH:mm")} - ${dayjs(props.label)
              .add(59, "minute")
              .format("HH:mm")}`
          : `${dayjs(props.label).format("YYYY-MM-DD")}`;
      return (
        <Paper className={classes.toolTip}>
          <Typography>{label}</Typography>
          <Typography className={classes.selectedText}>{`Count: ${
            props!.payload![0].value
          }`}</Typography>
        </Paper>
      );
    };

    const tickFormatter = (tick: Dayjs) => {
      let format = scaleUnit === "day" ? "YYYY-MM-DD" : "HH:mm";
      return dayjs(tick).format(format);
    };

    const getLineChartData = (daycounts: ICountPerDay[]) => {
      let dateScale: any[] = [];
      let date = dayjs(startDate);
      date.minute(0);
      date.second(0);
      while (date.isBefore(endDate)) {
        let count = 0;
        //eslint-disable-next-line no-loop-func
        const dayCount = daycounts.find((dc) =>
          date.isSame(dayjs(dc.date), scaleUnit)
        );
        if (dayCount) {
          count = dayCount.count;
        }
        dateScale.push({ name: date.second(0).format(), Count: count });
        date = date.add(1, scaleUnit as any);
      }
      return dateScale;
    };

    return (
      <ResponsiveContainer>
        <LineChart
          style={{ cursor: "pointer" }}
          onClick={onClick}
          data={getLineChartData(data)}
          margin={{
            top: theme.spacing(5),
            right: theme.spacing(5),
            bottom: theme.spacing(5),
            left: theme.spacing(5),
          }}
          id={id}
        >
          <XAxis
            dataKey="name"
            tickMargin={theme.spacing(1)}
            tickFormatter={tickFormatter}
          />
          <YAxis tickMargin={theme.spacing(1)} />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            content={renderLineTooltip}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="Count"
            stroke={theme.palette.secondary.dark}
            dot={EndpointLineChartDot}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
);

export default EndpointLineChart;
