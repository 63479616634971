import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import {
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { appStyles } from "../../App.Styles";
import { Behaviour } from "../../Common/Enums/Behaviour";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import Task from "./Task";
import TasksContext from "./TasksContext";

interface IProps extends IComponentProps {
  tasks: TaskContext[];
  editable: boolean;
  editInitialLoad: boolean;
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  tasksContext: TasksContext;
  dragInProgress: boolean;
  dropDisabled: boolean;
  moveTask: (index: number, up: boolean) => void;
}

const TaskList: React.FC<IProps> = observer(
  ({
    tasks,
    editable,
    editInitialLoad,
    provided,
    snapshot,
    tasksContext,
    dragInProgress,
    dropDisabled,
    moveTask,
    classes,
    appContext,
  }) => {
    const droppableStyle = () => {
      return dragInProgress ? classes.draggingOver : "";
    };

    const isTaskExpanded = (task: TaskContext) => {
      return tasksContext.activeTask === task;
    };

    const updateTaskIndexes = (task: number) => {
      for (let i = task; i < tasks.length; i++) {
        tasks[i].decrementTaskReferences(task);
      }
    };

    const getReferringTasks = (task: number) => {
      let ids: number[] = [];
      tasks.forEach((t, i) => {
        if (t.refersToTask(task)) {
          ids.push(i);
        }
      });
      return ids;
    };

    const getDialogTitle = () => {
      let label = tasks[tasksContext.deletionTask].labelField.value;
      let labelText = label.length > 0 ? ` (${label})` : "";
      return `Delete Task ${tasksContext.deletionTask + 1}${labelText}?`;
    };

    const closeDialog = () => {
      tasksContext.showDialog = false;
    };

    const showDeleteTaskDialog = (task: number, referringTasks: number[]) => {
      return new Promise<boolean>((resolve) => {
        tasksContext.showDialog = true;
        tasksContext.dependentTasks = referringTasks.length;
        tasksContext.deletionTask = task;
        tasksContext.handleCloseDelete = () => resolve(false);
        tasksContext.handleDeleteDependent = async () =>
          await deleteTasks(referringTasks).then(() => resolve(true));
        tasksContext.handleDeleteReferences = () =>
          deleteReferencingProperties(task, referringTasks).then(() =>
            resolve(true)
          );
      });
    };

    const removeTask = async (task: number) => {
      let referringTasks = getReferringTasks(task);
      if (referringTasks.length > 0) {
        return new Promise<void>(async (resolve) => {
          await showDeleteTaskDialog(task, referringTasks).then(
            async (deleteTask) => {
              if (deleteTask) {
                await removeTask(task);
              }
              resolve();
            }
          );
        });
      } else {
        tasks.splice(task, 1);
        updateTaskIndexes(task);
      }
    };

    const toggleActiveTask = (task: TaskContext) => {
      if (tasksContext.activeTask === task) {
        tasksContext.activeTask = undefined;
      } else {
        tasksContext.activeTask = task;
      }
    };

    const deleteTasks = async (dependentTasks: number[]) => {
      for (var i = 0; i < dependentTasks.length; i++) {
        await removeTask(dependentTasks[i]);
      }
    };

    const deleteReferencingProperties = async (
      task: number,
      dependentTasks: number[]
    ) => {
      dependentTasks.forEach((t) => {
        tasks[t].deleteReferencesToTask(task);
      });
    };

    const taskCanBeResponseHandler = (taskIndex: number) => {
      return tasks.some(
        (t, i) => t.behaviour === Behaviour.Request && i !== taskIndex
      );
    };

    const taskCanBeJob = () => {
      return tasksContext.endpoints.some((e) => !!e.environments["Production"]);
    };

    const taskCanBeMesh = () => {
      return (
        appContext.allowedMeshMailboxes !== undefined &&
        appContext.allowedMeshMailboxes!.length > 0
      );
    };

    const taskCanBeAireAudit = () => {
      return appContext.aireAuditEnabled;
    };

    const taskBehaviourAllowed = (behaviour: Behaviour, taskIndex: number) => {
      switch (behaviour) {
        case Behaviour.ResponseHandler:
          return taskCanBeResponseHandler(taskIndex);
        case Behaviour.Job:
          return taskCanBeJob();
        case Behaviour.MESH:
          return taskCanBeMesh();
        case Behaviour.AireAudit:
          return taskCanBeAireAudit();
        default:
          return true;
      }
    };

    return (
      <div ref={provided.innerRef} className={droppableStyle()}>
        <div id="top-drag-placeholder">{provided.placeholder}</div>
        {tasks.map((task, index) => (
          <Draggable
            key={index}
            draggableId={`task-panel-${index}`}
            index={index}
          >
            {(provided, snapshot) => (
              <Task
                task={task}
                editable={editable}
                editInitialLoad={editInitialLoad}
                index={index}
                expanded={() => isTaskExpanded(task) && !dragInProgress}
                removeTask={() => removeTask(index)}
                onExpandChange={() => toggleActiveTask(task)}
                provided={provided}
                appContext={appContext}
                tasks={tasks}
                moveTask={(index, up) => moveTask(index, up)}
                behaviourAllowed={taskBehaviourAllowed}
              ></Task>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
        {tasksContext.showDialog && (
          <Dialog
            open={tasksContext.showDialog}
            aria-labelledby="confirm-dialog"
            id="deletion-dialog"
          >
            <DialogTitle className={classes.info}>
              {getDialogTitle()}
            </DialogTitle>
            <DialogContent className={classes.paper}>
              This task is referenced by {tasksContext.dependentTasks} other{" "}
              {tasksContext.dependentTasks > 1 ? "tasks" : "task"}. Please
              choose an option.
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  closeDialog();
                  tasksContext.handleDeleteDependent();
                }}
                color="primary"
                id="delete-dependent-button"
              >
                Delete dependent tasks
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  closeDialog();
                  tasksContext.handleDeleteReferences();
                }}
                color="secondary"
                id="delete-references-button"
              >
                Delete referencing properties
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  closeDialog();
                  tasksContext.handleCloseDelete();
                }}
                color="default"
                id="cancel-delete-button"
              >
                Cancel Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
);

export default withStyles(appStyles, { withTheme: true })(TaskList);
