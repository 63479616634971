import { observable } from "mobx";
import BaseContext from "../../Common/BaseContext";
import IWithErrorsResponse from "../../Common/Interfaces/IWithErrorsResponse";

export default class EndpointImportContext extends BaseContext {
    @observable
    public definition: string = '';

    @observable
    public response?: IWithErrorsResponse;

    @observable
    public redirect: boolean = false;
}