import LoggingService from "./Common/Services/Logging.Service";
import IContextResult from "./Common/Interfaces/IContextResult";
import IConfigurationResult from "./Common/Interfaces/IConfigurationResult";
import AppContext from "./App.Context";
import axios from "axios";
import { getToken } from "./Common/Helpers/AuthenticationHelpers";

export class AppService {
  public static Context = (appContext: AppContext): Promise<IContextResult> => {
    const uri = `${appContext.managementHost}/Context`;
    return fetch(uri, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          appContext.logOut();
        }
        return response.json();
      })
      .catch((error) => {
        LoggingService.Log(error);
        throw error;
      });
  };

  public static Configuration = (): Promise<IConfigurationResult> => {
    // We call a local nginx location block to get the managementHost from an environment variable
    const uri = "/Configuration";
    return axios(uri, {
      method: "GET",
    })
      .then(async (response) => {
        let configuration = await response.data;
        return configuration;
      })
      .catch(() => {
        // For local development, the npm server doesn't have the /configuration endpoint
        return {
          managementHost: "http://localhost:6790",
          runtimeHost: "http://localhost:6793",
          authenticationProvider: 2,
          identityClientId: "aireglu-management",
          permissionsClaimType: "airesuite/permissions/AireGluManagementApi",
          clientV1Host: "http://localhost:3010",
          clientV2Host: "http://localhost:3001",
          identityScope: "profile email openid AireGluManagementApi",
          identityAuthority: "https://host.docker.internal:44363",
          aireAuditEnabled: true,
        };
      });
  };
}
