import { observer } from "mobx-react";
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import IEndpointXmlSchema from "../../Common/Interfaces/IEndpointXmlSchema";
import EndpointData from "../EndpointWizard/Context/Step.Context";

export const XSDPreviewNamespaceSelect : React.FC<{
    endpointData: EndpointData,
    schemas?: IEndpointXmlSchema[]
}> = observer(({endpointData, schemas}) => {
        return (
            <FormControl id="select-input-format" fullWidth={true}>
                <InputLabel>Namespace</InputLabel>
                <Select
                    id="input-namespace-select"                                    
                    value={endpointData.previewXsdNamespace}
                    onChange={(e : React.ChangeEvent<{ value: any }>) => {endpointData.previewXsdNamespace = e.target.value}}>
                    {
                        schemas?.map((xmlSchema) =>
                                <MenuItem
                                    key={xmlSchema.namespace}
                                    value={xmlSchema.namespace}>{xmlSchema.namespace}</MenuItem>)
                    }
                </Select>
            </FormControl>
        );
});