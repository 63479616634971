import * as React from 'react';
import { Paper, Theme } from '@material-ui/core';
import IComponentProps from '../../Common/Interfaces/IComponentProps';
import SequenceDiagram from 'react-sequence-diagram';
import IUsageDataPoint from '../../Common/Interfaces/IUsageDataPoint';
import { LoggableAction } from '../../Common/Enums/LoggableAction';
import ISequenceDiagramEvent from './ISequenceDiagramEvent';
import { getByKey } from '../../Common/Helpers/ObjectHelpers';
import LoggableActionHelpers from '../../Common/Helpers/LoggableActionHelpers';
import BehaviourHelpers from '../../Common/Helpers/BehaviourHelpers';
import { Behaviour } from '../../Common/Enums/Behaviour';
import dayjs from "dayjs";

interface IProps extends IComponentProps {
    transactionId: string;
    points: IUsageDataPoint[];
    theme: Theme;
}

export default class TransactionViewer extends React.Component<IProps> {
    private getInput = (points: IUsageDataPoint[]) => {
        let result = '';
        points = points
            .sort(
                (a,b) => (dayjs(getByKey((a as any), 'timestamp')) > dayjs(getByKey((b as any), 'timestamp'))) ? 1 : 
                ((dayjs(getByKey((b as any), 'timestamp')) > dayjs(getByKey((a as any), 'timestamp'))) ? -1 : 0));
        points
            .map((point, index) => {
                const invocationIndex = points
                    .slice(0, index + 1)
                    .findIndex(p => p.properties.transactionId === point.properties.transactionId && p.item.action === LoggableAction.EndpointInvocation);
                const initiatedBy =  invocationIndex > 0 && points
                    .slice(0, invocationIndex)
                    .reverse()
                    .some(p => p.properties.transactionId !== point.properties.transactionId) ? 
                        points
                            .slice(0, invocationIndex)
                            .reverse()
                            .find(p => p.properties.transactionId !== point.properties.transactionId)!.endpointName :
                        point.properties.ip;
                let event: ISequenceDiagramEvent = {
                    endpoint: point.properties.endpoint,
                    method: point.properties.method,
                    action: point.item.action,
                    initiatedBy: initiatedBy,
                    isAsync: point.properties.isAsync
                };
                if ([LoggableAction.EndpointTaskBegins, LoggableAction.EndpointTaskSuccess, LoggableAction.EndpointTaskFailure]
                    .includes(point.item.action)) {
                    event.behaviour = JSON.parse(point.item.context)['Behaviour'];
                }
                if ([LoggableAction.EndpointTaskSuccess, LoggableAction.EndpointTaskFailure]
                    .includes(point.item.action)) {
                    event.url = JSON.parse(point.item.data)['Url'];
                    event.method = JSON.parse(point.item.data)['Method'];
                    event.statusCode = JSON.parse(point.item.data)['StatusCode'];
                }
                if (point.item.action === LoggableAction.EndpointResponds) {
                    event.statusCode = JSON.parse(point.item.data)['StatusCode'];
                    event.success = JSON.parse(point.item.data)['Success'];
                }
                return event;
            })
            .forEach(event => { result += this.getDiagramInputsForEvent(event) })
        return result;
    }

    private getDiagramInputsForEvent(event: ISequenceDiagramEvent){
        if (event.action === LoggableAction.EndpointInvocation) {
            if (event.isAsync){
                return `Note left of "${event.endpoint}": "Async: ${event.method}"\n`;
            }
            return `"${event.initiatedBy}"->"${event.endpoint}": "${event.method}"\n`;
        }

        if ([LoggableAction.EndpointTaskBegins, LoggableAction.EndpointTaskSuccess, LoggableAction.EndpointTaskFailure]
            .includes(event.action)) {
                if (event.url) {
                    let instruction = '';
                    instruction += `"${event.endpoint}"->"${event.url}": "${event.method}"\n`;
                    instruction += `"${event.url}"-->"${event.endpoint}": "${event.statusCode}"\n`;
                    return instruction;
                }
                return `Note right of "${event.endpoint}": ${LoggableActionHelpers.GetDisplayName(event.action)}\\n${BehaviourHelpers.GetDisplayName(event.behaviour as Behaviour)}\n`
        }
        
        if (event.action === LoggableAction.EndpointResponds) {
            if (event.isAsync){
                return `Note left of "${event.endpoint}": "Success = ${event.success}"\n`;
            }
            return `"${event.endpoint}"-->"${event.initiatedBy}": "${event.statusCode}"\n`
        }
        
        return `Note right of "${event.endpoint}": ${LoggableActionHelpers.GetDisplayName(event.action)}\n`;        
    }

    public render() {
        const { theme } = this.props;
        return (
            <Paper style={{padding: theme.spacing(1)}} id="sequence-diagram-container">
                <SequenceDiagram input={this.getInput(this.props.points)} options={{theme: 'simple'}} />
            </Paper>
        )
    }
}