import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  withStyles,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import UpIcon from "@material-ui/icons/ArrowUpward";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import IJob from "../../Common/Interfaces/IJob";
import Help from "../Help/Help";
import JobService from "../Job/Job.Service";
import Loading from "../Loading/Loading";
import JoblistJob from "./JoblistJob";
import JobsContext from "./Jobs.Context";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { JobSortType } from "../../Common/Enums/JobSortType";
import { JobOrderType } from "../../Common/Enums/JobOrderType";
import { SortTypeDisplayName } from "../../Common/Helpers/JobHelpers";
import { Can } from "../Authorisation/AbilityContext";

interface IProps extends IComponentProps {
  context: JobsContext;
}

export const Jobs: React.FC<IProps> = observer(
  ({ classes, context, appContext }) => {
    const onClickDelete = async (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      job: IJob
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (window.confirm(`Are you sure you want to delete '${job.name}'?`)) {
        await JobService.Delete(appContext, job.id as string).then(() => {
          context.jobs = [];
          context.GetJobs(appContext);
        });
      }
    };

    const onClickActive = (job: IJob) => {
      job.active = !job.active;
      JobService.Save(appContext, job, job.id);
    };

    const onClickLoadMore = () => {
      context.page++;
      context.GetJobs(appContext);
    };

    const onSearch = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      context.submittedSearchTerm = context.searchTerm;
      context.Search(appContext);
    };

    const onClear = () => {
      context.searchTerm = "";
      context.submittedSearchTerm = "";
      context.page = 1;
      context.jobs = [];
      context.GetJobs(appContext);
    };

    return context.loading ? (
      <Loading fullscreen={false} />
    ) : (
      <Grid container spacing={2}>
        <Can I="create" a="Job">
          <Grid item xs={12}>
            <Link to="/Job">
              <Button
                id="add-job"
                variant="outlined"
                color="default"
                size="large"
                fullWidth
              >
                <AddIcon fontSize="large" />
              </Button>
            </Link>
          </Grid>
        </Can>
        <Can I="list" a="Job">
          <React.Fragment>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justify="space-between"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={context.showActiveOnly}
                    onChange={() => {
                      context.showActiveOnly = !context.showActiveOnly;
                      context.jobs = [];
                      context.GetJobs(appContext);
                    }}
                  />
                }
                label="Only Show Active Jobs"
              />
              <Grid item>
                <FormLabel data-testid="job-sort-label">
                  Sort By:&nbsp;
                  <Select
                    value={context.jobSortType}
                    inputProps={{
                      "data-testid": "job-sort",
                    }}
                    id="job-sort-select"
                    onChange={(e) => {
                      context.jobSortType = SortTypeDisplayName(
                        (JobSortType as any)[e.target.value as any]
                      ) as unknown as JobSortType;
                      context.jobs = [];
                      context.GetJobs(appContext);
                    }}
                  >
                    {Object.keys(JobSortType)
                      .filter((key) =>
                        isNaN(Number((JobSortType as any)[key as any]))
                      )
                      .map((key) => (
                        <MenuItem
                          key={key}
                          id={`job-sort-select-${
                            SortTypeDisplayName(
                              (JobSortType as any)[
                                (JobSortType as any)[key as any]
                              ]
                            ) as unknown as JobSortType
                          }`}
                          value={key}
                        >
                          {
                            SortTypeDisplayName(
                              (JobSortType as any)[
                                (JobSortType as any)[key as any]
                              ]
                            ) as unknown as JobSortType
                          }
                        </MenuItem>
                      ))}
                  </Select>
                </FormLabel>
              </Grid>
              <Grid item>
                <FormLabel>
                  Order:&nbsp;
                  <Select
                    value={context.jobOrderType}
                    inputProps={{
                      "data-testid": "job-order",
                    }}
                    id="job-order-select"
                    onChange={(e) => {
                      context.jobOrderType = (JobOrderType as any)[
                        e.target.value as any
                      ] as unknown as JobOrderType;
                      context.jobs = [];
                      context.GetJobs(appContext);
                    }}
                  >
                    {Object.keys(JobOrderType)
                      .filter((key) =>
                        isNaN(Number((JobOrderType as any)[key as any]))
                      )
                      .map((key) => (
                        <MenuItem
                          key={key}
                          id={`job-order-select-${key}`}
                          value={key}
                        >
                          {
                            (JobOrderType as any)[
                              (JobOrderType as any)[key as any]
                            ]
                          }
                        </MenuItem>
                      ))}
                  </Select>
                </FormLabel>
              </Grid>
              <Typography>
                Showing {context.jobs.length} of {context.total} jobs
                {context.submittedSearchTerm && (
                  <React.Fragment>
                    <span> matching '{context.submittedSearchTerm}'</span>
                    <IconButton onClick={onClear}>
                      <CloseIcon />
                    </IconButton>
                  </React.Fragment>
                )}
              </Typography>
              <form onSubmit={onSearch}>
                <Input
                  value={context.searchTerm}
                  onChange={(e) => (context.searchTerm = e.target.value)}
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </form>
            </Grid>
            {context.jobs.map((job, index) => {
              return (
                <Grid item xs={12} key={index} id={`${job.name}-card`}>
                  <JoblistJob
                    job={job}
                    onClickActive={onClickActive}
                    onClickDelete={onClickDelete}
                  />
                </Grid>
              );
            })}
            {context.jobs.length !== context.total && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={onClickLoadMore}
                >
                  Load More{" "}
                  <FontAwesomeIcon
                    className={classes.marginLeft}
                    icon={faPlus}
                  />
                </Button>
              </Grid>
            )}
          </React.Fragment>
        </Can>
        {context.jobs.length === 0 && !context.showActiveOnly && (
          <Typography className={classes.centerText} variant="h5">
            <UpIcon fontSize="large" /> Schedule a job..
            <Help
              classes={classes}
              helpText="Jobs can be scheduled to run an endpoint whenever you like"
            />
          </Typography>
        )}
      </Grid>
    );
  }
);

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps> {
    render() {
      const context = new JobsContext(this.props.appContext);

      return <WrappedComponent {...this.props} context={context} />;
    }
  }
  return HOC;
};

export default withStyles(appStyles, { withTheme: true })(withContext(Jobs));
