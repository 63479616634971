import { Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    tileText: {
        fontSize: 20,
        color: theme.palette.grey["400"],
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center' as 'center',
        color: theme.palette.text.secondary,
    },
    marker: {
        position: 'absolute' as 'absolute',
        backgroundColor: theme.palette.warning.light,
    }
});