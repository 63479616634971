import { Dictionary } from "lodash";
import { when } from "mobx";
import EndpointWizardContext from "./EndpointWizard.Context";
import IEndpointVersion from "../../../Common/Interfaces/IEndpointVersion";
import AppContext from "../../../App.Context";
import { AuthenticationType } from "../../../Common/Enums/AuthenticationType";
import { Behaviour } from "../../../Common/Enums/Behaviour";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import { DataSource } from "../../../Common/Enums/DataSource";
import { HttpMethod } from "../../../Common/Enums/HttpMethod";
import { JobAction } from "../../../Common/Enums/JobAction";
import { MapType } from "../../../Common/Enums/MapType";
import DataFactory from "../../../Common/Factories/DataFactory";
import EndpointHelpers from "../../../Common/Helpers/EndpointHelpers";
import IAPIKeyAuthenticationContext from "../../../Common/Interfaces/IAPIKeyAuthenticationContext";
import IAuthenticationContext from "../../../Common/Interfaces/IAuthenticationContext";
import IEndpoint from "../../../Common/Interfaces/IEndpoint";
import IEndpointEmailContext from "../../../Common/Interfaces/IEndpointEmailContext";
import IEndpointFaxContext from "../../../Common/Interfaces/IEndpointFaxContext";
import IEndpointJobContext from "../../../Common/Interfaces/IEndpointJobContext";
import IEndpointJobCreate from "../../../Common/Interfaces/IEndpointJobCreate";
import IEndpointMESHContext from "../../../Common/Interfaces/IEndpointMESHContext";
import IEndpointRedirectContext from "../../../Common/Interfaces/IEndpointRedirectContext";
import IEndpointRequestContext from "../../../Common/Interfaces/IEndpointRequestContext";
import IEndpointResponseContext from "../../../Common/Interfaces/IEndpointResponseContext";
import IEndpointResponseHandlerContext from "../../../Common/Interfaces/IEndpointResponseHandlerContext";
import IEndpointSMSContext from "../../../Common/Interfaces/IEndpointSMSContext";
import IEndpointAdapterContext from "../../../Common/Interfaces/IEndpointAdapterContext";
import IIPAuthenticationContext from "../../../Common/Interfaces/IIPAuthenticationContext";
import IJWTAuthenticationContext from "../../../Common/Interfaces/IJWTAuthenticationContext";
import ITask from "../../../Common/Interfaces/ITask";
import EndpointService from "../../../Common/Services/Endpoint.Service";
import SchemaService from "../../../Common/Services/Schema.Service";
import CertificateContext from "../../Certificates/CertificateContext";
import AuthenticationContext from "./AuthenticationContext";
import HeaderContext from "./HeaderContext";
import TaskContext from "./Task.Context";
import TextInputContext from "./TextInputContext";
import XSLTParameterContext from "./XSLTParameterContext";
import IEndpointPdfContext from "../../../Common/Interfaces/IEndpointPdfContext";
import { ResponseHandlerType } from "../../../Common/Enums/ResponseHandlerType";
import IEndpointHttpContext from "../../../Common/Interfaces/IEndpointHttpContext";
import { IAdapterFieldDefinition } from "../../../Common/Interfaces/IAdapterDefinition";
import { AdapterFieldEditorType } from "../../../Common/Enums/AdapterFieldEditorType";
import QuillInputContext from "./QuillInputContext";
import AceInputContext from "./AceInputContext";
import IEndpointSftpContext from "../../../Common/Interfaces/IEndpointSftpContext";
import IEndpointJwtContext from "../../../Common/Interfaces/IEndpointJwtContext";
import JWTClaimContext from "./JWTClaimContext";
import IJwtClaim from "../../../Common/Interfaces/IJwtClaim";
import { EndpointType } from "../../../Common/Enums/EndpointType";
import { v4 as uuidv4 } from "uuid";
import IClientCredentialsAuthenticationContext from "../../../Common/Interfaces/IClientCredentialsAuthenticationContext";
import IEndpointAireAuditContext from "../../../Common/Interfaces/IEndpointAireAuditContext";
import {
  AuditActorContext,
  AuditAuditableObjectContext,
  AuditAuditableObjectDetailContext,
  AuditEventTypeContext,
} from "./AireAuditContext";

export default class EndpointWizardContextFactory {
  constructor(appContext: AppContext) {
    this._appContext = appContext;
  }

  private readonly _appContext: AppContext;

  public Create(): EndpointWizardContext {
    const context = new EndpointWizardContext(this._appContext, false, false);
    context.authenticationMethods.push(
      new AuthenticationContext(AuthenticationType.InternalOnly)
    );

    return context;
  }

  public async Edit(
    endpointName: string,
    version: number,
    newVersionMode: boolean,
    readOnly: boolean,
    derivedVersion?: number
  ): Promise<EndpointWizardContext> {
    let context = new EndpointWizardContext(this._appContext, true, readOnly);
    let endpointVersion: IEndpointVersion | undefined;

    await when(() => !context.loading);
    context.endpoint = context.endpoints.find((e) => e.name === endpointName)!!;

    if (context.endpoint.type === EndpointType.Repository) {
      context.readOnly = true;
    }

    if (!newVersionMode) {
      endpointVersion = context.endpoint.versions.find(
        (v) => v.version === version
      )!!;
      context.endpointVersion = {
        endpointVersion,
        endpointId: context.endpoint.id as string,
        endpointName: context.endpoint.name,
        endpointGroup: context.endpoint.group,
        environments: Object.keys(context.endpoint.environments),
      };

      context.version = endpointVersion.version;
      context.deploymentEnvironments = Object.keys(
        context.endpoint.environments
      ).filter(
        (key) => context.endpoint!.environments[key] === context.version
      );
    } else {
      endpointVersion = derivedVersion
        ? context.endpoint.versions.find((v) => v.version === derivedVersion)!!
        : context.endpoint.versions.slice(-1)[0];

      context.endpointVersion = {
        endpointId: context.endpoint.id as string,
        endpointName: context.endpoint.name,
        endpointGroup: context.endpoint.group,
        newEndpointVersion: version,
        derivedEndpointVersion: endpointVersion,
      };
      context.version = version;
      context.derivedVersion = endpointVersion.version;
    }

    if (endpointVersion) {
      context.editInitialLoad = true;

      try {
        context.tasks = this.getTaskContexts(
          endpointVersion as IEndpointVersion,
          context.editInitialLoad
        );
      } catch {
        alert(
          "We're sorry, but there was an issue loading the tasks for this endpoint. Don't worry, the endpoint itself is still intact.\n\nWarning: If you proceed to edit this endpoint without the tasks loaded, any existing tasks may be overridden.\n\nIf this problem persists or you need assistance, please reach out to support@airelogic.com."
        );
      }

      context.authenticationMethods = this.getAuthenticationContexts(
        endpointVersion as IEndpointVersion
      );
      context.stopOnTaskFailure = endpointVersion!.stopOnTaskFailure;
      context.errorEmailField.value = endpointVersion!.errorEmail;
      context.endpointNameField.value = context.endpoint.name;
      context.endpointGroupField.value = context.endpoint.group;
      context.inputContent = "";
      context.input.mode = endpointVersion!.inputMode;
      context.input.dataSource = endpointVersion!.inputSource;
      context.inputSchema = endpointVersion!.schema;
      context.xmlInputSchemas = endpointVersion!.xmlSchemas;

      if (
        endpointVersion!.xmlSchemas &&
        endpointVersion!.xmlSchemas!.length > 0
      ) {
        context.input.previewXsdNamespace =
          endpointVersion!.xmlSchemas[0].namespace;
      }
      context.method = (HttpMethod as any)[endpointVersion!.method];

      if (endpointVersion!.input) {
        context.inputContent = endpointVersion!.input;
        context.saveInput = true;
      } else {
        new DataFactory(SchemaService.GenerateXml)
          .Generate(this._appContext.managementHost as string, endpointVersion!)
          .then((code) => (context.inputContent = code));
      }
    }

    return context;
  }

  public CreateEndpoint(
    context: EndpointWizardContext,
    id?: string
  ): IEndpoint {
    const environments = context.endpoint!?.environments
      ? context.endpoint!?.environments
      : {};

    const undeployEnvironment = () => {
      const env = Object.keys(context.endpoint!?.environments || {}).find(
        (key) => context.endpoint!.environments[key] === context.version
      );
      if (env !== undefined) {
        delete endpoint.environments[env];
      }
    };

    let endpoint: IEndpoint = {
      id: id,
      name: context.endpointNameField.value,
      group: context.endpointGroupField.value,
      environments: environments,
      versions: [
        {
          stopOnTaskFailure: context.stopOnTaskFailure,
          errorEmail: context.errorEmailField.value,
          tasks: this.getTasks(context.tasks),
          inputMode: context.input.mode,
          method: HttpMethod[context.method],
          version: context.version,
          inputSource: context.input.dataSource,
          authenticationContexts: this.getAuthenticationMethods(
            context.authenticationMethods
          ),
          schema: context.inputSchema as string,
        },
      ],
    };
    if (context.saveInput) {
      endpoint.versions[0].input = context.inputContent;
    }
    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(context.input.mode)) {
      endpoint.versions[0].xmlSchemas = context.xmlInputSchemas!;
    }
    if (!context.deploymentEnvironments.length) {
      undeployEnvironment();
    }

    context.deploymentEnvironments.map(
      (env) => (endpoint.environments[env] = context.version)
    );
    return endpoint;
  }

  private getAuthenticationContexts(
    endpointVersion: IEndpointVersion
  ): AuthenticationContext[] {
    return endpointVersion.authenticationContexts.map((t) =>
      this.getAuthenticationContext(t)
    );
  }

  private getAuthenticationContext(method: IAuthenticationContext) {
    let context = new AuthenticationContext();
    context.type = method.authenticationType;
    context.editMode = true;

    if (method.authenticationType === AuthenticationType.APIKey) {
      context.apiKeyField.value = (method as IAPIKeyAuthenticationContext).key;
    }
    if (method.authenticationType === AuthenticationType.JWT) {
      context.usernameField.value = (method as IJWTAuthenticationContext).user;
    }
    if (method.authenticationType === AuthenticationType.IP) {
      context.clearIpAddresses();
      (method as IIPAuthenticationContext).ipAddresses.forEach((a) =>
        context.addIpAddress(a)
      );
    }
    if (method.authenticationType === AuthenticationType.Basic) {
      context.usernameField.value = (method as IJWTAuthenticationContext).user;
    }

    return context;
  }

  private getTaskContexts(
    endpointVersion: IEndpointVersion,
    editInitialLoad: boolean
  ): TaskContext[] {
    return endpointVersion.tasks.reduce(
      (acc: TaskContext[], task) => [
        ...acc,
        this.getTaskContext(task, editInitialLoad),
      ],
      []
    );
  }

  private getTaskContext(task: ITask, editInitialLoad: boolean): TaskContext {
    let context = new TaskContext(this._appContext, editInitialLoad);
    context.labelField.value = task.label;
    context.behaviour = task.behaviour;
    context.behaviourSelection = Behaviour[task.behaviour];
    context.tests = task.tests;
    context.filters = task.filters;
    context.output.selectedDataOrigin = task.dataOrigin;
    context.useCRLFLineEndings = task.useCRLFLineEndings;
    context.taskId = task.taskId;

    const behaviourContext = EndpointHelpers.GetBehaviourContext(task);
    context.addedProperties = behaviourContext.paths;
    context.addedPropertyOrigins = behaviourContext.pathOrigins;
    context.functions = behaviourContext.functions;

    switch (task.behaviour) {
      case Behaviour.Request:
        context.requestContent.content = behaviourContext.template as string;
        context.output.mode = task.requestContext!.outputMode;
        context.output.dataSource = task.requestContext!.outputSource;
        context.output.mapType = task.requestContext!.mapType;
        context.submissionUrl.content =
          task.requestContext!.submissionUrl || "";
        context.requestMethod = task.requestContext!
          .requestMethod as HttpMethod;
        context.retryOnFailure =
          task.requestContext!.retryOnFailure;
        context.retainStatusCode =
          task.requestContext!.retainStatusCode || false;
        context.xsltParameters = task.requestContext!.xsltParameters.map(
          (p) => {
            let parameter = new XSLTParameterContext();
            parameter.name.content = p.name;
            parameter.value.content = p.value;
            return parameter;
          }
        );
        context.clientCertificates = task.requestContext!.certificates.map(
          (c) => new CertificateContext(c.name, c.certificate, c.password)
        );
        if (task.requestContext!.headers) {
          context.headers = Object.keys(task.requestContext!.headers).map(
            (key) => {
              let header = new HeaderContext();
              header.name = key;
              header.values = task.requestContext!.headers[key].map((h) => {
                let input = new TextInputContext();
                input.content = h;
                return input;
              });
              return header;
            }
          );
        }
        break;
      case Behaviour.ResponseHandler:
        context.responseHandlerContent.content =
          behaviourContext.template as string;
        context.responseHandlerTypeField.value =
          task.responseHandlerContext!.type;
        context.submissionUrl.content =
          task.responseHandlerContext!.submissionUrl || "";
        context.responseHandlerRequestField.value =
          task.responseHandlerContext!.requestTaskIndex.toString();
        context.output.mode = task.responseHandlerContext!.outputMode;
        context.output.mapType = task.responseHandlerContext!.mapType;
        context.retainStatusCode =
          task.responseHandlerContext!.retainStatusCode || false;
        context.responseHandlerJsonSchema =
          task.responseHandlerContext!.jsonSchema;
        context.responseHandlerXmlSchemas =
          task.responseHandlerContext!.xmlSchemas;
        if (
          task.responseHandlerContext!.xmlSchemas &&
          task.responseHandlerContext!.xmlSchemas!.length > 0
        ) {
          context.output.previewXsdNamespace =
            task.responseHandlerContext!.xmlSchemas[0].namespace;
        }
        break;
      case Behaviour.MESH:
        context.meshSenderMailbox.content = task.meshContext!.senderMailbox;
        context.meshRecipientMailbox.content =
          task.meshContext!.recipientMailbox;
        context.meshWorkflowId.content = task.meshContext!.workflowId;
        context.meshBody.content = behaviourContext.template as string;
        context.meshCompress = task.meshContext!.compress;
        context.meshSubject.content = task.meshContext!.subject;
        context.meshLocalId.content = task.meshContext!.localId;
        break;
      case Behaviour.Email:
        context.emailBodyHtml.content = behaviourContext.template as string;
        context.emailBodyHtmlRaw.content = behaviourContext.template as string;
        context.emailBodyPlain.content = behaviourContext.template as string;
        context.emailTo.content = task.emailContext!.toCalculated || "";
        context.emailBcc.content = task.emailContext!.bccCalculated || "";
        context.emailFrom.content = task.emailContext!.from || "";
        context.emailSubject.content = task.emailContext!.subject;
        context.emailAttachment.content = task.emailContext!.attachment;
        context.emailAttachmentMime.content = task.emailContext!.attachmentMime;
        context.emailAttachmentFilename.content =
          task.emailContext!.attachmentFilename;
        context.attachmentIsFile = !!task.emailContext!.attachment
          ? false
          : undefined;
        context.archiveAttachment = task.emailContext!.archiveAttachment;
        context.archiveFilename.content = task.emailContext!.archiveFilename;
        context.isHtmlBody = task.emailContext!.htmlContent;
        context.isRawHtml = task.emailContext!.isRawHtml;
        context.emailUseCustomSmtp = task.emailContext!.useCustomSmtp;
        if (task.emailContext?.smtpDetails) {
          context.emailSmtpHost.content = task.emailContext!.smtpDetails!.host;
          context.emailSmtpPort.content =
            task.emailContext!.smtpDetails!.portString;
          context.emailSmtpUsername.content =
            task.emailContext!.smtpDetails.username;
          context.emailSmtpUseSSL = task.emailContext!.smtpDetails.useSSL!;
        }
        break;
      case Behaviour.Fax:
        context.faxBody.content = behaviourContext.template as string;
        context.faxFrom.content = task.faxContext!.fromName;
        context.faxTo.content = task.faxContext!.toName;
        context.faxNumber.content = task.faxContext!.toPhoneNumber;
        context.faxSubject.content = task.faxContext!.subject;
        context.faxAttachment.content = task.faxContext!.attachment;
        context.retainStatusCode = task.faxContext!.retainStatusCode || false;
        break;
      case Behaviour.SMS:
        context.smsTo.content = task.smsContext!.to;
        context.smsBody.content = behaviourContext.template as string;
        context.retainStatusCode = task.smsContext!.retainStatusCode || false;
        context.smsProvider = task.smsContext!.provider;
        context.smsSenderId.content = task.smsContext!.senderId;
        break;
      case Behaviour.Adapter:
        const adapter = this._appContext.adapters?.find(
          (a) => a.name === task.adapterContext!.adapterName
        );
        context.adapter = adapter;
        context.adapterFieldValues = this.getAdapterFieldValues(
          task.adapterContext!.adapterValues,
          adapter!.fields
        );
        context.behaviourSelection = `${Behaviour[task.behaviour]}-${
          adapter?.name
        }`;
        break;
      case Behaviour.Job:
        context.jobName.content = task.jobContext!.job.name;

        if (task.jobContext!.action === JobAction.Create) {
          EndpointService.Get(
            this._appContext,
            (task.jobContext!.job as IEndpointJobCreate).endpointName
          ).then((endpoint) => (context.jobEndpoint = endpoint));
          context.deleteOnJobExpire = (
            task.jobContext!.job as IEndpointJobCreate
          ).deleteOnExpire;
          context.jobEndpointEnvironmentOrVersion = (
            task.jobContext!.job as IEndpointJobCreate
          ).endpointEnvironmentOrVersion;
          context.jobSchedule = (
            task.jobContext!.job as IEndpointJobCreate
          ).schedule;
          context.jobExpression.content = (
            task.jobContext!.job as IEndpointJobCreate
          ).schedule;
          context.jobDisableOnFailure = (
            task.jobContext!.job as IEndpointJobCreate
          ).disableOnFailure;
          context.jobStartDate = (task.jobContext!.job as IEndpointJobCreate)
            .startDate
            ? new Date(
                (task.jobContext!.job as IEndpointJobCreate).startDate as string
              )
            : null;
          context.jobEndDate = (task.jobContext!.job as IEndpointJobCreate)
            .endDate
            ? new Date(
                (task.jobContext!.job as IEndpointJobCreate).endDate as string
              )
            : null;
          context.manualStartDate.content = (
            task.jobContext!.job as IEndpointJobCreate
          ).manualStartDate!;
          context.manualEndDate.content = (
            task.jobContext!.job as IEndpointJobCreate
          ).manualEndDate!;
        }
        context.jobAction = task.jobContext!.action;
        context.jobContent.content = task.jobContext!.template as string;
        break;
      case Behaviour.Response:
        context.responseContent.content = behaviourContext.template as string;
        context.output.mode = task.responseContext!.outputMode;
        context.output.mapType = task.responseContext!.mapType;
        context.statusCode.content = task.responseContext!.statusCode;
        context.xsltParameters = task.responseContext!.xsltParameters.map(
          (p) => {
            let parameter = new XSLTParameterContext();
            parameter.name.content = p.name;
            parameter.value.content = p.value;
            return parameter;
          }
        );
        if (task.responseContext!.headers) {
          context.headers = Object.keys(task.responseContext!.headers).map(
            (key) => {
              let header = new HeaderContext();
              header.name = key;
              header.values = task.responseContext!.headers[key].map(
                (h) => new TextInputContext(h)
              );
              return header;
            }
          );
        }
        break;
      case Behaviour.Mapping:
        context.responseContent.content = behaviourContext.template as string;
        context.output.mode = task.mappingContext!.outputMode;
        context.output.mapType = task.mappingContext!.mapType;
        context.xsltParameters = task.mappingContext!.xsltParameters.map(
          (p) => {
            let parameter = new XSLTParameterContext();
            parameter.name.content = p.name;
            parameter.value.content = p.value;
            return parameter;
          }
        );
        break;
      case Behaviour.Redirect:
        context.output.mode = DataFormat.QueryString;
        context.output.mapType = MapType.Path;
        context.output.dataSource = DataSource.QueryString;
        context.submissionUrl.content =
          task.redirectContext!.submissionUrl || "";
        break;
      case Behaviour.PDF:
        context.isRawHtml = task.pdfContext!.isRawHtml;
        context.rawHtmlPdfBody.content = behaviourContext.template as string;
        context.pdfBody.content = behaviourContext.template as string;
        context.pdfAttachment.content = task.pdfContext!.attachment;
        context.pdfAttachmentLocation = task.pdfContext!.attachmentLocation;
        break;
      case Behaviour.SFTP:
        context.sftpBody.content = behaviourContext.template as string;
        context.sftpUsername.content = task.sftpContext!.username;
        context.sftpUrl.content = task.sftpContext!.url;
        context.sftpFilename.content = task.sftpContext!.filename;
        context.sftpPort.content = task.sftpContext!.port;
        break;
      case Behaviour.JWT:
        context.algorithm = task.jwtContext!.algorithm;
        context.jwtHeaders = this.mapJwtContentFromTask(
          task.jwtContext!.jwtHeaders
        );
        context.jwtPayload = this.mapJwtContentFromTask(
          task.jwtContext!.jwtPayload
        );
        break;
      case Behaviour.AireAudit:
        context.aireAudit.auditSource.content =
          task.aireAuditContext!.auditSource;
        context.aireAudit.eventIdentifierType =
          task.aireAuditContext!.eventIdentifierType;
        context.aireAudit.outcomeIndicator =
          task.aireAuditContext!.outcomeIndicator;
        context.aireAudit.actionType = task.aireAuditContext!.actionType;
        context.aireAudit.auditActors = task.aireAuditContext!.actors.map(
          (actor) =>
            new AuditActorContext(
              actor.userId,
              actor.alternativeUserId,
              actor.userName
            )
        );
        context.aireAudit.eventTypes = task.aireAuditContext!.eventTypes.map(
          (eventType) =>
            new AuditEventTypeContext(
              eventType.code,
              eventType.codeSystem,
              eventType.displayName
            )
        );
        context.aireAudit.auditableObjects =
          task.aireAuditContext!.auditableObjects.map(
            (object) =>
              new AuditAuditableObjectContext(
                object.objectId,
                object.type,
                Object.entries(object.details).map(
                  ([type, value]) =>
                    new AuditAuditableObjectDetailContext(type, value)
                )
              )
          );
    }

    return context;
  }

  private mapJwtContentFromTask(taskClaims: IJwtClaim[]): JWTClaimContext[] {
    return taskClaims.map((c) => {
      return {
        key: new TextInputContext(c.key),
        value: new TextInputContext(c.value),
        type: c.valueType,
      };
    });
  }

  private getAdapterFieldValues(
    adapterValues: Dictionary<string>,
    adapterFields: IAdapterFieldDefinition[]
  ): Dictionary<TextInputContext> {
    let fieldValues: Dictionary<TextInputContext> = {};

    Object.keys(adapterValues).forEach((key) => {
      const adapterField = adapterFields.find((field) => field.name === key);
      if (
        [
          AdapterFieldEditorType.Text,
          AdapterFieldEditorType.TextArea,
          AdapterFieldEditorType.Select,
        ].includes(adapterField!.editorType)
      ) {
        fieldValues[key] = new TextInputContext(adapterValues[key]);
      } else if (adapterField?.editorType === AdapterFieldEditorType.RichText) {
        fieldValues[key] = new QuillInputContext(adapterValues[key]);
      } else if (
        adapterField?.editorType === AdapterFieldEditorType.CodeEditor
      ) {
        fieldValues[key] = new AceInputContext(adapterValues[key]);
      }
    });

    return fieldValues;
  }

  private getTasks(contexts: TaskContext[]): ITask[] {
    return contexts.map((c) => this.getTask(c));
  }

  private getTask(context: TaskContext): ITask {
    return {
      taskId: context.taskId || uuidv4().toString(),
      behaviour: context.behaviour,
      tests: context.tests,
      filters: context.filters,
      label: context.labelField.value,
      requestContext: this.getRequestContext(context),
      responseHandlerContext: this.getResponseHandlerContext(context),
      emailContext: this.getEmailContext(context),
      faxContext: this.getFaxContext(context),
      meshContext: this.getMESHContext(context),
      smsContext: this.getSMSContext(context),
      jobContext: this.getJobContext(context),
      responseContext: this.getResponseContext(context),
      redirectContext: this.getRedirectContext(context),
      mappingContext: this.getMappingContext(context),
      adapterContext: this.getAdapterContext(context),
      pdfContext: this.getPdfContext(context),
      sftpContext: this.getSftpContext(context),
      jwtContext: this.getJwtContext(context),
      aireAuditContext: this.getAireAuditContext(context),
      dataOrigin: context.output.selectedDataOrigin,
      useCRLFLineEndings: context.useCRLFLineEndings,
    };
  }

  private getAuthenticationMethods(
    contexts: AuthenticationContext[]
  ): IAuthenticationContext[] {
    return contexts.map((c) => this.getAuthenticationMethod(c));
  }

  private getAuthenticationMethod(
    context: AuthenticationContext
  ):
    | IAPIKeyAuthenticationContext
    | IJWTAuthenticationContext
    | IIPAuthenticationContext
    | IAuthenticationContext
    | IClientCredentialsAuthenticationContext {
    switch (context.type) {
      case AuthenticationType.APIKey:
        return {
          authenticationType: AuthenticationType.APIKey,
          key: context.apiKeyField.value,
        };
      case AuthenticationType.InternalOnly:
        return {
          authenticationType: AuthenticationType.InternalOnly,
        };
      case AuthenticationType.JWT:
        return {
          authenticationType: AuthenticationType.JWT,
          user: context.usernameField.value,
          password: context.passwordField.value,
        };
      case AuthenticationType.IP:
        return {
          authenticationType: AuthenticationType.IP,
          ipAddresses: context.ipAdressFields.map((f) => f.value),
        };
      case AuthenticationType.Basic:
        return {
          authenticationType: AuthenticationType.Basic,
          user: context.usernameField.value,
          password: context.passwordField.value,
        };
      case AuthenticationType.ClientCredentials:
        return {
          authenticationType: AuthenticationType.ClientCredentials,
        };
    }
  }

  private getRequestContext(
    context: TaskContext
  ): IEndpointRequestContext | undefined {
    if (context.behaviour === Behaviour.Request) {
      return {
        mapType: context.output.mapType,
        submissionUrl: context.submissionUrl.content,
        requestMethod: context.requestMethod,
        retryOnFailure: context.retryOnFailure,
        retainStatusCode: context.retainStatusCode,
        outputMode: context.output.mode,
        outputSource: context.output.dataSource,
        template: context.requestContent.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        headers: this.getHeaders(context.headers),
        xsltParameters: context.xsltParameters.map((p) => {
          return { name: p.name.content, value: p.value.content };
        }),
        certificates: context.clientCertificates.map((c) => {
          return {
            certificate: c.mappableCert.content ? c.mappableCert.content : c.base64,
            name: c.name,
            password: c.password.content,
          };
        }),
      };
    }
  }

  private getResponseContext(
    context: TaskContext
  ): IEndpointResponseContext | undefined {
    if (context.behaviour === Behaviour.Response) {
      return {
        mapType: context.output.mapType,
        outputMode: context.output.mode,
        template: context.responseContent.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        xsltParameters: context.xsltParameters.map((p) => {
          return { name: p.name.content, value: p.value.content };
        }),
        statusCode: context.statusCode.content,
        headers: this.getHeaders(context.headers),
      };
    }
  }

  private getMappingContext(
    context: TaskContext
  ): IEndpointHttpContext | undefined {
    if (context.behaviour === Behaviour.Mapping) {
      return {
        mapType: context.output.mapType,
        outputMode: context.output.mode,
        template: context.responseContent.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        xsltParameters: context.xsltParameters.map((p) => {
          return { name: p.name.content, value: p.value.content };
        }),
      };
    }
  }

  private getRedirectContext(
    context: TaskContext
  ): IEndpointRedirectContext | undefined {
    if (context.behaviour === Behaviour.Redirect) {
      return {
        submissionUrl: context.submissionUrl.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
      };
    }
  }

  private getHeaders(headers: HeaderContext[]): Dictionary<string[]> {
    let dict: Dictionary<string[]> = {};
    for (let header of headers) {
      dict[header.name] = header.values.map((h) => h.content);
    }
    return dict;
  }

  private getJwtProperties(properties: JWTClaimContext[]): IJwtClaim[] {
    let claims: IJwtClaim[] = [];

    for (let prop of properties) {
      claims.push({
        key: prop.key.content,
        value: prop.value.content,
        valueType: prop.type,
      });
    }
    return claims;
  }

  private getResponseHandlerContext(
    context: TaskContext
  ): IEndpointResponseHandlerContext | undefined {
    if (context.behaviour === Behaviour.ResponseHandler) {
      return {
        requestTaskIndex: parseInt(context.responseHandlerRequestField.value),
        mapType: context.output.mapType,
        requestMethod: context.requestMethod,
        headers: this.getHeaders(context.headers),
        submissionUrl: context.submissionUrl.content,
        retryOnFailure: context.retryOnFailure,
        retainStatusCode:
          context.retainStatusCode &&
          context.responseHandlerTypeField.value !==
            ResponseHandlerType.Receive,
        outputMode: context.output.mode,
        outputSource: context.output.dataSource,
        template: context.responseHandlerContent.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        xsltParameters: context.xsltParameters.map((p) => {
          return { name: p.name.content, value: p.value.content };
        }),
        certificates: context.clientCertificates.map((c) => {
          return {
            certificate: c.base64,
            name: c.name,
            password: c.password.content,
          };
        }),
        type: context.responseHandlerTypeField.value,
        jsonSchema: context.responseHandlerJsonSchema,
        xmlSchemas: context.responseHandlerXmlSchemas,
      };
    }
  }

  private getEmailContext(
    context: TaskContext
  ): IEndpointEmailContext | undefined {
    if (context.behaviour === Behaviour.Email) {
      return {
        toCalculated: context.emailTo.content,
        bccCalculated: context.emailBcc.content,
        from: context.emailFrom.content,
        subject: context.emailSubject.content,
        attachment: context.emailAttachment.content,
        attachmentMime: context.emailAttachmentMime.content,
        attachmentFilename: context.emailAttachmentFilename.content,
        template: context.isHtmlBody
          ? context.isRawHtml
            ? context.emailBodyHtmlRaw.content
            : context.emailBodyHtml.content
          : context.emailBodyPlain.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        archiveAttachment: context.archiveAttachment,
        archiveFilename: context.archiveFilename.content,
        archivePassword: context.archivePassword.content,
        htmlContent: context.isHtmlBody,
        isRawHtml: context.isRawHtml,
        useCustomSmtp: context.emailUseCustomSmtp,
        smtpDetails: {
          useSSL: context.emailSmtpUseSSL,
          host: context.emailSmtpHost.content,
          portString: context.emailSmtpPort.content,
          username: context.emailSmtpUsername.content,
          password: context.emailSmtpPassword.content,
          defaultFrom: "",
          port: "",
        },
      };
    }
  }

  private getFaxContext(context: TaskContext): IEndpointFaxContext | undefined {
    if (context.behaviour === Behaviour.Fax) {
      return {
        fromName: context.faxFrom.content,
        toName: context.faxTo.content,
        toPhoneNumber: context.faxNumber.content,
        subject: context.faxSubject.content,
        template: context.faxBody.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        attachment: context.faxAttachment.content,
        retainStatusCode: context.retainStatusCode,
      };
    }
  }

  private getMESHContext(
    context: TaskContext
  ): IEndpointMESHContext | undefined {
    if (context.behaviour === Behaviour.MESH) {
      const meshContext: IEndpointMESHContext = {
        senderMailbox: context.meshSenderMailbox.content,
        senderMailboxPassword: context.meshSenderMailboxPassword.content,
        recipientMailbox: context.meshRecipientMailbox.content,
        workflowId: context.meshWorkflowId.content,
        template: context.meshBody.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        compress: context.meshCompress,
        subject: context.meshSubject.content,
        localId: context.meshLocalId.content,
      };

      return meshContext;
    }
  }

  private getSMSContext(context: TaskContext): IEndpointSMSContext | undefined {
    if (context.behaviour === Behaviour.SMS) {
      return {
        to: context.smsTo.content,
        template: context.smsBody.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        retainStatusCode: context.retainStatusCode,
        provider: context.smsProvider,
        senderId: context.smsSenderId.content,
      };
    }
  }

  private getJobContext(context: TaskContext): IEndpointJobContext | undefined {
    if (context.behaviour !== Behaviour.Job) return undefined;
    switch (context.jobAction) {
      case JobAction.Create:
        return {
          action: context.jobAction,
          template: context.jobContent.content,
          paths: context.addedProperties,
          pathOrigins: context.addedPropertyOrigins,
          functions: context.functions,
          job: {
            name: context.jobName.content,
            schedule: context.jobSchedule,
            endpointName: context.jobEndpoint!.name,
            endpointEnvironmentOrVersion:
              context.jobEndpointEnvironmentOrVersion,
            disableOnFailure: context.jobDisableOnFailure,
            startDate: context.jobStartDate,
            endDate: context.jobEndDate,
            manualStartDate: context.manualStartDate.content,
            manualEndDate: context.manualEndDate.content,
            deleteOnExpire: context.deleteOnJobExpire,
          },
        };
      case JobAction.Remove:
        return {
          action: context.jobAction,
          template: context.jobContent.content,
          paths: context.addedProperties,
          pathOrigins: context.addedPropertyOrigins,
          functions: context.functions,
          job: {
            name: context.jobName.content,
            schedule: "0 0 5 31 2 ?",
          },
        };
      case JobAction.UpdateSchedule:
        return {
          action: context.jobAction,
          template: context.jobContent.content,
          paths: context.addedProperties,
          pathOrigins: context.addedPropertyOrigins,
          functions: context.functions,
          job: {
            name: context.jobName.content,
            schedule: context.jobSchedule,
            startDate: context.jobStartDate,
            endDate: context.jobEndDate,
            manualStartDate: context.manualStartDate.content,
            manualEndDate: context.manualEndDate.content,
          },
        };
    }
  }

  private getPdfContext(context: TaskContext): IEndpointPdfContext | undefined {
    if (context.behaviour === Behaviour.PDF) {
      return {
        attachment: context.pdfAttachment.content,
        attachmentLocation: context.pdfAttachmentLocation,
        retainStatusCode: context.retainStatusCode,
        template: context.isRawHtml
          ? context.rawHtmlPdfBody.content
          : context.pdfBody.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
        isRawHtml: context.isRawHtml,
      };
    }
  }

  private getAdapterContext(
    context: TaskContext
  ): IEndpointAdapterContext | undefined {
    if (context.behaviour === Behaviour.Adapter) {
      return {
        adapterName: context.adapter!.name,
        adapterValues: Object.fromEntries(
          Object.keys(context.adapterFieldValues).map((key) => [
            key,
            context.adapterFieldValues[key].content,
          ])
        ),
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
      };
    }
  }

  private getSftpContext(
    context: TaskContext
  ): IEndpointSftpContext | undefined {
    if (context.behaviour === Behaviour.SFTP) {
      return {
        username: context.sftpUsername.content,
        password: context.sftpPassword.content,
        filename: context.sftpFilename.content,
        template: context.sftpBody.content,
        url: context.sftpUrl.content,
        port: context.sftpPort.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
      };
    }
  }

  private getJwtContext(context: TaskContext): IEndpointJwtContext | undefined {
    if (context.behaviour === Behaviour.JWT) {
      return {
        algorithm: context.algorithm,
        jwtHeaders: this.getJwtProperties(context.jwtHeaders),
        jwtPayload: this.getJwtProperties(context.jwtPayload),
        jwtSecret: context.jwtSecret.content,
        jwtEncryptionSecret: context.jwtEncryptionSecret.content,
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
      };
    }
  }

  private getAireAuditContext(
    context: TaskContext
  ): IEndpointAireAuditContext | undefined {
    if (context.behaviour === Behaviour.AireAudit) {
      return {
        actionType: context.aireAudit.actionType,
        auditSource: context.aireAudit.auditSource.content,
        eventIdentifierType: context.aireAudit.eventIdentifierType,
        outcomeIndicator: context.aireAudit.outcomeIndicator,
        actors: context.aireAudit.auditActors.map((actor) => {
          return {
            userId: actor.userId.content,
            alternativeUserId: actor.alternativeUserId.content,
            userName: actor.userName.content,
          };
        }),
        eventTypes: context.aireAudit.eventTypes.map((eventType) => {
          return {
            code: eventType.code.content,
            codeSystem: eventType.codeSystem.content,
            displayName: eventType.displayName.content,
          };
        }),
        auditableObjects: context.aireAudit.auditableObjects.map(
          (auditableObject) => {
            return {
              objectId: auditableObject.objectId.content,
              type: auditableObject.type,
              details: auditableObject.details.reduce((acc, detail) => {
                acc[detail.type.content] = detail.value.content;
                return acc;
              }, {}),
            };
          }
        ),
        paths: context.addedProperties,
        pathOrigins: context.addedPropertyOrigins,
        functions: context.functions,
      };
    }
  }
}
