import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import UsageDataTableContext from './UsageDataTableContext';

interface IProps {
    context: UsageDataTableContext;
    sort: (locator: string, setOrder: boolean) => void;
}

const UsageDataTableHeader: React.FC<IProps> = observer(({ context, sort }) => {
    const displayTaskBehavior = context.displayTaskBehaviourColumn();
    return <TableRow>
        <TableCell>
            <TableSortLabel
                active={context.orderBy === "level"}
                direction={context.orderDesc ? "desc" : "asc"}
                onClick={() => sort("level", true)}>
                Level
            </TableSortLabel>
        </TableCell>
        <TableCell key="timestamp">
            <TableSortLabel
                active={context.orderBy === "timestamp"}
                direction={context.orderDesc ? "desc" : "asc"}
                onClick={() => sort("timestamp", true)}>
                Timestamp
            </TableSortLabel>
        </TableCell>
        <TableCell>
            <TableSortLabel
                active={context.orderBy === "properties.ip"}
                direction={context.orderDesc ? "desc" : "asc"}
                onClick={() => sort("properties.ip", true)}>
                IP Address
            </TableSortLabel>
        </TableCell>
        <TableCell>
            <TableSortLabel
                active={context.orderBy === "endpointName"}
                direction={context.orderDesc ? "desc" : "asc"}
                onClick={() => sort("endpointName", true)}>
                Endpoint
            </TableSortLabel>
        </TableCell>
        <TableCell>
            <TableSortLabel
                active={context.orderBy === "properties.method"}
                direction={context.orderDesc ? "desc" : "asc"}
                onClick={() => sort("properties.method", true)}>
                Method
            </TableSortLabel>
        </TableCell>
        <TableCell>
            Action
        </TableCell>
        {displayTaskBehavior ? (
            <TableCell>
                Task Behaviour
            </TableCell>
        ) : null}
        <TableCell align="right">
            Transaction ID
        </TableCell>
    </TableRow>
})

export default UsageDataTableHeader;