import * as React from "react";

import { Grid } from "@material-ui/core";
import IOutputProps from "../EndpointWizard/IOutputProps";
import JwtProperties from "./JwtProperties";
import PropertyEditor from "../PathEditor/PropertyEditor";
import { observer } from "mobx-react";
import { useDraggableToken } from "../../Common/Hooks/useDraggableToken";

const JwtOutput: React.FC<IOutputProps> = observer(
  ({
    context,
    onDrop,
    appContext,
    task,
    classes,
    onClickEditPath,
  }) => {
    // MAG-610
    useDraggableToken({ context, task });

    return (
      <Grid item md={6} onDrop={onDrop}>
        {context.tasks[task].editingPropertyToken && (
          <PropertyEditor
            appContext={appContext}
            context={context}
            task={task}
          />
        )}
        {context.tasks[task].editingPropertyToken === undefined && (
            <React.Fragment>
              <div
                onClick={() => {
                  context.isEditorTokenImportEnabled = true;
                }}
              >
                <JwtProperties
                  task={context.tasks[task]}
                  classes={classes}
                  context={context}
                  onDrop={onDrop}
                  onClickEditPath={onClickEditPath}
                />
              </div>
            </React.Fragment>
          )}
      </Grid>
    );
  }
);
export default JwtOutput;
