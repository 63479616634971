import { DataFormat } from "../Enums/DataFormat";
import { pd } from "pretty-data";

export default class Prettifier {
  public static prettify(str: string, format: DataFormat): string {
    if ([DataFormat.XML, DataFormat.HL7FHIRXML].includes(format)) {
      try {
        return pd.xml(str);
      } catch {
        return str;
      }
    }
    if (
      [DataFormat.JSON, DataFormat.HL7FHIRJSON, DataFormat.Callback].includes(
        format
      )
    ) {
      try {
        return JSON.stringify(JSON.parse(str), null, "\t");
      } catch {
        return str;
      }
    }
    return str;
  }
}
