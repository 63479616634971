import { Avatar, Chip, Switch, withStyles, Snackbar } from "@material-ui/core";
import DebugIcon from "@material-ui/icons/BugReport";
import { Alert } from "@material-ui/lab";
import { loader } from "graphql.macro";
import { observer } from "mobx-react";
import * as React from "react";
import { MutationFunction, useMutation } from "react-apollo";
import { appStyles } from "../../App.Styles";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import Help from "../Help/Help";
import EndpointsContext from "./Endpoints.Context";

interface IProps {
  context: EndpointsContext;
  endpoint: IEndpoint;
  classes: any;
  setPersistTransactionData: (endpoint: IEndpoint, value: boolean) => void;
}

const EndpointsDebugSwitch: React.FC<IProps> = observer(
  ({ context, endpoint, classes, setPersistTransactionData }) => {
    const [deleteAllTransactional, { error: deleteError }] =
      useMutation(deleteMutation);

    return (
      <React.Fragment>
        <Chip
          avatar={
            <Avatar>
              <DebugIcon
                color={
                  context.selectedVersions.find(
                    (v) => v.endpointName === endpoint.name
                  )!.endpointVersion.persistTransactionData
                    ? "primary"
                    : "disabled"
                }
              />
            </Avatar>
          }
          label={
            <React.Fragment>
              <Switch
                id={`${endpoint.name}-debug-mode`}
                checked={
                  context.selectedVersions.find(
                    (v) => v.endpointName === endpoint.name
                  )!.endpointVersion.persistTransactionData
                }
              />
              <Help
                classes={classes}
                helpText="Debug mode persists transactional data to be viewed in the usage data table"
              />
            </React.Fragment>
          }
          clickable
          onClick={() =>
            onClickPersistTransactional(
              !context.selectedVersions.find(
                (v) => v.endpointName === endpoint.name
              )!.endpointVersion.persistTransactionData,
              endpoint,
              context.selectedVersions.find(
                (v) => v.endpointName === endpoint.name
              )!.endpointVersion.version,
              deleteAllTransactional,
              setPersistTransactionData
            )
          }
        />
        <Snackbar open={deleteError?.message != null}>
          <Alert severity="error">
            {"Error deleting debug data: " + deleteError?.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
);

const onClickPersistTransactional = async (
  value: boolean,
  endpoint: IEndpoint,
  version: number,
  deleteTransactional: MutationFunction,
  setPersistTransactionData: (endpoint: IEndpoint, value: boolean) => void
) => {
  if (
    value === false ||
    window.confirm(
      `Debug mode will store transactional data which could contain PID. Are you sure you want to enable it for '${endpoint.name}' version ${version}?`
    )
  ) {
    setPersistTransactionData(endpoint, value);
  }

  if (
    value === false &&
    window.confirm(
      `Would you like to delete all previously collected transactional data for '${endpoint.name}'?`
    )
  ) {
    deleteTransactional({
      variables: {
        endpoint: endpoint.name,
      },
    });
  }
};

const deleteMutation = loader(
  "../../GraphQL/Mutations/delete-all-transactional.graphql"
);

export default withStyles(appStyles, { withTheme: true })(EndpointsDebugSwitch);
