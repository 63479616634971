import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { loader } from "graphql.macro";
import { observer } from "mobx-react";
import React from "react";
import dayjs from "dayjs";
import { useQuery } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { appStyles } from "../../App.Styles";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import Loading from "../Loading/Loading";

interface IProps extends IComponentProps {
  id: string;
}

const EndpointError: React.FC<IProps> = observer((props) => {
  const { loading, error, data } = useQuery(errorQuery, {
    variables: { id: props.id },
  });

  if (loading) return <Loading fullscreen={false} />;
  if (error) return <p>Error loading error details</p>;

  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={10}>
        <Paper className={props.classes.paper}>
          <Typography>
            <b>Endpoint:</b> {data.endpointError.endpoint}
          </Typography>
          <Typography>
            <b>Error date:</b>{" "}
            {dayjs(data.endpointError.timestamp).format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={5}>
        <Card>
          <CardContent>
            <Typography>
              <b>Errors:</b>
            </Typography>
            {data.endpointError.errors.map((err, index) => (
              <p key={index}>{err}</p>
            ))}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={5}>
        <Card>
          <CardContent>
            <Typography>
              <b>Input Data:</b>
            </Typography>
            <p>{data.endpointError.inputData}</p>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

const withContext = (WrappedComponent: React.ComponentType<any>) => {
  class HOC extends React.Component<IComponentProps & RouteComponentProps> {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          id={(this.props.match.params as any)["id"]}
        />
      );
    }
  }
  return HOC;
};

const errorQuery = loader("../../GraphQL/Queries/error.graphql");
export default withStyles(appStyles, { withTheme: true })(
  withRouter(withContext(EndpointError))
);
