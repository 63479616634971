import { observer } from "mobx-react";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import { Behaviour } from "../../Common/Enums/Behaviour";
import { ResponseHandlerType } from "../../Common/Enums/ResponseHandlerType";
import IFilter from "../TaskFilters/IFilter";

interface IProps {
  value: number;
  tasks: TaskContext[];
  taskIndex: number;
  includeInput?: boolean;
  disabled?: boolean;
  editPathMode?: boolean;
  filter?: IFilter;
  onChange?: (value: number) => void;
  taskValid?: (task: TaskContext) => boolean;
  label?: string;
  idPrefix?: string;
  selectSourceMode?: boolean;
}

const onLocalChange = (
  value: number,
  task: TaskContext,
  editPathMode?: boolean,
  filter?: IFilter,
  onChange?: (value: number) => void,
  selectSourceMode?: boolean
) => {
  if (filter) {
    filter.dataOrigin = value;
  } else if (editPathMode) {
    task.editingPropertyTokenOrigin = value;
    task.output.editingPathMatches = [];
    task.output.editingPathError = true;
  } else if (!selectSourceMode) {
    task.mappingSelectedDataOrigin = value;
  }
  if (onChange) {
    onChange(value);
  }
};

const taskCanMeMappedFrom = (
  task: TaskContext,
  index: number,
  taskIndex: number,
  taskValid?: (task: TaskContext) => boolean
): boolean => {
  if (index >= taskIndex) return false;
  if (!taskHasMappableType(task)) return false;
  return taskValid === undefined || taskValid(task);
};

const taskHasMappableType = (task: TaskContext): boolean => {
  return (
    (task.behaviour === Behaviour.ResponseHandler &&
      task.responseHandlerTypeField.value === ResponseHandlerType.Receive) ||
    task.behaviour === Behaviour.Mapping ||
    task.behaviour === Behaviour.PDF ||
    task.behaviour === Behaviour.JWT
  );
};

export const DataOriginSelect: React.FC<IProps> = observer(
  ({
    value,
    tasks,
    taskIndex,
    includeInput,
    disabled,
    editPathMode,
    filter,
    onChange,
    taskValid,
    label,
    idPrefix,
    selectSourceMode,
  }) => {
    const getElementId = (id: string): string => {
      if (idPrefix) {
        return `${idPrefix}-${id}`;
      }
      return id;
    };

    return (
      <FormControl fullWidth={true}>
        <InputLabel>{label ?? "Data Origin"}</InputLabel>
        <Select
          id={getElementId("data-origin-select")}
          value={value}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<{ value: any }>) =>
            onLocalChange(
              e.target.value,
              tasks[taskIndex],
              editPathMode,
              filter,
              onChange,
              selectSourceMode
            )
          }
        >
          {includeInput !== false && (
            <MenuItem value={-1} id={getElementId(`origin-option-input`)}>
              Input
            </MenuItem>
          )}
          {tasks.map(
            (task, index) =>
              taskCanMeMappedFrom(task, index, taskIndex, taskValid) && (
                <MenuItem
                  id={getElementId(`origin-option-${index}`)}
                  key={index}
                  value={index}
                >
                  {task.labelField.value && `[${task.labelField.value}]`} Task{" "}
                  {index}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
    );
  }
);
