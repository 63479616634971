import { Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({
    
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    margin: {
        margin: theme.spacing(1),
    },
    noPadding: {
        padding: '0 0 0 0 ',
    },
    hidden: {
        visibility: 'hidden' as 'hidden'
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'black'
    },
    centerText: {
        position: 'absolute' as 'absolute', 
        left: '50%', 
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    
    center: {
        textAlign: 'center' as 'center'
    },
    clickableHeaderStyle: {
        textDecoration: 'none',
        fontWeight: 'bold' as 'bold',
        color: 'black'
    },
});