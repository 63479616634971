import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react-lite";
import React from "react";
import AceEditor from "react-ace";
import { DataFormat } from "../../Common/Enums/DataFormat";
import { JwtDataType } from "../../Common/Enums/JwtDataType";
import DataFormatHelper from "../../Common/Helpers/DataFormatHelper";
import FormatValidator from "../../Common/Helpers/FormatValidator";
import JwtHelpers from "../../Common/Helpers/JwtHelpers";
import AceInputContext from "../EndpointWizard/Context/AceInputContext";
import JWTClaimContext from "../EndpointWizard/Context/JWTClaimContext";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import TextInputContext from "../EndpointWizard/Context/TextInputContext";
import CursorLocation from "../EndpointWizard/CursorLocation";
import TrackedTextField from "../Input/TrackedTextField";

interface IProps {
  task: TaskContext;
  title: string;
  claims: JWTClaimContext[];
  prefix: string;
}

const claimValueRef = React.createRef<AceEditor>();
const setUpClaimFromSelectedType = (
  claimContext: JWTClaimContext,
  claims: JWTClaimContext[],
  index: number,
  selectedType: any
) => {
  claimContext.type = JwtHelpers.GetJWTDataTypeFromNumber(selectedType);
  claims[index].value =
    claimContext.type === JwtDataType.json
      ? new AceInputContext(claimContext.value.content)
      : new TextInputContext(claimContext.value.content);
};

const renderClaimValueField = (
  claimContext: JWTClaimContext,
  task: TaskContext,
  prefix: string,
  index: number
) => {
  const valueValid = (claim: JWTClaimContext): boolean => {
    if (!claim.value.content) return false;
    if (
      claim.type === JwtDataType.number &&
      !FormatValidator.number(claim.value.content)
    )
      return false;
    return true;
  };
  return claimContext.type === JwtDataType.json ? (
    <>
      <Typography
        style={{
          fontSize: "0.81rem",
          color: claimContext.value.content === "" ? "red" : "grey",
        }}
      >
        Value *
      </Typography>
      <AceEditor
        ref={claimValueRef}
        setOptions={{ useWorker: false }} //turns off syntax checking but leaves syntax higlighting intact
        theme="tomorrow"
        style={{
          border: claimContext.value.content === "" ? "1px solid red" : "none",
        }}
        fontSize={18}
        height="15em"
        width="100%"
        value={claimContext.value.content}
        mode={DataFormatHelper.GetModeString(DataFormat.JSON)}
        onLoad={() =>
          claimContext.type === JwtDataType.json
            ? (claimContext.value = new AceInputContext(
                claimContext.value.content
              ))
            : (claimContext.value = new TextInputContext(
                claimContext.value.content
              ))
        }
        onChange={(entry) => (claimContext.value.content = entry)}
        onCursorChange={(e) => {
          task.activeTextInput = claimContext.value;
          task.activeTextInput.cursorLocation = new CursorLocation(
            e.getCursor().row,
            e.getCursor().column
          );
        }}
        onFocus={() => (task.activeTextInput = claimContext.value)}
      />
    </>
  ) : (
    <TrackedTextField
      context={claimContext.value}
      label={"Value"}
      placeholder={"Value"}
      required
      fullWidth
      error={!valueValid(claimContext)}
      variant="outlined"
      id={`${prefix}-value-${index}`}
      InputLabelProps={{
        shrink: true,
      }}
      onFocus={(_) => (task.activeTextInput = claimContext.value)}
    />
  );
};

const RequestOutputJwt: React.FC<IProps> = observer(
  ({ task, title, claims, prefix }) => {
    const keyValid = (claim: JWTClaimContext): boolean => {
      if (!claim.key.content) return false;

      return (
        claims.filter((h) => h.key.content === claim.key.content).length === 1
      );
    };

    return (
      <Accordion style={{ width: "100%" }} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                {title} ({claims.length})
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {claims.map((claimContext, index) => {
              return (
                <React.Fragment key={`${prefix}-${index}`}>
                  <Grid item xs={12}>
                    <Card key={index}>
                      <Divider></Divider>
                      <CardContent>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item xs={4}>
                            <TrackedTextField
                              context={claimContext.key}
                              label={"Key"}
                              placeholder={"Key"}
                              required
                              fullWidth
                              error={!keyValid(claimContext)}
                              variant="outlined"
                              id={`${prefix}-key-${index}`}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onFocus={(_) =>
                                (task.activeTextInput = claimContext.key)
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl fullWidth required variant="outlined">
                              <InputLabel id="jwt-data-type-label">
                                Type
                              </InputLabel>
                              <Select
                                labelId="jwt-data-type-label"
                                value={claimContext.type}
                                label="Type"
                                id={`${prefix}-type-${index}`}
                                onChange={(e) => {
                                  setUpClaimFromSelectedType(
                                    claimContext,
                                    claims,
                                    index,
                                    e.target.value
                                  );
                                }}
                              >
                                {Object.keys(JwtDataType)
                                  .filter((key) =>
                                    isNaN(
                                      Number((JwtDataType as any)[key as any])
                                    )
                                  )
                                  .map((key) => (
                                    <MenuItem key={key} value={key}>
                                      {JwtHelpers.GetValueTypeDisplayName(
                                        (JwtDataType as any)[
                                          (JwtDataType as any)[key as any]
                                        ]
                                      )}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={5}>
                            {renderClaimValueField(
                              claimContext,
                              task,
                              prefix,
                              index
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={() => claims.splice(index, 1)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="text"
                fullWidth
                id={`${prefix}-add`}
                onClick={() =>
                  claims.push({
                    key: new TextInputContext(),
                    value: new TextInputContext(),
                    type: JwtDataType.text,
                  })
                }
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default RequestOutputJwt;
