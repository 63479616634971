import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import AppContext from "../../App.Context";
import { AuthenticationProvider } from "../../Common/Enums/AuthenticationProvider";
import Account from "../Account/Account";
import CompleteProfile from "../Account/CompleteProfile";
import Login from "../Account/Login";
import Register from "../Account/Register";
import ResetPassword from "../Account/ResetPassword";
import { AuthenticatedRoute } from "../AuthenticatedRoute/AuthenticatedRoute";
import { OIDCLogin } from "../Authentication/OIDCLogin";
import { SignInCallback } from "../Authentication/SignInCallBack";
import { AbilityContextProvider } from "../Authorisation/AbilityContext";
import Dashboard from "../Dashboard/Dashboard";
import Endpoint from "../Endpoint/Endpoint";
import EndpointError from "../EndpointErrors/EndpointError";
import EndpointErrors from "../EndpointErrors/EndpointErrors";
import EndpointImport from "../EndpointImport/EndpointImport";
import Endpoints from "../Endpoints/Endpoints";
import EndpointWizard from "../EndpointWizard/EndpointWizard";
import Job from "../Job/Job";
import Jobs from "../Jobs/Jobs";
import Repositories from "../Repositories/Repositories";
import Repository from "../Repository/Repository";
import Receiver from "../Receiver/Receiver";
import Receivers from "../Receivers/Receivers";
import Secret from "../Secret/Secret";
import Secrets from "../Secrets/Secrets";
import Variable from "../Variable/Variable";
import Variables from "../Variables/Variables";
import { SignoutCallback } from "../Authentication/SignoutCallBack";
import { useParentWindowNavigation } from "../../Common/Hooks/useParentWindowNavigation";
import { applyTenancyToV2Origin } from "../../Common/TenancyHelpers";

interface IProps {
  context: AppContext;
  classes: any;
}

export const Routes: React.FC<IProps> = ({ context, classes }) => {
  const history = useHistory();
  useParentWindowNavigation(history, applyTenancyToV2Origin(context));

  const renderInternalAuthRoutes = () => {
    return (
      <React.Fragment>
        <Route
          exact={true}
          path="/Login"
          render={() => <Login appContext={context} />}
        />
        <Route
          exact={true}
          path="/Register"
          render={() => <Register appContext={context} />}
        />
        <Route
          exact={true}
          path="/ResetPassword"
          render={() => <ResetPassword appContext={context} />}
        />
      </React.Fragment>
    );
  };

  const renderOidcAuthRoutes = () => {
    return (
      <React.Fragment>
        <Route exact path="/login">
          <OIDCLogin />
        </Route>
        <Route exact path="/oidc-signin">
          <SignInCallback />
        </Route>
        <AuthenticatedRoute exact path="/oidc-signout">
          <SignoutCallback />
        </AuthenticatedRoute>
      </React.Fragment>
    );
  };

  const renderAuthRoutes = () => {
    return context.authenticationProvider === AuthenticationProvider.Internal
      ? renderInternalAuthRoutes()
      : renderOidcAuthRoutes();
  };

  return (
    <AbilityContextProvider
      authenticationProviderType={context.authenticationProvider}
      permissionsClaimType={context.permissionsClaimType}
    >
      {renderAuthRoutes()}
      <AuthenticatedRoute
        exact={true}
        path="/"
        permission="list"
        subject="Usagedata"
        render={() => <Dashboard appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/DrillDown"
        permission="list"
        subject="Usagedata"
        render={() => <Dashboard appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoints"
        permission="list"
        subject="Endpoint"
        render={() => <Endpoints {...{ appContext: context }} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoint"
        permission="create"
        subject="Endpoint"
        render={() => <EndpointWizard appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoint/Import"
        permission="create"
        subject="Endpoint"
        render={() => <EndpointImport appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoint/:name/:version/View/:source"
        permission="view"
        subject="Endpoint"
        render={() => <Endpoint appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Repositories"
        permission="list"
        subject="Repository"
        render={() => <Repositories appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Repository"
        permission="create"
        subject="Repository"
        render={() => <Repository appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Repository/:id"
        permission="edit"
        subject="Repository"
        render={() => <Repository appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Secrets"
        permission="list"
        subject="Secret"
        render={() => <Secrets appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Secret"
        permission="create"
        subject="Secret"
        render={() => <Secret appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Jobs"
        permission="list"
        subject="Job"
        render={() => <Jobs appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Receivers"
        permission="list"
        subject="Retriever"
        render={() => <Receivers appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Job"
        permission="create"
        subject="Job"
        render={() => <Job appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Job/:id"
        permission="edit"
        subject="Job"
        render={() => <Job appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Receiver"
        permission="create"
        subject="Retriever"
        render={() => <Receiver appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Receiver/:id"
        permission="edit"
        subject="Retriever"
        render={() => <Receiver appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Receiver/:type/:id"
        permission="edit"
        subject="Retriever"
        render={() => <Receiver appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoint/:name/:versionId"
        permission="edit"
        subject="Endpoint"
        render={() => <EndpointWizard appContext={context} editMode={true} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoint/:name/:versionId/View"
        permission="view"
        subject="Endpoint"
        render={() => <EndpointWizard appContext={context} editMode={true} readOnly={true} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Endpoint/:name/:versionId/New"
        permission="edit"
        subject="Endpoint"
        render={() => (
          <EndpointWizard
            appContext={context}
            newVersionMode={true}
            editMode={true}
          />
        )}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Account"
        render={() => <Account appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Errors"
        permission="list"
        subject="Transactional"
        render={() => <EndpointErrors appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Error/:id"
        permission="view"
        subject="Transactional"
        render={() => <EndpointError appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/CompleteProfile"
        render={() => <CompleteProfile appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Variables"
        permission="list"
        subject="Variable"
        render={() => <Variables appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Variable/:name"
        permission="edit"
        subject="Variable"
        render={() => <Variable appContext={context} />}
      />
      <AuthenticatedRoute
        exact={true}
        path="/Variable"
        permission="create"
        subject="Variable"
        render={() => <Variable appContext={context} />}
      />
    </AbilityContextProvider>
  );
};


