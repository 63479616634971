import {
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react";
import * as React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { appStyles } from "../../App.Styles";
import { Behaviour } from "../../Common/Enums/Behaviour";
import BehaviourHelpers from "../../Common/Helpers/BehaviourHelpers";
import IComponentProps from "../../Common/Interfaces/IComponentProps";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import Help from "../Help/Help";

interface IProps extends IComponentProps {
  task: TaskContext;
  editable: boolean;
  editInitialLoad: boolean;
  provided: DraggableProvided;
  index: number;
  expanded: () => boolean;
  onExpandChange: () => void;
  removeTask: () => void;
  tasks: TaskContext[];
  moveTask: (index: number, up: boolean) => void;
  behaviourAllowed: (behaviour: Behaviour, taskIndex: number) => boolean;
}

@observer
class Task extends React.Component<IProps> {
  private onChangeBehaviour = (event: React.ChangeEvent<{ value: any }>) => {
    const behaviourString = event.target.value.includes("-")
      ? event.target.value.substring(0, event.target.value.indexOf("-"))
      : event.target.value;
    this.props.task.behaviour = (Behaviour as any)[
      (Behaviour as any)[behaviourString]
    ];
    this.props.task.behaviourSelection = behaviourString;
    if (this.props.task.behaviour === Behaviour.Adapter) {
      this.props.task.behaviourSelection += event.target.value.substring(
        event.target.value.indexOf("-")
      );
    }
  };

  public render() {
    return (
      <Accordion
        expanded={this.props.expanded()}
        onChange={this.props.onExpandChange}
        id={`task-panel-${this.props.index}`}
        className="task-panel"
        ref={this.props.provided.innerRef}
        {...this.props.provided.draggableProps}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={1} onClick={(event) => event.stopPropagation()}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <IconButton
                  id={`move-up-task-button-${this.props.index}`}
                  className={`${this.props.classes.smallPadding}`}
                  disabled={this.props.index === 0}
                  onClick={() => {
                    this.props.moveTask(this.props.index, true);
                  }}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  id={`drag-task-button-${this.props.index}`}
                  className={`${this.props.classes.smallPadding}`}
                  {...this.props.provided.dragHandleProps}
                >
                  <DragIndicatorIcon />
                </IconButton>
                <IconButton
                  id={`move-down-task-button-${this.props.index}`}
                  onClick={() => this.props.moveTask(this.props.index, false)}
                  className={`${this.props.classes.smallPadding}`}
                  disabled={this.props.index === this.props.tasks.length - 1}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={2} onClick={(event) => event.stopPropagation()}>
              <TextField
                id={`task-label-${this.props.index}`}
                placeholder="Task label"
                variant="outlined"
                onChange={(e) =>
                  this.props.task.labelField.onChange(e.target.value)
                }
                value={this.props.task.labelField.value}
                onClick={(e) => e.stopPropagation()}
              ></TextField>
              {this.props.provided.placeholder}
            </Grid>
            <Grid item xs={7} className={this.props.classes.paper}>
              <Typography
                variant="h6"
                color="primary"
                id={`task-title-${this.props.index}`}
              >
                Task {this.props.index + 1}:{" "}
                {BehaviourHelpers.GetDisplayName(
                  this.props.task.behaviour,
                  this.props.task.adapter?.name
                )}
              </Typography>
            </Grid>
            <Grid item xs={2} className={this.props.classes.alignRight}>
              <IconButton
                id={`delete-task-button-${this.props.index}`}
                className={this.props.classes.margin}
                onClick={this.props.removeTask}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <FormControl
              id={`behaviour-select-${this.props.index}`}
              disabled={!this.props.editable}
            >
              <FormLabel>Behaviour</FormLabel>
              <Select
                defaultValue={0}
                renderValue={() =>
                  BehaviourHelpers.GetDisplayName(
                    this.props.task.behaviour,
                    this.props.task.adapter?.name
                  )
                }
                onChange={this.onChangeBehaviour}
              >
                {Object.keys(Behaviour)
                  .filter(
                    (key) =>
                      isNaN(Number(Behaviour[key as any])) &&
                      (Behaviour as any)[Behaviour[key as any]] !==
                        Behaviour.Adapter
                  )
                  .map((key) => (
                    <MenuItem
                      id={`${Behaviour[key as any]}-behaviour`}
                      key={key}
                      disabled={
                        !this.props.behaviourAllowed(
                          (Behaviour as any)[Behaviour[key as any]],
                          this.props.index
                        )
                      }
                      value={key}
                    >
                      {BehaviourHelpers.GetDisplayName(
                        (Behaviour as any)[(Behaviour as any)[key as any]]
                      )}
                    </MenuItem>
                  ))}
                {this.props.appContext.adapters &&
                  this.props.appContext.adapters.map((adapter, index) => (
                    <MenuItem
                      id={`${adapter.name}-adapter-behaviour`}
                      key={index}
                      selected={
                        this.props.task.behaviour === Behaviour.Adapter &&
                        this.props.task.adapter?.name === adapter.name
                      }
                      onClick={() =>
                        (this.props.task.adapter =
                          this.props.appContext.adapters?.find(
                            (x) => x.name === adapter.name
                          ))
                      }
                      value={`${Behaviour.Adapter}-${adapter.name}`}
                    >
                      {BehaviourHelpers.GetDisplayName(
                        Behaviour.Adapter,
                        adapter.name
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {[Behaviour.Request, Behaviour.SMS, Behaviour.Fax].includes(
              this.props.task.behaviour
            ) && (
              <Grid
                container
                className={this.props.classes.margin}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="retain-status-code"
                        disabled={!this.props.editable}
                        onChange={() =>
                          (this.props.task.retainStatusCode =
                            !this.props.task.retainStatusCode)
                        }
                        checked={this.props.task.retainStatusCode}
                      />
                    }
                    label="Retain status code"
                  />
                  <Help
                    classes={this.props.classes}
                    helpText="If selected, the response code of the request will be passed back as the response code of this endpoint. Note: this is superseded by retaining the status code of the response handler"
                  />
                </Grid>
              </Grid>
            )}
            {[Behaviour.ResponseHandler].includes(
              this.props.task.behaviour
            ) && (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <FormControl>
                    <FormLabel className={this.props.classes.margin}>
                      Associated Request Task
                    </FormLabel>
                    <NativeSelect
                      id={`response-handler-request-select-${this.props.index}`}
                      className={this.props.classes.margin}
                      value={this.props.task.responseHandlerRequestField.value}
                      style={{ minWidth: "300px" }}
                      error={!this.props.task.responseHandlerRequestField.value}
                      onChange={(e) =>
                        this.props.task.responseHandlerRequestField.onChange(
                          e.target.value
                        )
                      }
                    >
                      <option value="">[Select a task]</option>
                      {this.props.tasks.map((requestTask, requestIndex) => {
                        if (
                          requestIndex < this.props.index &&
                          requestTask.behaviour === Behaviour.Request
                        ) {
                          return (
                            <option
                              id={`response-handler-request-${
                                requestIndex + 1
                              }`}
                              key={requestIndex}
                              value={requestIndex}
                              disabled={this.props.tasks.some(
                                (t) =>
                                  parseInt(
                                    t.responseHandlerRequestField.value
                                  ) === requestIndex
                              )}
                            >
                              {this.props.tasks[requestIndex].labelField.value
                                ? `[${
                                    this.props.tasks[requestIndex].labelField
                                      .value
                                  }] Task ${requestIndex + 1}`
                                : `Task ${requestIndex + 1}`}
                            </option>
                          );
                        }
                        return "";
                      })}
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(appStyles, { withTheme: true })(Task);
