import * as React from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import AceEditor from "react-ace";
import { AttachmentLocation } from "../../Common/Enums/AttachmentLocation";
import AttachmentLocationHelpers from "../../Common/Helpers/AttachmentLocationHelpers";
import CursorLocation from "../EndpointWizard/CursorLocation";
import IOutputProps from "../EndpointWizard/IOutputProps";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../Loading/Loading";
import PropertyEditor from "../PathEditor/PropertyEditor";
import QuillWrapper from "../QuillWrapper/QuillWrapper";
import ReactQuill from "react-quill";
import { observer } from "mobx-react";

@observer
export default class PdfOutput extends React.Component<IOutputProps> {
  private task = this.props.context.tasks[this.props.context.activeTask];
  private attachmentRef = React.createRef<HTMLInputElement>();

  private setAttachmentLocation = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    _: React.ReactNode
  ) => {
    this.props.context.tasks[
      this.props.task
    ].pdfAttachmentLocation = (AttachmentLocation as any)[
      (AttachmentLocation as any)[event.target.value as any]
    ];
  };

  public render() {
    return (
      <Grid
        onDrop={this.props.onDrop}
        item
        md={6}
      >
        {this.task.editingPropertyToken && (
          <PropertyEditor
            appContext={this.props.appContext}
            context={this.props.context}
            task={this.props.task}
          />
        )}
        {this.task.editingPropertyToken === undefined && (
            <React.Fragment>
              {this.task.output.loading ? (
                <Loading fullscreen={false} />
              ) : (
                <React.Fragment>
                  <Grid justify="flex-end" container>
                    <Grid item>
                      <div className={this.props.classes.emailToolbar}>
                        {this.task.selectedPropertyToken && (
                          <div className={this.props.classes.emailPathLink}>
                            <Button
                              className={this.props.classes.margin}
                              onClick={this.props.onClickEditPath}
                              variant={"text"}
                            >
                              <LinkIcon />
                            </Button>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="pdf-attachment"
                        type="text"
                        inputRef={this.attachmentRef}
                        onDrop={this.props.onDrop}
                        label="PDF attachment"
                        onChange={(e) =>
                          (this.task.pdfAttachment.content = e.target.value)
                        }
                        onSelect={(_) =>
                          (this.task.pdfAttachment.cursorLocation =
                            this.attachmentRef.current &&
                            (this.attachmentRef.current
                              .selectionStart as number))
                        }
                        onFocus={(_) =>
                          (this.task.activeTextInput = this.task.pdfAttachment)
                        }
                        error={!this.task.pdfAttachment.content}
                        placeholder="pdf as base64"
                        fullWidth
                        value={this.task.pdfAttachment.content}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        id="select-attachment-location"
                        fullWidth={true}
                      >
                        <InputLabel>Attachment Location</InputLabel>
                        <Select
                          id="attachment-location-select"
                          value={
                            this.props.context.tasks[this.props.task]
                              .pdfAttachmentLocation
                          }
                          onChange={this.setAttachmentLocation}
                        >
                          {Object.keys(AttachmentLocation)
                            .filter((key) =>
                              isNaN(
                                Number((AttachmentLocation as any)[key as any])
                              )
                            )
                            .map((key) => (
                              <MenuItem key={key} value={key}>
                                {AttachmentLocationHelpers.GetDisplayName(
                                  (AttachmentLocation as any)[
                                    (AttachmentLocation as any)[key as any]
                                  ]
                                )}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={this.props.classes.marginLeft}
                            onChange={() => {
                              this.task.isRawHtml = !this.task.isRawHtml;
                              this.task.output.modified = true;
                            }}
                            checked={this.task.isRawHtml}
                            id="raw-html"
                          />
                        }
                        label="Raw HTML"
                      />
                    </Grid>
                  </Grid>
                  <div onDrop={this.props.onDrop}>
                    <>
                      {this.task.isRawHtml ? (
                        <AceEditor
                          onChange={(code) => {
                            this.task.rawHtmlPdfBody.content = code;
                            this.task.output.modified = true;
                          }}
                          value={this.task.rawHtmlPdfBody.content}
                          mode="html"
                          showGutter={true}
                          onFocus={(_) =>
                            (this.task.activeTextInput = this.task.rawHtmlPdfBody)
                          }
                          onCursorChange={(e) =>
                            (this.task.rawHtmlPdfBody.cursorLocation = new CursorLocation(
                              e.getCursor().row,
                              e.getCursor().column
                            ))
                          }
                          width="100%"
                          highlightActiveLine={true}
                          fontSize={20}
                          theme="tomorrow"
                          name="input-editor"
                          setOptions={{
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 4,
                          }}
                        />
                      ) : (
                        <QuillWrapper
                          id="pdf-body"
                          setRef={(ref) =>
                            (this.task.pdfBody.editorRef = ref as ReactQuill)
                          }
                          style={{ height: "446px" }}
                          className={this.props.classes.marginTop}
                          defaultValue={this.task.pdfBody.content}
                          onChange={(code) =>
                            (this.task.pdfBody.content = code)
                          }
                          onFocus={() =>
                            (this.task.activeTextInput = this.task.pdfBody)
                          }
                          onChangeSelection={(range) => {
                            range !== null &&
                              !isNaN(range.index) &&
                              (this.task.pdfBody.cursorLocation = range.index);
                          }}
                        />
                      )}
                    </>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </Grid>
    );
  }
}
