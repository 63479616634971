import { observable, autorun } from "mobx";
import IEndpointVersion from "../../Common/Interfaces/IEndpointVersion";
import BaseContext from "../../Common/BaseContext";
import AppContext from "../../App.Context";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import SchemaService from "../../Common/Services/Schema.Service";
import IValidationResult from "../../Common/Interfaces/IValidationResult";

export default class EndpointInputTesterContext extends BaseContext {
    constructor(
        appContext: AppContext, 
        endpoint: IEndpoint,
        input: string,
        version: number) {
        super(appContext);
        this.input = input;
        this.endpointVersion = endpoint.versions.find(v => v.version === version);
    }

    @observable
    public input: string = '';

    @observable
    public endpointVersion?: IEndpointVersion;

    @observable
    public viewingMode: 'input' | 'validation' = 'input';

    @observable
    public loading: boolean = false;

    @observable 
    public validationResult?: IValidationResult;

    public updateInput = autorun(
        () => {
            if (this.endpointVersion && this.input) {
                SchemaService.Validate(this.AppContext.managementHost as string, this.endpointVersion, this.input)
                    .then(result => this.validationResult = {
                        valid: result.valid,
                        messages: result.errors.map((e) => {
                            return {
                                location: e.property ? e.property : 'Property',
                                severity: 'error',
                                message: e.message
                            }
                        }),
                    })
                    .finally(() => this.loading = false)
            }
        }
    );
}