import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import dayjs from "dayjs";

interface IProps {
    date: Date
}

@observer
export default class MagicFriendlyDate extends React.Component<IProps> {
    private _context = new MagicFriendlyDateContext(this.props.date);

    public render() {
        return this._context.text;
    }
}

class MagicFriendlyDateContext {
    constructor(date: Date) {
        this.text = this.getDate(date);
        setInterval(() => {
            this.text = this.getDate(date);
        }, 5000);
    }

    @observable
    public text: string = '';

    private getDate(date: Date) {
        if (date) {
            return dayjs(date).format('DD/MM/YYYY, HH:mm')
        } else {
            return 'Never';
        }
    }
}