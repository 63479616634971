import { Ability, AbilityBuilder } from "@casl/ability";
import { Profile } from "oidc-client";
import { getAbilityFromString } from "./AbilityHelpers";
import { AppAbility } from "./AppAbilities";

export function defineAbilitiesFor(profile: Profile, permissionsClaimType: string): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(Ability);

  if (profile) {
    let permissions = profile[permissionsClaimType];
    if (Array.isArray(permissions)) {
      permissions.forEach((p) => {
        let ability = getAbilityFromString(p);
        can(ability[0], ability[1]);
      });
    } else if (permissions !== undefined) {
      let ability = getAbilityFromString(permissions);
      can(ability[0], ability[1]);
    }
  }
  return build();
}

export function setAllPermissions(): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(Ability);
  let actions = ["list", "view", "edit", "delete", "create"];
  let subjects = [
    "Endpoint",
    "Secret",
    "Variable",
    "Job",
    "Repository",
    "Retriever",
    "Usagedata",
    "Transactional",
  ];
  for (var i in actions) {
    for (var j in subjects) {
      can(
        actions[i] as "list" | "create" | "view" | "edit",
        subjects[j] as
          | "Endpoint"
          | "Secret"
          | "Variable"
          | "Job"
          | "Repository"
          | "Retriever"
          | "Usagedata"
          | "Transactional"
      );
    }
  }
  return build();
}
