import ApolloClient from "apollo-client";
import { NormalizedCacheObject, InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { concat } from 'apollo-link';

export default class ApolloClientFactory {
    public static Get(managementHost: string, authToken: string | null): ApolloClient<NormalizedCacheObject> {
        return new ApolloClient({
            link: concat(
                new RetryLink({ attempts : { max : Infinity } }),
                new HttpLink({ uri: `${managementHost}/graphql`, headers: { 
                    'Authorization': `Bearer ${authToken}`}}), ),
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                  fetchPolicy: 'no-cache',
                },
                query: {
                  fetchPolicy: 'no-cache',
                },
            }
        });
    }
}