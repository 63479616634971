import { observable } from "mobx";
import IEndpoint from "../../Common/Interfaces/IEndpoint";
import TaskContext from "../EndpointWizard/Context/Task.Context";
import ISecret from "../../Common/Interfaces/ISecret";
import SecretsService from "../Secrets/Secrets.Service";
import AppContext from "../../App.Context";

export default class TasksContext {
    constructor(activeTask: TaskContext | undefined, appContext: AppContext) {
        this.activeTask = activeTask;
        this.getSecrets(appContext);
    }

    @observable
    public activeTask?: TaskContext;

    @observable
    public loading: boolean = false;

    @observable
    public endpoints: IEndpoint[] = [];

    @observable
    public secrets: ISecret[] = [];

    @observable
    public showDialog: boolean = false;

    @observable
    public dependentTasks: number = 0;

    @observable
    public deletionTask: number = 0;


    public handleDeleteDependent: () => void = () => {};
    public handleDeleteReferences: () => void = () => {};
    public handleCloseDelete: () => void = () => {};

    public getSecrets(appContext: AppContext) {
        new SecretsService().Get(appContext)
            .then(secrets => this.secrets = secrets)
            .finally(() => this.loading = false);
    }
}