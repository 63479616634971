import { FormState, FieldState } from 'formstate';
import { FormEvent } from "react";
import { AccountService } from "./Account.Service";
import AppContext from "../../App.Context";
import BaseContext from "../../Common/BaseContext";
import { observable } from 'mobx';
import FormatValidator from '../../Common/Helpers/FormatValidator';

export default class ResetContext extends BaseContext {
    constructor(appContext: AppContext, params: any) {
        super(appContext);
        if (params && params.length === 3) {
            this.providePasswordMode = true;
            this.resetParams = { email: params[1], code: params[2]};
        }
    }

    public email = new FieldState('').validators((val) => !val && 'Email required');

    public password = new FieldState('')
        .validators(val => !FormatValidator.password(val) && 'Passwords must be at least 8 characters and contain at 3 of 4 of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)');

    public confirmPassword = new FieldState('')
        .validators(val => val !== this.password.value && "Password and confimation must match");

    form = new FormState({
        email: this.email,
    });

    resetForm = new FormState({
        password: this.password,
        confirmPassword: this.confirmPassword
    });

    @observable
    public redirect: boolean = false;

    @observable
    public loading: boolean = false;

    @observable
    public providePasswordMode: boolean = false;

    @observable
    public resetParams?: IResetParams;
  
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if (this.providePasswordMode) {
            const validationResult = await this.resetForm.validate();

            if (validationResult.hasError) {
                return false;
            }

            this.loading = true;
            await AccountService.ResetPassword(
                this.AppContext.managementHost as string, 
                (this.resetParams as IResetParams).email,
                validationResult.value.password.value,
                (this.resetParams as IResetParams).code)
                .then(() => {
                    this.loading = false;
                    this.redirect = true;
                })
        } else {
            const validationResult = await this.form.validate();

            if (validationResult.hasError) {
                return false;
            }

            this.loading = true;
            await AccountService.ForgotPassword(
                this.AppContext.managementHost as string, 
                validationResult.value.email.value)
                .then(() => {
                    this.loading = false;
                    this.redirect = true;
                })
        }
    };
}

interface IResetParams {
    email: string;
    code: string;
}